

<app-layout [header]="'WebsiteDashboard.Label.MyDay' | texttransform" >
  <div class="dashboardMyDay">
    <div class="dashboardMyDay__inner">
    <div class="dashboardMyDay__tabs">
        <!-- <app-nav-tab></app-nav-tab> -->
      <app-tab svg="calendar-dots" [active]="page == 0" [label]=" 'Dashboard.Label.AllAppointments' | texttransform" (onClick)="page = 0"></app-tab>
      <app-tab svg="calendar-dots" [active]="page == 1" [label]=" 'Dashboard.Label.AISuggestions' | texttransform" (onClick)="page = 1" *ngIf="aiContacts.length > 0"></app-tab>
  </div>
  <div *ngIf="page === 0" class="dashboardMyDay__page">
    <div class="websiteDashboard__page__header">
        <h2>{{ 'General.Label.FollowUps' | texttransform }}</h2>
        <!-- <h2>{{'WebsiteDashboard.Label.Termine' | texttransform }}</h2> -->
        <app-select-input [(ngModel)]="myDayContentFilter" [options]="followUpOptions" bind="id"></app-select-input>
    </div>

    <div class="dashboardMyDay__page__content">

        <div *ngIf="noData" class="dashboardMyDay__page__content__infoLabel">
            <div >
                {{ 'General.Label.NoDataAvailable' | texttransform }}<br>
                <br>
            </div>
        </div>

        <div *ngIf="loading" class="dashboardMyDay__page__content__infoLabel">
            <span class="loadingDataSpinner">
                <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </span>
        </div> 


        <app-mydayappointment 
            [parentHasAi]="checkIfArrayHasAi(myFollowUpsToday)" 
            [followUp]="followUp" 
            *ngFor="let followUp of myFollowUpsToday; let rowIndex = index"  
            [ngClass]="{ hideInDom: myDayContentFilter != '2' && myDayContentFilter != '1' }"
            class="fingerCursor" 
            [isOdd]="commonService.isOdd(rowIndex)"
            (click)="loadFollowUp(followUp)">
        </app-mydayappointment>
        <app-mydayappointment 
            [parentHasAi]="checkIfArrayHasAi(myFollowUpsPast)" 
            [followUp]="followUp" 
            *ngFor="let followUp of myFollowUpsPast; let rowIndex = index" 
            [ngClass]="{ hideInDom: myDayContentFilter != '4' && myDayContentFilter != '1' }"
            class="fingerCursor" 
            [isOdd]="commonService.isOdd(rowIndex)"
            (click)="loadFollowUp(followUp)">
        </app-mydayappointment>
        <app-mydayappointment 
            [parentHasAi]="checkIfArrayHasAi(myFollowUpsFuture)" 
            [followUp]="followUp" 
            *ngFor="let followUp of myFollowUpsFuture; let rowIndex = index" 
            [ngClass]="{ hideInDom: myDayContentFilter != '3' && myDayContentFilter != '1' }"
            class="fingerCursor" 
            [isOdd]="commonService.isOdd(rowIndex)"
            (click)="loadFollowUp(followUp)">
        </app-mydayappointment>
    </div>
</div>

<div *ngIf="page === 1" class="dashboardMyDay__page">
    <div class="dashboardMyDay__page__header">
        <h2>{{ 'Dashboard.Label.AISuggestions' | texttransform}} </h2>
        <!-- <app-select-input [(ngModel)]="myDayContentFilter" [options]="[{id:0, name:'todo'}]" bind="id"></app-select-input> -->
    </div>
    <div class="dashboardMyDay__page__content">
        <app-mytopcontact [parentHasAI]="true" [isOdd]="commonService.isOdd(rowIndex)"
            *ngFor="let aiContact of aiContacts; let rowIndex = index" 
            [contact]="aiContact" 
            (click)="loadContact(aiContact.id)">
        </app-mytopcontact>
    </div>
</div>
</div>
<div class="footer" *ngIf="applicationInfoService?.applicationSettings['showStartWorkButton']=='true'">
  <app-main-button svg="rocket" 
    (onClick)="startWorking()" [label]="'Button.WelcomePage.StartWork' | texttransform"></app-main-button>
</div>
</div>
</app-layout>



import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { trigger, transition, style, animate } from '@angular/animations'
import { Subscription } from 'rxjs';


export type Severity= 'error' | 'info' | 'warn' | 'success';


export interface ToastObject{
  severity:Severity;
  summary: string;
  sticky?: any;
  detail:string | any; 
  lightColor?:string;
  color?:string;
  timeoutId?:any;
  id?: number;
}


@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrl: './toasts.component.scss',
  animations:[
    trigger('toastAnimation', [
      transition(':enter', [
        style({transform:'translateX(100%)', opacity:0}),
        animate('300ms ease-out', style({transform:'translateX(0)', opacity:1}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform:'translateX(100%)', opacity:0}))
      ])
    ])

  ]
})
export class ToastsComponent implements OnInit, OnDestroy{

  constructor(
    private eventService: EventService
  ){}

  messageSubscription = new Subscription();
 toastArray=[]




  ngOnInit(){
    this.messageSubscription = this.eventService.toasterEvent.subscribe((event) =>{
      // * the event is the toast object;
      this.addToast(event)
    })

    // ! for testing purposes
 /*    this.addToast(  { severity:'error', summary:'Attention', detail:' this is a description of a error, its actuall a very very long message from the api its super hiper long message',sticky:true })
    this.addToast(  { severity:'error', summary:'Attention',sticky:true })
    this.addToast(  {severity:'info', summary:'Info', detail:' this is a description of Info', sticky:false})
    this.addToast(  {severity:'success', summary:'Submited', detail:' this is a description of Success' ,sticky:false })
    this.addToast(  {severity:'warn', summary:'Failure', detail:' this is a description of an ERROR', sticky:false})
    this.addToast(  { severity:'error', summary:'Attention',sticky:false })
    this.addToast(  {severity:'info', summary:'Info', detail:' this is a description of Info', sticky:false})
    this.addToast(  {severity:'success', summary:'Submited', detail:' this is a description of Success' ,sticky:false })
    this.addToast(  {severity:'warn', summary:'Failure', detail:' this is a description of an ERROR', sticky:false})
    this.addToast(  { severity:'error', summary:'Attention', detail:' this is a description of a error, its actuall a very very long message from the api its super hiper long message',sticky:false })
    this.addToast(  {severity:'info', summary:'Info', detail:' this is a description of Info', sticky:false})
    this.addToast(  {severity:'success', summary:'Submited',sticky:false })
    this.addToast(  {severity:'warn', summary:'Failure', sticky:false}) */
  }

  addToast(event){
    let toast = {
      ...event,
      ...this.getColor(event.severity), 
      id: this.toastArray.length,
      sticky: event.sticky ? event.sticky : false, 
    }
    this.toastArray.push(toast)

    if(toast.sticky === false){
      toast.timeoutId = setTimeout(() =>{
        this.deleteToast(toast);
      }, 5000);


    }

  };


  deleteToast(toast){
    if(toast.timeoutId){
      clearTimeout(toast.timeoutId);
    }
    this.toastArray = this.toastArray.filter((item) => item.id !== toast.id);
  };


  activateToast(toast){
    if(toast.sticky === true){
      return
    } else {
      clearTimeout(toast.timeoutId);
      toast.timeoutId = null;
    }
  }


  deactivateToast(toast){
    if(!toast.sticky){
      toast.timeoutId = setTimeout(() =>{
        this.deleteToast(toast);
      },1000);
  }
}

// todo add to HTML container__inner (mouseenter) if all toast
activateAllToasts(){
  this.toastArray.map((toast) =>{
    if(!toast.sticky && toast.timeoutId){
      clearTimeout(toast.timeoutId);
      toast.timeoutId = null;
    }
  } )
}

// todo add to HTML container__inner (mouseleave) if all toast
deactivateAllToasts(){
  this.toastArray.map((toast) =>{
    if(!toast.sticky){
      if(toast.timeoutId){
        clearTimeout(toast.timeoutId)
      } else {
        toast.timeoutId = setTimeout(() =>{
          this.deleteToast(toast)
        },1000);
      }
    }
  })
}

  getEllipsis(string){

    if(!string){
      return
    }else {
      return  string?.length >= 90 ? string.slice(0, 90) + '...' : string
    }
  }


  getColor(type){
    switch(type){
      case 'info':
        return { lightColor:'#BFD7FF', color: '#5465FF'};
      case 'warn':
        return { lightColor: '#FFFBE6', color: '#FFD600'};
      case 'error':
        return { lightColor: '#FFCBCB', color:'#FF5050'};
      case 'success':
        return { lightColor:'#ECFCEF', color: '#3FE163'};
        default:
        return
    }
  }


  getIcon(severity){
    if(severity !== 'error'){
      return 'info-bold'
    } else {
      return 'warning-bold'
    }
  }



  getStyleForToast(toast){
    return { 
      'backgroundColor': toast.lightColor,
      'borderRight': '5px solid' + toast.color 
    }
  }


  ngOnDestroy(){
    if(this.messageSubscription){
      this.messageSubscription.unsubscribe();
    }
  }

}

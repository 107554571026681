import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { QuestionaireControlService } from '../questionaire-control.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-questionaire-designer-control',
  templateUrl: './questionaire-designer-control.component.html',
  styleUrls: ['./questionaire-designer-control.component.scss']
})
export class QuestionaireDesignerControlComponent implements OnInit {
  showOnlyActive = true;
  filterString = '';
  questionaireTargets = [
    { id: 1, defaultName: 'Account.Label.Header' },
    { id: 4, defaultName: 'Contact.Label.Header' }
  ];
  newQuestionaire = false;
  newQuestionaireObject = null;

  constructor(
    public questionaireControlService: QuestionaireControlService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.questionaireControlService.getQuestionaires();
  }

  selectQuestionaire(questionaire) {
    this.questionaireControlService.selectedQuestionaire = questionaire;
    this.questionaireControlService.getQuestionsById(this.questionaireControlService.questionaireLookupId, questionaire.id).then(getQuestionsByIdResult => {
      this.questionaireControlService.selectedQuestionaire.questions = getQuestionsByIdResult;      
    });
  }

  addFreeTextQuestion() {
    this.addQuestion().then(addFreeTextQuestionResult => {
      this.questionaireControlService.addAnswer({ id: addFreeTextQuestionResult.id, answers: [] }, true).then(() => {
        this.selectQuestionaire(this.questionaireControlService.selectedQuestionaire);
        this.eventService.customEvent.emit({ id: 'refreshQuestionaireControl' });
      });
    });
  }

  addQuestion(): Promise<any> {
    return new Promise((addQuestionResolve, addQuestionReject) => {
      this.questionaireControlService.addQuestion().then(addQuestionResult => {       
        // this.questionaireControlService.selectedQuestionaire.questions.push(addQuestionResult);      
        // console.warn(this.questionaireControlService.selectedQuestionaire.questions);
        addQuestionResolve(addQuestionResult);
        this.eventService.customEvent.emit({ id: 'refreshQuestionaireControl' });
        this.questionaireControlService.getQuestionsById(this.questionaireControlService.questionaireLookupId, 
          this.questionaireControlService.selectedQuestionaire.id).then(getQuestionsByIdResult => {
          this.questionaireControlService.selectedQuestionaire.questions = getQuestionsByIdResult;      
        });  
      });
    });
    
  }

  moveInList(event: CdkDragDrop<string[]>, item: any) {
    console.warn(event, event.container.data, event.previousIndex, event.currentIndex);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  toggleQuestionnaire(questionnaire) {
    questionnaire.isActive = !questionnaire.isActive;
    this.questionaireControlService.updateQuestionaire(questionnaire);
  }

  isItemFiltered(questionaire) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(questionaire.defaultName, this.filterString)) {
      returnValue = false;
    }
  }

  prepareNewQuestionaire() {
    this.newQuestionaireObject = {
      defaultName: '',
      entityId: null,
      projectId: this.applicationInfoService.projectID
    };
    this.newQuestionaire = true;
    setTimeout(() => {
      const element = document.getElementById('newQuestionnaireLabel');
      if (element) { element.focus(); }  
    }, 300);
  }
  
  createNewQuestionaire() {
    this.questionaireControlService.createQuestionaire(this.newQuestionaireObject).then(createdQuestionaire => { 
      this.newQuestionaire = false; 
      this.selectQuestionaire(createdQuestionaire);
      this.eventService.customEvent.emit({ id: 'refreshQuestionaireControl' });
    });
  }
}

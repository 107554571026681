import { Injectable } from '@angular/core';
import { LoaderService } from 'app/shared/service/loader-service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { Router } from '@angular/router';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternalContentService } from './external-content.service';
import { SettingsService, Setting } from 'app/shared/service/settings.service';
import { TranslationLanguage } from 'app/shared/translation/translationLanguage';
import { LanguageService } from 'app/shared/translation/language.service';
import { EventService } from './event.service';
import { ProjectService } from './project.service';
import { SessionStorageService } from 'ngx-webstorage';
import { MethodService, InternalMethodType } from './method.service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { MiddlewareSignalrService } from '../signalr/middleware-signalr-service.service';
import { RefreshValueService } from '../signalr/refresh-value.service';
import { isNullOrUndefined } from 'util';
import { environment } from 'environments/environment';
import { RingcentralService } from './ringcentral.service';
import { ChatbotService } from './chatbot.service';
import { WebpushService } from './webpush.service';
import { UiService } from './ui.service';
import { CommonService } from './common.service';
import { ApolloMethod, GraphQLService } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from './graphqlquery.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { TrackingService } from './tracking.service';
import { RingcentralwebphoneService } from './ringcentralwebphone.service';
import { PackagefeatureService } from './packagefeature.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationinitService {
  initRetries = 0;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private userService: UserService,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private eventService: EventService,
    private sessionStorage: SessionStorageService,
    private methodService: MethodService,
    private refreshValueService: RefreshValueService,
    private middlewareSignalrService: MiddlewareSignalrService,
    private externaldatasourceService: ExternaldatasourceService,
    private projectService: ProjectService,
    private ringcentralService: RingcentralService,
    private ringcentralwebphoneService: RingcentralwebphoneService,
    private externalContentService: ExternalContentService,
    private applicationInfoService: ApplicationInfoService,
    private webpushService: WebpushService,
    private uiService: UiService,
    private commonService: CommonService,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private axivasTranslateService: AxivasTranslateService,
    private sharedAPI: SharedAPI,
    private trackingService: TrackingService,
    private packagefeatureService: PackagefeatureService
  ) { }

  initWebpush() {
    this.webpushService.initConnection();
  }

  initSoftphone() {
    if (this.applicationInfoService.phoneConnection == this.applicationInfoService.phoneRingCentral) {
      this.ringcentralService.initConnection().catch(() => {});
    } else {
      this.middlewareSignalrService.InitService();
    }
  }
  
  redirectToSettings() {    
    this.methodService.launchInternalMethod(InternalMethodType.ShowSettingPage, [Number(localStorage.getItem('oneTimeShowSettingPage'))]);
    localStorage.removeItem('oneTimeShowSettingPage');
  }



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // New
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  startApplication() {    
    this.applicationInfoService.showLoadProjectSpinner = true;    
    this.userService.getJollyJupiterUserInformation()
    .then(userInfo => {
      this.applicationInfoService.loadingProjectPercentage = 0;
      const promiseArray: Promise<any>[] = [];      
      this.externalContentService.addStyleFromDataBase([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 42])
      .then(() => {
        this.applicationInfoService.loadingProjectPercentage = 10;
        this.applicationInfoService.cssInitialized = true;
        promiseArray.push(this.refreshValueService.getPrereqs());            
        Promise.all(promiseArray).then(() => {
          this.applicationInfoService.loadingProjectPercentage = 20;
          this.refreshValueService.refreshGlobalTranslations();
          if (this.applicationInfoService.noProject == false) {         
            this.redirectApplication();               
          }  
        });  
      });
    })
  }

  loadProject(projectId): Promise<any> {    
    // this.applicationInfoService.loadingProjectPercentage = 0;
    return new Promise((loadProjectResolve, loadProjectReject) => {
      this.refreshValueService.clearProjectValues();      
      this.applicationInfoService.projectLoaded = false;
      this.axivasTranslateService.translationTextArray = null;
      this.applicationInfoService.splashScreenLoadingText = 'Loading Project...';
      this.applicationInfoService.applicationInitialized = true;  
      this.applicationInfoService.projectSelected = true;
      console.warn('Loading project', new Date(Date.now()).toLocaleTimeString());      
      this.applicationInfoService.getCompleteProjectInformatonLoaded = false;      
      this.externaldatasourceService.executeExternalDataSource(925, [projectId])
      .then(loadProjectResult => {
        this.applicationInfoService.loadingProjectPercentage = 60;
        if (loadProjectResult.phoneSystemLookupId != null) {
          this.applicationInfoService.phoneConnection = loadProjectResult.phoneSystemLookupId;
        }
        this.applicationInfoService.projectID = loadProjectResult.id;
        this.applicationInfoService.projectName = loadProjectResult.projectName;
        this.applicationInfoService.projectInfo = loadProjectResult;
        this.applicationInfoService.currentProject = loadProjectResult;

        // Backend API
        if (!this.commonService.isNullOrUndefined(loadProjectResult.dataInstance)) {
          if (loadProjectResult.dataInstance.backendUrlResource == null) {
            this.applicationInfoService.apiBackendUrl = environment.apiBackend;
          } else {
            this.applicationInfoService.apiBackendUrl = loadProjectResult.dataInstance.backendUrlResource[environment.dataStage];
            if (!this.commonService.endsWidth(this.applicationInfoService.apiBackendUrl, 'api')) {
              this.applicationInfoService.apiBackendUrl= this.applicationInfoService.apiBackendUrl.concat('/api');
            }
          }  
        } else {
          this.applicationInfoService.apiBackendUrl = environment.apiBackend;
        } 

        //this.projectService.initializeProjectDataEndpointConsumeData(loadProjectResult.id)
        this.projectService.initializeProjectDataEndpoint(loadProjectResult);
        //this.refreshValueService.getProjectFollowUpGroups().then(() => {
          this.userService.getUserRoles().then(() => {
          this.getProjectSettings().then(() => {
            this.applicationInfoService.loadingProjectPercentage = 70;         
            setTimeout(() => {
              this.applicationInfoService.loadingProjectPercentage = 80;
            }, 1000)            
            if (this.packagefeatureService.allFeatures.length == 0) {
              this.packagefeatureService.initService();
            }     
            let languageId = 2;
            if (this.applicationInfoService.userSettings['LanguageIdentifier'] == 'en') { languageId = 1; }
            this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
            this.graphqlqueryService.loadProjectDetails, [languageId])
            .then(getCompleteProjectInformatonResult => {    
              this.packagefeatureService.getProjectFeatures();
              this.applicationInfoService.tipps = getCompleteProjectInformatonResult.data.applicationTipps;
              console.warn('Project loaded', new Date(Date.now()).toLocaleTimeString());            
              this.applicationInfoService.preloadedControlInfo = getCompleteProjectInformatonResult.data.uiContainerArrayUiControls;
              this.setProjectEnvironment(getCompleteProjectInformatonResult);            
              this.externalContentService.setProjectCSS(loadProjectResult.uiStyle.style);          
              this.applicationInfoService.projectLoaded = true;
              this.applicationInfoService.showLoadProjectSpinner = false;          
              this.applicationInfoService.loadingProjectPercentage = 100;
              this.addProjectDetails();     
              this.loadProjectAftermath();              
              if (this.applicationInfoService.userSettings['useRingCentralWebRTC'] == true) {
                this.applicationInfoService.phoneConnection = this.applicationInfoService.phoneRingCentralWebRTC;
              }              
              loadProjectResolve(loadProjectResult);
              setTimeout(() => { 
                this.refreshValueService.getLookupsForProject().then(() => {
                  this.eventService.customEvent.emit({ id: 'lookupsLoaded' });
                  this.ringcentralwebphoneService.initSoftphone();
                });                
                this.axivasTranslateService.translationTextArray = [];               
              }, 200);
              setTimeout(() => { this.refreshValueService.getCompleteProjectInformaton().then(()=> {
                if (!this.commonService.isNullOrUndefined(this.applicationInfoService.securityRoles)) {
                  const userRole = this.applicationInfoService.securityRoles.find(item => item.id == this.applicationInfoService.currentUserRoleId);                
                  if (this.applicationInfoService.projectInfo.isLoadingTaskOnLoginForAgent 
                      && userRole?.permissionLevel == 1
                      && userRole?.isSupervisor == false
                      && userRole?.isAdmin == false
                  ) {
                    this.methodService.launchInternalMethod(InternalMethodType.StartWorking, []);
                  }  
                }

                // First steps
                if (this.applicationInfoService.projectInfo.packageId != 4 && 
                    this.applicationInfoService.userSettings['showFirstSteps'.concat(this.applicationInfoService.projectID.toString())] != true) {
                  if (this.applicationInfoService.applicationSettings['showFirstStepsBanner'] == true) {
                    this.applicationInfoService.showBanner = true;
                  } else {
                    setTimeout(() => {
                      if (this.userService.hasPermission('CanUseIsSupervisor')) {
                        this.packagefeatureService.showFeatureInfoPopup(Number(this.applicationInfoService.applicationSettings['showFirstStepsFeatureId']));
                      } else {
                        this.packagefeatureService.showFeatureInfoPopup(Number(this.applicationInfoService.applicationSettings['showFirstStepsAgentFeatureId']));
                      } 
                      this.applicationInfoService.userSettings['showFirstSteps'.concat(this.applicationInfoService.projectID.toString())] = true;
                      this.settingsService.updateSecurityUserSettingsJson();  
                    }, 2000);
                  }
                }

                this.checkDemoLandingPageVisit();
                this.applicationInfoService.getCompleteProjectInformatonLoaded = true;
                this.eventService.customEvent.emit('getCompleteProjectInformaton');          
                const lastNavigation = this.commonService.getSessionStorageItem('LastNavigation');
                const lastNavigationContainerId = this.commonService.getSessionStorageItem('LastNavigationContainerId');                  
              }); }, 100);          
            }); 
          });                 
          });                 
        //});                 
      })
      .catch(error => {
        loadProjectReject(error);
      });  
    });
  }

  loadProjectAftermath() {
    // this.sharedAPI.getBoldBiTimeStamp().subscribe(result => {
    //   this.applicationInfoService.lastBoldBiSync = result.result;            
    // });
    this.applicationInfoService.menuInitId = '1200';
    this.eventService.navigationInitialized('1200');
    this.projectService.checkVideoAndBanner();
    this.trackingService.startTracking();
    this.eventService.updateManuBar('mainmenu', '1000');
  }

  addProjectDetails() {
    const project = this.applicationInfoService.projectInfo;
    this.sessionStorage.store('lastproject', project.id);
    localStorage.setItem('lastProject', project.id);
    if (project.pools.length > 0) {
      project.pools.sort((a, b) => a.id < b.id ? -1 : 1);
      project.pools.forEach(poolItem => {
        this.applicationInfoService.pools.Add(poolItem.id, poolItem);
      });

      if (!this.applicationInfoService.blockSettingChange && !this.applicationInfoService.userSettingsPoolSelected) {
        this.applicationInfoService.poolId = this.applicationInfoService.projectInfo.pools[0].id;    
        const lastSelectedPool = this.commonService.getSessionStorageItem('lastSelectedPool');       
        if (!this.commonService.isNullOrUndefined(lastSelectedPool)) {
          this.applicationInfoService.projectInfo.pools.forEach(pool => {
            if (pool.id == Number(lastSelectedPool)) {
              this.applicationInfoService.poolId = pool.id;
            }
          });
        } 
      } 
    } else {
      this.applicationInfoService.poolId = 0;
    }
  }

  checkDemoLandingPageVisit() {
    this.applicationInfoService.demoLandingPageVisited = true;  
    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true') {      
      if (this.applicationInfoService.userSettings['demoLandingPageVisited' + this.applicationInfoService.projectID] != true) {
        this.applicationInfoService.demoLandingPageVisited = false;
        this.applicationInfoService.demoLandingPageNotVisitedInfo = true;        
        this.eventService.customEvent.emit({ id: 'showDemoLandingPageNotVisitedInfo' });
      } 
    }
  }

  redirectApplication() {
    let startNormal = true;

    if (this.sessionStorage.retrieve('mailAcceptValuesCreated') === 'true') {
      this.router.navigate(['../' + '/mailaccept']);
      startNormal = false;
    }
    if (this.sessionStorage.retrieve('trialextensionValuesCreated') === 'true') {
      this.router.navigate(['../' + '/trialextension']);
      startNormal = false;
    }

    if (this.sessionStorage.retrieve('redirectValuesCreated') === 'true') {
      this.applicationInfoService.projectID = this.sessionStorage.retrieve('redirectprojectid');
      this.loadProject(this.applicationInfoService.projectID)
      .then(() => {       
        this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/account']);  
      });
      startNormal = false;
    } 

    if (startNormal) {
      if (this.applicationInfoService.noProject) { return; }      
      let projectId = null;
      let startupInfo = localStorage.getItem('locationStartInfo');
      if (this.commonService.isNullOrUndefined(startupInfo)) {
          projectId = this.projectService.getLastProject();        
      } else {
        const startupInfoDetails = JSON.parse(startupInfo);
        projectId = startupInfoDetails.pid;          
      }
      if (this.commonService.isNullOrUndefined(projectId)) {
        this.applicationInfoService.showLoadProjectSpinner = false;
        this.applicationInfoService.projectLoaded = true;
        this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '700px;80', false);        
      } else {
        this.loadProject(projectId).then(() => {
          if (localStorage.getItem('oneTimeShowSettingPage')) {
            this.redirectToSettings();          
          }           
        }).catch(() => {
          this.applicationInfoService.showLoadProjectSpinner = false;
          this.applicationInfoService.projectLoaded = true;
          this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '700px;80', false);          
        });  
      }
    }
  }

  setProjectEnvironment(getCompleteProjectInformatonResult) {
    // Controls
    this.uiService.consumeControls(getCompleteProjectInformatonResult);

    // Project translations
    this.axivasTranslateService.translationTextArrayProject = getCompleteProjectInformatonResult.data.translationsByProjectId;    
    // Application settings
    this.applicationInfoService.applicationSettings = [];
    getCompleteProjectInformatonResult.data.applicationSettings.forEach(applicationSetting => {
      this.applicationInfoService.applicationSettings[applicationSetting.setting] = applicationSetting.value;
    });
    // Usersettings 
    this.settingsService.userSettings = [];
    this.settingsService.consumeUserSettings(getCompleteProjectInformatonResult.data.userSettings);
  }

  getProjectSettings(): Promise<any> {
    return new Promise((getProjectSettingsResolve, getProjectSettingsReject) => {
      this.externaldatasourceService.executeExternalDataSource(970)
      .then(projectSettingsResult => { 
        this.applicationInfoService.projectSettings = projectSettingsResult;
        getProjectSettingsResolve(projectSettingsResult);
      }).catch(error => { getProjectSettingsReject(error); });
    })
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lookuptables',
  templateUrl: './lookuptables.component.html',
  styleUrls: ['./lookuptables.component.scss']
})
export class LookuptablesComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  lookupTableDefinitions = [];
  lookupTableSource = 'data';
  lookupTableSourceId = 2;
  lookupTableSources = [
    { id: 1, defaultName: 'EntityMemberDesign.Label.GeneralDatabase' },
    { id: 2, defaultName: 'EntityMemberDesign.Label.CustomerDatabase' }
  ]
  filterString = '';
  selectedLookupTableDefinition = null;
  lookups = [];
  showPopup = false;
  newEntryName = '';
  loadingData = true;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.getLookupsBySource();

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getLookupsBySource();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }
  
  getLookupsBySource() {
    this.loadingData = true;
    if (this.userService.hasPermission('CanUpdateLookupTable')) {
      this.lookupTableSource = 'central';
      if (this.lookupTableSourceId == 2) {
        this.lookupTableSource = 'data';
      }
      this.externaldatasourceService.executeExternalDataSource(1005, []).then(getLookupsBySourceResult => {
        this.lookupTableDefinitions = getLookupsBySourceResult.filter(lookupDefinition => 
          lookupDefinition.source == this.lookupTableSource &&
          lookupDefinition.isActive == true
        );
        this.loadingData = false;
      });  
    } else {
      this.loadingData = false;
      this.commonService.showStartPage(true);
    }
  }

  selecteLookupTableDefinition(lookupTableDefinition) {
    this.lookups = lookupTableDefinition.lookups;
    this.selectedLookupTableDefinition = lookupTableDefinition;
  }

  saveLookupTable(lookupTable) {
    let externalDatasourceId = 215;
    if (this.lookupTableSource == 'data') {
      externalDatasourceId = 216;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [
      this.commonService.getModifyArrayBody(lookupTable, [])
    ]);
  }

  createLookupTableDefinition() {
    const lookupTableDefinition = {
      defaultName: this.newEntryName,
      nameTranslationToken: null,
      descriptionTranslationToken: null
    }
    let externalDatasourceId = 213;
    if (this.lookupTableSource == 'data') {
      externalDatasourceId = 212;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [this.commonService.getModifyArrayBody(lookupTableDefinition, [])])
    .then(createLookupTableDefinitionResult => {
      lookupTableDefinition['id'] = createLookupTableDefinitionResult.id;
      lookupTableDefinition['definitionLogicalId'] = createLookupTableDefinitionResult.id;
      lookupTableDefinition['definitionName'] = this.newEntryName;
      lookupTableDefinition['lookups'] = [];
      console.warn(createLookupTableDefinitionResult, lookupTableDefinition)
      this.lookupTableDefinitions.push(lookupTableDefinition);
      this.showPopup = false;
      this.eventService.customEvent.emit({ id: 'refreshLookupTables' });
    });
  }

  createLookupTable() {
    const lookupTable = {
      defaultName: this.newEntryName,
      nameTranslationToken: null,
      descriptionTranslationToken: null,
      definitionId: this.selectedLookupTableDefinition.definitionId,      
      order: this.lookups.length + 1,      
    }
    let externalDatasourceId = 214;
    if (this.lookupTableSource == 'data') {      
      externalDatasourceId = 211;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [this.commonService.getModifyArrayBody(lookupTable, [])]).then(createLookupTableDefinitionResult => {
      lookupTable['id'] = createLookupTableDefinitionResult.id;
      lookupTable['isActive'] = true;
      this.lookups.push(lookupTable);
      this.saveLookupTable(lookupTable);
      this.eventService.customEvent.emit({ id: 'refreshLookupTables' });
      this.showPopup = false;
    });
  }

  getDefinitionName(selectedLookupTableDefinition) {
    if (selectedLookupTableDefinition.nameTranslationToken) {
      return selectedLookupTableDefinition.nameTranslationToken.tokenFullName;
    } else {
      return selectedLookupTableDefinition.definitionName;
    }
  }
}

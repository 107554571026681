import { Component, OnInit } from '@angular/core';
import { EntitytableService } from '../entitytable.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';


interface FilterList{  
  filterId:number ;
  entityId:number | null;
  memberId: number | null;
  member?: any;
  operator:string | null;
  value: any;
  value2?: any;
}

interface GroupFilter{
  filterGroupId:number
  filterList: FilterList[]
}

@Component({
  selector: 'app-entitytable-filter',
  templateUrl: './entitytable-filter.component.html',
  styleUrl: './entitytable-filter.component.scss'
})
export class EntitytableFilterComponent implements OnInit {
  dataTableItem = null;
  filterList:FilterList[] = [{filterId:1, entityId:null, memberId:null, member:null, operator:null, value:null}];

  groupFilter:GroupFilter[] = [{ filterGroupId:1 ,filterList: this.filterList}];
  filterOptions = [];

  selectedFields=[];
  fieldOptions;
  dataLayout;
  field;
  advancedFilter=false;
  selectedGroupId;

  isValid=false
  is_FILTER_OPTIONS_Resolved=false;
  isSavedFilter;
  
  page = 1;
  savedFilters = [];

  entityMembers = [];

  // select options 
  entityOptions=[];
  memberOptions=[];
  availableMembers;
  showSaveFilterPopup = false;
  newFilterName = 'Filter';
  typeOperators = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private entitytableService: EntitytableService,
    private eventService: EventService,
    private settingsService: SettingsService,
    private axivasTranslateService: AxivasTranslateService
  ) {}



  // * INFO in case of operator that makes two fields appear please search for 'BETWEEN in the code

  ngOnInit(): void {
    this.dataTableItem = this.entitytableService.getEntityTableDefinitionForControl(this.applicationInfoService.miscSettings['entityTableLogicalControlId']);
    this.filterOptions = JSON.parse(this.applicationInfoService.applicationSettings['defaultEntityTableFilterOptions']);

    if(this.dataTableItem != null){      
        this.isSavedFilter = false;

        this.dataLayout = JSON.parse(this.dataTableItem.jsonLayout);
        this.availableMembers=this.entitytableService.getAvailableEntities(this.dataTableItem);
          
        this.entityOptions = this.entitytableService.getAvailableEntities(this.dataTableItem).filter((item) =>{
          const find = this.dataLayout.find((entity) => entity.entityId === item.id)
          if(find){
            return true
          } else {
            return false
          }
        });  
      
    }

    // If there was already a filter used in this session, show it
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()])) {
      this.groupFilter = this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()];
    }

    // Check if there are seaved user filters for this entity
    if (!this.commonService.isNullOrUndefined(
      this.applicationInfoService.userSettings['entityTableCustomFilter'.concat(this.dataTableItem.mainEntityId).toString()])
    ) {
      this.savedFilters = JSON.parse(this.applicationInfoService.userSettings['entityTableCustomFilter'.concat(this.dataTableItem.mainEntityId).toString()]);
      this.isSavedFilter = true;
    } else {
      this.savedFilters = [];
    }
  }


  // ! validation of fields are not empty
  checkValidFields(){
    this.isValid = this.groupFilter.every((item)=> item.filterList.every((item) =>{
      if(item.operator === 'BETWEEN'){
        return  item?.entityId !== null &&  item.memberId !== null && item.operator !== null && item.value && item.value !== "" && item.value !== null && item.value2 !== null
      } else {
        return item?.entityId !== null &&  item.memberId !== null && item.operator !== null && item.value && item.value !== "" && item.value !== null
      }
    }))
  }

  // ! entity select
 changeEntity( list, groupIndex, listIndex){
  // * reset all fields
  const { filterId, entityId, ...rest } =list
  for(let key in rest){
     rest[key] = null
  }
  this.groupFilter[groupIndex]['filterList'][listIndex]={ ...rest, filterId:filterId, entityId: entityId } 
}


// ! member select
  checkMemberOptions(id) {
    if (!this.commonService.isNullOrUndefined(this.entityMembers[id])) {
      this.memberOptions = this.entityMembers[id];
      return this.entityMembers[id];
    }

    this.entityMembers[id] = [];
    const entity = this.applicationInfoService.entities.toArray().find(entity => entity.id == id);
    if (entity) {
      const checkArray = this.applicationInfoService.controls.toArray().filter(uicontrol => 
        uicontrol.isActive == true &&
        uicontrol.uiEntityInstanceId == id
      );      
      const tempMembers = JSON.parse(JSON.stringify(entity.entityMembers));
      tempMembers.forEach(member => {        
        const uiControl = checkArray.find(checkArrayItem => checkArrayItem.value == member.defaultName);
        if (uiControl) {
          this.entityMembers[id].push(member);
        }        
      });
      
      this.commonService.newSortArrayWithTranslationToken(this.entityMembers[id]);
      return this.entityMembers[id];
    } else {
      return [];
    }
    return;

    let memberFound = [] ;
    if (id === null) {
      return []
    }    
    let entityId = id === 9 ? 2 : id;

    const find = this.entityOptions.find((item) => item.id === entityId);
    if (!find) {
      return []
    } else {
      this.memberOptions = find.entityMembers.filter((item) =>{
        const foundMember = this.dataLayout.find((layout) => layout.memberId === item.id || layout.memberName === item.defaultName);
        if(foundMember){
          return true
        } else {
          return false
        }
      });
      this.commonService.newSortArrayWithTranslationToken(this.memberOptions);
      return this.memberOptions
    }    
  }

  findMember(list, groupIndex, listIndex){
    const find = this.memberOptions.find(item => item.id === list.memberId);
    const { filterId, entityId, memberId, ...rest }= list

    // * reset fields
    for(let key in rest){
      rest[key]= null
    }
    this.groupFilter[groupIndex]['filterList'][listIndex]= { ...rest, filterId:filterId, entityId:entityId, memberId:memberId, member:find };
  }


  // ! operator select
  changeOperator(list, groupIndex, listIndex){
    // * reset fields

    if(list.operator === 'null' ){
      const { operator, value, value2, ...rest } = list
      this.groupFilter[groupIndex]['filterList'][listIndex]={ ...rest } 
    }else if(list.operator == 'BETWEEN'){
      const { value, value2 , ...rest } = list
    this.groupFilter[groupIndex]['filterList'][listIndex]={ ...rest, value:null, value2:null } 
    } else {
      const { value, ...rest} = list
      this.groupFilter[groupIndex]['filterList'][listIndex]={ ...rest, value:null } 
    }
  }


  // ! ALL BUTTONS section 
  openAdvancedFilter(filterRowId){
    if(this.selectedGroupId === filterRowId){
      this.advancedFilter = !this.advancedFilter;
    }
  }

  addGroup(){
    let groupId = this.groupFilter.length 
    this.groupFilter.push({ filterGroupId:groupId + 1 ,filterList: [{
      filterId:1,
      entityId:null, 
      memberId:null, 
      member:null, 
      operator:null, 
      value:null
    }] });
    this.checkValidFields()
  }


  addParameter(group, index){
    this.groupFilter[index]['filterList'].push(
    {      
      filterId: group.filterList.length + 1, 
      entityId:null, 
      memberId:null, 
      member:null, 
      operator:null, 
      value: null   
    })
    this.checkValidFields()
  }



  deleteParameterRow(groupIndex, listFilter){
    let list = this.groupFilter[groupIndex].filterList.filter((item => item.filterId !== listFilter.filterId))
    this.groupFilter[groupIndex].filterList=list.map((item,i) =>{
      return{
        ...item,
        filterId: i + 1
      }
    });

    this.checkValidFields()

  }

  deleteGroupFilter(group, index){
    let newGroup = this.groupFilter.filter((item) => item.filterGroupId !== group.filterGroupId);
    this.groupFilter = newGroup.map((item, i) => {
      return{
        ...item,
        filterGroupId: i +1
      }
    })

    this.checkValidFields()

  }


  performSearch() {
    this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()] = this.groupFilter;
    this.eventService.customEvent.emit({ id: 'refreshEntityTable' });
    this.eventService.closeJjPopup();
  }

  resetFilter(){
    this.groupFilter = [{ filterGroupId:1 ,filterList:[{
      filterId:1, 
      entityId:null, 
      memberId:null, 
      member:null, 
      operator:null, 
      value:null
    }]}]
  }


  removeFilter() {
    this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()] = null;
    this.eventService.customEvent.emit({ id: 'refreshEntityTable' });
    this.eventService.closeJjPopup();
  }

  saveFilter() {
    this.savedFilters.push({ 
      name: this.newFilterName, 
      groupFilter: this.groupFilter,
      isFavorit: false
    });
    this.saveUserFilter();
    this.showSaveFilterPopup = false;
  }


  // ?  do we need this

  getOptions(filter) {
    return this.filterOptions.filter(item => item.target == filter.memberType);
  }

  getInputValues(selectedOption) {
    if (this.commonService.isNullOrUndefined(selectedOption)) {
      return 0;
    }
    const optionItem = this.filterOptions.find(filteritem => filteritem.operator == selectedOption);
    if (optionItem) {
      return optionItem.fields;
    } else {
      return 0;
    }
  }

  loadFilter() {
    this.page = 2;
  }

  loadSavedFilter(savedFilter) {
    this.groupFilter = savedFilter.groupFilter;
    this.checkValidFields();
    this.page = 1;
  }

  deleteSavedFilter(savedFilter) {
    this.commonService.removeItemFromArray(this.savedFilters, savedFilter);
    this.saveUserFilter();
  }

  saveUserFilter() {
    this.applicationInfoService.userSettings['entityTableCustomFilter'.concat(this.dataTableItem.mainEntityId).toString()] = JSON.stringify(
      this.savedFilters
    );
    this.settingsService.updateSecurityUserSettingsJson();
  }

  getFavoriteFilters() {
    return this.savedFilters.filter(filter => filter.isFavorit == true);
  }

  toggleFavorite(savedFilter) {
    savedFilter.isFavorit = !savedFilter.isFavorit;
    this.saveUserFilter();
  }

  savedFiltersAmount() {
    let returnValue = this.axivasTranslateService.getTranslationTextForToken('EntityFilter.Label.SavedFilters');
    returnValue = returnValue.concat(' (', this.savedFilters.length.toString() ,')')
    return returnValue;
  }

  getLookupTableOptions(member) {
    return this.commonService.getProjectLookupTableValues(member.lookupTableId, member.lookupTableSource);;
  }

  getAlternateLookupTableOptions(member) {
    const table = this.commonService.getAlternateTable(member.alternativeLookupTable);
    
    if (table) {
      let returnValue = table.table
      if (member.alternativeLookupTable == 'projectleadstate' || member.alternativeLookupTable == 'leadstate') {
        returnValue.forEach(item => {
          item.translation = this.axivasTranslateService.getTranslationTextForObject(item.lookupTable, 'nameTranslationToken');
        });
      }  
      console.warn(returnValue, member.alternativeLookupTable)
      return returnValue;
    } else {
      return [];
    }
  }

  getAlternateDisplayName(member) {
    const table = this.commonService.getAlternateTable(member.alternativeLookupTable);
    if (member.alternativeLookupTable == 'projectleadstate' || member.alternativeLookupTable == 'leadstate') {
      return 'translation';
    }
    if (table) {
      return table.alternateDisplayName;
    } else {
      return null;
    }
  }

  getTypeOperator(valueLine) {
    if (this.commonService.isNullOrUndefined(valueLine.memberId)) {
      return [];
    }
    const member = this.applicationInfoService.entityMember.toArray().find(member => member.id == valueLine.memberId);
    if (this.commonService.isNullOrUndefined(this.typeOperators[member.memberType])) {
      this.typeOperators[member.memberType] = this.filterOptions.filter(item => item.target == member.memberType);
      return this.typeOperators[member.memberType];
      return [];
    } else {
      return this.typeOperators[member.memberType];
    }
  }
}

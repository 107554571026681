<svg *ngIf="type === 'envelope'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48Zm-96,85.15L52.57,64H203.43ZM98.71,128,40,181.81V74.19Zm11.84,10.85,12,11.05a8,8,0,0,0,10.82,0l12-11.05,58,53.15H52.57ZM157.29,128,216,74.18V181.82Z">
    </path>
</svg>

<svg *ngIf="type === 'envelope-open'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M228.44,89.34l-96-64a8,8,0,0,0-8.88,0l-96,64A8,8,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A8,8,0,0,0,228.44,89.34ZM96.72,152,40,192V111.53Zm16.37,8h29.82l56.63,40H56.46Zm46.19-8L216,111.53V192ZM128,41.61l81.91,54.61-67,47.78H113.11l-67-47.78Z"></path></svg>

<svg *ngIf="type === 'user'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z">
    </path>
</svg>

<svg *ngIf="type === 'arrows-counter-clockwise'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,48V96a8,8,0,0,1-8,8H168a8,8,0,0,1,0-16h28.69L182.06,73.37a79.56,79.56,0,0,0-56.13-23.43h-.45A79.52,79.52,0,0,0,69.59,72.71,8,8,0,0,1,58.41,61.27a96,96,0,0,1,135,.79L208,76.69V48a8,8,0,0,1,16,0ZM186.41,183.29a80,80,0,0,1-112.47-.66L59.31,168H88a8,8,0,0,0,0-16H40a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V179.31l14.63,14.63A95.43,95.43,0,0,0,130,222.06h.53a95.36,95.36,0,0,0,67.07-27.33,8,8,0,0,0-11.18-11.44Z">
    </path>
</svg>

<svg *ngIf="type === 'plus'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z">
    </path>
</svg>

<svg *ngIf="type === 'plus-bold'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z">
    </path>
</svg>

<svg *ngIf="type === 'navigation-arrow'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M237.33,106.21,61.41,41l-.16-.05A16,16,0,0,0,40.9,61.25a1,1,0,0,0,.05.16l65.26,175.92A15.77,15.77,0,0,0,121.28,248h.3a15.77,15.77,0,0,0,15-11.29l.06-.2,21.84-78,78-21.84.2-.06a16,16,0,0,0,.62-30.38ZM149.84,144.3a8,8,0,0,0-5.54,5.54L121.3,232l-.06-.17L56,56l175.82,65.22.16.06Z">
    </path>
</svg>

<svg *ngIf="type === 'caret-down'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'caret-up'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z">
    </path>
</svg>

<svg *ngIf="type === 'caret-left'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z">
    </path>
</svg>

<svg *ngIf="type === 'caret-right'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z">
    </path>
</svg>

<svg *ngIf="type === 'caret-circle-right'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm29.66-93.66a8,8,0,0,1,0,11.32l-40,40a8,8,0,0,1-11.32-11.32L140.69,128,106.34,93.66a8,8,0,0,1,11.32-11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'dots-three-outline-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path 
        d="M156,128a28,28,0,1,1-28-28A28,28,0,0,1,156,128ZM48,100a28,28,0,1,0,28,28A28,28,0,0,0,48,100Zm160,0a28,28,0,1,0,28,28A28,28,0,0,0,208,100Z">
    </path>
</svg>



<svg *ngIf="type === 'caret-circle-right-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm29.66,109.66-40,40a8,8,0,0,1-11.32-11.32L140.69,128,106.34,93.66a8,8,0,0,1,11.32-11.32l40,40A8,8,0,0,1,157.66,133.66Z">
    </path>
</svg>


<svg *ngIf="type === 'sliders'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M64,105V40a8,8,0,0,0-16,0v65a32,32,0,0,0,0,62v49a8,8,0,0,0,16,0V167a32,32,0,0,0,0-62Zm-8,47a16,16,0,1,1,16-16A16,16,0,0,1,56,152Zm80-95V40a8,8,0,0,0-16,0V57a32,32,0,0,0,0,62v97a8,8,0,0,0,16,0V119a32,32,0,0,0,0-62Zm-8,47a16,16,0,1,1,16-16A16,16,0,0,1,128,104Zm104,64a32.06,32.06,0,0,0-24-31V40a8,8,0,0,0-16,0v97a32,32,0,0,0,0,62v17a8,8,0,0,0,16,0V199A32.06,32.06,0,0,0,232,168Zm-32,16a16,16,0,1,1,16-16A16,16,0,0,1,200,184Z">
    </path>
</svg>
<svg *ngIf="type === 'list'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z">
    </path>
</svg>
<svg *ngIf="type === 'arrow-left'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z">
    </path>
</svg>

<svg *ngIf="type === 'arrow-down'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M205.66,149.66l-72,72a8,8,0,0,1-11.32,0l-72-72a8,8,0,0,1,11.32-11.32L120,196.69V40a8,8,0,0,1,16,0V196.69l58.34-58.35a8,8,0,0,1,11.32,11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'arrow-up'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M205.66,117.66a8,8,0,0,1-11.32,0L136,59.31V216a8,8,0,0,1-16,0V59.31L61.66,117.66a8,8,0,0,1-11.32-11.32l72-72a8,8,0,0,1,11.32,0l72,72A8,8,0,0,1,205.66,117.66Z">
    </path>
</svg>

<svg *ngIf="type === 'arrow-right'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z">
    </path>
</svg>

<svg *ngIf="type === 'binoculars'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M237.2,151.87v0a47.1,47.1,0,0,0-2.35-5.45L193.26,51.8a7.82,7.82,0,0,0-1.66-2.44,32,32,0,0,0-45.26,0A8,8,0,0,0,144,55V80H112V55a8,8,0,0,0-2.34-5.66,32,32,0,0,0-45.26,0,7.82,7.82,0,0,0-1.66,2.44L21.15,146.4a47.1,47.1,0,0,0-2.35,5.45v0A48,48,0,1,0,112,168V96h32v72a48,48,0,1,0,93.2-16.13ZM76.71,59.75a16,16,0,0,1,19.29-1v73.51a47.9,47.9,0,0,0-46.79-9.92ZM64,200a32,32,0,1,1,32-32A32,32,0,0,1,64,200ZM160,58.74a16,16,0,0,1,19.29,1l27.5,62.58A47.9,47.9,0,0,0,160,132.25ZM192,200a32,32,0,1,1,32-32A32,32,0,0,1,192,200Z">
    </path>
</svg>

<svg *ngIf="type === 'chart-bar'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,200h-8V40a8,8,0,0,0-8-8H152a8,8,0,0,0-8,8V80H96a8,8,0,0,0-8,8v40H48a8,8,0,0,0-8,8v64H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16ZM160,48h40V200H160ZM104,96h40V200H104ZM56,144H88v56H56Z">
    </path>
</svg>

<svg *ngIf="type === 'chart-bar-horizontal'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,96H184V56a8,8,0,0,0-8-8H56V40a8,8,0,0,0-16,0V216a8,8,0,0,0,16,0v-8h88a8,8,0,0,0,8-8V160h72a8,8,0,0,0,8-8V104A8,8,0,0,0,224,96ZM168,64V96H56V64ZM136,192H56V160h80Zm80-48H56V112H216Z"></path></svg>

<svg *ngIf="type === 'chart-bar-horizontal-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M232,112v32a8,8,0,0,1-8,8H56v16h88a8,8,0,0,1,8,8v24a8,8,0,0,1-8,8H56v8a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0v8H176a8,8,0,0,1,8,8V80a8,8,0,0,1-8,8H56v16H224A8,8,0,0,1,232,112Z"></path></svg>

<svg *ngIf="type === 'gear'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Zm109.94-52.79a8,8,0,0,0-3.89-5.4l-29.83-17-.12-33.62a8,8,0,0,0-2.83-6.08,111.91,111.91,0,0,0-36.72-20.67,8,8,0,0,0-6.46.59L128,41.85,97.88,25a8,8,0,0,0-6.47-.6A112.1,112.1,0,0,0,54.73,45.15a8,8,0,0,0-2.83,6.07l-.15,33.65-29.83,17a8,8,0,0,0-3.89,5.4,106.47,106.47,0,0,0,0,41.56,8,8,0,0,0,3.89,5.4l29.83,17,.12,33.62a8,8,0,0,0,2.83,6.08,111.91,111.91,0,0,0,36.72,20.67,8,8,0,0,0,6.46-.59L128,214.15,158.12,231a7.91,7.91,0,0,0,3.9,1,8.09,8.09,0,0,0,2.57-.42,112.1,112.1,0,0,0,36.68-20.73,8,8,0,0,0,2.83-6.07l.15-33.65,29.83-17a8,8,0,0,0,3.89-5.4A106.47,106.47,0,0,0,237.94,107.21Zm-15,34.91-28.57,16.25a8,8,0,0,0-3,3c-.58,1-1.19,2.06-1.81,3.06a7.94,7.94,0,0,0-1.22,4.21l-.15,32.25a95.89,95.89,0,0,1-25.37,14.3L134,199.13a8,8,0,0,0-3.91-1h-.19c-1.21,0-2.43,0-3.64,0a8.08,8.08,0,0,0-4.1,1l-28.84,16.1A96,96,0,0,1,67.88,201l-.11-32.2a8,8,0,0,0-1.22-4.22c-.62-1-1.23-2-1.8-3.06a8.09,8.09,0,0,0-3-3.06l-28.6-16.29a90.49,90.49,0,0,1,0-28.26L61.67,97.63a8,8,0,0,0,3-3c.58-1,1.19-2.06,1.81-3.06a7.94,7.94,0,0,0,1.22-4.21l.15-32.25a95.89,95.89,0,0,1,25.37-14.3L122,56.87a8,8,0,0,0,4.1,1c1.21,0,2.43,0,3.64,0a8.08,8.08,0,0,0,4.1-1l28.84-16.1A96,96,0,0,1,188.12,55l.11,32.2a8,8,0,0,0,1.22,4.22c.62,1,1.23,2,1.8,3.06a8.09,8.09,0,0,0,3,3.06l28.6,16.29A90.49,90.49,0,0,1,222.9,142.12Z">
    </path>
</svg>

<svg *ngIf="type === 'gear-six'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Zm109.94-52.79a8,8,0,0,0-3.89-5.4l-29.83-17-.12-33.62a8,8,0,0,0-2.83-6.08,111.91,111.91,0,0,0-36.72-20.67,8,8,0,0,0-6.46.59L128,41.85,97.88,25a8,8,0,0,0-6.47-.6A112.1,112.1,0,0,0,54.73,45.15a8,8,0,0,0-2.83,6.07l-.15,33.65-29.83,17a8,8,0,0,0-3.89,5.4,106.47,106.47,0,0,0,0,41.56,8,8,0,0,0,3.89,5.4l29.83,17,.12,33.62a8,8,0,0,0,2.83,6.08,111.91,111.91,0,0,0,36.72,20.67,8,8,0,0,0,6.46-.59L128,214.15,158.12,231a7.91,7.91,0,0,0,3.9,1,8.09,8.09,0,0,0,2.57-.42,112.1,112.1,0,0,0,36.68-20.73,8,8,0,0,0,2.83-6.07l.15-33.65,29.83-17a8,8,0,0,0,3.89-5.4A106.47,106.47,0,0,0,237.94,107.21Zm-15,34.91-28.57,16.25a8,8,0,0,0-3,3c-.58,1-1.19,2.06-1.81,3.06a7.94,7.94,0,0,0-1.22,4.21l-.15,32.25a95.89,95.89,0,0,1-25.37,14.3L134,199.13a8,8,0,0,0-3.91-1h-.19c-1.21,0-2.43,0-3.64,0a8.08,8.08,0,0,0-4.1,1l-28.84,16.1A96,96,0,0,1,67.88,201l-.11-32.2a8,8,0,0,0-1.22-4.22c-.62-1-1.23-2-1.8-3.06a8.09,8.09,0,0,0-3-3.06l-28.6-16.29a90.49,90.49,0,0,1,0-28.26L61.67,97.63a8,8,0,0,0,3-3c.58-1,1.19-2.06,1.81-3.06a7.94,7.94,0,0,0,1.22-4.21l.15-32.25a95.89,95.89,0,0,1,25.37-14.3L122,56.87a8,8,0,0,0,4.1,1c1.21,0,2.43,0,3.64,0a8.08,8.08,0,0,0,4.1-1l28.84-16.1A96,96,0,0,1,188.12,55l.11,32.2a8,8,0,0,0,1.22,4.22c.62,1,1.23,2,1.8,3.06a8.09,8.09,0,0,0,3,3.06l28.6,16.29A90.49,90.49,0,0,1,222.9,142.12Z">
    </path>
</svg>

<svg *ngIf="type === 'megaphone'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M248,120a48.05,48.05,0,0,0-48-48H160.2c-2.91-.17-53.62-3.74-101.91-44.24A16,16,0,0,0,32,40V200a16,16,0,0,0,26.29,12.25c37.77-31.68,77-40.76,93.71-43.3v31.72A16,16,0,0,0,159.12,214l11,7.33A16,16,0,0,0,194.5,212l11.77-44.36A48.07,48.07,0,0,0,248,120ZM48,199.93V40h0c42.81,35.91,86.63,45,104,47.24v65.48C134.65,155,90.84,164.07,48,199.93Zm131,8,0,.11-11-7.33V168h21.6ZM200,152H168V88h32a32,32,0,1,1,0,64Z">
    </path>
</svg>

<svg *ngIf="type === 'puzzle-piece'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M220.27,158.54a8,8,0,0,0-7.7-.46,20,20,0,1,1,0-36.16A8,8,0,0,0,224,114.69V72a16,16,0,0,0-16-16H171.78a35.36,35.36,0,0,0,.22-4,36.11,36.11,0,0,0-11.36-26.24,36,36,0,0,0-60.55,23.62,36.56,36.56,0,0,0,.14,6.62H64A16,16,0,0,0,48,72v32.22a35.36,35.36,0,0,0-4-.22,36.12,36.12,0,0,0-26.24,11.36,35.7,35.7,0,0,0-9.69,27,36.08,36.08,0,0,0,33.31,33.6,35.68,35.68,0,0,0,6.62-.14V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V165.31A8,8,0,0,0,220.27,158.54ZM208,208H64V165.31a8,8,0,0,0-11.43-7.23,20,20,0,1,1,0-36.16A8,8,0,0,0,64,114.69V72h46.69a8,8,0,0,0,7.23-11.43,20,20,0,1,1,36.16,0A8,8,0,0,0,161.31,72H208v32.23a35.68,35.68,0,0,0-6.62-.14A36,36,0,0,0,204,176a35.36,35.36,0,0,0,4-.22Z">
    </path>
</svg>

<svg *ngIf="type === 'x'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()"
    [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
    fill="#000000" viewBox="0 0 256 256">
    <path
        d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z">
    </path>
</svg>

<svg *ngIf="type === 'building'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M232,224H208V32h8a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16h8V224H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16ZM64,32H192V224H160V184a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8v40H64Zm80,192H112V192h32ZM88,64a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H96A8,8,0,0,1,88,64Zm48,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H144A8,8,0,0,1,136,64ZM88,104a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H96A8,8,0,0,1,88,104Zm48,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H144A8,8,0,0,1,136,104ZM88,144a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H96A8,8,0,0,1,88,144Zm48,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H144A8,8,0,0,1,136,144Z">
    </path>
</svg>

<svg *ngIf="type === 'magnifying-glass'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z">
    </path>
</svg>

<svg *ngIf="type === 'export'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,112v96a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V112A16,16,0,0,1,56,96H80a8,8,0,0,1,0,16H56v96H200V112H176a8,8,0,0,1,0-16h24A16,16,0,0,1,216,112ZM93.66,69.66,120,43.31V136a8,8,0,0,0,16,0V43.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,69.66Z">
    </path>
</svg>

<svg *ngIf="type === 'arrow-square-out'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z">
    </path>
</svg>

<svg *ngIf="type === 'editor'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M229.66,58.34l-32-32a8,8,0,0,0-11.32,0l-96,96A8,8,0,0,0,88,128v32a8,8,0,0,0,8,8h32a8,8,0,0,0,5.66-2.34l96-96A8,8,0,0,0,229.66,58.34ZM124.69,152H104V131.31l64-64L188.69,88ZM200,76.69,179.31,56,192,43.31,212.69,64ZM224,128v80a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h80a8,8,0,0,1,0,16H48V208H208V128a8,8,0,0,1,16,0Z">
    </path>
</svg>

<svg *ngIf="type ==='browser'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V88H40V56Zm0,144H40V104H216v96Z">
    </path>
</svg>

<svg *ngIf="type === 'globe'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24h0A104,104,0,1,0,232,128,104.12,104.12,0,0,0,128,24Zm88,104a87.61,87.61,0,0,1-3.33,24H174.16a157.44,157.44,0,0,0,0-48h38.51A87.61,87.61,0,0,1,216,128ZM102,168H154a115.11,115.11,0,0,1-26,45A115.27,115.27,0,0,1,102,168Zm-3.9-16a140.84,140.84,0,0,1,0-48h59.88a140.84,140.84,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.84a157.44,157.44,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154,88H102a115.11,115.11,0,0,1,26-45A115.27,115.27,0,0,1,154,88Zm52.33,0H170.71a135.28,135.28,0,0,0-22.3-45.6A88.29,88.29,0,0,1,206.37,88ZM107.59,42.4A135.28,135.28,0,0,0,85.29,88H49.63A88.29,88.29,0,0,1,107.59,42.4ZM49.63,168H85.29a135.28,135.28,0,0,0,22.3,45.6A88.29,88.29,0,0,1,49.63,168Zm98.78,45.6a135.28,135.28,0,0,0,22.3-45.6h35.66A88.29,88.29,0,0,1,148.41,213.6Z">
    </path>
</svg>

<svg *ngIf="type === 'lock-simple'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,80H176V56a48,48,0,0,0-96,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM96,56a32,32,0,0,1,64,0V80H96ZM208,208H48V96H208V208Z">
    </path>
</svg>

<svg *ngIf="type === 'lock-simple-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,80H176V56a48,48,0,0,0-96,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM96,56a32,32,0,0,1,64,0V80H96Z">
    </path>
</svg>

<svg *ngIf="type === 'lock-simple-open'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,80H96V56a32,32,0,0,1,32-32c15.37,0,29.2,11,32.16,25.59a8,8,0,0,0,15.68-3.18C171.32,24.15,151.2,8,128,8A48.05,48.05,0,0,0,80,56V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,128H48V96H208V208Z">
    </path>
</svg>

<svg *ngIf="type === 'info'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z">
    </path>
</svg>

<svg *ngIf="type === 'info-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z">
    </path>
</svg>

<svg *ngIf="type === 'info-bold'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M108,84a16,16,0,1,1,16,16A16,16,0,0,1,108,84Zm128,44A108,108,0,1,1,128,20,108.12,108.12,0,0,1,236,128Zm-24,0a84,84,0,1,0-84,84A84.09,84.09,0,0,0,212,128Zm-72,36.68V132a20,20,0,0,0-20-20,12,12,0,0,0-4,23.32V168a20,20,0,0,0,20,20,12,12,0,0,0,4-23.32Z"></path></svg>



<svg *ngIf="type === 'phone'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [class.disabled]="disabled" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()"
    xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z">
    </path>
</svg>

<svg *ngIf="type === 'pencil-simple'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M227.31,73.37,182.63,28.68a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H92.69A15.86,15.86,0,0,0,104,219.31L227.31,96a16,16,0,0,0,0-22.63ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.68,147.31,64l24-24L216,84.68Z">
    </path>
</svg>

<svg *ngIf="type === 'pencil-simple-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M227.31,73.37,182.63,28.68a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H92.69A15.86,15.86,0,0,0,104,219.31L227.31,96a16,16,0,0,0,0-22.63ZM192,108.68,147.31,64l24-24L216,84.68Z">
    </path>
</svg>

<svg *ngIf="type === 'pencil-simple-line'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z">
    </path>
</svg>

<svg *ngIf="type === 'warning'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z">
    </path>
</svg>

<svg *ngIf="type === 'warning-bold'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M240.26,186.1,152.81,34.23h0a28.74,28.74,0,0,0-49.62,0L15.74,186.1a27.45,27.45,0,0,0,0,27.71A28.31,28.31,0,0,0,40.55,228h174.9a28.31,28.31,0,0,0,24.79-14.19A27.45,27.45,0,0,0,240.26,186.1Zm-20.8,15.7a4.46,4.46,0,0,1-4,2.2H40.55a4.46,4.46,0,0,1-4-2.2,3.56,3.56,0,0,1,0-3.73L124,46.2a4.77,4.77,0,0,1,8,0l87.44,151.87A3.56,3.56,0,0,1,219.46,201.8ZM116,136V104a12,12,0,0,1,24,0v32a12,12,0,0,1-24,0Zm28,40a16,16,0,1,1-16-16A16,16,0,0,1,144,176Z"></path></svg>

<svg *ngIf="type === 'note-pencil'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M229.66,58.34l-32-32a8,8,0,0,0-11.32,0l-96,96A8,8,0,0,0,88,128v32a8,8,0,0,0,8,8h32a8,8,0,0,0,5.66-2.34l96-96A8,8,0,0,0,229.66,58.34ZM124.69,152H104V131.31l64-64L188.69,88ZM200,76.69,179.31,56,192,43.31,212.69,64ZM224,128v80a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h80a8,8,0,0,1,0,16H48V208H208V128a8,8,0,0,1,16,0Z">
    </path>
</svg>

<svg *ngIf="type === 'check'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'square'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z">
    </path>
</svg>
<svg *ngIf="type === 'check-square-offset'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,48V208a16,16,0,0,1-16,16H136a8,8,0,0,1,0-16h72V48H48v96a8,8,0,0,1-16,0V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM125.66,154.34a8,8,0,0,0-11.32,0L64,204.69,45.66,186.34a8,8,0,0,0-11.32,11.32l24,24a8,8,0,0,0,11.32,0l56-56A8,8,0,0,0,125.66,154.34Z">
    </path>
</svg>
<svg *ngIf="type === 'check-square'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208H208Z">
    </path>
</svg>

<svg *ngIf="type === 'circle'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z">
    </path>
</svg>

<svg *ngIf="type === 'radio-button-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256" [attr.stroke]="getStrokeColor()">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm56-88a56,56,0,1,1-56-56A56.06,56.06,0,0,1,184,128Z">
    </path>
</svg>



<svg *ngIf="type === 'magic-wand'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M48,64a8,8,0,0,1,8-8H72V40a8,8,0,0,1,16,0V56h16a8,8,0,0,1,0,16H88V88a8,8,0,0,1-16,0V72H56A8,8,0,0,1,48,64ZM184,192h-8v-8a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0v-8h8a8,8,0,0,0,0-16Zm56-48H224V128a8,8,0,0,0-16,0v16H192a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V160h16a8,8,0,0,0,0-16ZM219.31,80,80,219.31a16,16,0,0,1-22.62,0L36.68,198.63a16,16,0,0,1,0-22.63L176,36.69a16,16,0,0,1,22.63,0l20.68,20.68A16,16,0,0,1,219.31,80Zm-54.63,32L144,91.31l-96,96L68.68,208ZM208,68.69,187.31,48l-32,32L176,100.69Z">
    </path>
</svg>
<svg *ngIf="type === 'trash'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z">
    </path>
</svg>
<svg *ngIf="type === 'tray-arrow-down'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,16V152h-28.7A15.86,15.86,0,0,0,168,156.69L148.69,176H107.31L88,156.69A15.86,15.86,0,0,0,76.69,152H48V48Zm0,160H48V168H76.69L96,187.31A15.86,15.86,0,0,0,107.31,192h41.38A15.86,15.86,0,0,0,160,187.31L179.31,168H208v40ZM90.34,125.66a8,8,0,0,1,11.32-11.32L120,132.69V72a8,8,0,0,1,16,0v60.69l18.34-18.35a8,8,0,0,1,11.32,11.32l-32,32a8,8,0,0,1-11.32,0Z">
    </path>
</svg>
<svg *ngIf="type === 'dot'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()"
    [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96"
    fill="#000000" viewBox="0 0 256 256">
    <path d="M140,128a12,12,0,1,1-12-12A12,12,0,0,1,140,128Z"></path>
</svg>

<!-- <svg *ngIf="type === 'key-return'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()">
    <path
        d="M184,104v32a8,8,0,0,1-8,8H99.31l10.35,10.34a8,8,0,0,1-11.32,11.32l-24-24a8,8,0,0,1,0-11.32l24-24a8,8,0,0,1,11.32,11.32L99.31,128H168V104a8,8,0,0,1,16,0Zm48-48V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Z">
    </path>
</svg> -->
<svg *ngIf="type === 'arrows-in-simple'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M213.66,53.66,163.31,104H192a8,8,0,0,1,0,16H144a8,8,0,0,1-8-8V64a8,8,0,0,1,16,0V92.69l50.34-50.35a8,8,0,0,1,11.32,11.32ZM112,136H64a8,8,0,0,0,0,16H92.69L42.34,202.34a8,8,0,0,0,11.32,11.32L104,163.31V192a8,8,0,0,0,16,0V144A8,8,0,0,0,112,136Z">
    </path>
</svg>
<svg *ngIf="type === 'rocket'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M152,224a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,224ZM128,112a12,12,0,1,0-12-12A12,12,0,0,0,128,112Zm95.62,43.83-12.36,55.63a16,16,0,0,1-25.51,9.11L158.51,200h-61L70.25,220.57a16,16,0,0,1-25.51-9.11L32.38,155.83a16.09,16.09,0,0,1,3.32-13.71l28.56-34.26a123.07,123.07,0,0,1,8.57-36.67c12.9-32.34,36-52.63,45.37-59.85a16,16,0,0,1,19.6,0c9.34,7.22,32.47,27.51,45.37,59.85a123.07,123.07,0,0,1,8.57,36.67l28.56,34.26A16.09,16.09,0,0,1,223.62,155.83ZM99.43,184h57.14c21.12-37.54,25.07-73.48,11.74-106.88C156.55,47.64,134.49,29,128,24c-6.51,5-28.57,23.64-40.33,53.12C74.36,110.52,78.31,146.46,99.43,184Zm-15,5.85Q68.28,160.5,64.83,132.16L48,152.36,60.36,208l.18-.13ZM208,152.36l-16.83-20.2q-3.42,28.28-19.56,57.69l23.85,18,.18.13Z">
    </path>
</svg>

<svg *ngIf="type === 'calendar-dots'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-68-76a12,12,0,1,1-12-12A12,12,0,0,1,140,132Zm44,0a12,12,0,1,1-12-12A12,12,0,0,1,184,132ZM96,172a12,12,0,1,1-12-12A12,12,0,0,1,96,172Zm44,0a12,12,0,1,1-12-12A12,12,0,0,1,140,172Zm44,0a12,12,0,1,1-12-12A12,12,0,0,1,184,172Z">
    </path>
</svg>

<svg *ngIf="type === 'calendar-check'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>


<svg *ngIf="type === 'frame-corners'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M200,80v32a8,8,0,0,1-16,0V88H160a8,8,0,0,1,0-16h32A8,8,0,0,1,200,80ZM96,168H72V144a8,8,0,0,0-16,0v32a8,8,0,0,0,8,8H96a8,8,0,0,0,0-16ZM232,56V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Z">
    </path>
</svg>
<svg *ngIf="type === 'image'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V158.75l-26.07-26.06a16,16,0,0,0-22.63,0l-20,20-44-44a16,16,0,0,0-22.62,0L40,149.37V56ZM40,172l52-52,80,80H40Zm176,28H194.63l-36-36,20-20L216,181.38V200ZM144,100a12,12,0,1,1,12,12A12,12,0,0,1,144,100Z">
    </path>
</svg>

<svg *ngIf="type === 'table'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM40,112H80v32H40Zm56,0H216v32H96ZM216,64V96H40V64ZM40,160H80v32H40Zm176,32H96V160H216v32Z">
    </path>
</svg>

<svg *ngIf="type === 'arrow-up-right'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M200,64V168a8,8,0,0,1-16,0V83.31L69.66,197.66a8,8,0,0,1-11.32-11.32L172.69,72H88a8,8,0,0,1,0-16H192A8,8,0,0,1,200,64Z">
    </path>
</svg>

<svg *ngIf="type === 'circle-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path d="M232,128A104,104,0,1,1,128,24,104.13,104.13,0,0,1,232,128Z"></path>
</svg>

<svg *ngIf="type === 'arrow-circle-right-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,109.66-32,32a8,8,0,0,1-11.32-11.32L148.69,136H88a8,8,0,0,1,0-16h60.69l-18.35-18.34a8,8,0,0,1,11.32-11.32l32,32A8,8,0,0,1,173.66,133.66Z">
    </path>
</svg>

<svg *ngIf="type === 'note'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M88,96a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,96Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Zm32,16H96a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16ZM224,48V156.69A15.86,15.86,0,0,1,219.31,168L168,219.31A15.86,15.86,0,0,1,156.69,224H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM48,208H152V160a8,8,0,0,1,8-8h48V48H48Zm120-40v28.7L196.69,168Z">
    </path>
</svg>

<svg *ngIf="type === 'headset'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M201.89,54.66A103.43,103.43,0,0,0,128.79,24H128A104,104,0,0,0,24,128v56a24,24,0,0,0,24,24H64a24,24,0,0,0,24-24V144a24,24,0,0,0-24-24H40.36A88.12,88.12,0,0,1,190.54,65.93,87.39,87.39,0,0,1,215.65,120H192a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h24a24,24,0,0,1-24,24H136a8,8,0,0,0,0,16h56a40,40,0,0,0,40-40V128A103.41,103.41,0,0,0,201.89,54.66ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm128,56a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v56Z">
    </path>
</svg>

<svg *ngIf="type === 'minus'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128Z"></path></svg>

<svg *ngIf="type === 'phone-call'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M144.27,45.93a8,8,0,0,1,9.8-5.66,86.22,86.22,0,0,1,61.66,61.66,8,8,0,0,1-5.66,9.8A8.23,8.23,0,0,1,208,112a8,8,0,0,1-7.73-5.94,70.35,70.35,0,0,0-50.33-50.33A8,8,0,0,1,144.27,45.93Zm-2.33,41.8c13.79,3.68,22.65,12.54,26.33,26.33A8,8,0,0,0,176,120a8.23,8.23,0,0,0,2.07-.27,8,8,0,0,0,5.66-9.8c-5.12-19.16-18.5-32.54-37.66-37.66a8,8,0,1,0-4.13,15.46Zm81.94,95.35A56.26,56.26,0,0,1,168,232C88.6,232,24,167.4,24,88A56.26,56.26,0,0,1,72.92,32.12a16,16,0,0,1,16.62,9.52l21.12,47.15,0,.12A16,16,0,0,1,109.39,104c-.18.27-.37.52-.57.77L88,129.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,16,16,0,0,1,15.17-1.4l.13.06,47.11,21.11A16,16,0,0,1,223.88,183.08Zm-15.88-2s-.07,0-.11,0h0l-47-21.05-24.35,20.71a8.44,8.44,0,0,1-.74.56,16,16,0,0,1-15.75,1.14c-18.73-9.05-37.4-27.58-46.46-46.11a16,16,0,0,1,1-15.7,6.13,6.13,0,0,1,.57-.77L96,95.15l-21-47a.61.61,0,0,1,0-.12A40.2,40.2,0,0,0,40,88,128.14,128.14,0,0,0,168,216,40.21,40.21,0,0,0,208,181.07Z">
    </path>
</svg>

<svg *ngIf="type === 'phone-incoming'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M144,104V64a8,8,0,0,1,16,0V84.69l34.34-34.35a8,8,0,0,1,11.32,11.32L171.32,96H192a8,8,0,0,1,0,16H152A8,8,0,0,1,144,104Zm79.88,79.08A56.26,56.26,0,0,1,168,232C88.6,232,24,167.4,24,88A56.24,56.24,0,0,1,72.92,32.13a16,16,0,0,1,16.62,9.51l21.12,47.15,0,.12A16,16,0,0,1,109.39,104c-.18.27-.37.52-.57.77L88,129.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,16,16,0,0,1,15.17-1.4l.13.06,47.11,21.11A16,16,0,0,1,223.88,183.08Zm-15.88-2s-.07,0-.11,0h0l-47-21.05-24.35,20.71a6.84,6.84,0,0,1-.74.56,16,16,0,0,1-15.75,1.14c-18.73-9.05-37.4-27.58-46.46-46.11a16,16,0,0,1,1-15.7,6.13,6.13,0,0,1,.57-.77L96,95.15l-21-47a.61.61,0,0,1,0-.12A40.2,40.2,0,0,0,40,88,128.14,128.14,0,0,0,168,216,40.21,40.21,0,0,0,208,181.07Z">
    </path>
</svg>

<svg *ngIf="type === 'phone-outgoing'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M146.34,109.66a8,8,0,0,1,0-11.32L180.69,64H160a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8V96a8,8,0,0,1-16,0V75.31l-34.34,34.35a8,8,0,0,1-11.32,0Zm77.54,73.42A56.26,56.26,0,0,1,168,232C88.6,232,24,167.4,24,88A56.26,56.26,0,0,1,72.92,32.12a16,16,0,0,1,16.62,9.51l21.12,47.16,0,.12A16,16,0,0,1,109.39,104c-.18.27-.37.52-.57.77L88,129.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,15.93,15.93,0,0,1,15.17-1.4l.13.06,47.11,21.11A16,16,0,0,1,223.88,183.08Zm-15.88-2s-.07,0-.11,0h0l-47-21.06-24.35,20.72a8.44,8.44,0,0,1-.74.56,16,16,0,0,1-15.75,1.14c-18.73-9.05-37.4-27.58-46.46-46.11a16,16,0,0,1,1-15.7,6.13,6.13,0,0,1,.57-.77L96,95.15l-21-47a.61.61,0,0,1,0-.12A40.2,40.2,0,0,0,40,88,128.14,128.14,0,0,0,168,216,40.21,40.21,0,0,0,208,181.07Z">
    </path>
</svg>

<svg *ngIf="type === 'phone-pause'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M214.36,166.46l-47.1-21.11-.12-.06a16,16,0,0,0-15.18,1.4,8.12,8.12,0,0,0-.75.56L126.87,168c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L89.54,41.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,24,88c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,214.36,166.46ZM168,216A128.14,128.14,0,0,1,40,88,40.2,40.2,0,0,1,74.87,48a.61.61,0,0,0,0,.12l21,47L75.2,119.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,6.92,6.92,0,0,0,.74-.57L160.89,160l47,21.06h0s.08,0,.11,0A40.21,40.21,0,0,1,168,216Zm24-112V48a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm-40,0V48a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Z">
    </path>
</svg>

<svg *ngIf="type === 'phone-transfer'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M136,72a8,8,0,0,1,8-8h52.69L178.34,45.66a8,8,0,0,1,11.32-11.32l32,32a8,8,0,0,1,0,11.32l-32,32a8,8,0,0,1-11.32-11.32L196.69,80H144A8,8,0,0,1,136,72Zm87.87,111.08A56.24,56.24,0,0,1,168,232C88.6,232,24,167.4,24,88A56.24,56.24,0,0,1,72.92,32.13a16,16,0,0,1,16.62,9.51l21.12,47.15,0,.12A16,16,0,0,1,109.39,104c-.18.27-.37.52-.57.77L88,129.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,16,16,0,0,1,15.18-1.4l.11.06,47.11,21.11A16,16,0,0,1,223.87,183.08Zm-15.87-2s-.07,0-.11,0h0l-47-21.05-24.35,20.71a8,8,0,0,1-.74.56,16,16,0,0,1-15.75,1.14c-18.73-9-37.4-27.58-46.46-46.11a16,16,0,0,1,1-15.7,7,7,0,0,1,.57-.77L96,95.15l-21-47a.61.61,0,0,1,0-.12A40.2,40.2,0,0,0,40,88,128.14,128.14,0,0,0,168,216,40.21,40.21,0,0,0,208,181.07Z">
    </path>
</svg>

<svg *ngIf="type === 'phone-x'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M146.34,98.34,164.69,80,146.34,61.66a8,8,0,0,1,11.32-11.32L176,68.69l18.34-18.35a8,8,0,0,1,11.32,11.32L187.32,80l18.34,18.34a8,8,0,0,1-11.32,11.32L176,91.31l-18.34,18.35a8,8,0,0,1-11.32-11.32Zm77.54,84.74A56.26,56.26,0,0,1,168,232C88.6,232,24,167.4,24,88A56.26,56.26,0,0,1,72.92,32.12a16,16,0,0,1,16.62,9.52l21.12,47.15,0,.12A16,16,0,0,1,109.39,104c-.18.27-.37.52-.57.77L88,129.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,16,16,0,0,1,15.17-1.4l.13.06,47.11,21.11A16,16,0,0,1,223.88,183.08Zm-15.88-2s-.07,0-.11,0h0l-47-21.05-24.35,20.71a8.44,8.44,0,0,1-.74.56,16,16,0,0,1-15.75,1.14c-18.73-9.05-37.4-27.58-46.46-46.11a16,16,0,0,1,1-15.7,6.13,6.13,0,0,1,.57-.77L96,95.15l-21-47a.61.61,0,0,1,0-.12A40.2,40.2,0,0,0,40,88,128.14,128.14,0,0,0,168,216,40.21,40.21,0,0,0,208,181.07Z">
    </path>
</svg>

<svg *ngIf="type === 'phone-disconnect'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M231.59,90.13h0C175.44,34,80.56,34,24.41,90.13c-20,20-21.92,49.49-4.69,71.71A16,16,0,0,0,32.35,168a15.8,15.8,0,0,0,5.75-1.08l49-17.37.29-.11a16,16,0,0,0,9.75-11.73l5.9-29.52a76.52,76.52,0,0,1,49.68-.11h0l6.21,29.75a16,16,0,0,0,9.72,11.59l.29.11,49,17.39a16,16,0,0,0,18.38-5.06C253.51,139.62,251.58,110.13,231.59,90.13ZM223.67,152l-.3-.12-48.82-17.33-6.21-29.74A16,16,0,0,0,158,93a92.56,92.56,0,0,0-60.34.13,16,16,0,0,0-10.32,12l-5.9,29.51L32.63,151.86c-.1,0-.17.13-.27.17-12.33-15.91-11-36.23,3.36-50.58,25-25,58.65-37.53,92.28-37.53s67.27,12.51,92.28,37.53C234.61,115.8,236,136.12,223.67,152Zm.32,48a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,200Z">
    </path>
</svg>

<svg *ngIf="type === 'question'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180ZM128,72c-22.06,0-40,16.15-40,36v4a8,8,0,0,0,16,0v-4c0-11,10.77-20,24-20s24,9,24,20-10.77,20-24,20a8,8,0,0,0-8,8v8a8,8,0,0,0,16,0v-.72c18.24-3.35,32-17.9,32-35.28C168,88.15,150.06,72,128,72Zm104,56A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z">
    </path>
</svg>

<svg *ngIf="type === 'bell-ringing'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,71.1a8,8,0,0,1-10.78-3.42,94.13,94.13,0,0,0-33.46-36.91,8,8,0,1,1,8.54-13.54,111.46,111.46,0,0,1,39.12,43.09A8,8,0,0,1,224,71.1ZM35.71,72a8,8,0,0,0,7.1-4.32A94.13,94.13,0,0,1,76.27,30.77a8,8,0,1,0-8.54-13.54A111.46,111.46,0,0,0,28.61,60.32,8,8,0,0,0,35.71,72Zm186.1,103.94A16,16,0,0,1,208,200H167.2a40,40,0,0,1-78.4,0H48a16,16,0,0,1-13.79-24.06C43.22,160.39,48,138.28,48,112a80,80,0,0,1,160,0C208,138.27,212.78,160.38,221.81,175.94ZM150.62,200H105.38a24,24,0,0,0,45.24,0ZM208,184c-10.64-18.27-16-42.49-16-72a64,64,0,0,0-128,0c0,29.52-5.38,53.74-16,72Z">
    </path>
</svg>

<svg *ngIf="type === 'bell-slash'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M53.92,34.62A8,8,0,1,0,42.08,45.38L58.82,63.8A79.59,79.59,0,0,0,48,104c0,35.34-8.26,62.38-13.81,71.94A16,16,0,0,0,48,200H88.8a40,40,0,0,0,78.4,0h15.44l19.44,21.38a8,8,0,1,0,11.84-10.76ZM128,216a24,24,0,0,1-22.62-16h45.24A24,24,0,0,1,128,216ZM48,184c7.7-13.24,16-43.92,16-80a63.65,63.65,0,0,1,6.26-27.62L168.09,184Zm166-4.73a8.13,8.13,0,0,1-2.93.55,8,8,0,0,1-7.44-5.08C196.35,156.19,192,129.75,192,104A64,64,0,0,0,96.43,48.31a8,8,0,0,1-7.9-13.91A80,80,0,0,1,208,104c0,35.35,8.05,58.59,10.52,64.88A8,8,0,0,1,214,179.25Z"></path></svg>

<svg *ngIf="type === 'toggle-left'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="24" height="24" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M176,60H80a68,68,0,0,0,0,136h96a68,68,0,0,0,0-136Zm0,128H80A60,60,0,0,1,80,68h96a60,60,0,0,1,0,120ZM80,92a36,36,0,1,0,36,36A36,36,0,0,0,80,92Zm0,64a28,28,0,1,1,28-28A28,28,0,0,1,80,156Z">
    </path>
</svg>

<svg *ngIf="type === 'toggle-right-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="24" height="24" fill="#000000" viewBox="0 0 256 256">
    <path d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144Zm0,112a40,40,0,1,1,40-40A40,40,0,0,1,176,168Z"></path>
</svg>

<svg *ngIf="type === 'toggle-left-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="24" height="24" fill="#000000" viewBox="0 0 256 256" class="mirrorImage">
    <path d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144Zm0,112a40,40,0,1,1,40-40A40,40,0,0,1,176,168Z"></path>
</svg>

<svg *ngIf="type === 'pencil-ruler'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,32H160a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H160V176h24a8,8,0,0,0,0-16H160V136h24a8,8,0,0,0,0-16H160V96h24a8,8,0,0,0,0-16H160V48h48V208ZM77.66,26.34a8,8,0,0,0-11.32,0l-32,32A8,8,0,0,0,32,64V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V64a8,8,0,0,0-2.34-5.66ZM48,176V80H64v96ZM80,80H96v96H80ZM72,43.31,92.69,64H51.31ZM48,208V192H96v16Z">
    </path>
</svg>

<svg *ngIf="type === 'sign-in'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M141.66,133.66l-40,40a8,8,0,0,1-11.32-11.32L116.69,136H24a8,8,0,0,1,0-16h92.69L90.34,93.66a8,8,0,0,1,11.32-11.32l40,40A8,8,0,0,1,141.66,133.66ZM200,32H136a8,8,0,0,0,0,16h56V208H136a8,8,0,0,0,0,16h64a8,8,0,0,0,8-8V40A8,8,0,0,0,200,32Z"></path></svg>

<svg *ngIf="type === 'sign-out'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M120,216a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H56V208h56A8,8,0,0,1,120,216Zm109.66-93.66-40-40a8,8,0,0,0-11.32,11.32L204.69,120H112a8,8,0,0,0,0,16h92.69l-26.35,26.34a8,8,0,0,0,11.32,11.32l40-40A8,8,0,0,0,229.66,122.34Z">
    </path>
</svg>
<svg *ngIf="type === 'plus-circle'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z">
    </path>
</svg>
<svg *ngIf="type === 'user-plus'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M256,136a8,8,0,0,1-8,8H232v16a8,8,0,0,1-16,0V144H200a8,8,0,0,1,0-16h16V112a8,8,0,0,1,16,0v16h16A8,8,0,0,1,256,136Zm-57.87,58.85a8,8,0,0,1-12.26,10.3C165.75,181.19,138.09,168,108,168s-57.75,13.19-77.87,37.15a8,8,0,0,1-12.25-10.3c14.94-17.78,33.52-30.41,54.17-37.17a68,68,0,1,1,71.9,0C164.6,164.44,183.18,177.07,198.13,194.85ZM108,152a52,52,0,1,0-52-52A52.06,52.06,0,0,0,108,152Z">
    </path>
</svg>

<svg *ngIf="type === 'chart-bar-thin'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,204H212V40a4,4,0,0,0-4-4H152a4,4,0,0,0-4,4V84H96a4,4,0,0,0-4,4v44H48a4,4,0,0,0-4,4v68H32a4,4,0,0,0,0,8H224a4,4,0,0,0,0-8ZM156,44h48V204H156ZM100,92h48V204H100ZM52,140H92v64H52Z"></path></svg>

<svg *ngIf="type === 'users'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()"xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z"></path></svg>


<svg *ngIf="type === 'users-four'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M27.2,126.4a8,8,0,0,0,11.2-1.6,52,52,0,0,1,83.2,0,8,8,0,0,0,11.2,1.59,7.73,7.73,0,0,0,1.59-1.59h0a52,52,0,0,1,83.2,0,8,8,0,0,0,12.8-9.61A67.85,67.85,0,0,0,203,93.51a40,40,0,1,0-53.94,0,67.27,67.27,0,0,0-21,14.31,67.27,67.27,0,0,0-21-14.31,40,40,0,1,0-53.94,0A67.88,67.88,0,0,0,25.6,115.2,8,8,0,0,0,27.2,126.4ZM176,40a24,24,0,1,1-24,24A24,24,0,0,1,176,40ZM80,40A24,24,0,1,1,56,64,24,24,0,0,1,80,40ZM203,197.51a40,40,0,1,0-53.94,0,67.27,67.27,0,0,0-21,14.31,67.27,67.27,0,0,0-21-14.31,40,40,0,1,0-53.94,0A67.88,67.88,0,0,0,25.6,219.2a8,8,0,1,0,12.8,9.6,52,52,0,0,1,83.2,0,8,8,0,0,0,11.2,1.59,7.73,7.73,0,0,0,1.59-1.59h0a52,52,0,0,1,83.2,0,8,8,0,0,0,12.8-9.61A67.85,67.85,0,0,0,203,197.51ZM80,144a24,24,0,1,1-24,24A24,24,0,0,1,80,144Zm96,0a24,24,0,1,1-24,24A24,24,0,0,1,176,144Z"></path></svg>

<svg *ngIf="type === 'clock'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48A8,8,0,0,1,192,128Z">
    </path>
</svg>

<svg *ngIf="type === 'mouse-simple'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M144,16H112A64.07,64.07,0,0,0,48,80v96a64.07,64.07,0,0,0,64,64h32a64.07,64.07,0,0,0,64-64V80A64.07,64.07,0,0,0,144,16Zm48,160a48.05,48.05,0,0,1-48,48H112a48.05,48.05,0,0,1-48-48V80a48.05,48.05,0,0,1,48-48h32a48.05,48.05,0,0,1,48,48ZM136,64v48a8,8,0,0,1-16,0V64a8,8,0,0,1,16,0Z">
    </path>
</svg>

<svg *ngIf="type === 'arrows-out'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,48V96a8,8,0,0,1-16,0V67.31l-42.34,42.35a8,8,0,0,1-11.32-11.32L188.69,56H160a8,8,0,0,1,0-16h48A8,8,0,0,1,216,48ZM98.34,146.34,56,188.69V160a8,8,0,0,0-16,0v48a8,8,0,0,0,8,8H96a8,8,0,0,0,0-16H67.31l42.35-42.34a8,8,0,0,0-11.32-11.32ZM208,152a8,8,0,0,0-8,8v28.69l-42.34-42.35a8,8,0,0,0-11.32,11.32L188.69,200H160a8,8,0,0,0,0,16h48a8,8,0,0,0,8-8V160A8,8,0,0,0,208,152ZM67.31,56H96a8,8,0,0,0,0-16H48a8,8,0,0,0-8,8V96a8,8,0,0,0,16,0V67.31l42.34,42.35a8,8,0,0,0,11.32-11.32Z">
    </path>
</svg>
<svg *ngIf="type === 'eye'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()"
    [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96"
    fill="#000000" viewBox="0 0 256 256">
    <path
        d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z">
    </path>
</svg>

<svg *ngIf="type === 'eye-slash'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()"
[style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M53.92,34.62A8,8,0,1,0,42.08,45.38L61.32,66.55C25,88.84,9.38,123.2,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208a127.11,127.11,0,0,0,52.07-10.83l22,24.21a8,8,0,1,0,11.84-10.76Zm47.33,75.84,41.67,45.85a32,32,0,0,1-41.67-45.85ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.16,133.16,0,0,1,25,128c4.69-8.79,19.66-33.39,47.35-49.38l18,19.75a48,48,0,0,0,63.66,70l14.73,16.2A112,112,0,0,1,128,192Zm6-95.43a8,8,0,0,1,3-15.72,48.16,48.16,0,0,1,38.77,42.64,8,8,0,0,1-7.22,8.71,6.39,6.39,0,0,1-.75,0,8,8,0,0,1-8-7.26A32.09,32.09,0,0,0,134,96.57Zm113.28,34.69c-.42.94-10.55,23.37-33.36,43.8a8,8,0,1,1-10.67-11.92A132.77,132.77,0,0,0,231.05,128a133.15,133.15,0,0,0-23.12-30.77C185.67,75.19,158.78,64,128,64a118.37,118.37,0,0,0-19.36,1.57A8,8,0,1,1,106,49.79,134,134,0,0,1,128,48c34.88,0,66.57,13.26,91.66,38.35,18.83,18.83,27.3,37.62,27.65,38.41A8,8,0,0,1,247.31,131.26Z"></path></svg>

<svg *ngIf="type === 'floppy-disk'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M219.31,72,184,36.69A15.86,15.86,0,0,0,172.69,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V83.31A15.86,15.86,0,0,0,219.31,72ZM168,208H88V152h80Zm40,0H184V152a16,16,0,0,0-16-16H88a16,16,0,0,0-16,16v56H48V48H172.69L208,83.31ZM160,72a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h56A8,8,0,0,1,160,72Z">
    </path>
</svg>
<svg *ngIf="type === 'funnel'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M230.6,49.53A15.81,15.81,0,0,0,216,40H40A16,16,0,0,0,28.19,66.76l.08.09L96,139.17V216a16,16,0,0,0,24.87,13.32l32-21.34A16,16,0,0,0,160,194.66V139.17l67.74-72.32.08-.09A15.8,15.8,0,0,0,230.6,49.53ZM40,56h0Zm106.18,74.58A8,8,0,0,0,144,136v58.66L112,216V136a8,8,0,0,0-2.16-5.47L40,56H216Z">
    </path>
</svg>
<svg *ngIf="type === 'user-check'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M144,157.68a68,68,0,1,0-71.9,0c-20.65,6.76-39.23,19.39-54.17,37.17a8,8,0,0,0,12.25,10.3C50.25,181.19,77.91,168,108,168s57.75,13.19,77.87,37.15a8,8,0,0,0,12.25-10.3C183.18,177.07,164.6,164.44,144,157.68ZM56,100a52,52,0,1,1,52,52A52.06,52.06,0,0,1,56,100Zm197.66,33.66-32,32a8,8,0,0,1-11.32,0l-16-16a8,8,0,0,1,11.32-11.32L216,148.69l26.34-26.35a8,8,0,0,1,11.32,11.32Z">
    </path>
</svg>
<!-- <svg *ngIf="type === 'user'" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"></path></svg> -->
<svg *ngIf="type === 'paper-plane-tilt'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M227.32,28.68a16,16,0,0,0-15.66-4.08l-.15,0L19.57,82.84a16,16,0,0,0-2.49,29.8L102,154l41.3,84.87A15.86,15.86,0,0,0,157.74,248q.69,0,1.38-.06a15.88,15.88,0,0,0,14-11.51l58.2-191.94c0-.05,0-.1,0-.15A16,16,0,0,0,227.32,28.68ZM157.83,231.85l-.05.14,0-.07-40.06-82.3,48-48a8,8,0,0,0-11.31-11.31l-48,48L24.08,98.25l-.07,0,.14,0L216,40Z">
    </path>
</svg>
<svg *ngIf="type === 'house-line'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M240,208H224V136l2.34,2.34A8,8,0,0,0,237.66,127L139.31,28.68a16,16,0,0,0-22.62,0L18.34,127a8,8,0,0,0,11.32,11.31L32,136v72H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM48,120l80-80,80,80v88H160V152a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8v56H48Zm96,88H112V160h32Z">
    </path>
</svg>
<svg *ngIf="type === 'head-circuit'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M192.5,171.47A88.34,88.34,0,0,0,224,101.93c-1-45.71-37.61-83.4-83.24-85.8A88,88,0,0,0,48,102L25.55,145.18c-.09.18-.18.36-.26.54a16,16,0,0,0,7.55,20.62l.25.11L56,176.94V208a16,16,0,0,0,16,16h48a8,8,0,0,0,0-16H72V171.81a8,8,0,0,0-4.67-7.28L40,152l23.07-44.34A7.9,7.9,0,0,0,64,104a72,72,0,0,1,56-70.21V49.38a24,24,0,1,0,16,0V32c1.3,0,2.6,0,3.9.1A72.26,72.26,0,0,1,203.84,80H184a8,8,0,0,0-6.15,2.88L152.34,113.5a24.06,24.06,0,1,0,12.28,10.25L187.75,96h19.79q.36,3.12.44,6.3a72.26,72.26,0,0,1-28.78,59.3,8,8,0,0,0-3.14,7.39l8,64a8,8,0,0,0,7.93,7,8.39,8.39,0,0,0,1-.06,8,8,0,0,0,6.95-8.93ZM128,80a8,8,0,1,1,8-8A8,8,0,0,1,128,80Zm16,64a8,8,0,1,1,8-8A8,8,0,0,1,144,144Z">
    </path>
</svg>

<svg *ngIf="type === 'bug-beetle'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,152h16a8,8,0,0,0,0-16H208V120h16a8,8,0,0,0,0-16H207.6a79.76,79.76,0,0,0-21.44-46.85l19.5-19.49a8,8,0,0,0-11.32-11.32l-20.29,20.3a79.74,79.74,0,0,0-92.1,0L61.66,26.34A8,8,0,0,0,50.34,37.66l19.5,19.49A79.76,79.76,0,0,0,48.4,104H32a8,8,0,0,0,0,16H48v16H32a8,8,0,0,0,0,16H48v8c0,2.7.14,5.37.4,8H32a8,8,0,0,0,0,16H51.68a80,80,0,0,0,152.64,0H224a8,8,0,0,0,0-16H207.6c.26-2.63.4-5.3.4-8ZM128,48a64.07,64.07,0,0,1,63.48,56h-127A64.07,64.07,0,0,1,128,48Zm8,175.48V144a8,8,0,0,0-16,0v79.48A64.07,64.07,0,0,1,64,160V120H192v40A64.07,64.07,0,0,1,136,223.48Z"></path></svg>

<svg *ngIf="type === 'tree-structure'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M160,112h48a16,16,0,0,0,16-16V48a16,16,0,0,0-16-16H160a16,16,0,0,0-16,16V64H128a24,24,0,0,0-24,24v32H72v-8A16,16,0,0,0,56,96H24A16,16,0,0,0,8,112v32a16,16,0,0,0,16,16H56a16,16,0,0,0,16-16v-8h32v32a24,24,0,0,0,24,24h16v16a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V160a16,16,0,0,0-16-16H160a16,16,0,0,0-16,16v16H128a8,8,0,0,1-8-8V88a8,8,0,0,1,8-8h16V96A16,16,0,0,0,160,112ZM56,144H24V112H56v32Zm104,16h48v48H160Zm0-112h48V96H160Z">
    </path>
</svg>

<svg *ngIf="type === 'flow-arrow'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M245.66,74.34l-32-32a8,8,0,0,0-11.32,11.32L220.69,72H208c-49.33,0-61.05,28.12-71.38,52.92-9.38,22.51-16.92,40.59-49.48,42.84a40,40,0,1,0,.1,16c43.26-2.65,54.34-29.15,64.14-52.69C161.41,107,169.33,88,208,88h12.69l-18.35,18.34a8,8,0,0,0,11.32,11.32l32-32A8,8,0,0,0,245.66,74.34ZM48,200a24,24,0,1,1,24-24A24,24,0,0,1,48,200Z"></path></svg>


<svg *ngIf="type === 'tree-view'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M176,152h32a16,16,0,0,0,16-16V104a16,16,0,0,0-16-16H176a16,16,0,0,0-16,16v8H88V80h8a16,16,0,0,0,16-16V32A16,16,0,0,0,96,16H64A16,16,0,0,0,48,32V64A16,16,0,0,0,64,80h8V192a24,24,0,0,0,24,24h64v8a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V192a16,16,0,0,0-16-16H176a16,16,0,0,0-16,16v8H96a8,8,0,0,1-8-8V128h72v8A16,16,0,0,0,176,152ZM64,32H96V64H64ZM176,192h32v32H176Zm0-88h32v32H176Z"></path></svg>


<!-- <svg *ngIf="type === 'file-pdf'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,152a8,8,0,0,1-8,8H192v16h16a8,8,0,0,1,0,16H192v16a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8h32A8,8,0,0,1,224,152ZM92,172a28,28,0,0,1-28,28H56v8a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8H64A28,28,0,0,1,92,172Zm-16,0a12,12,0,0,0-12-12H56v24h8A12,12,0,0,0,76,172Zm88,8a36,36,0,0,1-36,36H112a8,8,0,0,1-8-8V152a8,8,0,0,1,8-8h16A36,36,0,0,1,164,180Zm-16,0a20,20,0,0,0-20-20h-8v40h8A20,20,0,0,0,148,180ZM40,112V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88v24a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0ZM160,80h28.69L160,51.31Z">
    </path>
</svg> -->
<svg *ngIf="type === 'file'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" svg
    xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z">
    </path>
</svg>
<svg *ngIf="type === 'seal-check'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M225.86,102.82c-3.77-3.94-7.67-8-9.14-11.57-1.36-3.27-1.44-8.69-1.52-13.94-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52-3.56-1.47-7.63-5.37-11.57-9.14C146.28,23.51,138.44,16,128,16s-18.27,7.51-25.18,14.14c-3.94,3.77-8,7.67-11.57,9.14C88,40.64,82.56,40.72,77.31,40.8c-9.76.15-20.82.31-28.51,8S41,67.55,40.8,77.31c-.08,5.25-.16,10.67-1.52,13.94-1.47,3.56-5.37,7.63-9.14,11.57C23.51,109.72,16,117.56,16,128s7.51,18.27,14.14,25.18c3.77,3.94,7.67,8,9.14,11.57,1.36,3.27,1.44,8.69,1.52,13.94.15,9.76.31,20.82,8,28.51s18.75,7.85,28.51,8c5.25.08,10.67.16,13.94,1.52,3.56,1.47,7.63,5.37,11.57,9.14C109.72,232.49,117.56,240,128,240s18.27-7.51,25.18-14.14c3.94-3.77,8-7.67,11.57-9.14,3.27-1.36,8.69-1.44,13.94-1.52,9.76-.15,20.82-.31,28.51-8s7.85-18.75,8-28.51c.08-5.25.16-10.67,1.52-13.94,1.47-3.56,5.37-7.63,9.14-11.57C232.49,146.28,240,138.44,240,128S232.49,109.73,225.86,102.82Zm-11.55,39.29c-4.79,5-9.75,10.17-12.38,16.52-2.52,6.1-2.63,13.07-2.73,19.82-.1,7-.21,14.33-3.32,17.43s-10.39,3.22-17.43,3.32c-6.75.1-13.72.21-19.82,2.73-6.35,2.63-11.52,7.59-16.52,12.38S132,224,128,224s-9.15-4.92-14.11-9.69-10.17-9.75-16.52-12.38c-6.1-2.52-13.07-2.63-19.82-2.73-7-.1-14.33-.21-17.43-3.32s-3.22-10.39-3.32-17.43c-.1-6.75-.21-13.72-2.73-19.82-2.63-6.35-7.59-11.52-12.38-16.52S32,132,32,128s4.92-9.15,9.69-14.11,9.75-10.17,12.38-16.52c2.52-6.1,2.63-13.07,2.73-19.82.1-7,.21-14.33,3.32-17.43S70.51,56.9,77.55,56.8c6.75-.1,13.72-.21,19.82-2.73,6.35-2.63,11.52-7.59,16.52-12.38S124,32,128,32s9.15,4.92,14.11,9.69,10.17,9.75,16.52,12.38c6.1,2.52,13.07,2.63,19.82,2.73,7,.1,14.33.21,17.43,3.32s3.22,10.39,3.32,17.43c.1,6.75.21,13.72,2.73,19.82,2.63,6.35,7.59,11.52,12.38,16.52S224,124,224,128,219.08,137.15,214.31,142.11ZM173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34Z">
    </path>
</svg>

<svg *ngIf="type === 'x-circle'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z">
    </path>
</svg>

<svg *ngIf="type === 'download-simple'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,124.69V32a8,8,0,0,0-16,0v92.69L93.66,98.34a8,8,0,0,0-11.32,11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'file-dashed'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M80,224a8,8,0,0,1-8,8H56a16,16,0,0,1-16-16V184a8,8,0,0,1,16,0v32H72A8,8,0,0,1,80,224ZM216,88v48a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H120a8,8,0,0,1,0-16h32a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88Zm-56-8h28.69L160,51.31ZM80,24H56A16,16,0,0,0,40,40V64a8,8,0,0,0,16,0V40H80a8,8,0,0,0,0-16ZM208,168a8,8,0,0,0-8,8v40h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V176A8,8,0,0,0,208,168ZM48,152a8,8,0,0,0,8-8V104a8,8,0,0,0-16,0v40A8,8,0,0,0,48,152Zm104,64H112a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z">
    </path>
</svg>

<svg *ngIf="type === 'list-numbers'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,128a8,8,0,0,1-8,8H104a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM104,72H216a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16ZM216,184H104a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM43.58,55.16,48,52.94V104a8,8,0,0,0,16,0V40a8,8,0,0,0-11.58-7.16l-16,8a8,8,0,0,0,7.16,14.32ZM79.77,156.72a23.73,23.73,0,0,0-9.6-15.95,24.86,24.86,0,0,0-34.11,4.7,23.63,23.63,0,0,0-3.57,6.46,8,8,0,1,0,15,5.47,7.84,7.84,0,0,1,1.18-2.13,8.76,8.76,0,0,1,12-1.59A7.91,7.91,0,0,1,63.93,159a7.64,7.64,0,0,1-1.57,5.78,1,1,0,0,0-.08.11L33.59,203.21A8,8,0,0,0,40,216H72a8,8,0,0,0,0-16H56l19.08-25.53A23.47,23.47,0,0,0,79.77,156.72Z">
    </path>
</svg>

<svg *ngIf="type === 'sort-descending'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M40,128a8,8,0,0,1,8-8h72a8,8,0,0,1,0,16H48A8,8,0,0,1,40,128Zm8-56h56a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16ZM184,184H48a8,8,0,0,0,0,16H184a8,8,0,0,0,0-16ZM229.66,82.34l-40-40a8,8,0,0,0-11.32,0l-40,40a8,8,0,0,0,11.32,11.32L176,67.31V144a8,8,0,0,0,16,0V67.31l26.34,26.35a8,8,0,0,0,11.32-11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'text-aa'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M87.24,52.59a8,8,0,0,0-14.48,0l-64,136a8,8,0,1,0,14.48,6.81L39.9,160h80.2l16.66,35.4a8,8,0,1,0,14.48-6.81ZM47.43,144,80,74.79,112.57,144ZM200,96c-12.76,0-22.73,3.47-29.63,10.32a8,8,0,0,0,11.26,11.36c3.8-3.77,10-5.68,18.37-5.68,13.23,0,24,9,24,20v3.22A42.76,42.76,0,0,0,200,128c-22.06,0-40,16.15-40,36s17.94,36,40,36a42.73,42.73,0,0,0,24-7.25,8,8,0,0,0,16-.75V132C240,112.15,222.06,96,200,96Zm0,88c-13.23,0-24-9-24-20s10.77-20,24-20,24,9,24,20S213.23,184,200,184Z">
    </path>
</svg>

<svg *ngIf="type === 'user-list'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M152,80a8,8,0,0,1,8-8h88a8,8,0,0,1,0,16H160A8,8,0,0,1,152,80Zm96,40H160a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16Zm0,48H184a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm-96.25,22a8,8,0,0,1-5.76,9.74,7.55,7.55,0,0,1-2,.26,8,8,0,0,1-7.75-6c-6.16-23.94-30.34-42-56.25-42s-50.09,18.05-56.25,42a8,8,0,0,1-15.5-4c5.59-21.71,21.84-39.29,42.46-48a48,48,0,1,1,58.58,0C129.91,150.71,146.16,168.29,151.75,190ZM80,136a32,32,0,1,0-32-32A32,32,0,0,0,80,136Z">
    </path>
</svg>

<svg *ngIf="type === 'list-checks'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H128a8,8,0,0,1,0-16h88A8,8,0,0,1,224,128ZM128,72h88a8,8,0,0,0,0-16H128a8,8,0,0,0,0,16Zm88,112H128a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16ZM82.34,42.34,56,68.69,45.66,58.34A8,8,0,0,0,34.34,69.66l16,16a8,8,0,0,0,11.32,0l32-32A8,8,0,0,0,82.34,42.34Zm0,64L56,132.69,45.66,122.34a8,8,0,0,0-11.32,11.32l16,16a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Zm0,64L56,196.69,45.66,186.34a8,8,0,0,0-11.32,11.32l16,16a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Z"></path></svg>

<!-- Added CHST -->
<svg *ngIf="type === 'key-return'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M184,104v32a8,8,0,0,1-8,8H99.31l10.35,10.34a8,8,0,0,1-11.32,11.32l-24-24a8,8,0,0,1,0-11.32l24-24a8,8,0,0,1,11.32,11.32L99.31,128H168V104a8,8,0,0,1,16,0Zm48-48V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Z">
    </path>
</svg>

<svg *ngIf="type === 'file-xls'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M156,208a8,8,0,0,1-8,8H120a8,8,0,0,1-8-8V152a8,8,0,0,1,16,0v48h20A8,8,0,0,1,156,208ZM92.65,145.49a8,8,0,0,0-11.16,1.86L68,166.24,54.51,147.35a8,8,0,1,0-13,9.3L58.17,180,41.49,203.35a8,8,0,0,0,13,9.3L68,193.76l13.49,18.89a8,8,0,0,0,13-9.3L77.83,180l16.68-23.35A8,8,0,0,0,92.65,145.49Zm98.94,25.82c-4-1.16-8.14-2.35-10.45-3.84-1.25-.82-1.23-1-1.12-1.9a4.54,4.54,0,0,1,2-3.67c4.6-3.12,15.34-1.72,19.82-.56a8,8,0,0,0,4.07-15.48c-2.11-.55-21-5.22-32.83,2.76a20.58,20.58,0,0,0-8.95,14.95c-2,15.88,13.65,20.41,23,23.11,12.06,3.49,13.12,4.92,12.78,7.59-.31,2.41-1.26,3.33-2.15,3.93-4.6,3.06-15.16,1.55-19.54.35A8,8,0,0,0,173.93,214a60.63,60.63,0,0,0,15.19,2c5.82,0,12.3-1,17.49-4.46a20.81,20.81,0,0,0,9.18-15.23C218,179,201.48,174.17,191.59,171.31ZM40,112V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88v24a8,8,0,1,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0ZM160,80h28.68L160,51.31Z">
    </path>
</svg>

<svg *ngIf="type === 'copy'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="96" height="96" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z">
    </path>
</svg>

<svg *ngIf="type === 'prohibit'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm88,104a87.56,87.56,0,0,1-20.41,56.28L71.72,60.4A88,88,0,0,1,216,128ZM40,128A87.56,87.56,0,0,1,60.41,71.72L184.28,195.6A88,88,0,0,1,40,128Z">
    </path>
</svg>

<svg *ngIf="type === 'receipt-x'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,40H40A16,16,0,0,0,24,56V208a8,8,0,0,0,11.58,7.15L64,200.94l28.42,14.21a8,8,0,0,0,7.16,0L128,200.94l28.42,14.21a8,8,0,0,0,7.16,0L192,200.94l28.42,14.21A8,8,0,0,0,232,208V56A16,16,0,0,0,216,40Zm0,155.06-20.42-10.22a8,8,0,0,0-7.16,0L160,199.06l-28.42-14.22a8,8,0,0,0-7.16,0L96,199.06,67.58,184.84a8,8,0,0,0-7.16,0L40,195.06V56H216ZM98.34,138.34,116.69,120,98.34,101.66a8,8,0,0,1,11.32-11.32L128,108.69l18.34-18.35a8,8,0,0,1,11.32,11.32L139.31,120l18.35,18.34a8,8,0,0,1-11.32,11.32L128,131.31l-18.34,18.35a8,8,0,0,1-11.32-11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'stop'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M200,40H56A16,16,0,0,0,40,56V200a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V56A16,16,0,0,0,200,40Zm0,160H56V56H200V200Z">
    </path>
</svg>

<svg *ngIf="type === 'stop-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path d="M216,56V200a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V56A16,16,0,0,1,56,40H200A16,16,0,0,1,216,56Z"></path>
</svg>

<svg *ngIf="type === 'pause'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M200,32H160a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm0,176H160V48h40ZM96,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V48A16,16,0,0,0,96,32Zm0,176H56V48H96Z">
    </path>
</svg>


<svg *ngIf="type === 'pause-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,48V208a16,16,0,0,1-16,16H160a16,16,0,0,1-16-16V48a16,16,0,0,1,16-16h40A16,16,0,0,1,216,48ZM96,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V48A16,16,0,0,0,96,32Z">
    </path>
</svg>

<svg *ngIf="type === 'play'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M232.4,114.49,88.32,26.35a16,16,0,0,0-16.2-.3A15.86,15.86,0,0,0,64,39.87V216.13A15.94,15.94,0,0,0,80,232a16.07,16.07,0,0,0,8.36-2.35L232.4,141.51a15.81,15.81,0,0,0,0-27ZM80,215.94V40l143.83,88Z">
    </path>
</svg>

<svg *ngIf="type === 'play-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M240,128a15.74,15.74,0,0,1-7.6,13.51L88.32,229.65a16,16,0,0,1-16.2.3A15.86,15.86,0,0,1,64,216.13V39.87a15.86,15.86,0,0,1,8.12-13.82,16,16,0,0,1,16.2.3L232.4,114.49A15.74,15.74,0,0,1,240,128Z">
    </path>
</svg>



<svg *ngIf="type === 'play-pause'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M184,64V192a8,8,0,0,1-16,0V64a8,8,0,0,1,16,0Zm40-8a8,8,0,0,0-8,8V192a8,8,0,0,0,16,0V64A8,8,0,0,0,224,56Zm-80,72a15.76,15.76,0,0,1-7.33,13.34L48.48,197.49A15.91,15.91,0,0,1,24,184.15V71.85A15.91,15.91,0,0,1,48.48,58.51l88.19,56.15A15.76,15.76,0,0,1,144,128Zm-16.18,0L40,72.08V183.93Z">
    </path>
</svg>


<svg *ngIf="type === 'play-pause-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M184,64V192a8,8,0,0,1-16,0V64a8,8,0,0,1,16,0Zm40-8a8,8,0,0,0-8,8V192a8,8,0,0,0,16,0V64A8,8,0,0,0,224,56Zm-87.33,58.66L48.48,58.51A15.91,15.91,0,0,0,24,71.85v112.3A15.83,15.83,0,0,0,32.23,198a15.95,15.95,0,0,0,16.25-.53l88.19-56.15a15.8,15.8,0,0,0,0-26.68Z">
    </path>
</svg>


<svg *ngIf="type === 'cursor'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M168,132.69,214.08,115l.33-.13A16,16,0,0,0,213,85.07L52.92,32.8A15.95,15.95,0,0,0,32.8,52.92L85.07,213a15.82,15.82,0,0,0,14.41,11l.78,0a15.84,15.84,0,0,0,14.61-9.59l.13-.33L132.69,168,184,219.31a16,16,0,0,0,22.63,0l12.68-12.68a16,16,0,0,0,0-22.63ZM195.31,208,144,156.69a16,16,0,0,0-26,4.93c0,.11-.09.22-.13.32l-17.65,46L48,48l159.85,52.2-45.95,17.64-.32.13a16,16,0,0,0-4.93,26h0L208,195.31Z"></path></svg>





<svg *ngIf="type === 'seal-check-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M225.86,102.82c-3.77-3.94-7.67-8-9.14-11.57-1.36-3.27-1.44-8.69-1.52-13.94-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52-3.56-1.47-7.63-5.37-11.57-9.14C146.28,23.51,138.44,16,128,16s-18.27,7.51-25.18,14.14c-3.94,3.77-8,7.67-11.57,9.14C88,40.64,82.56,40.72,77.31,40.8c-9.76.15-20.82.31-28.51,8S41,67.55,40.8,77.31c-.08,5.25-.16,10.67-1.52,13.94-1.47,3.56-5.37,7.63-9.14,11.57C23.51,109.72,16,117.56,16,128s7.51,18.27,14.14,25.18c3.77,3.94,7.67,8,9.14,11.57,1.36,3.27,1.44,8.69,1.52,13.94.15,9.76.31,20.82,8,28.51s18.75,7.85,28.51,8c5.25.08,10.67.16,13.94,1.52,3.56,1.47,7.63,5.37,11.57,9.14C109.72,232.49,117.56,240,128,240s18.27-7.51,25.18-14.14c3.94-3.77,8-7.67,11.57-9.14,3.27-1.36,8.69-1.44,13.94-1.52,9.76-.15,20.82-.31,28.51-8s7.85-18.75,8-28.51c.08-5.25.16-10.67,1.52-13.94,1.47-3.56,5.37-7.63,9.14-11.57C232.49,146.28,240,138.44,240,128S232.49,109.73,225.86,102.82Zm-52.2,6.84-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'layout'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V96H40V56ZM40,112H96v88H40Zm176,88H112V112H216v88Z">
    </path>
</svg>

<svg *ngIf="type === 'sliders-horizontal'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M40,88H73a32,32,0,0,0,62,0h81a8,8,0,0,0,0-16H135a32,32,0,0,0-62,0H40a8,8,0,0,0,0,16Zm64-24A16,16,0,1,1,88,80,16,16,0,0,1,104,64ZM216,168H199a32,32,0,0,0-62,0H40a8,8,0,0,0,0,16h97a32,32,0,0,0,62,0h17a8,8,0,0,0,0-16Zm-48,24a16,16,0,1,1,16-16A16,16,0,0,1,168,192Z">
    </path>
</svg>

<svg *ngIf="type === 'chart-line-up'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0V156.69l50.34-50.35a8,8,0,0,1,11.32,0L128,132.69,180.69,80H160a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8v40a8,8,0,0,1-16,0V91.31l-58.34,58.35a8,8,0,0,1-11.32,0L96,123.31l-56,56V200H224A8,8,0,0,1,232,208Z">
    </path>
</svg>

<svg *ngIf="type === 'chart-line'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0v94.37L90.73,98a8,8,0,0,1,10.07-.38l58.81,44.11L218.73,90a8,8,0,1,1,10.54,12l-64,56a8,8,0,0,1-10.07.38L96.39,114.29,40,163.63V200H224A8,8,0,0,1,232,208Z"></path></svg>

<svg *ngIf="type === 'pulse'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M240,128a8,8,0,0,1-8,8H204.94l-37.78,75.58A8,8,0,0,1,160,216h-.4a8,8,0,0,1-7.08-5.14L95.35,60.76,63.28,131.31A8,8,0,0,1,56,136H24a8,8,0,0,1,0-16H50.85L88.72,36.69a8,8,0,0,1,14.76.46l57.51,151,31.85-63.71A8,8,0,0,1,200,120h32A8,8,0,0,1,240,128Z">
    </path>
</svg>

<svg *ngIf="type === 'ranking'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M112.41,102.53a8,8,0,0,1,5.06-10.12l12-4A8,8,0,0,1,140,96v40a8,8,0,0,1-16,0V107.1l-1.47.49A8,8,0,0,1,112.41,102.53ZM248,208a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16h8V104A16,16,0,0,1,40,88H80V56A16,16,0,0,1,96,40h64a16,16,0,0,1,16,16v72h40a16,16,0,0,1,16,16v56h8A8,8,0,0,1,248,208Zm-72-64v56h40V144ZM96,200h64V56H96Zm-56,0H80V104H40Z">
    </path>
</svg>

<svg *ngIf="type === 'address-book-tabs'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm-16,72h16v48H192Zm16-16H192V48h16ZM48,48H176V208H48ZM208,208H192V168h16v40Zm-56.25-42a39.76,39.76,0,0,0-17.19-23.34,32,32,0,1,0-45.12,0A39.84,39.84,0,0,0,72.25,166a8,8,0,0,0,15.5,4c2.64-10.25,13.06-18,24.25-18s21.62,7.73,24.25,18a8,8,0,1,0,15.5-4ZM96,120a16,16,0,1,1,16,16A16,16,0,0,1,96,120Z">
    </path>
</svg>

<svg *ngIf="type === 'folder-simple-plus'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,72H130.67L102.93,51.2a16.12,16.12,0,0,0-9.6-3.2H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216.89A15.13,15.13,0,0,0,232,200.89V88A16,16,0,0,0,216,72Zm0,128H40V64H93.33L123.2,86.4A8,8,0,0,0,128,88h88Zm-56-56a8,8,0,0,1-8,8H136v16a8,8,0,0,1-16,0V152H104a8,8,0,0,1,0-16h16V120a8,8,0,0,1,16,0v16h16A8,8,0,0,1,160,144Z">
    </path>
</svg>

<svg *ngIf="type === 'folder'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,72H131.31L104,44.69A15.86,15.86,0,0,0,92.69,40H40A16,16,0,0,0,24,56V200.62A15.4,15.4,0,0,0,39.38,216H216.89A15.13,15.13,0,0,0,232,200.89V88A16,16,0,0,0,216,72ZM40,56H92.69l16,16H40ZM216,200H40V88H216Z">
    </path>
</svg>

<svg *ngIf="type === 'folder-simple-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M232,88V200.89A15.13,15.13,0,0,1,216.89,216H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.33a16.12,16.12,0,0,1,9.6,3.2L130.67,72H216A16,16,0,0,1,232,88Z">
    </path>
</svg>

<svg *ngIf="type === 'folder-open'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M245,110.64A16,16,0,0,0,232,104H216V88a16,16,0,0,0-16-16H130.67L102.94,51.2a16.14,16.14,0,0,0-9.6-3.2H40A16,16,0,0,0,24,64V208h0a8,8,0,0,0,8,8H211.1a8,8,0,0,0,7.59-5.47l28.49-85.47A16.05,16.05,0,0,0,245,110.64ZM93.34,64,123.2,86.4A8,8,0,0,0,128,88h72v16H69.77a16,16,0,0,0-15.18,10.94L40,158.7V64Zm112,136H43.1l26.67-80H232Z">
    </path>
</svg>

<svg *ngIf="type === 'folder-simple-lock-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,160h-8v-4a28,28,0,0,0-56,0v4h-8a8,8,0,0,0-8,8v40a8,8,0,0,0,8,8h72a8,8,0,0,0,8-8V168A8,8,0,0,0,224,160Zm-24,0H176v-4a12,12,0,0,1,24,0Zm32-72v16a8,8,0,0,1-16,0V88H130.67a16.12,16.12,0,0,1-9.6-3.2L93.33,64H40V200h72a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.33a16.12,16.12,0,0,1,9.6,3.2L130.67,72H216A16,16,0,0,1,232,88Z">
    </path>
</svg>

<svg *ngIf="type === 'folder-simple-lock'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M232,88v16a8,8,0,0,1-16,0V88H130.67a16.12,16.12,0,0,1-9.6-3.2L93.33,64H40V200h72a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.33a16.12,16.12,0,0,1,9.6,3.2L130.67,72H216A16,16,0,0,1,232,88Zm0,80v40a8,8,0,0,1-8,8H152a8,8,0,0,1-8-8V168a8,8,0,0,1,8-8h8v-4a28,28,0,0,1,56,0v4h8A8,8,0,0,1,232,168Zm-56-8h24v-4a12,12,0,0,0-24,0Zm40,16H160v24h56Z">
    </path>
</svg>

<svg *ngIf="type === 'arrow-circle-left'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H107.31l18.35,18.34a8,8,0,0,1-11.32,11.32l-32-32a8,8,0,0,1,0-11.32l32-32a8,8,0,0,1,11.32,11.32L107.31,120H168A8,8,0,0,1,176,128Z">
    </path>
</svg>

<svg *ngIf="type === 'arrow-circle-left-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm40,112H107.31l18.35,18.34a8,8,0,0,1-11.32,11.32l-32-32a8,8,0,0,1,0-11.32l32-32a8,8,0,0,1,11.32,11.32L107.31,120H168a8,8,0,0,1,0,16Z">
    </path>
</svg>

<!--      doc -->
<svg *ngIf="type === 'file-doc'|| type === 'file-docx'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M52,144H36a8,8,0,0,0-8,8v56a8,8,0,0,0,8,8H52a36,36,0,0,0,0-72Zm0,56H44V160h8a20,20,0,0,1,0,40Zm169.53-4.91a8,8,0,0,1,.25,11.31A30.06,30.06,0,0,1,200,216c-17.65,0-32-16.15-32-36s14.35-36,32-36a30.06,30.06,0,0,1,21.78,9.6,8,8,0,0,1-11.56,11.06A14.24,14.24,0,0,0,200,160c-8.82,0-16,9-16,20s7.18,20,16,20a14.24,14.24,0,0,0,10.22-4.66A8,8,0,0,1,221.53,195.09ZM128,144c-17.65,0-32,16.15-32,36s14.35,36,32,36,32-16.15,32-36S145.65,144,128,144Zm0,56c-8.82,0-16-9-16-20s7.18-20,16-20,16,9,16,20S136.82,200,128,200ZM48,120a8,8,0,0,0,8-8V40h88V88a8,8,0,0,0,8,8h48v16a8,8,0,0,0,16,0V88a8,8,0,0,0-2.34-5.66l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40v72A8,8,0,0,0,48,120ZM160,51.31,188.69,80H160Z">
    </path>
</svg>

<!--    pdf -->
<svg *ngIf="type === 'file-pdf'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()"
    [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
    fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,152a8,8,0,0,1-8,8H192v16h16a8,8,0,0,1,0,16H192v16a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8h32A8,8,0,0,1,224,152ZM92,172a28,28,0,0,1-28,28H56v8a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8H64A28,28,0,0,1,92,172Zm-16,0a12,12,0,0,0-12-12H56v24h8A12,12,0,0,0,76,172Zm88,8a36,36,0,0,1-36,36H112a8,8,0,0,1-8-8V152a8,8,0,0,1,8-8h16A36,36,0,0,1,164,180Zm-16,0a20,20,0,0,0-20-20h-8v40h8A20,20,0,0,0,148,180ZM40,112V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88v24a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0ZM160,80h28.69L160,51.31Z">
    </path>
</svg>

<!--      powerpoint -->
<svg *ngIf="type === 'file-pptx'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M224,152a8,8,0,0,1-8,8H204v48a8,8,0,0,1-16,0V160H176a8,8,0,0,1,0-16h40A8,8,0,0,1,224,152ZM92,172a28,28,0,0,1-28,28H56v8a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8H64A28,28,0,0,1,92,172Zm-16,0a12,12,0,0,0-12-12H56v24h8A12,12,0,0,0,76,172Zm84,0a28,28,0,0,1-28,28h-8v8a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8h16A28,28,0,0,1,160,172Zm-16,0a12,12,0,0,0-12-12h-8v24h8A12,12,0,0,0,144,172ZM40,112V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88v24a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0ZM160,80h28.69L160,51.31Z">
    </path>
</svg>

<!--  TXT -->
<svg *ngIf="type === 'file-txt'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()"
    [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
    fill="#000000" viewBox="0 0 256 256">
    <path
        d="M48,120a8,8,0,0,0,8-8V40h88V88a8,8,0,0,0,8,8h48v16a8,8,0,0,0,16,0V88a8,8,0,0,0-2.34-5.66l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40v72A8,8,0,0,0,48,120ZM160,51.31,188.69,80H160Zm-5.49,105.34L137.83,180l16.68,23.35a8,8,0,0,1-13,9.3L128,193.76l-13.49,18.89a8,8,0,1,1-13-9.3L118.17,180l-16.68-23.35a8,8,0,1,1,13-9.3L128,166.24l13.49-18.89a8,8,0,0,1,13,9.3ZM92,152a8,8,0,0,1-8,8H72v48a8,8,0,0,1-16,0V160H44a8,8,0,0,1,0-16H84A8,8,0,0,1,92,152Zm128,0a8,8,0,0,1-8,8H200v48a8,8,0,0,1-16,0V160H172a8,8,0,0,1,0-16h40A8,8,0,0,1,220,152Z">
    </path>
</svg>

<!--     XLS  -->
<svg *ngIf="type === 'file-xlsx'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M156,208a8,8,0,0,1-8,8H120a8,8,0,0,1-8-8V152a8,8,0,0,1,16,0v48h20A8,8,0,0,1,156,208ZM92.65,145.49a8,8,0,0,0-11.16,1.86L68,166.24,54.51,147.35a8,8,0,1,0-13,9.3L58.17,180,41.49,203.35a8,8,0,0,0,13,9.3L68,193.76l13.49,18.89a8,8,0,0,0,13-9.3L77.83,180l16.68-23.35A8,8,0,0,0,92.65,145.49Zm98.94,25.82c-4-1.16-8.14-2.35-10.45-3.84-1.25-.82-1.23-1-1.12-1.9a4.54,4.54,0,0,1,2-3.67c4.6-3.12,15.34-1.72,19.82-.56a8,8,0,0,0,4.07-15.48c-2.11-.55-21-5.22-32.83,2.76a20.58,20.58,0,0,0-8.95,14.95c-2,15.88,13.65,20.41,23,23.11,12.06,3.49,13.12,4.92,12.78,7.59-.31,2.41-1.26,3.33-2.15,3.93-4.6,3.06-15.16,1.55-19.54.35A8,8,0,0,0,173.93,214a60.63,60.63,0,0,0,15.19,2c5.82,0,12.3-1,17.49-4.46a20.81,20.81,0,0,0,9.18-15.23C218,179,201.48,174.17,191.59,171.31ZM40,112V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88v24a8,8,0,1,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0ZM160,80h28.68L160,51.31Z">
    </path>
</svg>

<!--    CSV -->
<svg *ngIf="type === 'file-csv'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()"
    [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
    fill="#000000" viewBox="0 0 256 256">
    <path
        d="M48,180c0,11,7.18,20,16,20a14.24,14.24,0,0,0,10.22-4.66A8,8,0,0,1,85.78,206.4,30.06,30.06,0,0,1,64,216c-17.65,0-32-16.15-32-36s14.35-36,32-36a30.06,30.06,0,0,1,21.78,9.6,8,8,0,0,1-11.56,11.06A14.24,14.24,0,0,0,64,160C55.18,160,48,169,48,180Zm79.6-8.69c-4-1.16-8.14-2.35-10.45-3.84-1.25-.81-1.23-1-1.12-1.9a4.57,4.57,0,0,1,2-3.67c4.6-3.12,15.34-1.73,19.82-.56A8,8,0,0,0,142,145.86c-2.12-.55-21-5.22-32.84,2.76a20.58,20.58,0,0,0-9,14.95c-2,15.88,13.65,20.41,23,23.11,12.06,3.49,13.12,4.92,12.78,7.59-.31,2.41-1.26,3.34-2.14,3.93-4.6,3.06-15.17,1.56-19.55.36A8,8,0,0,0,109.94,214a61.34,61.34,0,0,0,15.19,2c5.82,0,12.3-1,17.49-4.46a20.82,20.82,0,0,0,9.19-15.23C154,179,137.49,174.17,127.6,171.31Zm83.09-26.84a8,8,0,0,0-10.23,4.84L188,184.21l-12.47-34.9a8,8,0,0,0-15.07,5.38l20,56a8,8,0,0,0,15.07,0l20-56A8,8,0,0,0,210.69,144.47ZM216,88v24a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88Zm-27.31-8L160,51.31V80Z">
    </path>
</svg>


<svg *ngIf="type === 'file-arrow-down-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34Zm-56,83.32-24,24a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L120,164.69V120a8,8,0,0,1,16,0v44.69l10.34-10.35a8,8,0,0,1,11.32,11.32ZM152,88V44l44,44Z">
    </path>
</svg>

<svg *ngIf="type === 'file-arrow-down'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-42.34-61.66a8,8,0,0,1,0,11.32l-24,24a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L120,164.69V120a8,8,0,0,1,16,0v44.69l10.34-10.35A8,8,0,0,1,157.66,154.34Z">
    </path>
</svg>

<svg *ngIf="type === 'file-arrow-up'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-42.34-77.66a8,8,0,0,1-11.32,11.32L136,139.31V184a8,8,0,0,1-16,0V139.31l-10.34,10.35a8,8,0,0,1-11.32-11.32l24-24a8,8,0,0,1,11.32,0Z">
    </path>
</svg>

<svg *ngIf="type === 'file-arrow-up-fill'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34Zm-56,67.32a8,8,0,0,1-11.32,0L136,139.31V184a8,8,0,0,1-16,0V139.31l-10.34,10.35a8,8,0,0,1-11.32-11.32l24-24a8,8,0,0,1,11.32,0l24,24A8,8,0,0,1,157.66,149.66ZM152,88V44l44,44Z">
    </path>
</svg>

<svg *ngIf="type === 'arrow-circle-right'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm45.66-93.66a8,8,0,0,1,0,11.32l-32,32a8,8,0,0,1-11.32-11.32L148.69,136H88a8,8,0,0,1,0-16h60.69l-18.35-18.34a8,8,0,0,1,11.32-11.32Z">
    </path>
</svg>

<svg *ngIf="type === 'tray'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,16V152h-28.7A15.86,15.86,0,0,0,168,156.69L148.69,176H107.31L88,156.69A15.86,15.86,0,0,0,76.69,152H48V48Zm0,160H48V168H76.69L96,187.31A15.86,15.86,0,0,0,107.31,192h41.38A15.86,15.86,0,0,0,160,187.31L179.31,168H208v40Z">
    </path>
</svg>

<svg *ngIf="type === 'newspaper-clipping'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M216,40H40A16,16,0,0,0,24,56V216a8,8,0,0,0,11.58,7.15L64,208.94l28.42,14.21a8,8,0,0,0,7.16,0L128,208.94l28.42,14.21a8,8,0,0,0,7.16,0L192,208.94l28.42,14.21A8,8,0,0,0,232,216V56A16,16,0,0,0,216,40Zm0,163.06-20.42-10.22a8,8,0,0,0-7.16,0L160,207.06l-28.42-14.22a8,8,0,0,0-7.16,0L96,207.06,67.58,192.84a8,8,0,0,0-7.16,0L40,203.06V56H216ZM136,112a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H144A8,8,0,0,1,136,112Zm0,32a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H144A8,8,0,0,1,136,144ZM64,168h48a8,8,0,0,0,8-8V96a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8v64A8,8,0,0,0,64,168Zm8-64h32v48H72Z">
    </path>
</svg>

<svg *ngIf="type === 'crosshair'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M232,120h-8.34A96.14,96.14,0,0,0,136,32.34V24a8,8,0,0,0-16,0v8.34A96.14,96.14,0,0,0,32.34,120H24a8,8,0,0,0,0,16h8.34A96.14,96.14,0,0,0,120,223.66V232a8,8,0,0,0,16,0v-8.34A96.14,96.14,0,0,0,223.66,136H232a8,8,0,0,0,0-16Zm-96,87.6V200a8,8,0,0,0-16,0v7.6A80.15,80.15,0,0,1,48.4,136H56a8,8,0,0,0,0-16H48.4A80.15,80.15,0,0,1,120,48.4V56a8,8,0,0,0,16,0V48.4A80.15,80.15,0,0,1,207.6,120H200a8,8,0,0,0,0,16h7.6A80.15,80.15,0,0,1,136,207.6ZM128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,152Z">
    </path>
</svg>

<svg *ngIf="type === 'trend-down'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M240,128v64a8,8,0,0,1-8,8H168a8,8,0,0,1,0-16h44.69L136,107.31l-34.34,34.35a8,8,0,0,1-11.32,0l-72-72A8,8,0,0,1,29.66,58.34L96,124.69l34.34-34.35a8,8,0,0,1,11.32,0L224,172.69V128a8,8,0,0,1,16,0Z">
    </path>
</svg>

<svg *ngIf="type === 'trend-up'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M240,56v64a8,8,0,0,1-16,0V75.31l-82.34,82.35a8,8,0,0,1-11.32,0L96,123.31,29.66,189.66a8,8,0,0,1-11.32-11.32l72-72a8,8,0,0,1,11.32,0L136,140.69,212.69,64H168a8,8,0,0,1,0-16h64A8,8,0,0,1,240,56Z">
    </path>
</svg>


<svg *ngIf="type === 'plug'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
    [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg"
    width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <path
        d="M237.66,66.34a8,8,0,0,0-11.32,0L192,100.69,155.31,64l34.35-34.34a8,8,0,1,0-11.32-11.32L144,52.69,117.66,26.34a8,8,0,0,0-11.32,11.32L112.69,44l-53,53a40,40,0,0,0,0,56.57l15.71,15.71L26.34,218.34a8,8,0,0,0,11.32,11.32l49.09-49.09,15.71,15.71a40,40,0,0,0,56.57,0l53-53,6.34,6.35a8,8,0,0,0,11.32-11.32L203.31,112l34.35-34.34A8,8,0,0,0,237.66,66.34ZM147.72,185a24,24,0,0,1-33.95,0L71,142.23a24,24,0,0,1,0-33.95l53-53L200.69,132Z">
    </path>
</svg>

<svg *ngIf="type === 'link-simple'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M165.66,90.34a8,8,0,0,1,0,11.32l-64,64a8,8,0,0,1-11.32-11.32l64-64A8,8,0,0,1,165.66,90.34ZM215.6,40.4a56,56,0,0,0-79.2,0L106.34,70.45a8,8,0,0,0,11.32,11.32l30.06-30a40,40,0,0,1,56.57,56.56l-30.07,30.06a8,8,0,0,0,11.31,11.32L215.6,119.6a56,56,0,0,0,0-79.2ZM138.34,174.22l-30.06,30.06a40,40,0,1,1-56.56-56.57l30.05-30.05a8,8,0,0,0-11.32-11.32L40.4,136.4a56,56,0,0,0,79.2,79.2l30.06-30.07a8,8,0,0,0-11.32-11.31Z"></path></svg>

<svg *ngIf="type === 'paperclip'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M209.66,122.34a8,8,0,0,1,0,11.32l-82.05,82a56,56,0,0,1-79.2-79.21L147.67,35.73a40,40,0,1,1,56.61,56.55L105,193A24,24,0,1,1,71,159L154.3,74.38A8,8,0,1,1,165.7,85.6L82.39,170.31a8,8,0,1,0,11.27,11.36L192.93,81A24,24,0,1,0,159,47L59.76,147.68a40,40,0,1,0,56.53,56.62l82.06-82A8,8,0,0,1,209.66,122.34Z"></path></svg>

<svg *ngIf="type === 'database'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M128,24C74.17,24,32,48.6,32,80v96c0,31.4,42.17,56,96,56s96-24.6,96-56V80C224,48.6,181.83,24,128,24Zm80,104c0,9.62-7.88,19.43-21.61,26.92C170.93,163.35,150.19,168,128,168s-42.93-4.65-58.39-13.08C55.88,147.43,48,137.62,48,128V111.36c17.06,15,46.23,24.64,80,24.64s62.94-9.68,80-24.64ZM69.61,53.08C85.07,44.65,105.81,40,128,40s42.93,4.65,58.39,13.08C200.12,60.57,208,70.38,208,80s-7.88,19.43-21.61,26.92C170.93,115.35,150.19,120,128,120s-42.93-4.65-58.39-13.08C55.88,99.43,48,89.62,48,80S55.88,60.57,69.61,53.08ZM186.39,202.92C170.93,211.35,150.19,216,128,216s-42.93-4.65-58.39-13.08C55.88,195.43,48,185.62,48,176V159.36c17.06,15,46.23,24.64,80,24.64s62.94-9.68,80-24.64V176C208,185.62,200.12,195.43,186.39,202.92Z"></path></svg>

<svg *ngIf="type === 'memory'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M232,56H24A16,16,0,0,0,8,72V200a8,8,0,0,0,16,0V184H40v16a8,8,0,0,0,16,0V184H72v16a8,8,0,0,0,16,0V184h16v16a8,8,0,0,0,16,0V184h16v16a8,8,0,0,0,16,0V184h16v16a8,8,0,0,0,16,0V184h16v16a8,8,0,0,0,16,0V184h16v16a8,8,0,0,0,16,0V72A16,16,0,0,0,232,56ZM24,72H232v96H24Zm88,80a8,8,0,0,0,8-8V96a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8ZM56,104h48v32H56Zm88,48h64a8,8,0,0,0,8-8V96a8,8,0,0,0-8-8H144a8,8,0,0,0-8,8v48A8,8,0,0,0,144,152Zm8-48h48v32H152Z"></path></svg>

<svg *ngIf="type === 'user-circle-dashed'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M96.26,37A8,8,0,0,1,102,27.29a104.11,104.11,0,0,1,52,0,8,8,0,0,1-2,15.75,8.15,8.15,0,0,1-2-.26,88,88,0,0,0-44,0A8,8,0,0,1,96.26,37ZM33.35,110a8,8,0,0,0,9.85-5.57,87.88,87.88,0,0,1,22-38.09A8,8,0,0,0,53.8,55.14a103.92,103.92,0,0,0-26,45A8,8,0,0,0,33.35,110ZM150,213.22a88,88,0,0,1-44,0,8,8,0,0,0-4,15.49,104.11,104.11,0,0,0,52,0,8,8,0,0,0-4-15.49Zm62.8-108.77a8,8,0,0,0,15.42-4.28,104,104,0,0,0-26-45,8,8,0,1,0-11.41,11.21A88.14,88.14,0,0,1,212.79,104.45Zm15.44,51.39a103.68,103.68,0,0,1-30.68,49.47A8,8,0,0,1,185.07,203a64,64,0,0,0-114.14,0,8,8,0,0,1-12.48,2.32,103.74,103.74,0,0,1-30.68-49.49,8,8,0,0,1,15.42-4.27,87.58,87.58,0,0,0,19,34.88,79.57,79.57,0,0,1,36.1-28.77,48,48,0,1,1,59.38,0,79.57,79.57,0,0,1,36.1,28.77,87.58,87.58,0,0,0,19-34.88,8,8,0,1,1,15.42,4.28ZM128,152a32,32,0,1,0-32-32A32,32,0,0,0,128,152Z"></path></svg>


<svg *ngIf="type === 'qr-code'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M104,40H56A16,16,0,0,0,40,56v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,104,40Zm0,64H56V56h48v48Zm0,32H56a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V152A16,16,0,0,0,104,136Zm0,64H56V152h48v48ZM200,40H152a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,200,40Zm0,64H152V56h48v48Zm-64,72V144a8,8,0,0,1,16,0v32a8,8,0,0,1-16,0Zm80-16a8,8,0,0,1-8,8H184v40a8,8,0,0,1-8,8H144a8,8,0,0,1,0-16h24V144a8,8,0,0,1,16,0v8h24A8,8,0,0,1,216,160Zm0,32v16a8,8,0,0,1-16,0V192a8,8,0,0,1,16,0Z"></path></svg>

<svg *ngIf="type === 'shield-star'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M80.57,117A8,8,0,0,1,91,112.57l29,11.61V96a8,8,0,0,1,16,0v28.18l29-11.61A8,8,0,1,1,171,127.43l-30.31,12.12L158.4,163.2a8,8,0,1,1-12.8,9.6L128,149.33,110.4,172.8a8,8,0,1,1-12.8-9.6l17.74-23.65L85,127.43A8,8,0,0,1,80.57,117ZM224,56v56c0,52.72-25.52,84.67-46.93,102.19-23.06,18.86-46,25.27-47,25.53a8,8,0,0,1-4.2,0c-1-.26-23.91-6.67-47-25.53C57.52,196.67,32,164.72,32,112V56A16,16,0,0,1,48,40H208A16,16,0,0,1,224,56Zm-16,0L48,56l0,56c0,37.3,13.82,67.51,41.07,89.81A128.25,128.25,0,0,0,128,223.62a129.3,129.3,0,0,0,39.41-22.2C194.34,179.16,208,149.07,208,112Z"></path></svg>

<svg *ngIf="type === 'book'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,24H72A32,32,0,0,0,40,56V224a8,8,0,0,0,8,8H192a8,8,0,0,0,0-16H56a16,16,0,0,1,16-16H208a8,8,0,0,0,8-8V32A8,8,0,0,0,208,24Zm-8,160H72a31.82,31.82,0,0,0-16,4.29V56A16,16,0,0,1,72,40H200Z"></path></svg>

<svg *ngIf="type === 'kanban'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M216,48H40a8,8,0,0,0-8,8V208a16,16,0,0,0,16,16H88a16,16,0,0,0,16-16V160h48v16a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V56A8,8,0,0,0,216,48ZM88,208H48V128H88Zm0-96H48V64H88Zm64,32H104V64h48Zm56,32H168V128h40Zm0-64H168V64h40Z"></path></svg>


<svg *ngIf="type === 'flag'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M42.76,50A8,8,0,0,0,40,56V224a8,8,0,0,0,16,0V179.77c26.79-21.16,49.87-9.75,76.45,3.41,16.4,8.11,34.06,16.85,53,16.85,13.93,0,28.54-4.75,43.82-18a8,8,0,0,0,2.76-6V56A8,8,0,0,0,218.76,50c-28,24.23-51.72,12.49-79.21-1.12C111.07,34.76,78.78,18.79,42.76,50ZM216,172.25c-26.79,21.16-49.87,9.74-76.45-3.41-25-12.35-52.81-26.13-83.55-8.4V59.79c26.79-21.16,49.87-9.75,76.45,3.4,25,12.35,52.82,26.13,83.55,8.4Z"></path></svg>

<svg *ngIf="type === 'list-magnifying-glass'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm8,72h72a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm88,48H40a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16Zm109.66,13.66a8,8,0,0,1-11.32,0L206,177.36A40,40,0,1,1,217.36,166l20.3,20.3A8,8,0,0,1,237.66,197.66ZM184,168a24,24,0,1,0-24-24A24,24,0,0,0,184,168Z"></path></svg>

<svg *ngIf="type === 'list-star'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm8,72H96a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm72,48H40a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm125.09-40.22-22.52,18.59,6.86,27.71a8,8,0,0,1-11.82,8.81L184,183.82l-25.61,15.07a8,8,0,0,1-11.82-8.81l6.85-27.71-22.51-18.59a8,8,0,0,1,4.47-14.14l29.84-2.31,11.43-26.5a8,8,0,0,1,14.7,0l11.43,26.5,29.84,2.31a8,8,0,0,1,4.47,14.14Zm-25.47.28-14.89-1.15a8,8,0,0,1-6.73-4.8l-6-13.92-6,13.92a8,8,0,0,1-6.73,4.8l-14.89,1.15,11.11,9.18a8,8,0,0,1,2.68,8.09l-3.5,14.12,13.27-7.81a8,8,0,0,1,8.12,0l13.27,7.81-3.5-14.12a8,8,0,0,1,2.68-8.09Z"></path></svg>

<svg *ngIf="type === 'list-bullets'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M80,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H88A8,8,0,0,1,80,64Zm136,56H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM44,52A12,12,0,1,0,56,64,12,12,0,0,0,44,52Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,44,116Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,44,180Z"></path></svg>


<svg *ngIf="type === 'palette'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M200.77,53.89A103.27,103.27,0,0,0,128,24h-1.07A104,104,0,0,0,24,128c0,43,26.58,79.06,69.36,94.17A32,32,0,0,0,136,192a16,16,0,0,1,16-16h46.21a31.81,31.81,0,0,0,31.2-24.88,104.43,104.43,0,0,0,2.59-24A103.28,103.28,0,0,0,200.77,53.89Zm13,93.71A15.89,15.89,0,0,1,198.21,160H152a32,32,0,0,0-32,32,16,16,0,0,1-21.31,15.07C62.49,194.3,40,164,40,128a88,88,0,0,1,87.09-88h.9a88.35,88.35,0,0,1,88,87.25A88.86,88.86,0,0,1,213.81,147.6ZM140,76a12,12,0,1,1-12-12A12,12,0,0,1,140,76ZM96,100A12,12,0,1,1,84,88,12,12,0,0,1,96,100Zm0,56a12,12,0,1,1-12-12A12,12,0,0,1,96,156Zm88-56a12,12,0,1,1-12-12A12,12,0,0,1,184,100Z"></path></svg>


<svg *ngIf="type === 'hand-grabbing'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M188,80a27.79,27.79,0,0,0-13.36,3.4,28,28,0,0,0-46.64-11A28,28,0,0,0,80,92v20H68a28,28,0,0,0-28,28v12a88,88,0,0,0,176,0V108A28,28,0,0,0,188,80Zm12,72a72,72,0,0,1-144,0V140a12,12,0,0,1,12-12H80v24a8,8,0,0,0,16,0V92a12,12,0,0,1,24,0v28a8,8,0,0,0,16,0V92a12,12,0,0,1,24,0v28a8,8,0,0,0,16,0V108a12,12,0,0,1,24,0Z"></path></svg>


<svg *ngIf="type === 'cloud-arrow-up'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M178.34,165.66,160,147.31V208a8,8,0,0,1-16,0V147.31l-18.34,18.35a8,8,0,0,1-11.32-11.32l32-32a8,8,0,0,1,11.32,0l32,32a8,8,0,0,1-11.32,11.32ZM160,40A88.08,88.08,0,0,0,81.29,88.68,64,64,0,1,0,72,216h40a8,8,0,0,0,0-16H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.12A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,100.8,66,8,8,0,0,0,3.2,15.34,7.9,7.9,0,0,0,3.2-.68A88,88,0,0,0,160,40Z"></path></svg>

<svg *ngIf="type === 'cloud-slash'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M53.92,34.62A8,8,0,1,0,42.08,45.38L81.32,88.55l-.06.12A65,65,0,0,0,72,88a64,64,0,0,0,0,128h88a87.34,87.34,0,0,0,31.8-5.93l10.28,11.31a8,8,0,1,0,11.84-10.76ZM160,200H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.3.12A88.4,88.4,0,0,0,72,128a8,8,0,0,0,16,0,72.25,72.25,0,0,1,5.06-26.54l87,95.7A71.66,71.66,0,0,1,160,200Zm88-72a87.89,87.89,0,0,1-22.35,58.61A8,8,0,0,1,213.71,176,72,72,0,0,0,117.37,70a8,8,0,0,1-9.48-12.89A88,88,0,0,1,248,128Z"></path></svg>


<svg *ngIf="type === 'flask'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M221.69,199.77,160,96.92V40h8a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16h8V96.92L34.31,199.77A16,16,0,0,0,48,224H208a16,16,0,0,0,13.72-24.23ZM110.86,103.25A7.93,7.93,0,0,0,112,99.14V40h32V99.14a7.93,7.93,0,0,0,1.14,4.11L183.36,167c-12,2.37-29.07,1.37-51.75-10.11-15.91-8.05-31.05-12.32-45.22-12.81ZM48,208l28.54-47.58c14.25-1.74,30.31,1.85,47.82,10.72,19,9.61,35,12.88,48,12.88a69.89,69.89,0,0,0,19.55-2.7L208,208Z"></path></svg>


<svg *ngIf="type === 'microscope'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,208H203.94A88.05,88.05,0,0,0,144,64.37V32a16,16,0,0,0-16-16H80A16,16,0,0,0,64,32V136a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V80.46A72,72,0,0,1,181.25,208H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16Zm-96-72H80V32h48V136ZM72,184a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z"></path></svg>


<svg *ngIf="type === 'test-tube'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M237.66,86.34l-60-60a8,8,0,0,0-11.32,0L37.11,155.57a44.77,44.77,0,0,0,63.32,63.32L212.32,107l22.21-7.4a8,8,0,0,0,3.13-13.25ZM89.11,207.57a28.77,28.77,0,0,1-40.68-40.68l28.8-28.8c8.47-2.9,21.75-4,39.07,5,10.6,5.54,20.18,8,28.56,8.73ZM205.47,92.41a8,8,0,0,0-3.13,1.93l-39.57,39.57c-8.47,2.9-21.75,4-39.07-5-10.6-5.54-20.18-8-28.56-8.73L172,43.31,217.19,88.5Z"></path></svg>

<svg *ngIf="type === 'swap'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,48V152a16,16,0,0,1-16,16H99.31l10.35,10.34a8,8,0,0,1-11.32,11.32l-24-24a8,8,0,0,1,0-11.32l24-24a8,8,0,0,1,11.32,11.32L99.31,152H208V48H96v8a8,8,0,0,1-16,0V48A16,16,0,0,1,96,32H208A16,16,0,0,1,224,48ZM168,192a8,8,0,0,0-8,8v8H48V104H156.69l-10.35,10.34a8,8,0,0,0,11.32,11.32l24-24a8,8,0,0,0,0-11.32l-24-24a8,8,0,0,0-11.32,11.32L156.69,88H48a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16H160a16,16,0,0,0,16-16v-8A8,8,0,0,0,168,192Z"></path></svg>

<svg *ngIf="type === 'thumbs-up'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M234,80.12A24,24,0,0,0,216,72H160V56a40,40,0,0,0-40-40,8,8,0,0,0-7.16,4.42L75.06,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H204a24,24,0,0,0,23.82-21l12-96A24,24,0,0,0,234,80.12ZM32,112H72v88H32ZM223.94,97l-12,96a8,8,0,0,1-7.94,7H88V105.89l36.71-73.43A24,24,0,0,1,144,56V80a8,8,0,0,0,8,8h64a8,8,0,0,1,7.94,9Z"></path></svg>
<svg *ngIf="type === 'thumbs-down'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M239.82,157l-12-96A24,24,0,0,0,204,40H32A16,16,0,0,0,16,56v88a16,16,0,0,0,16,16H75.06l37.78,75.58A8,8,0,0,0,120,240a40,40,0,0,0,40-40V184h56a24,24,0,0,0,23.82-27ZM72,144H32V56H72Zm150,21.29a7.88,7.88,0,0,1-6,2.71H152a8,8,0,0,0-8,8v24a24,24,0,0,1-19.29,23.54L88,150.11V56H204a8,8,0,0,1,7.94,7l12,96A7.87,7.87,0,0,1,222,165.29Z"></path></svg>
<svg *ngIf="type === 'mailbox'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()"  xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M104,152a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H96A8,8,0,0,1,104,152Zm136-36v60a16,16,0,0,1-16,16H136v32a8,8,0,0,1-16,0V192H32a16,16,0,0,1-16-16V116A60.07,60.07,0,0,1,76,56h76V24a8,8,0,0,1,8-8h32a8,8,0,0,1,0,16H168V56h12A60.07,60.07,0,0,1,240,116ZM120,176V116a44,44,0,0,0-88,0v60Zm104-60a44.05,44.05,0,0,0-44-44H168v72a8,8,0,0,1-16,0V72H116.75A59.86,59.86,0,0,1,136,116v60h88Z"></path></svg>
<svg *ngIf="type === 'chat-dots'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()"  xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M116,128a12,12,0,1,1,12,12A12,12,0,0,1,116,128ZM84,140a12,12,0,1,0-12-12A12,12,0,0,0,84,140Zm88,0a12,12,0,1,0-12-12A12,12,0,0,0,172,140Zm60-76V192a16,16,0,0,1-16,16H83l-32.6,28.16-.09.07A15.89,15.89,0,0,1,40,240a16.13,16.13,0,0,1-6.8-1.52A15.85,15.85,0,0,1,24,224V64A16,16,0,0,1,40,48H216A16,16,0,0,1,232,64ZM40,224h0ZM216,64H40V224l34.77-30A8,8,0,0,1,80,192H216Z"></path></svg>

<svg *ngIf="type === 'equals'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><line x1="40" y1="160" x2="216" y2="160" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="40" y1="96" x2="216" y2="96" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg>

<svg *ngIf="type === 'package'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M223.68,66.15,135.68,18a15.88,15.88,0,0,0-15.36,0l-88,48.17a16,16,0,0,0-8.32,14v95.64a16,16,0,0,0,8.32,14l88,48.17a15.88,15.88,0,0,0,15.36,0l88-48.17a16,16,0,0,0,8.32-14V80.18A16,16,0,0,0,223.68,66.15ZM128,32l80.34,44-29.77,16.3-80.35-44ZM128,120,47.66,76l33.9-18.56,80.34,44ZM40,90l80,43.78v85.79L40,175.82Zm176,85.78h0l-80,43.79V133.82l32-17.51V152a8,8,0,0,0,16,0V107.55L216,90v85.77Z"></path></svg>
<svg *ngIf="type === 'identification-card'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm40-80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Zm-80.26-34a8,8,0,1,1-15.5,4c-2.63-10.26-13.06-18-24.25-18s-21.61,7.74-24.25,18a8,8,0,1,1-15.5-4,39.84,39.84,0,0,1,17.19-23.34,32,32,0,1,1,45.12,0A39.76,39.76,0,0,1,135.75,166ZM96,136a16,16,0,1,0-16-16A16,16,0,0,0,96,136Z"></path></svg>

<svg *ngIf="type === 'user-minus'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()"  xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M256,136a8,8,0,0,1-8,8H200a8,8,0,0,1,0-16h48A8,8,0,0,1,256,136Zm-57.87,58.85a8,8,0,0,1-12.26,10.3C165.75,181.19,138.09,168,108,168s-57.75,13.19-77.87,37.15a8,8,0,0,1-12.25-10.3c14.94-17.78,33.52-30.41,54.17-37.17a68,68,0,1,1,71.9,0C164.6,164.44,183.18,177.07,198.13,194.85ZM108,152a52,52,0,1,0-52-52A52.06,52.06,0,0,0,108,152Z"></path></svg>

<svg *ngIf="type === 'star'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()"  xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M239.18,97.26A16.38,16.38,0,0,0,224.92,86l-59-4.76L143.14,26.15a16.36,16.36,0,0,0-30.27,0L90.11,81.23,31.08,86a16.46,16.46,0,0,0-9.37,28.86l45,38.83L53,211.75a16.38,16.38,0,0,0,24.5,17.82L128,198.49l50.53,31.08A16.4,16.4,0,0,0,203,211.75l-13.76-58.07,45-38.83A16.43,16.43,0,0,0,239.18,97.26Zm-15.34,5.47-48.7,42a8,8,0,0,0-2.56,7.91l14.88,62.8a.37.37,0,0,1-.17.48c-.18.14-.23.11-.38,0l-54.72-33.65a8,8,0,0,0-8.38,0L69.09,215.94c-.15.09-.19.12-.38,0a.37.37,0,0,1-.17-.48l14.88-62.8a8,8,0,0,0-2.56-7.91l-48.7-42c-.12-.1-.23-.19-.13-.5s.18-.27.33-.29l63.92-5.16A8,8,0,0,0,103,91.86l24.62-59.61c.08-.17.11-.25.35-.25s.27.08.35.25L153,91.86a8,8,0,0,0,6.75,4.92l63.92,5.16c.15,0,.24,0,.33.29S224,102.63,223.84,102.73Z"></path></svg>

<svg *ngIf="type === 'sort-ascending'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}"
[attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M128,128a8,8,0,0,1-8,8H48a8,8,0,0,1,0-16h72A8,8,0,0,1,128,128ZM48,72H184a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm56,112H48a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16Zm125.66-21.66a8,8,0,0,0-11.32,0L192,188.69V112a8,8,0,0,0-16,0v76.69l-26.34-26.35a8,8,0,0,0-11.32,11.32l40,40a8,8,0,0,0,11.32,0l40-40A8,8,0,0,0,229.66,162.34Z"></path></svg>

<svg *ngIf="type === 'stack-plus'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" 
xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M230.91,124A8,8,0,0,1,228,134.91l-96,56a8,8,0,0,1-8.06,0l-96-56A8,8,0,0,1,36,121.09l92,53.65,92-53.65A8,8,0,0,1,230.91,124ZM24,80a8,8,0,0,1,4-6.91l96-56a8,8,0,0,1,8.06,0l96,56a8,8,0,0,1,0,13.82l-96,56a8,8,0,0,1-8.06,0l-96-56A8,8,0,0,1,24,80Zm23.88,0L128,126.74,208.12,80,128,33.26ZM232,192H216V176a8,8,0,0,0-16,0v16H184a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V208h16a8,8,0,0,0,0-16Zm-92,23.76-12,7L36,169.09A8,8,0,0,0,28,182.91l96,56a8,8,0,0,0,8.06,0l16-9.33A8,8,0,1,0,140,215.76Z"></path></svg>

<svg *ngIf="type === 'wifi-high'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" 
xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M140,204a12,12,0,1,1-12-12A12,12,0,0,1,140,204ZM237.08,87A172,172,0,0,0,18.92,87,8,8,0,0,0,29.08,99.37a156,156,0,0,1,197.84,0A8,8,0,0,0,237.08,87ZM205,122.77a124,124,0,0,0-153.94,0A8,8,0,0,0,61,135.31a108,108,0,0,1,134.06,0,8,8,0,0,0,11.24-1.3A8,8,0,0,0,205,122.77Zm-32.26,35.76a76.05,76.05,0,0,0-89.42,0,8,8,0,0,0,9.42,12.94,60,60,0,0,1,70.58,0,8,8,0,1,0,9.42-12.94Z"></path></svg>

<svg *ngIf="type === 'coins'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M184,89.57V84c0-25.08-37.83-44-88-44S8,58.92,8,84v40c0,20.89,26.25,37.49,64,42.46V172c0,25.08,37.83,44,88,44s88-18.92,88-44V132C248,111.3,222.58,94.68,184,89.57ZM232,132c0,13.22-30.79,28-72,28-3.73,0-7.43-.13-11.08-.37C170.49,151.77,184,139,184,124V105.74C213.87,110.19,232,122.27,232,132ZM72,150.25V126.46A183.74,183.74,0,0,0,96,128a183.74,183.74,0,0,0,24-1.54v23.79A163,163,0,0,1,96,152,163,163,0,0,1,72,150.25Zm96-40.32V124c0,8.39-12.41,17.4-32,22.87V123.5C148.91,120.37,159.84,115.71,168,109.93ZM96,56c41.21,0,72,14.78,72,28s-30.79,28-72,28S24,97.22,24,84,54.79,56,96,56ZM24,124V109.93c8.16,5.78,19.09,10.44,32,13.57v23.37C36.41,141.4,24,132.39,24,124Zm64,48v-4.17c2.63.1,5.29.17,8,.17,3.88,0,7.67-.13,11.39-.35A121.92,121.92,0,0,0,120,171.41v23.46C100.41,189.4,88,180.39,88,172Zm48,26.25V174.4a179.48,179.48,0,0,0,24,1.6,183.74,183.74,0,0,0,24-1.54v23.79a165.45,165.45,0,0,1-48,0Zm64-3.38V171.5c12.91-3.13,23.84-7.79,32-13.57V172C232,180.39,219.59,189.4,200,194.87Z"></path></svg>

<svg *ngIf="type === 'gender-female'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" 
xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M208,96a80,80,0,1,0-88,79.6V200H88a8,8,0,0,0,0,16h32v24a8,8,0,0,0,16,0V216h32a8,8,0,0,0,0-16H136V175.6A80.11,80.11,0,0,0,208,96ZM64,96a64,64,0,1,1,64,64A64.07,64.07,0,0,1,64,96Z"></path></svg>

<svg *ngIf="type === 'gender-male'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" 
xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M216,32H168a8,8,0,0,0,0,16h28.69L154.62,90.07a80,80,0,1,0,11.31,11.31L208,59.32V88a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM149.24,197.29a64,64,0,1,1,0-90.53A64.1,64.1,0,0,1,149.24,197.29Z"></path></svg>

<svg *ngIf="type === 'cpu'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" 
xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M152,96H104a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V104A8,8,0,0,0,152,96Zm-8,48H112V112h32Zm88,0H216V112h16a8,8,0,0,0,0-16H216V56a16,16,0,0,0-16-16H160V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H56A16,16,0,0,0,40,56V96H24a8,8,0,0,0,0,16H40v32H24a8,8,0,0,0,0,16H40v40a16,16,0,0,0,16,16H96v16a8,8,0,0,0,16,0V216h32v16a8,8,0,0,0,16,0V216h40a16,16,0,0,0,16-16V160h16a8,8,0,0,0,0-16Zm-32,56H56V56H200v95.87s0,.09,0,.13,0,.09,0,.13V200Z"></path></svg>
<svg *ngIf="type === 'triangle-dashed'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()"  xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M160,216a8,8,0,0,1-8,8H104a8,8,0,0,1,0-16h48A8,8,0,0,1,160,216Zm76.8-27.91L232.14,180a8,8,0,0,0-13.86,8l4.65,8.09a7.59,7.59,0,0,1,0,7.72,8.5,8.5,0,0,1-7.48,4.2H192a8,8,0,0,0,0,16h23.45a24.34,24.34,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM64,208H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L37.72,188a8,8,0,1,0-13.86-8l-4.66,8.08a23.51,23.51,0,0,0,0,23.72A24.34,24.34,0,0,0,40.55,224H64a8,8,0,0,0,0-16Zm138.18-56a8,8,0,0,0,6.93-12l-23-40a8,8,0,0,0-13.86,8l23,40A8,8,0,0,0,202.18,152ZM149.35,36.22a24.76,24.76,0,0,0-42.7,0L93,60a8,8,0,1,0,13.86,8l13.7-23.78a8.75,8.75,0,0,1,15,0L149.18,68a8,8,0,0,0,6.94,4,7.91,7.91,0,0,0,4-1.07A8,8,0,0,0,163,60ZM80.85,97.07A8,8,0,0,0,69.93,100l-23,40a8,8,0,0,0,13.87,8l23-40A8,8,0,0,0,80.85,97.07Z"></path></svg>

<svg  *ngIf="type === 'recycle'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M96,208a8,8,0,0,1-8,8H40a24,24,0,0,1-20.77-36l34.29-59.25L39.47,124.5A8,8,0,1,1,35.33,109l32.77-8.77a8,8,0,0,1,9.8,5.66l8.79,32.77A8,8,0,0,1,81,148.5a8.37,8.37,0,0,1-2.08.27,8,8,0,0,1-7.72-5.93l-3.8-14.15L33.11,188A8,8,0,0,0,40,200H88A8,8,0,0,1,96,208Zm140.73-28-23.14-40a8,8,0,0,0-13.84,8l23.14,40A8,8,0,0,1,216,200H147.31l10.34-10.34a8,8,0,0,0-11.31-11.32l-24,24a8,8,0,0,0,0,11.32l24,24a8,8,0,0,0,11.31-11.32L147.31,216H216a24,24,0,0,0,20.77-36ZM128,32a7.85,7.85,0,0,1,6.92,4l34.29,59.25-14.08-3.78A8,8,0,0,0,151,106.92l32.78,8.79a8.23,8.23,0,0,0,2.07.27,8,8,0,0,0,7.72-5.93l8.79-32.79a8,8,0,1,0-15.45-4.14l-3.8,14.17L148.77,28a24,24,0,0,0-41.54,0L84.07,68a8,8,0,0,0,13.85,8l23.16-40A7.85,7.85,0,0,1,128,32Z"></path></svg>

<svg *ngIf="type === 'shield-check'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" 
 xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M208,40H48A16,16,0,0,0,32,56v56c0,52.72,25.52,84.67,46.93,102.19,23.06,18.86,46,25.26,47,25.53a8,8,0,0,0,4.2,0c1-.27,23.91-6.67,47-25.53C198.48,196.67,224,164.72,224,112V56A16,16,0,0,0,208,40Zm0,72c0,37.07-13.66,67.16-40.6,89.42A129.3,129.3,0,0,1,128,223.62a128.25,128.25,0,0,1-38.92-21.81C61.82,179.51,48,149.3,48,112l0-56,160,0ZM82.34,141.66a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32l-56,56a8,8,0,0,1-11.32,0Z"></path></svg>

 <svg *ngIf="type === 'notepad'" [ngStyle]="pointer ? {'cursor':'pointer' } : {'cursor': 'default'}" [attr.fill]="getColor()" [style.height]="getHeight()" [style.width]="getWidth()" xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#000000" viewBox="0 0 256 256"><path d="M168,128a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,128Zm-8,24H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16ZM216,40V200a32,32,0,0,1-32,32H72a32,32,0,0,1-32-32V40a8,8,0,0,1,8-8H72V24a8,8,0,0,1,16,0v8h32V24a8,8,0,0,1,16,0v8h32V24a8,8,0,0,1,16,0v8h24A8,8,0,0,1,216,40Zm-16,8H184v8a8,8,0,0,1-16,0V48H136v8a8,8,0,0,1-16,0V48H88v8a8,8,0,0,1-16,0V48H56V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16Z"></path></svg>

<div class="userHierarchyWrapper">
    <app-layout header="UserHierarchy.Label.UserHierarchy">
        <div class="userHierarchyDesign" cdkDropListGroup>
            <div class="userHierarchyGroups">
                <div *ngFor="let userHierarchyGroup of userHierarchyGroups" class="userHierarchyGroup">
                    <app-layout [header]="userHierarchyGroup.defaultName" *ngIf="users.length > 0">
                        <div class="userHierarchyGroupItem">
                            <app-userhierarchy-designer-item 
                                [userId]="userHierarchyGroup.userId"
                                [users]="users"
                                [groupConnections]="groupConnections"
                                [isGroupOwner]="true"
                            ></app-userhierarchy-designer-item>
                        </div>
                    </app-layout>
                </div>    
                <app-main-button svg="plus" (onClick)="createGroup()" label="UserHierarchy.Label.CreateUserHierarchyGroup"></app-main-button>
            </div>    
            <div class="hierarchyDrag" cdkDropList [cdkDropListData]="users">
                <div *ngFor="let user of users" class="hierarchyDrag__user" [cdkDragData]="user" cdkDrag data-html="true">
                    <div>{{ user.userName }}</div>
                    
                </div>
            </div>
        </div>
    </app-layout>
</div>


<div *ngIf="control" class="entityTableValueControl" [ngClass]="{
    entityTableValueControl__centerContent: control.controlType == 6 || 
                                            control.controlType == 4 ||
                                            control.controlType == 7 ||
                                            control.controlType == 15 ,
    entityTableValueDarkMode:               darkmode==true,
    entityTableValueMobileColumn:           control.controlType == 16,
    entitytableValueBreakLineAfterDescription: control.breakLineAfterDescription == true,
    maxHeight:                              (subcontrol == false && subcontrols.length == 0),
    hideInDom:                              ((value == null || value == '') && 
                                                (   
                                                    control.controlType == 0 || 
                                                    control.controlType == 1 || 
                                                    control.controlType == 2
                                                )
                                            )
}">
    <div class="entityTableValueControlInfo" *ngIf="control.showHeaderAsRowDesription || isSmallControlWidth" [ngClass]="{
        entityTableValueBold: control.bold,
        entityTableValueItalic: control.italic
    }">
        <span *ngIf="control.alternateQuickTextForValueControl != '' && 
            commonService.isNullOrUndefined(control.alternateQuickTextForValueControl)==false">
            {{ control.alternateQuickTextForValueControl | texttransform }}
        </span>
        <span *ngIf="control.alternateQuickTextForValueControl == '' || 
            commonService.isNullOrUndefined(control.alternateQuickTextForValueControl)">
            {{ control.displayText | texttransform }}
        </span>        
    </div>


    <div *ngIf="control.controlType == 0" [ngClass]="{
        entityTableValueBold: control.bold,
        entityTableValueItalic: control.italic,
        entityTableValueBreakWhitespace: control.breakWhitespace == true
    }" class="entityTableValueText ellipsisText">
        <!-- Text --> 
        <span *ngIf="control.lookupTableId == null && control.alternateLookupTable == null" 
            [ngClass]="{ entityTableShowMultiline: control.showMultiline==true }"
            class="ellipsisText" [tippy]="value" [tippyOptions]="{placement:'bottom'}" >{{ value }}</span>
        <span *ngIf="control.lookupTableId != null || control.alternateLookupTable != null" [tippy]="getTranslatedValue() | texttransform" 
            [ngClass]="{ entityTableShowMultiline: control.showMultiline==true }"
            [tippyOptions]="{ placement: 'left' }" class="ellipsisText">{{ getTranslatedValue() | texttransform }}</span>
    </div>


    <!-- Date -->
    <div *ngIf="control.controlType == 1" class="maxWidth" [tippy]="getDate()" [tippyOptions]="{ placement:'left' }">
        {{ getDate() }}
    </div>


    <!-- Date mit roter Schrift wenn überschritten -->
    <div *ngIf="control.controlType == 2" class="maxWidth" [ngClass]="{ everleadCoral: valueDateIsInPast }" [tippy]="getDate()" [tippyOptions]="{placement:'left'}">
        {{ getDate() }}
    </div>
    

    <!-- Web-Image -->
    <div *ngIf="control.controlType == 3 && valueline._917_isisp == true" (click)="activateIspProspect();$event.stopPropagation()" class="prospectIsIsp"
        [tippy]="'EntityTable.ActivateISP.Tippy' | texttransform">
        <div class="prospectIsIsp__inner">
            <app-jj-icons [type]="'wifi-high'" [width]="36" [height]="36" [color]="'red'"></app-jj-icons>
            <div class="prospectIsIsp__provider">Service provider</div>
        </div>
    </div>
    <div *ngIf="control.controlType == 3 && valueline._917_isisp != true" class="maxWidth">
        <div *ngIf="commonService.isNullOrUndefined(value)==false && dataTableItem.mainEntityId != 1227" class="maxWidth webImage">        
            <img [src]="getImage()" class="entityTableValueImage" [ngClass]="{ hideInDom: imageName == '' }">
        </div>
        <div *ngIf="dataTableItem.mainEntityId == 1227" class="maxWidth webImage">
            <app-icon-file [value]="getDocTypeName()" [darkmode]="darkmode" [height]="24" [width]="24"></app-icon-file>
        </div>
    
        <div *ngIf="commonService.isNullOrUndefined(value)" class="maxWidth webImage">        
            <app-jj-icons [type]="'calendar-dots'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==2 && commonService.isNullOrUndefined(valueline['_2_resultid'])"></app-jj-icons>
            <app-jj-icons [type]="'check-square-offset'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==2 && !commonService.isNullOrUndefined(valueline['_2_resultid'])"></app-jj-icons>
            <app-jj-icons [type]="'check-square-offset'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==20"></app-jj-icons>
            <app-jj-icons [type]="'browser'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==99"></app-jj-icons>
            <app-jj-icons [type]="'browser'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==462"></app-jj-icons>
            <app-jj-icons [type]="'user'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==4"></app-jj-icons>
            <app-jj-icons [type]="'user'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==992"></app-jj-icons>
            <app-jj-icons [type]="'building'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==1"></app-jj-icons>
            <app-jj-icons [type]="'building'" [width]="36" [height]="36" [color]="'secondary'" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==917"></app-jj-icons>
        </div>
    </div>
    


    <!-- Thermometer -->
    <div *ngIf="control.controlType == 4" class="maxWidth prospectQuality" [tippy]="'Thermometer.Label.Description' | texttransform">        
        <app-custom-thermometer [value]="value"></app-custom-thermometer>
    </div>      

    <!-- Research -->
    <div *ngIf="control.controlType == 5" class="entityTableValueResearch">
        <div class="entityTableValueResearch__buttons">
            <svg-icon src="assets/images/svg/google.svg" class="entityTableValueIcon fingerCursor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                (click)="commonService.openGoogle(value);$event.stopPropagation()">
            </svg-icon>
            <svg-icon src="assets/images/svg/xing.svg" class="entityTableValueIcon fingerCursor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                (click)="commonService.openXing(value);$event.stopPropagation()">
            </svg-icon>
        </div>
        <div class="ellipsisText" [ngClass]="{
            entityTableValueBold: control.bold,
            entityTableValueItalic: control.italic
        }">
            <span *ngIf="control.lookupTableId == null && control.alternateLookupTable == null" [tippy]="value">{{ value }}</span>
            <span *ngIf="control.lookupTableId != null || control.alternateLookupTable != null" [tippy]="getTranslatedValue() | texttransform">{{ getTranslatedValue() | texttransform }}</span>
        </div>        
    </div>


    <!-- Leadscore -->
    <div *ngIf="control.controlType == 6">
        <app-bar-leadscore [value]="value" *ngIf="featureLeadscoring"></app-bar-leadscore>
    </div>
    <!-- Leadscore Prospect -->
    <div *ngIf="control.controlType == 15">
        <app-bar-leadscore [value]="getLeadScoreProspectValue(value)"></app-bar-leadscore>
    </div>

    <!-- Delete -->
    <div *ngIf="control.controlType == 7" (click)="deleteItem();$event.stopPropagation()" class="entityTableValueDelete">
        <app-jj-icons [type]="'trash'" [width]="28" [height]="28" [pointer]="true"></app-jj-icons>
    </div>    

    <!-- Visitlist -->
    <div *ngIf="control.controlType == 8" class="maxWidth">        
        <div class="entityTableValueListItem entityTableValueProspectVisits" *ngFor="let item of commonService.splitRows(value); let i = index" [ngClass]="{
            hideInDom: item == '\r' || item == '' || i > 2
        }">
            <app-jj-icons [type]="'eye'" [width]="28" [height]="28" [pointer]="true"></app-jj-icons>
            <label [tippy]="removeDemoGuid(item)">{{ removeDemoGuid(item) }}</label>
        </div>        
    </div>  

    <!-- Add to accountgroup -->
    <div *ngIf="control.controlType == 9" class="maxWidth">
        <app-jj-icons [type]="'plus-circle'" [width]="28" [height]="28" [pointer]="true" (click)="addToAccountGroup()"></app-jj-icons>
    </div>

     <!-- DownloadRecording -->
     <div *ngIf="control.controlType == 10" class="maxWidth entityTableValueRecording">
        <div class="ellipsisText">{{ getTranslatedValue() | texttransform }}</div>
        <div class="entityTableValueRecording__download">
            <i class="fas fa-download fa-lg fingerCursor" (click)="downloadRecording()"></i>
        </div>
    </div>

    <!-- Weblead AI -->
    <div *ngIf="control.controlType == 11" class="maxWidth entityTableValueAIContact" [style.min-height]="getMinHeight()">
        <div class="entityTableValueAIContact__inner">
            <div class="aiMarker" *ngIf="value == '2'" [tippy]="'MatTable.Label.AIHigh' | texttransform">                
                <app-blue-marker label="EntityTable.Label.AI" svg="thumbs-up"></app-blue-marker>                
            </div>
            <div class="aiMarkerDisabled" *ngIf="value != '2' && value != '0'">
                <app-blue-marker label="EntityTable.Label.AI" svg="thumbs-up" [disabled]="true"></app-blue-marker>
            </div>
            <div class="aiMarker" *ngIf="value == '0'" [tippy]="'MatTable.Label.AILow' | texttransform">
                <app-blue-marker label="EntityTable.Label.AI" svg="thumbs-down"></app-blue-marker>
            </div>
        </div>
    </div>

    <!-- AIContact -->
    <div *ngIf="control.controlType == 12" class="maxWidth entityTableValueAIContact" [style.min-height]="getMinHeight()"
        [ngClass]="{ 'entityTableValueAIContact__active': valueline._4_isaisuggestion == true || valueline._4_isaicontact == true }">
        <div class="entityTableValueAIContact__inner">
            <div class="aiMarker" *ngIf="valueline._4_isaisuggestion == true || valueline._4_isaicontact == true">
                <app-blue-marker label="EntityTable.Label.AI" svg="thumbs-up" color="primaryLight"></app-blue-marker>
            </div>
            <div class="aiMarkerDisabled" *ngIf="valueline._4_isaisuggestion != true && valueline._4_isaicontact != true &&
                applicationInfoService.applicationSettings['showInactiveAIIcon']=='true'">
                <app-blue-marker label="EntityTable.Label.AI" svg="thumbs-up" [disabled]="true"></app-blue-marker>
            </div>           
        </div>
    </div>

    <!-- VIP  -->
    <div *ngIf="control.controlType == 13" class="maxWidth entityTableValueVIP" [style.min-height]="getMinHeight()" 
        (click)="changeVIPStatus();$event.stopPropagation()">
        <svg-icon class="everleadCoral" src="assets/images/svg/vipsmall.svg" [svgStyle]="{ 'width.px': 40, 'height.px': 40 }" 
            *ngIf="value != null" [tippy]="'EntityTableValue.Label.VIPControlRemove' | texttransform"></svg-icon>        
        <svg-icon class="noVip" src="assets/images/svg/vipsmall.svg" [svgStyle]="{ 'width.px': 40, 'height.px': 40 }" 
            *ngIf="value == null" [tippy]="'EntityTableValue.Label.VIPControlAdd' | texttransform"></svg-icon>
    </div>

    <!-- VIP OnlyView -->
    <div *ngIf="control.controlType == 21" class="maxWidth entityTableValueVIP" [style.min-height]="getMinHeight()" >
        <svg-icon class="everleadCoral" src="assets/images/svg/vipsmall.svg" [svgStyle]="{ 'width.px': 40, 'height.px': 40 }" 
            *ngIf="value == null"></svg-icon>
    </div>

    <!-- Asset Link -->
    <div *ngIf="control.controlType == 14" class="maxWidth entityTableValueAssetLink fingerCursor"
        [tippy]="getAssetLinkTitle() | texttransform" (click)="callAsstetLink();$event.stopPropagation()">
        <label>{{ value }}</label>
        <svg-icon src="assets/images/svg/toolbar/toolbar_openurl.svg" class="fingerCursor" 
            [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"></svg-icon>
    </div>

    <!-- Connected account -->
    <div *ngIf="control.controlType == 16" class="maxWidth entityTableValueProspectConnectedAccount"
        [style.min-height]="getMinHeight()" (click)="$event?.stopPropagation()">
        <div *ngIf="valueline.accountid == null" class="entityTableValueProspectConnectedAccount__accountNotFound">
            <div class="entityTableValueProspectConnectedAccount__accountNotFound__nowrapline">
                {{ 'EntityTableValue.Label.NoConnectionDesc' | texttransform }}
            </div>
            <div class="entityTableValueProspectConnectedAccount__accountFound__icons">
                <app-main-button svg="building" (onClick)="createAccountFromProspect()" label="Prospect.Label.ConvertToAccount" type="small"></app-main-button>
            </div>
        </div>
        <div *ngIf="valueline.accountid != null" class="entityTableValueProspectConnectedAccount__accountNotFound">           
            <div class="entityTableValueProspectConnectedAccount__accountFound__line jjBold">{{ valueline._1_name1 }}</div>
            <div class="entityTableValueProspectConnectedAccount__accountFound__line">{{ valueline._1_street }}</div>
            <div class="entityTableValueProspectConnectedAccount__accountFound__line">{{ valueline._1_zipcode }} {{ valueline._1_city }}</div>
            <div class="entityTableValueProspectConnectedAccount__accountFound__icons">
                <app-main-button svg="building" (onClick)="loadConnectedAccount()" label="Prospect.Label.LoadConnectedAccount" type="borderSmall"></app-main-button>
            </div>
        </div>
    </div>

    <!-- Prospect blacklisted -->
    <div *ngIf="control.controlType == 17" class="maxWidth entityTableProspectBlacklisted fingerCursor" 
        (click)="changeProspectBlacklistMode();$event.stopPropagation()">
        <app-jj-icons [type]="'prohibit'" [width]="32" [height]="32" *ngIf="value == true" [tippy]="'Prospect.Label.RemoveFromBlacklist' | texttransform"></app-jj-icons>
        <app-jj-icons [type]="'prohibit'" [width]="32" [height]="32" *ngIf="value == false" color="disabledGray" [tippy]="'Prospect.Label.Blacklist' | texttransform"></app-jj-icons>        
    </div>

    <div *ngIf="control.controlType == 18" class="maxWidth entityTableProspectLeadstateChange fingerCursor" (click)="$event.stopPropagation()">
        <select [(ngModel)]="value" (focusout)="updateLeadstate();$event.stopPropagation()">
            <option *ngFor="let leadstate of getLeadstates()" [ngValue]="leadstate.id">
                {{ leadstate.lookupTable.defaultName }}
            </option>
        </select>
    </div>
    <div *ngIf="control.controlType == 19" class="maxWidth entityTableEditOptin fingerCursor" (click)="$event.stopPropagation()">
        <app-jj-icons [type]="'pencil-simple-line'" [width]="28" [height]="28" [pointer]="true" (click)="editOptin(valueLine);$event.stopPropagation()" *ngIf="valueline['_20_enddate'] == null"></app-jj-icons>
    </div>

    <!-- New task for contact -->
    <div *ngIf="control.controlType == 20" class="maxWidth entityTableNewTaskForContact" [tippy]="'NewTask.Label.CreateNewTask' | texttransform">
        <app-jj-icons [type]="'plus-circle'" (click)="createTaskForContact();$event.stopPropagation()" class="fingerCursor" [pointer]="true"
            [width]="32" [height]="32"></app-jj-icons>
    </div>

    <!-- Download entity file -->
    <div *ngIf="control.controlType == 22" class="maxWidth entityTableNewTaskForContact" 
        [tippy]="'EntityFileUpload.Label.DownloadEntityFile' | texttransform" [tippyOptions]="{placement:'bottom'}">
        <i class="fas fa-download fingerCursor" (click)="downloadEntityFile();$event.stopPropagation()"></i>
    </div>

    <!-- File size -->
    <div *ngIf="control.controlType == 23" class="maxWidth" [tippy]="'EntityFileUpload.Label.EntityFileSize' | texttransform" [tippyOptions]="{placement: 'bottom'}">
        {{ commonService.getAttachmentSizeStringFromAttachmantSize(value) }}
    </div>

    <!-- Delete entity file -->
    <div *ngIf="control.controlType == 24" class="maxWidth entityTableNewTaskForContact" 
        [tippy]="'EntityFileUpload.Label.DeleteEntityFile' | texttransform" [tippyOptions]="{ placement: 'bottom' }">
        <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteEntityFile();$event.stopPropagation()"></i>
    </div>

    <!-- Edit opportunity -->
    <div *ngIf="control.controlType == 25" class="maxWidth entityTableEditOpportunity">
        <app-jj-icons [type]="'pencil-simple-line'" [width]="28" [height]="28" color="disabledGray" *ngIf="!canEditOpportunity()" [pointer]="false"></app-jj-icons>
        <app-jj-icons [type]="'pencil-simple-line'" [width]="28" [height]="28" (click)="editOpportunity();$event.stopPropagation()" *ngIf="canEditOpportunity()"></app-jj-icons>
    </div>

    <!-- Change opportunity status -->
    <div *ngIf="control.controlType == 26" class="maxWidth entityTableProspectLeadstateChange">
        <select [(ngModel)]="value" (focusout)="changeOpportunityStatus();$event.stopPropagation()">
            <option *ngFor="let opportunityState of oppotunityService.opportunityStates" [ngValue]="opportunityState.id">
                {{ opportunityState.defaultName }}
            </option>
        </select>       
    </div>

    <!-- Currency control -->
    <div *ngIf="control.controlType == 27" class="maxWidth">
        {{ value | currency:'EUR':true }}
    </div>

    <!-- Only date no time control -->
    <div *ngIf="control.controlType == 28" class="maxWidth">
        {{ value | date: 'dd.MM.yyyy' }}
    </div>

    <!-- user table role id -->
    <div *ngIf="control.controlType == 29" class="maxWidth entityTableUserProjectRole">
        <select [(ngModel)]="value" (change)="changeUserRole();$event.stopPropagation()" 
            [disabled]="getRolePermissionLevel(value) || valueline._1354_userid == applicationInfoService.projectInfo.projectAdminUserId">
            <option *ngFor="let userRole of userRoles" [ngValue]="userRole.id">
                {{ commonService.getTranslationValueFromArray(userRole) | texttransform }}
            </option>
        </select>    
    </div>


    <!-- SecurityUserChange -->
    <div *ngIf="control.controlType == 31" class="maxWidth entityTableUserProjectChangeUserValues">
        <div *ngIf="userService.hasPermission('CanUpdateUser')">
            <input [(ngModel)]="value" (change)="saveSecurityUser()">
        </div>
        <div *ngIf="!userService.hasPermission('CanUpdateUser')">
            {{ value }}
        </div>
    </div>

    <!-- deactivate security user -->
    <div *ngIf="control.controlType == 32" class="maxWidth entityTableUserProjectDeactivate">
        <app-jj-icons [type]="'lock-simple'" [width]="28" [height]="28" (click)="deactivateSecurityUser();$event.stopPropagation()"
            *ngIf="
                valueline._1354_userid != applicationInfoService.userID 
                && valueline._1354_userid != applicationInfoService.projectInfo.projectAdminUserId
                && valueline._1353_isactive == true" [tippy]="'User.Label.DeactivateUser' | texttransform"></app-jj-icons>
        <app-jj-icons [type]="'lock-simple-open'" [width]="28" [height]="28"  [tippy]="'User.Label.ReactivateUser' | texttransform"
            (click)="activateSecurityUser();$event.stopPropagation()" *ngIf="
                valueline._1354_userid != applicationInfoService.userID 
                && valueline._1353_isactive == false 
                && licensingService.licenseInfo.freeLicenses > 0"></app-jj-icons>
        <app-jj-icons [type]="'lock-simple-open'" [width]="28" [height]="28" color="red"
            [tippy]="'User.Label.CantReactivateUser' | texttransform" *ngIf="
                valueline._1354_userid != applicationInfoService.userID 
                && valueline._1353_isactive == false 
                && licensingService.licenseInfo.freeLicenses == 0"></app-jj-icons>
    </div>

    <!-- Data enrichment -->
    <div *ngIf="control.controlType == 33" class="maxWidth entityTableEnrichDataWithExternalInterface" 
        [tippy]="'DataInterface.Label.EnrichDataWithExternalInterface' | texttransform">
        <app-jj-icons [type]="'stack-plus'" [width]="28" [height]="28" (click)="enrichDataWithExternalInterface();$event.stopPropagation()"></app-jj-icons>
    </div>

    <!-- URL -->
    <div *ngIf="control.controlType == 34" class="maxWidth entityTableUrl ellipsisText" (click)="$event.stopPropagation()" [tippy]="value">
        <a [href]="value" target="_blank">{{ value }}</a>
    </div>

    <!-- Security user reset password -->
    <div *ngIf="control.controlType == 35" class="maxWidth entityTableValueResetUserPassword" (click)="$event.stopPropagation()">
        <app-jj-icons [type]="'envelope'" [width]="28" [height]="28" [tippy]="'EntityTable.ResetUserPassword.Header' | texttransform"
            (click)="resetSecurityUserPassword();$event.stopPropagation()"></app-jj-icons>
    </div>

    <!-- Security user event option -->
    <div *ngIf="control.controlType == 36 && value!=null" class="maxWidth entityTableValueResetUserPassword" (click)="$event.stopPropagation()">
        {{ getItemFromObjectText('itemFromObjectPretext') | texttransform }}
        <span *ngIf="control.itemFromObjectType == 'text'">{{ getItemFromObjectValue() | texttransform }}</span>
        <span *ngIf="control.itemFromObjectType == 'datetime'">{{ getItemFromObjectValue() | date:'medium' }}</span>
        <span *ngIf="control.itemFromObjectType == 'date'">{{ getItemFromObjectValue() | date: 'dd.MM.yyyy' }}</span>
        {{ getItemFromObjectText('itemFromObjectPostText') | texttransform }}
    </div>
</div>
<div class="userHierarchyItemWrapper">
    <div class="userHierarchyItem" cdkDropList [cdkDropListData]="userChildren"
    (cdkDropListDropped)="addMe($event)">
        <div class="userHierarchyItem__icon">
            <app-jj-icons type="user" [width]="48" [height]="48"></app-jj-icons>
        </div>
        <div class="userHierarchyItem__name">
            {{ getUserName() }}
        </div>                          
    </div>
    <div class="userHierarchyChildren">
        <div *ngFor="let child of userChildren" class="childItem">
            <div class="childItem__border">
                <div class="childItem__border__borderdesign"></div>
            </div>
            <app-userhierarchy-designer-item 
                [userId]="child.userId"
                [users]="users"
                [groupConnections]="groupConnections"
                [isGroupOwner]="false"
            ></app-userhierarchy-designer-item>
        </div>
    </div>
</div>
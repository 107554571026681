
<app-layout header="Supervisor.Label.UserManagement">
    <span id="userManagementUserList"></span>
    <div class="userManagement__wrapper">
        <div class="userManagementList">
            <div class="userManagement__sectionHeader">
                <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' | texttransform }}</div>            
                <div class="userManagement__sectionHeader__nav">
                    <app-filter (keyUp)="filterString = $event"></app-filter>
                    <app-main-button type="small" svg="user-plus" label="General.User.NewUser" (onClick)="newUser()"></app-main-button>
                </div>        
            </div>
            <div id="userManagementUserList" class="userManagement__itemContainer"
                [style.height.px]="uiService.getContainerHeightEx('userManagementUserList', 110, 300)">
                <div id="userWrapper" *ngFor="let user of users; let rowIndex = index" 
                    class="everleadTableRow"[ngClass]="{ hideInDom: isFilterActive(user) }" (click)="selectUser(user)">
                    <div [id]="getUserControlId(user)" class="userManagement__item__container fingerCursor">
                        <div class="userManagement__item__name">
                            <div class="ellipsisText">{{ user.userName }}</div>
                            <div class="ellipsisText userManagement__item__name__email">{{ user.email }}</div>                        
                        </div>                
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-layout>

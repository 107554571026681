import { Component, OnInit } from '@angular/core';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { LanguageService } from 'app/shared/translation/language.service';
import { GraphQLService, JJApolloClient, ApolloMethod } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { isNullOrUndefined } from 'util';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-leadreport',
  templateUrl: './leadreport.component.html',
  styleUrls: ['./leadreport.component.scss']
})
export class LeadreportComponent implements OnInit {
  leadReportFileType = 'pdf';
  leadReportTemplate: any = null;
  leadReportLevel: any = null;
  selectedLanguage: any = null;
  leadReportContact: any = null;

  contactRequired = false;
  contacts: any[];
  languages: any[];
  templates: any[];
  leadReportLevels: any[];
  leadReportCollapsed = false;
  selectedItemsCount = 0;
  onlyNetResults = false;
  loadingControlData = true;

  constructor(
    private sharedAPI: SharedAPI,
    private applicationInfoService: ApplicationInfoService,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private commonService: CommonService,
    private languageService: LanguageService,
    private messagingService: MessagingService,
    private eventService: EventService,
    public axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit() {
    if (this.applicationInfoService.currentContact) {
      this.leadReportContact = this.applicationInfoService.currentContact.id;
    }
    const promiseArray: Promise<any>[] = [];
    this.getAvailableLanguages();
    promiseArray.push(this.getAvailableTemplates());
    promiseArray.push(this.getAvailableLevels());
    promiseArray.push(this.getAccountContacts());
    Promise.all(promiseArray)
    .then(() => {
      this.loadingControlData = false;
    })
    .catch(error => {
      console.error('LeadreportComponent', error);
    });
  }

  getAccountContacts(): Promise<any> {
    return new Promise((getAccountCustomersResolve, getAccountCustomersReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.DataApi, ApolloMethod.Query,
        this.graphqlqueryService.getQuery('leadReportsContacts'), [])
      .then(leadReportsTemplateDefinitionsResult => {
        this.contacts = leadReportsTemplateDefinitionsResult.data.contacts;
        getAccountCustomersResolve(leadReportsTemplateDefinitionsResult);
      })
      .catch(error => { getAccountCustomersReject(error); });
    });
  }

  getAvailableTemplates(): Promise<any> {
    return new Promise((getAvailableTemplatesResolve, getAvailableTemplatesReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.CentralAPI, ApolloMethod.Query,
        this.graphqlqueryService.getQuery('leadReportsTemplateDefinitions'), [])
      .then(leadReportsTemplateDefinitionsResult => {
        this.templates = leadReportsTemplateDefinitionsResult.data.templateDefinitions;
        this.leadReportTemplate = this.commonService.getFirstItemFromArrayIfAvailable(this.templates);
        getAvailableTemplatesResolve(leadReportsTemplateDefinitionsResult);
      })
      .catch(error => { getAvailableTemplatesReject(error); });
    });
  }

  getAvailableLevels(): Promise<any> {
    return new Promise((getAvailableLevelsResolve, getAvailableLevelsReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.CentralAPI, ApolloMethod.Query,
        this.graphqlqueryService.getQuery('leadReportsLevels'), [])
      .then(leadReportsLevelsResult => {
        this.leadReportLevels = leadReportsLevelsResult.data.centralLookupTableDefinitions;
        this.leadReportLevel = this.commonService.getFirstItemFromArrayIfAvailable(this.leadReportLevels);
        if (this.applicationInfoService.currentAccount.isDummyAccount) {
          this.leadReportLevel = { id: 107 };
        }
        getAvailableLevelsResolve(leadReportsLevelsResult);
      })
      .catch(error => { getAvailableLevelsReject(error); });
    });
  }

  getAvailableLanguages() {
    this.languageService.getLanguages()
    .then(getAvailableLanguagesResult => {
      this.languages = getAvailableLanguagesResult;
      this.selectedLanguage = this.commonService.getFirstItemFromArrayIfAvailable(this.languages);
    })
    .catch(error => {
      console.error('LeadreportComponent getAvailableLanguages', error);
    });
  }

  executeLeadReport() {
    this.requestAndDownloadReport()
    .then(requestAndDownloadReportResult => {
      console.log('requestAndDownloadReportResult', requestAndDownloadReportResult);
    })
    .catch(requestAndDownloadReportError => { console.error('executeLeadReport', requestAndDownloadReportError); });
  }

  requestAndDownloadReport(): Promise<any> {
    return new Promise<any>((executeLeadReportResolve, executeLeadReportReject) => {
      if (this.applicationInfoService.currentAccount == null) {
        executeLeadReportReject('No account selected.');
        return;
      }
      this.contactRequired = false;
      let route = `/Leadreport/create/<0>?accountId=<1>&languageId=<3>&onlyNetResults=<4>&level=<5>&fileType=<6>`;
      if (this.leadReportLevel.id === 107) {
        if (!this.leadReportContact) {
          this.contactRequired = true;
          return;
        }
        route = `/Leadreport/create/<0>?accountId=<1>&contactId=<2>&languageId=<3>&onlyNetResults=<4>&level=<5>&fileType=<6>`;
      }
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info, '',
        this.axivasTranslateService.getTranslationTextForToken('Leadreport.Request.Message'), false);
      this.sharedAPI.executeLeadReport(
        route,
        this.applicationInfoService.currentAccount.id,
        this.leadReportTemplate.id,
        this.selectedLanguage.id,
        this.onlyNetResults,
        this.leadReportLevel.id,
        this.leadReportContact,
        this.leadReportFileType
      )
      .subscribe(executeLeadReportResult => {
        const headers = executeLeadReportResult.headers;
        const filename = headers.get('x-filename') ? decodeURI(headers.get('x-filename')) : `leadReport.${this.leadReportFileType}`;
        console.warn(headers)
        // const filename = isNullOrUndefined(executeLeadReportResult.headers.get('x-filename')) ? 'leadReport.' + this.leadReportFileType : decodeURI(executeLeadReportResult.headers.get('x-filename'));
        this.commonService.downloadFile(executeLeadReportResult.body, `application/${this.leadReportFileType}`, filename);
        executeLeadReportResolve(executeLeadReportResult);
      }, (error) => {
        executeLeadReportReject(error);
      });
      this.eventService.closePopup();
    });
  }

  toggleItem(toggleName: string) {
    this[toggleName] = !this[toggleName];
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-jj-icons',
  templateUrl: './jj-icons.component.html',
  styleUrl: './jj-icons.component.scss'
})
export class JjIconsComponent {

  @Input() type:string= '';
  @Input() width:number = 32;
  @Input() height: number = 32;
  @Input() color: string ='secondary'
  @Input() strokeColor: string ='secondary'
  @Input() pointer: boolean = true;
  @Input() disabled: boolean = false;

  private isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }


  getHeight() {
    if(!this.height){ 
      return '20px' 
    } else {
      return this.isNumeric(this.height) ? `${this.height}px` : this.height;
    }

  }

  getWidth(){
    if(!this.width){ 
      return '20px' 
    } else {
    return this.isNumeric(this.width) ? `${this.width}px` : this.width;
    }
  }

  getColor(){

    if(!this.color){
      return '#5465FF';
    } else {
      switch(this.color){
        case 'secondary':
          return '#5465FF';
        case 'primaryLight':
          return '#FFFFFF';
        case 'secondaryShade2':
          return '#788BFF';
        case 'primaryDark':
          return '#FBFBFB';
        case 'tertiary':
          return '#E2FDFF';
        case 'red':
          return '#FF5050';
        case 'green':
          return '#3FE163';        
        case 'yellow':
          return '#FFD600';
        case 'orange':
          return '#FF6B00';
        case 'disabledGray':
          return '#808080';
        case 'gray0':
          return '#DFDFDF';
        case 'disabled':
          return '#d2d2d2';
        case 'fontDark':
          return'#333333'
        default:
          return this.color;
      }
    } 
  }

  getStrokeColor(){
    if(!this.strokeColor){
      return '#5465FF';
    } else {
      switch(this.strokeColor){
        case 'secondary':
          return '#5465FF';
        case 'primaryLight':
          return '#FFFFFF';
        case 'secondaryShade2':
          return '#788BFF';
        case 'primaryDark':
          return '#FBFBFB';
        case 'tertiary':
          return '#E2FDFF';
        case 'red':
          return '#FF5050';
        case 'green':
          return '#3FE163';        
        case 'yellow':
          return '#FFD600';
        case 'orange':
          return '#FF6B00';
        case 'disabledGray':
          return '#808080';
        case 'gray0':
          return '#DFDFDF';
        case 'disabled':
          return '#d2d2d2';
        default:
          return this.color;
      }
    } 
  }


}

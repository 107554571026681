<div class="flowchart"
[style.height]="getHeight()"
[style.width]="getWidth()">
    <div class="flowChartNav">
        <div class="tooltip__info" *ngIf="showInfo == true && toolboxInfo == true" (click)="getToolBox('info')">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#262626" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
            <p><strong>{{ 'ProcessVisual.Label.Datazoom' | texttransform}} </strong> {{ 'ProcessVisual.Label.DatazoomDesc' | texttransform}}</p> 
            <p><strong>{{ 'ProcessVisual.Label.Grab' | texttransform }}</strong> {{ 'ProcessVisual.Label.GrabDesc' | texttransform}}</p>
        </div>
        <div class="flowChartNav__toolbox">
            <button *ngIf="toolboxInfo" (click)="getToolBox('info')">
               <app-jj-icons type="mouse-simple" [width]="20" [height]="20"></app-jj-icons>
            </button>
            <button *ngIf="toolboxExpand" (click)="getToolBox('expand')">
                <app-jj-icons type="arrows-out" [width]="20" [height]="20"></app-jj-icons>
            </button>
        </div>
    </div>
<div echarts 
    class="flowChart"
    [id]="controlid"
    [options]="options"
    [merge]="updateOptions"
    (chartClick)="onChartClick($event)"
    (chartInit)="onChartInit($event)"
    [style.height]="getHeight()"
    [style.width]="getHeight()"
    [autoResize]="true"
    ></div>
</div>


<div class="featureWrapper">
  <div class="featureHeader">
    <span *ngIf="packagefeatureService.getFeatureById(this.featureId).nameTranslationTokenId">
      {{ axivasTranslateService.getTranslationTextForId(this.packagefeatureService.getFeatureById(this.featureId).nameTranslationTokenId)  | texttransform }}
    </span>
    <span *ngIf="!packagefeatureService.getFeatureById(this.featureId).nameTranslationTokenId">
      {{ commonService.getTranslationValueFromArray(packagefeatureService.getFeatureById(featureId)) | texttransform }}
    </span>
  
  </div>
  <div class="featureSubHeader">
    {{ packagefeatureService.getFeatureSubHeaderById(featureId) | texttransform }}
  </div>

  <div class="featureSpinner" *ngIf="!showPopupImage">
    <div class="featureSpinner__spinnArrow" *ngIf="slider?.length > 1">
       <app-main-button type ="action" svg="arrow-left" (onClick)="changeSlide(false)"></app-main-button>
    </div>
    <div class="featureBody">
      <div class="featureBody__picture">
        <img class="featureBody__picture__inner fingerCursor"
            (click)="popupImage()" 
            [src]="getFeatureSliderImage(currentSlider)"
            [alt]="commonService.getTranslationValueFromArray(currentSlider)">
      </div>
      <div class="featureBody__text">
        <div [innerHTML]="getFeatureSliderInnerHtml(currentSlider)"></div>
      </div>
      <div class="featureBody__button">
        <app-main-button svg="globe" (onClick)="openWebsite()" label="Feature.Label.OpenWebsite"></app-main-button>
      </div>
    </div>
    <div class="featureSpinner__spinnArrow" *ngIf="slider?.length > 1">
      <app-main-button type="action" svg="arrow-right" (onClick)="changeSlide(true)"></app-main-button>
 <!--      <i class="fas fa-angle-double-right fingerCursor" (click)="changeSlide(true)"></i>     -->  
    </div>
  </div>

  
  <div *ngIf="showPopupImage" class="popupWrapper">
    <img class="featureBody__picture__popup fingerCursor"
      (click)="popupImage()"
      [src]="getFeatureSliderImage(currentSlider)"
      [alt]="commonService.getTranslationValueFromArray(currentSlider)">
  </div>
</div>

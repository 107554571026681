import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EventService } from './event.service';

export enum MessageSeverity {
  Error = 'error',
  Info = 'info',
  Warning = 'warn',
  Success = 'success'
}

export enum MessagePosition {
  TopRight = 'topRight',
  TopLeft = 'topLeft',
  TopCenter = 'topCenter',
  Center = 'center',
  BottomRight = 'bottomRight',
  BottomLeft = 'bottomLeft',
  BottomCenter = 'bottomCenter'
}

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(
    private messageService: MessageService,
    private axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService,
    private eventService:EventService,
  ) { }

  showDebugMessageInConsole(message: string, args: any[] = []) {
    if (this.applicationInfoService.isDebugMode) {
      console.log('DEBUG: ', message, args);
    }
  }

  showDefaultError(header: string, message: string, sticky = false) {
    header = this.axivasTranslateService.getTranslationTextForToken(header);
    message = this.axivasTranslateService.getTranslationTextForToken(message);    
    this.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, header, message, sticky);
  }

  showDefaultWarning(header: string, message: string, sticky = false) {
    header = this.axivasTranslateService.getTranslationTextForToken(header);
    message = this.axivasTranslateService.getTranslationTextForToken(message);    
    this.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, header, message, sticky);
  }

  showDefaultInfo(header: string, message: string, sticky = false) {
    header = this.axivasTranslateService.getTranslationTextForToken(header);
    message = this.axivasTranslateService.getTranslationTextForToken(message);    
    this.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info, header, message, sticky);
  }

  showDefaultSuccess(header: string, message: string, sticky = false) {
    header = this.axivasTranslateService.getTranslationTextForToken(header);
    message = this.axivasTranslateService.getTranslationTextForToken(message);    
    this.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, header, message, sticky);
  }

  showNewMessage(position: MessagePosition, severity: MessageSeverity, header: string, message: string, sticky: boolean) {
    
    this.eventService.toasterEvent.emit({
      key: position,
      severity: severity,
      summary:  this.axivasTranslateService.getTranslationTextForToken(header),
      sticky: sticky,
      detail: this.axivasTranslateService.getTranslationTextForToken(message)
    })
/*     this.messageService.add(
      {
        key: position,
        severity: severity,
        summary:  this.axivasTranslateService.getTranslationTextForToken(header),
        sticky: sticky,
        detail: this.axivasTranslateService.getTranslationTextForToken(message)
      }
    ); */
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashbdashboardcomponent-myday',
  templateUrl: './dashbdashboardcomponent-myday.component.html',
  styleUrls: ['./dashbdashboardcomponent-myday.component.scss']
})
export class DashbdashboardcomponentMydayComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  myFollowUpsPast = [];
  myFollowUpsToday = [];
  myFollowUpsFuture = [];
  myDayContentFilter = 1;
  aiContacts = [];
  page= 0;

  loading: boolean = false;
  noData: boolean = false;

  followUpOptions=[
    {
      id:1,
      name:this.axivasTranslationService.getTranslationTextForToken( 'WebsiteDashboard.Label.All' )
    },
    {
      id:2,
      name:this.axivasTranslationService.getTranslationTextForToken( 'WebsiteDashboard.Label.OnlyToday' )
    },
    {
      id:3,
      name:this.axivasTranslationService.getTranslationTextForToken( 'WebsiteDashboard.Label.OnlyFuture')
    },
    {
      id:4,
      name:this.axivasTranslationService.getTranslationTextForToken( 'WebsiteDashboard.Label.OnlyInthePast')
    },
  ]

  constructor(
    private methodService: MethodService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    public axivasTranslationService: AxivasTranslateService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getMyFollowUps();
    this.getAIContacts();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'selectedPoolChanged') { 
        this.getMyFollowUps();
        this.getAIContacts();    
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }
  
  loadFollowUp(followUp) {
    this.methodService.launchInternalMethod(InternalMethodType.ShowTask, [followUp]);
  }

  getMyFollowUps() {
    this.loading = true;

    this.externaldatasourceService.executeExternalDataSource(995, [this.applicationInfoService.poolId])
    .then(getMyFollowUpsResult => {

      this.loading = false;

      this.myFollowUpsToday = getMyFollowUpsResult.presentTasks.slice(0, 50);
      this.myFollowUpsPast = getMyFollowUpsResult.pastTasks.slice(0, 50);
      this.myFollowUpsFuture = getMyFollowUpsResult.futureTasks.slice(0, 50);

      if(!getMyFollowUpsResult){
        this.noData = true
      }

      if(this.myFollowUpsToday.length  === 0 && this.myFollowUpsPast.length === 0 && this.myFollowUpsFuture.length === 0){
        this.noData = true
      }

    }).catch((error) => {
      this.noData = true;
      //console.log('error')
    });
  }

  startWorking() {
    this.methodService.launchInternalMethod(InternalMethodType.NextTask, []);
  }


  checkIfArrayHasAi(array){
    return array.some((item) => item.contact.isAiContact || item.contact.isAiSuggestion)
  }

  myDayContentFilterChanged() {

  }

  showMyFollowUps() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['myfollowups', 'myfollowups']);
  }

  getAIContacts() {   
    const tableSettings = {
      projectId: this.applicationInfoService.projectID,
      definitionId: 409,
      orderBy: { columnName: "leadScoringCalculated", orderByOperator: 'DESC_NULLS_LAST' },
      filterId: null,
      columnFilter: [
        {columnName: "poolId", value: this.applicationInfoService.poolId.toString() }, 
        {columnName: "isAiContact", value: "true"}, 
        {columnName: "leadScoringCalculated", value: "80", operator: "GTE"}],
      pageSize: 20,
      pageNumber: 1,      
    }

    this.externaldatasourceService.executeExternalDataSource(939, [
      tableSettings.definitionId,
      tableSettings.pageSize,
      tableSettings.pageNumber,
      this.mapFilterArray(tableSettings.columnFilter),
      this.mapFilterArray(tableSettings.orderBy),
      '',
      null, null, null, null, null, null, null, null, null, null, null
    ]).then((getAIContactsResults)=> {
      if (getAIContactsResults.jsonData.length > 0) {
        getAIContactsResults.jsonData.forEach(item => {
          const itemData = JSON.parse(item);
          this.aiContacts.push({
            leadscore: itemData._4_leadscoringcalculated,
            accountname: itemData._1_name1,
            email: itemData._4_email,
            firstname: itemData._4_firstname,
            lastname: itemData._4_lastname,
            aiContact: true
          });
        })
      }      
    }).catch((error) => {
    }); 
  }

  mapFilterArray(filterArray){
    let filterArrayJson = JSON.stringify(filterArray);
    Object.keys(filterArray).forEach(() =>  {      
      filterArrayJson = filterArrayJson.replace('"'.concat('columnName', '"'), 'columnName');
      filterArrayJson = filterArrayJson.replace('"'.concat('value', '"'), 'value');
      filterArrayJson = filterArrayJson.replace('"'.concat('operator', '"'), 'operator');
      filterArrayJson = filterArrayJson.replace('"'.concat('orderByOperator', '"'), 'orderByOperator');
      filterArrayJson = filterArrayJson.replace('"'.concat('negate', '"'), 'negate');
      filterArrayJson = filterArrayJson.replace('"'.concat('DESC_NULLS_LAST', '"'), 'DESC_NULLS_LAST');
      filterArrayJson = filterArrayJson.replace('"'.concat('ASC_NULLS_LAST', '"'), 'ASC_NULLS_LAST');
    });
    return filterArrayJson;
  }

  loadContact(aiContactId) {
    this.methodService.launchInternalMethod(InternalMethodType.LoadContact, [aiContactId]);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { DashboardcoreService } from '../../dashboardcore.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagePosition, MessageSeverity, MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-defaultdashboard-projectoverview-account',
  templateUrl: './defaultdashboard-projectoverview-account.component.html',
  styleUrl: './defaultdashboard-projectoverview-account.component.scss'
})

export class DefaultdashboardProjectoverviewAccountComponent implements OnInit {
  overViewData = null;
  overViewDataInProgress: any = null;
  overViewDataClosed: any = null;
  dashboardResultData = [];

  @Input() reports = null;
  @Input() selectedReport = null;
  paramterExternalDataSourceResults = [];
  reportTemplate = null;
  fileName = '';
  reportParameterValues = [];
  progressResult = null;
  closedResult = null;
  dataResult = null;

  globalObjectionsResult = null;
  stepObjectionResult=null;

  tab:  number = 1;
  chartTab: number =1;
  viewChart: boolean = true;
  loadingData = false;
  loadingDependentData = false;
  loadingDataArray= false;

  nodata= true;
  nodependentData=true;
  nodataArray=true;

  showData = true;

  step: string | null;
  leadStatus: string | null;

  stepOptions : any[] | null;
  leadStatusOptions : any[] | null;
  selectedObjectionsOptions;
  objectionsList
  dashId: any = null;

  constructor(
    private dashboardcoreService: DashboardcoreService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    public commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit(): void {
    this.initReportTemplate(this.selectedReport.reportId);
  }

    getDashboardData() {
    switch(this.tab) {
      case 1:
        this.getDataOverview(this.selectedReport.dashboardIdAccountOverview);
        this.getDataOverviewInProgress(this.selectedReport.dashboardIdAccountOverviewProgress);
        this.getDataOverviewClosed(this.selectedReport.dashboardIdAccountOverviewClosed);    
        break;
      case 2:
        this.getDataOverview(this.selectedReport.dashboardIdContactOverview);
        this.getDataOverviewInProgress(this.selectedReport.dashboardIdContactOverviewProgress);        
        this.getDataOverviewClosed(this.selectedReport.dashboardIdContactOverviewClosed);    
        this.getDashboardDataArray(this.selectedReport.dashboardIdContactOverviewData);   
        break;
    }    
  }

  getDashboardDataArray(dashboardId) {
    this.loadingDataArray = true
    this.nodataArray= false

    let params = this.fillParams();
    this.dashboardResultData[dashboardId] = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(result => {
      this.dashboardResultData[dashboardId] = null;
      if (result) {
        if (result[0]) {
          this.loadingDataArray = false;
          //console.log('here', result[0]);
          this.dashId= dashboardId
          this.createObjectionsData(JSON.parse(JSON.stringify(result[0])))
          //this.dashboardResultData[dashboardId] = JSON.parse(JSON.stringify(result[0]));
           //this.dashboardResultData[dashboardId] = this.createObjectionsData(JSON.parse(JSON.stringify(result[0])))
        } else {
          this.loadingDataArray = false;
          this.nodataArray = true;
        }
      }
    }).catch((error)=>{
      console.log(error);
      this.nodataArray = true;
    });
  }

  getDataOverview(dashboardId) {
    this.loadingData = true;
    this.nodata = false;
    let params = this.fillParams();

    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(result => {
      this.overViewData = null;
      if (result) {
        if (result[0]) {
          this.dashboardResultData = [];
          this.overViewData = JSON.parse(JSON.stringify(result[0]))
          if (this.overViewData) {
            this.createoverviewData()
            this.loadingData = false;
            this.nodata = false;
          } else {
            this.nodata = true;
            this.loadingData = false;
          }
        } else {
          this.loadingData = false;
          this.nodata = true
        }
      } else {
        this.loadingData = false;
        this.nodata = true
      }
    }).catch((error) => {
      console.log('error', error)
      this.nodata = true

    });
  }

  fillParams() {
    const params = [];
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@language_id', value: this.applicationInfoService.languageId });
    this.reportTemplate.reportParameters.forEach(parameter => {
      if (parameter.defaultName != 'language_id') {
        if (this.commonService.isNullOrUndefined(this.reportParameterValues[parameter.id])) {
          params.push({ key: '@'.concat(parameter.defaultName), value: null });
          params.push({ key: '@'.concat(parameter.id), value: null });
        } else {
          params.push({ key: '@'.concat(parameter.defaultName), value: this.reportParameterValues[parameter.id] });
          params.push({ key: '@'.concat(parameter.id), value: this.reportParameterValues[parameter.id] });
        }
      }
    });
    return params;
  }

  getDataOverviewInProgress(dashboardId) {
    this.loadingDependentData = true;
    this.nodependentData = false;
    let params = this.fillParams();

    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(result => {
      this.overViewDataInProgress = null;
      this.nodependentData = true;
      this.loadingDependentData = true;
      if (result) {
        if (result[0]) {
          this.overViewDataInProgress = JSON.parse(JSON.stringify(result[0]));
          if (this.overViewDataInProgress && this.overViewDataClosed) {
            this.createProgressData();
            this.createClosedData();
            this.loadingDependentData = false
            this.nodependentData = false
          } else {
            this.nodependentData = true
            this.loadingDependentData = false
          }
        } else {
          this.nodependentData = true
          this.loadingDependentData = false
        }
      } else {
        this.loadingDependentData = false
        this.nodependentData = true
      }
    }).catch((error) => {
      console.log('error', error)
      this.nodependentData = true

    });
  }

  getDataOverviewClosed(dashboardId) {
    this.loadingDependentData = true;
    this.nodependentData = false
    let params = this.fillParams();

    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(result => {
      this.overViewDataClosed = null;
      if (result) {
        if (result[0]) {
          this.overViewDataClosed = JSON.parse(JSON.stringify(result[0]));
          if (this.overViewDataClosed && this.overViewDataInProgress) {
            this.createClosedData()
            this.createProgressData()
            this.loadingDependentData = false;
          } else {
            this.nodependentData = true;
            this.loadingDependentData = false;
          }
        } else {
          this.loadingDependentData = false;
          this.nodependentData = true;
        }
      } else {
        this.loadingDependentData = false;
        this.nodependentData = true;
      }
    }).catch((error) => {
      console.log('error', error)
      this.nodependentData = true;
    });
  }


  createObjectionsData(data?: any){
    const aData = data?.map((item) => {
      return{
        ...item,
        name: item.answer,
        value:item.anzahl,
        color:'#5465FF'
      }
    })

    const result = this.createSum(aData, 'anzahl')
    this.globalObjectionsResult = {name: 'Report.ProjectOverview.Gesamtergebnis', total: result }
  
    this.dashboardResultData[this.dashId] = aData; 
    console.log('this.dashboardResultData', this.dashboardResultData);

    this.createSelectValue(aData);
  }

  createSelectValue(data){

    if (data.length > 0){
       // make variablesand selectoptions
      this.stepOptions = Array.from(new Set(data.map((item) => item.step)))
      this.leadStatusOptions = Array.from(new Set(data.map((item) => item.leadstatus)))
      this.step= null;
      this.leadStatus = this.leadStatusOptions[0];
      // make default display data for chart and list;
       this.createAllStepsData();
    }
  }

  createAllStepsData(){
    const array=[]
    const allsteps =  this.dashboardResultData[this.dashId].filter((item) => item.leadstatus === this.leadStatus);
    const steps = Array.from(new Set(allsteps.map((item)=> item.step)));
    steps.map((step)=>{
      const stepGroup = allsteps.filter((item) => item.step === step)
      if(stepGroup.length > 0){        
        array.push(stepGroup)
      }
    })

    this.selectedObjectionsOptions = array;
    this.stepObjectionResult = {name: this.leadStatus, total: this.globalObjectionsResult.total};

  }

  onChangeLeadStateOptions(){

    if(this.leadStatus === null){
      return 
    } else {

      if(this.step == null){
        this.createAllStepsData()
      } else {
        this.selectedObjectionsOptions =  this.dashboardResultData[this.dashId].filter((item) => item.leadstatus === this.leadStatus && item.step === this.step);
      }
    }
  }


  onChangeStepOptions(){

    if(this.step === null){
    this.createAllStepsData();
    } else {
      this.selectedObjectionsOptions =  this.dashboardResultData[this.dashId].filter((item) => item.step === this.step);
      const sum = this.createSum(this.selectedObjectionsOptions, 'anzahl')
      this.stepObjectionResult = { name: this.step , total: sum}

    }
  }


  createoverviewData() {
    this.overViewData = this.dashboardcoreService.getPercentagefromArray(this.overViewData, 'anzahl', 'percentage', 1);
    const colors = ['#5465FF', '#FF5050', '#3FE163'];

    this.overViewData = this.overViewData.map((item, i) => {
      return {
        ...item,
        name: item.editing_state ? item.editing_state : 'not defined',
        value: [item.percentage],
        //color: colors[i],
        color: 
              item.editing_state_id === 2 ? '#5465FF' : 
              item.editing_state_id === 0 ? '#3FE163' : 
              item.editing_state_id === 1 ? '#FF6B00' : 
              '#808080'
      }
    })


    const sum = this.createSum(this.overViewData, 'anzahl')
    const sumPercent = this.createSum(this.overViewData, 'percentage');
    this.dataResult = { name: 'result', sum: sum, sumPercent: sumPercent.toFixed(1) }

    this.eventService.customEvent.emit({
      id: 'defaultdashboard_overviewData',
      data: this.overViewData,
    })
  }

  createProgressData() {
    this.overViewDataInProgress = this.dashboardcoreService.getPercentagefromArray(this.overViewDataInProgress, 'anzahl', 'percentage', 1);
    this.overViewDataInProgress = this.overViewDataInProgress.map((item) => {
      return {
        ...item,
        name: item.lead_state,
        value: item.anzahl,
        color: '#FF6B00',
      }
    })

    if (this.overViewDataInProgress?.length > 0 && this.overViewDataClosed?.length > 0) {
      this.nodependentData = false
      const gesamt = this.overViewDataInProgress.map((item, i) => {
        const foundID = this.overViewDataClosed.find((data) => data.lead_status_id === item.lead_status_id)
        if (foundID) {
          const sum = item.anzahl + foundID.anzahl
          return {
            ...item,
            gesamt: sum
          }
        } else {
          return {
            ...item,
            gesamt: item.anzahl
          }
        }
      })

      this.overViewDataInProgress = this.dashboardcoreService.getPercentagefromArray(gesamt, 'gesamt', 'gesamtPercentage', 1); gesamt
      const sum = this.createSum(this.overViewDataInProgress, 'anzahl')
      const sumPercent = this.createSum(this.overViewDataInProgress, 'percentage')
      const gesamtSum = this.createSum(this.overViewDataInProgress, 'gesamtPercentage')
      this.progressResult = { name: 'result', sum: sum, sumPercent: sumPercent.toFixed(1), sumGesamt: gesamtSum.toFixed(1) }

      this.eventService.customEvent.emit({
        id: 'defaultdashboard_ReportProgressData',
        data: this.overViewDataInProgress,
        result: this.progressResult
      })
    }
  }


  createClosedData() {
    this.overViewDataClosed = this.dashboardcoreService.getPercentagefromArray(this.overViewDataClosed, 'anzahl', 'percentage', 1);
    this.overViewDataClosed = this.overViewDataClosed.map((item) => {
      return {
        ...item,
        name: item.lead_state,
        value: item.anzahl,
        color: '#5465FF',
      }
    })

    if (this.overViewDataInProgress?.length > 0 && this.overViewDataClosed?.length > 0) {
      this.nodependentData = false
      const gesamt = this.overViewDataClosed.map((item, i) => {
        const foundID = this.overViewDataInProgress.find((data) => data.lead_status_id === item.lead_status_id)
        if (foundID) {
          const sum = item.anzahl + foundID.anzahl
          return {
            ...item,
            gesamt: sum
          }
        } else {
          return {
            ...item,
            gesamt: item.anzahl
          }
        }
      })
      this.overViewDataClosed = this.dashboardcoreService.getPercentagefromArray(gesamt, 'gesamt', 'gesamtPercentage', 1);
      const sum = this.createSum(this.overViewDataClosed, 'anzahl')
      const sumPercent = this.createSum(this.overViewDataClosed, 'percentage')
      const gesamtSum = this.createSum(this.overViewDataClosed, 'gesamtPercentage');
      this.closedResult = { name: 'result', sum: sum, sumPercent: sumPercent.toFixed(1), sumGesamt: gesamtSum.toFixed(1) }

      this.eventService.customEvent.emit({
        id: 'defaultdashboard_ReportClosedData',
        data: this.overViewDataClosed,
        result: this.closedResult
      })
    }
  }

  createSum(array, property) {
    let sumValue = array?.reduce((sum, item) => sum + item[property], 0);
    if (property == 'percentage') {      
      if (sumValue > 99.8) { sumValue = 100; }
    }
    return sumValue;
  }

  getHeaderText() {
    if (this.tab == 1) {
      return 'Report.ProjectOverview.ProjekübersichtProAdresseStandard';
    } else {
      return 'Report.ProjectOverview.ProjekübersichtProAnsprechpartnerStandard';
    }
  }

  getBestTableHeight(array) {
    if (this.commonService.isNullOrUndefined(array)) {
      return 0;
    }
    let height = 50 + (array.length * 40);
    if (height < 140) { height = 140; }
    return height;
  }

  initReportTemplate(reportId) {
    this.reportParameterValues = [];
    this.paramterExternalDataSourceResults = [];
    const template = this.reports.find(report => report.id == reportId);
    if (template) {
      this.reportTemplate = template;
    } else {
      return;
    }
    this.reportTemplate.reportParameters.sort((a, b) => a['order'] < b['order'] ? -1 : 1);
    const promiseArray: Promise<any>[] = [];
    this.reportTemplate.reportParameters.forEach(reportParameter => {      
      if (!this.commonService.isNullOrUndefined(reportParameter.value)) {        
        switch (reportParameter.parameterTypeLookupId) {
          case 16:
            this.reportParameterValues[reportParameter.id] = Number(reportParameter.value);
            break;
          case 104:
            this.reportParameterValues[reportParameter.id] = JSON.parse(reportParameter.value);
            break;
          default:
            this.reportParameterValues[reportParameter.id] = reportParameter.value;
            break;
        }
      }
      if (!this.commonService.isNullOrUndefined(reportParameter.externalDataSource)) {
        promiseArray.push(this.externaldatasourceService.executeExternalDataSource(reportParameter.externalDataSource.id, [])
          .then(externalDataSourceResult => {
            this.paramterExternalDataSourceResults[reportParameter.externalDataSource.id] = externalDataSourceResult;
            if ( externalDataSourceResult.length > 0 && 
                 this.commonService.isNullOrUndefined(reportParameter.defaultValue) &&
                 reportParameter.defaultName != 'user_id'
            ) {
              this.reportParameterValues[reportParameter.id] = externalDataSourceResult[0][reportParameter.externalDataSource.dataKey];
            }           
            if (reportParameter.defaultName == 'language_id') { this.reportParameterValues[reportParameter.id] = this.applicationInfoService.languageId; }
         })
         .catch(error => {
          console.error(error);
        }));
      } else {
        if (reportParameter.defaultName == 'language_id') { this.reportParameterValues[reportParameter.id] = this.applicationInfoService.languageId; }  
      }
    });
    Promise.all(promiseArray).then(() => {
      this.getDashboardData();
    });
  }

  requestReport(): Promise<any> {
    return new Promise<any>((requestReportResolve, requestReportReject) => {
      const body = [];
      this.reportTemplate.reportParameters.forEach(reportParameter => {
        if (!this.commonService.isNullOrUndefined(this.reportParameterValues[reportParameter.id])) {
          let value = this.reportParameterValues[reportParameter.id];
          if (reportParameter.parameterTypeLookup.defaultName === 'date') {
            const date = new Date(value);
            value = ''.concat(
              date.getFullYear().toString(), '-',
              (date.getMonth() + 1).toString().padStart(2, '0'), '-',
              date.getUTCDate().toString().padStart(2, '0')
            );
          }
          body.push({ id: reportParameter.id, value: value.toString() });
        }
      });

      let finalBody = `{
        "additionalFilters": <0>,
        "userReportParameters": <1>
      }`;
      let filter = '[]';
      finalBody = finalBody.replace('<0>', filter);
      finalBody = finalBody.replace('<1>', JSON.stringify(body));
      requestReportResolve(this.requestAndDownloadReport(finalBody));
      
    });
  }

  requestAndDownloadReport(body): Promise<any> {
    this.fileName = 'Report '.concat(this.applicationInfoService.projectName, ' ',
      this.commonService.getDateTimeString(new Date(Date.now())));
    return new Promise<any>((executeLeadReportResolve, executeLeadReportReject) => {
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info, '',
        this.axivasTranslateService.getTranslationTextForToken('Reporting.Request.Message'), false);
      this.sharedAPI.executeReporting('/Report/get/<0>/<1>/?projectId=<2>', this.reportTemplate.id, 'report', this.applicationInfoService.projectID, body )
      .subscribe(executeReportingResult => {
        if (executeReportingResult == null) {
           this.eventService.customEvent.emit({ id: 'startReportTimer' });
         } else {
          this.commonService.downloadFile(executeReportingResult, 'application/pdf', this.fileName + '.xlsx');
          executeLeadReportResolve(executeReportingResult);
        }
       }, (error) => {
         this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, '',
          this.axivasTranslateService.getTranslationTextForToken('Reporting.Message.ErrorDescription'), false);
         executeLeadReportReject(error);
      });
    });
  }

  switchTab(tab) {
    this.tab = tab;
    switch (tab) {
      case 1:
        this.initReportTemplate(486);
        break;
      case 2:
        this.initReportTemplate(492);
        break;
    }
  }
}

<div [ngClass]="{
  everleadSelectWrapper: !uiType,
  everleadControlRegister: uiType === 'custom-register'
 }" [class.disabled]="disabled" [class.error]="error">
  <label *ngIf="label" class="everleadSelectWrapper__label" [class.disabled]="disabled" [class.error]="error">
    {{ label | texttransform }}
    <app-wizard-infobutton *ngIf="info" [displayText]="info" [width]="17" [height]="17"></app-wizard-infobutton>
  </label>

  <div class="everleadSelectWrapper__valueWrapper" [class.disabled]="disabled" [class.error]="error">
    <div class="select__box">
      <select *ngIf="!complex && subArray == null" [id]="id" [disabled]="disabled" [(ngModel)]="value" (change)="handleSimpleValue($event)"
        (blur)="handleBlur()">
        <option *ngIf="placeholder" [value]="null">{{ placeholder | texttransform }}</option>
        <option *ngFor="let option of options" [value]="option[bind]">
          <span *ngIf="displayMultiValue != null">{{ getDisplayMultiValue(option) }}</span>
          <span *ngIf="displayMultiValue == null">{{ commonService.getNameTranslationOrDefaultName(option, display) |
            texttransform }}</span>
        </option>
      </select>

      <select *ngIf="!complex && subArray != null" [id]="id" [disabled]="disabled" [(ngModel)]="value" (change)="handleSimpleValue($event)"
        (blur)="handleBlur()">
        <option *ngIf="placeholder" [value]="null">{{ placeholder | texttransform }}</option>
        <option *ngFor="let option of options" [value]="option[subArray][bind]">
          <span *ngIf="displayMultiValue != null">{{ getDisplayMultiValue(option) }}</span>
          <span *ngIf="displayMultiValue == null">{{ commonService.getNameTranslationOrDefaultName(option, display) |
            texttransform }}</span>
        </option>
      </select>

      <select *ngIf="complex" [id]="id" [disabled]="disabled" [(ngModel)]="value" (change)="handleObjectValue($event)"
        (blur)="handleBlur()">
        <option *ngIf="placeholder" [ngValue]="null">{{ placeholder | texttransform }}</option>
        <option *ngFor="let option of options" [ngValue]="option">
          <span *ngIf="displayMultiValue == null && display !== 'option'">
            {{ commonService.getNameTranslationOrDefaultName(option, display) | texttransform }}
          </span>
          <span *ngIf="displayMultiValue != null && display !== 'option'">
            {{ getDisplayMultiValue(option) }}
          </span>
          <span *ngIf="displayMultiValue == null && display === 'option' && !ellipsis">{{ option }}</span>
          <span *ngIf="displayMultiValue == null && display === 'option' && ellipsis">{{ ellipsisLabel(option, ellipsis)
            }}</span>
          <span *ngIf="displayMultiValue != null && display === 'option'">{{ getDisplayMultiValue(option) }}</span>
        </option>
      </select>
    </div>
  </div>
</div>
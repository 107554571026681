import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashbdashboardcomponent-kpis',
  templateUrl: './dashbdashboardcomponent-kpis.component.html',
  styleUrls: ['./dashbdashboardcomponent-kpis.component.scss']
})
export class DashbdashboardcomponentKpisComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  kpis = null;
  loading = true;
  pieId = '';
  pieContactId = '';
  kpiList = [];
  @Input() showKPIs = true;

  // ! data for charts
/*   colors = [
    { background: '#ED5C73', font: 'white' },
    { background: '#59AA8E', font: 'white' },
    { background: '#F07E26', font: 'white' }
  ]; */
  chartOptionsAccounts = {
    color: ['#5465FF', '#5162F6','#788BFF'],
    data: [],
    type:'pie'

  }
  chartOptionsContacts={
    color: ['#5465FF', '#5162F6','#788BFF'],
    data: [],
    type: 'pie'

  }

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getKPIs();
    this.pieId = this.commonService.createGuid();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'selectedPoolChanged') { 
        this.getKPIs();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getKPIs() {
    this.loading = true;
    this.kpis = null;
    this.chartOptionsAccounts = { color: ['#5465FF', '#5162F6','#788BFF'], data: [], type:'pie' };
    this.chartOptionsContacts = { color: ['#5465FF', '#5162F6','#788BFF'], data: [], type: 'pie' };
    this.externaldatasourceService.executeExternalDataSource(823, [0, ''])
      .then(getKPIsResult => {
      //console.log('get KPIS', getKPIsResult)
      if (getKPIsResult != null) {
        this.kpis = getKPIsResult[0];
        this.fillKpisForChart();
      }
      this.loading = false;
    });
  }

  fillKpisForChart() {
    this.kpis.accountProgressCounts.sort((a, b) => a.type < b.type ? -1 : 1).forEach((accountProgressCount):any => {
      this.chartOptionsAccounts.data.push({ name: accountProgressCount.status , value: accountProgressCount.count,});
      this.chartOptionsAccounts.type="pie"
    });

    this.kpis.contactProgressCounts.sort((a, b) => a.type < b.type ? -1 : 1).forEach((contactProgressCount): any => {
      this.chartOptionsContacts.data.push({ name: contactProgressCount.status , value: contactProgressCount.count,});
      this.chartOptionsContacts.type="pie"
    });

  }
}

import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { LicensingService } from '../licensing.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-licensing-adduser',
  templateUrl: './licensing-adduser.component.html',
  styleUrl: './licensing-adduser.component.scss'
})

export class LicensingAdduserComponent implements OnInit {
  mode = 5;
  acceptNewFee = false;
  userRoles = [];
 
  newUser = {
    firstName: '',
    lastName: '',
    email: '',
    languageId: 2,
    roleId: null,
    sendEmailToUser: true,
    projectId: this.applicationInfoService.projectID,
    customerId: this.applicationInfoService.projectInfo.customerId,
    isExternalUser: true
  }
  newUserList = [];
  additionalLicenses = 0;
  licensingChangePackage = null;
  monthlySubscription = false;
  packageChanged = false;

  constructor(
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService,
    public licensingService: LicensingService,
    private packagefeatureService: PackagefeatureService,
    private commonService: CommonService,
    private axivasTranslateService: AxivasTranslateService,
    private sharedAPI: SharedAPI,
    private externaldatasourceService: ExternaldatasourceService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private methodService: MethodService
  ) {}

  ngOnInit(): void {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['licensingAdduserMode'])) {
      this.changeMode(this.applicationInfoService.miscSettings['licensingAdduserMode']);
      this.applicationInfoService.miscSettings['licensingAdduserMode'] = null;
    }    
    
    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'changeLicensingPage') {
        this.changeMode(event.value);    
      }
    });
    
    this.licensingChangePackage = this.applicationInfoService.miscSettings['licensingChangePackage']
    if (!this.commonService.isNullOrUndefined(this.licensingChangePackage)) {
      this.monthlySubscription = this.licensingChangePackage.subscription;      
    }

    this.addUserToList();
    // this.changeMode(5);
    this.changeMode(this.mode);
    this.getUserRoles();
    this.getLicenseCosts();
  }

  getUserRoles() {
    this.externaldatasourceService.executeExternalDataSource(819).then(getUserRolesResult => {
      this.userRoles = getUserRolesResult.filter(roleItem => roleItem.projectId == this.applicationInfoService.projectID);
    });    
  }

  getLicenseCosts() {
    let packageId = this.applicationInfoService.projectInfo.packageId;
    this.licensingService.getLicensePaymentDetails(packageId, 1).then(getLicenseCostResult => { });
  }

  closePopup() {
    this.eventService.closeJjPopup();
  }

  changeMode(mode) {
    switch(mode) {
      case 1:
        this.eventService.customEvent.emit({ id: 'changePopupHeader', value: '' });
        this.eventService.customEvent.emit({ id: 'changePopupSubHeader', value: null });
        this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: this.licensingService.mode1Size });        
        break;
      case 2: 
        this.eventService.customEvent.emit({ id: 'changePopupHeader', value: 'Licensing.Label.AddUserLicenseHeader' });
        this.eventService.customEvent.emit({ id: 'changePopupSubHeader', value: 'Licensing.Label.AddUserLicenseDesc' });
        this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: this.licensingService.mode2Size });        
        break;
      case 3: 
        this.eventService.customEvent.emit({ id: 'changePopupHeader', value: '' });
        this.eventService.customEvent.emit({ id: 'changePopupSubHeader', value: null });
        this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: this.licensingService.mode3Size });        
        break;
      case 4: 
        this.eventService.customEvent.emit({ id: 'changePopupHeader', value: 'Licensing.Label.AddUserHeader' });
        this.eventService.customEvent.emit({ id: 'changePopupSubHeader', value: 'Licensing.Label.AddUserDesc' });
        this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: this.licensingService.mode4Size });        
        break;
      case 5: 
        this.eventService.customEvent.emit({ id: 'changePopupHeader', value: '' });
        this.eventService.customEvent.emit({ id: 'changePopupSubHeader', value: '' });
        this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: this.licensingService.mode5Size });        
        break;
    }
    this.mode = mode;
  }

  getNewFeeText() {    
    let returnValue = this.axivasTranslateService.getTranslationTextForToken('Licensing.Label.NewLicenseInfoText');
    returnValue = returnValue.replace('<0>', (this.licensingService.licenseInfo.licenseCount + this.additionalLicenses).toString());
    if (this.monthlySubscription) {
      returnValue = returnValue.replace('<1>', this.licensingService.licenseDetails.pricePerMonth.toString());
    } else {
      returnValue = returnValue.replace('<1>', this.licensingService.licenseDetails.pricePerYear.toString());
    }    
    returnValue = returnValue.toUpperCase();
    return returnValue;
  }

  getFee(withAdditionalLicenses = false) {
    let returnValue = null;
    if (this.licensingService.licenseDetails == null) { 
      return '';
    }
    if (this.monthlySubscription) {
      returnValue = this.licensingService.licenseInfo.licenseCount * this.licensingService.licenseDetails.pricePerMonth;
      if (withAdditionalLicenses) {
        returnValue = this.licensingService.licenseDetails.pricePerMonth * (this.additionalLicenses + this.licensingService.licenseInfo.licenseCount);
      }        
    } else {
      returnValue = this.licensingService.licenseInfo.licenseCount * this.licensingService.licenseDetails.pricePerYear;
      if (withAdditionalLicenses) {
        returnValue = this.licensingService.licenseDetails.pricePerYear * (this.additionalLicenses + this.licensingService.licenseInfo.licenseCount);
      }   
    }   
    return returnValue + ' '.concat(this.licensingService.licenseDetails.currency.toUpperCase());
  }

  getPackageName() {
    return this.packagefeatureService.getPackageName(this.applicationInfoService.projectInfo.packageId);
  }

  addUserToList() {
    if (this.maxUserReached() == false) {
      this.newUserList.push(JSON.parse(JSON.stringify(this.newUser)));
    }    
  }

  removeUser(newUser) {
    this.commonService.removeItemFromArray(this.newUserList, newUser);
  }

  maxUserReached() {
    let returnValue = false;
    if (this.newUserList.length >= this.licensingService.licenseInfo.freeLicenses) {
      returnValue = true;
    }
    return returnValue;
  }

  getAvailableRoles() {
    this.userRoles = [];
  }  

  checkIfUserCanBeCreated() {
    let returnValue = true;
    this.newUserList.forEach(user => {
      if (user.email == '' || user.lastName == '' || user.firstName == '' || user.roleId == null) {
        returnValue = false;
      }
    });
    if (this.newUserList.length == 0) {
      returnValue = false;
    }
    return returnValue;
  }

  createNewUser() {
    let counter = 0;
    this.loaderService.display(true);
    this.newUserList.forEach(user => {
      this.sharedAPI.createExternalAccount(user).subscribe(
        createExternalUserResult => {
          counter ++;
          if (counter == this.newUserList.length) {
            this.loaderService.display(false);
            this.newUserList = [];
            this.eventService.customEvent.emit({ id: 'refreshEntityTable' });
            this.messagingService.showDefaultSuccess('', 'Licensing.Label.CreationSuccess');
            setTimeout(() => { this.licensingService.getLicenses() }, 500);
          }          
        },
        error => {          
          this.messagingService.showDefaultError('', 'Licensing.Label.ErrorAddingUser');
          this.loaderService.display(false);
        }, () => {}
      );
    })
  }

  addLicenses() {
    this.loaderService.display(true);
    this.sharedAPI.selfServiceUpdateLicences(
      this.licensingService.licenseInfo.licenseCount + this.additionalLicenses,
      this.licensingService.isMonthlySubscription()
    ).subscribe(
      result => {
        console.warn(result)
        this.changeMode(3);
        this.loaderService.display(false);
        this.licensingService.getLicenses();
      },
      error => {
        this.messagingService.showDefaultError('', 'Licensing.Label.ErrorAddingLicences');
        this.loaderService.display(false);
      }
    );
  }

  upgradePlan() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['licenseupgrade', 'licenseupgrade']);
    this.eventService.closeJjPopup();
  }

  changePackage() {
    console.warn(this.licensingChangePackage)
    this.loaderService.display(true);
    this.sharedAPI.selfServiceUpgradePackage(
      this.licensingChangePackage.packageDetails.id, 
      this.licensingService.licenseInfo.licenseCount,
      this.monthlySubscription
    ).subscribe(
      changePackageSuccess => {
        this.packageChanged = true;
        setTimeout(() => {
          this.eventService.customEvent.emit({ id: 'getLicensePendingStatus' });          
        }, 800);        
        this.loaderService.display(false);
      },
      changePackageError => {
        this.messagingService.showDefaultError('', 'Licensing.Label.ErrorChangingPackage');
        this.loaderService.display(false);
      }
    );
  }
}

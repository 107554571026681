import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  lastYammerNewsCheckResult = null;
  component: string = '';
  featurePhoneNotFound = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    private eventService: EventService,
    private methodService: MethodService,
    private externaldatasourceService: ExternaldatasourceService,
    private settingsService: SettingsService,
    private axivasTranslateService: AxivasTranslateService,
    private packagefeatureService: PackagefeatureService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'closeQuickMenu') {
        this.closePopup();
      }
      if (event == 'getCompleteProjectInformaton') {
        this.getPhoneFeature();
      }
    });
  }

  openSearch() {
    this.eventService.showJjPopup(this.axivasTranslateService
      .getTranslationTextForToken('General._.QuickSearch'), 'quicksearch', '80', true);    
  }

  getUserNameShortCut(user) {
    let userShortCut = '';
    if (user == null) { return ''; }
    if (user.firstName != null) {
      userShortCut = userShortCut.concat(user.firstName.substring(0,1))
    }
    if (user.lastName != null) {
      userShortCut = userShortCut.concat(user.lastName.substring(0,1))
    }    
    return userShortCut;
  }
  
  getNewUserMessages() {
    try {
      const unreadMessages = this.applicationInfoService.userMessages.filter(message => message.userMessages[0].readDateAt == null);
      if (this.commonService.isNullOrUndefined(unreadMessages)) {
        return 0;
      } else {
        return unreadMessages.length;
      }    
    }
    catch {
      console.error('getNewUserMessages error');
    }
  }


  calltoolbarNav(element:string){
    this.component = element
  }

  callSection(section, caller = null) {   
    let arrowLeft = 0;
    let arrowRight = 0;
    const element = document.getElementById(caller);
    if (element) {
      element.style.setProperty('display', 'flex');
      var rect= element.getBoundingClientRect();
      arrowLeft = (rect.left - (rect.width / 2)) - 20;
    }    
    this.eventService.customEvent.emit({ 
      id: 'calltoolbarPopup', 
      value: section ,
      arrowLeft: arrowLeft + 'px',
      arrowRight: (this.applicationInfoService.innerWidth - 95 - arrowLeft) + 'px'
    });
  }

  unlockAccount() {
    if (this.applicationInfoService.currentAccount != null) {
      this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, ['Message.UnlockAccount.Header', 'Message.UnlockAccount.Message'])
      .then(() => {
        this.methodService.launchInternalMethod(InternalMethodType.UnlockAccount, [this.applicationInfoService.currentAccount.id])
        .then(() => {
          this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, [
            '', 
            this.applicationInfoService.applicationSettings['projectStartSection']
          ]).catch(error => { console.error(error); });
        }).catch(error => { console.error(error); });
      }).catch(() => {});
    }
  }
  
  closePopup() {
    this.eventService.customEvent.emit({ id: 'closeToolbarPopup' });
  }

  accountExport() {
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['Leadreport.Label.Header', 'leadreport']);
    this.closePopup();
  }

  checkIfTippIsAvailable() {
    return this.commonService.getApplicationSectionContentTipp(window.location.href.toString())
  }

  showHelpContent() {
    this.closePopup();
    this.eventService.customEvent.emit('showHelp');
  }

  showAccountData() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'account'])
    .catch(error => { console.error(error); });
  }

  getReturnToSVG() {
    if (this.applicationInfoService.currentTask != null) {
      return 'assets/images/svg/toolbar/toolbar_back_task.svg'
    }
    if (this.applicationInfoService.currentContact != null) {
      return 'assets/images/svg/toolbar/toolbar_back_contact.svg'
    }
    if (this.applicationInfoService.currentAccount != null) {
      return 'assets/images/svg/toolbar/toolbar_back_account.svg'
    }
  }

  getReturnToText() {
    if (this.applicationInfoService.currentTask != null) {
      return 'Toolbar.Label.ReturnToTask';
    }
    if (this.applicationInfoService.currentContact != null) {
      return 'Toolbar.Label.ReturnToContact';
    }
    if (this.applicationInfoService.currentAccount != null) {
      return 'Toolbar.Label.ReturnToAccount';
    }
  }

  createDemoUser() {
    this.eventService.showJjPopup('Toolbar.Label.CreateDemoUser', 'createdemoaccount', '400px;450px');
  }

  showDemoWebsite() {
    //console.log('called')
    this.externaldatasourceService.executeExternalDataSource(493, []).then(result => {
      if (result.length > 0) {
        this.applicationInfoService.userSettings['demoLandingPageVisited' + this.applicationInfoService.projectID] = true;
        this.settingsService.updateSecurityUserSettingsJson();
        this.commonService.openUrl(result[0].domainFilter, '_blank');
        this.closePopup();
      }
    });
  }

  showEVERLEADYammer() {
    this.applicationInfoService.userSettings['lastYammerVisit'] = new Date(Date.now());
    this.settingsService.updateSecurityUserSettingsJson();
    this.commonService.openUrl(this.applicationInfoService.applicationSettings['everleadYammer'], '_new');
  }

  checkIfThereAreNewsForUser() {
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['everleadYammerNewsdate'])) {
      return false;
    }

    if (this.commonService.isNullOrUndefined(this.applicationInfoService.userSettings['lastYammerVisit'])) {
      return true;
    } else {
      const lastVisitDate = new Date(this.applicationInfoService.userSettings['lastYammerVisit']);
      const lastNewsDate = new Date(this.applicationInfoService.applicationSettings['everleadYammerNewsdate']);
      if (lastNewsDate < lastVisitDate) {
        return false;
      } else {
        return true;
      }    
    }
  }

  prepareMail() {
    if (!this.checkIfMailCanBeSentToContact()) {
      return;
    }
    let eMail = '';
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.currentContact.email) == false) {
      eMail = this.applicationInfoService.currentContact.email;
    } else {
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.currentContact.email2) == false) {
        eMail = this.applicationInfoService.currentContact.email2;
      }
    }
    this.applicationInfoService.miscSettings['syncMailTo'] = eMail;
    this.eventService.showJjPopup('CustomEMail.Label.MailToContact', 'emaileditorsync', '90');
    return;
    // if (this.commonService.validateEMail(this.controlValue)) {
    //   this.applicationInfoService.miscSettings['syncMailTo'] = this.controlValue;
    //   this.eventService.showJjPopup('CustomEMail.Label.MailToContact', 'emaileditorsync', '90');
    // }
  }

  checkIfMailCanBeSentToContact() {
    let returnValue = false;
    if (this.applicationInfoService.currentContact) {
      if (this.applicationInfoService.currentContact.email != '' && 
          this.commonService.isNullOrUndefined(this.applicationInfoService.currentContact.email) == false) {
        returnValue = true;
      }
    }
    if (this.applicationInfoService.currentContact) {
      if (this.applicationInfoService.currentContact.email2 != '' && 
          this.commonService.isNullOrUndefined(this.applicationInfoService.currentContact.email2) == false) {
        returnValue = true;
      }
    }
    if (!this.userService.hasPermission('CanUseNonSelfServiceNaviagionItem')) {
      returnValue = false;
    }
    return returnValue;
  }

  downloadReports() {
    this.applicationInfoService.miscSettings['showReportingDownload'] = true;
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['reporting', 'reporting']);
  }

  getPhoneFeature() {
    if (this.packagefeatureService.selectedPackageHasFeature(28) == false) {
      this.featurePhoneNotFound = true;
    } else {
      this.featurePhoneNotFound = false;
	  }   
  }
}

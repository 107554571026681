<div class="lookupTableDesigner" *ngIf="userService.hasPermission('CanUpdateLookupTable')">
    <app-layout header="LookupTableEditor.Label.LookupTableEditor">
        <div *ngIf="loadingData==true">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </div>

        <div class="lookupTableDesignerContentWrapper" *ngIf="loadingData==false">        
            <div class="lookupTableDesignerToolbar">            
                <app-select-input label="EntityMemberDesign.Label.LookupTableSource" [(ngModel)]="lookupTableSourceId" 
                    *ngIf="applicationInfoService.isDeveloper" (valueChange)="getLookupsBySource()"
                    [options]="lookupTableSources" display="defaultName" bind="id"></app-select-input>
                <div class="lookupTableDesignerToolbar__buttons">
                    <app-main-button type="small" svg="key-return" (onClick)="selectedLookupTableDefinition = null" 
                        *ngIf="selectedLookupTableDefinition != null"
                        label="LookupTableEditor.Label.Back"></app-main-button>                    

                    <app-main-button type="small" svg="plus" (onClick)="showPopup = true" *ngIf="selectedLookupTableDefinition != null"
                        label="LookupTableEditor.Label.CreateValue"></app-main-button>
                    <app-main-button type="small" svg="plus" (onClick)="showPopup = true" 
                        *ngIf="selectedLookupTableDefinition == null && userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                        label="LookupTableEditor.Label.CreateDefinition"></app-main-button>                    
                </div>
            </div>

            <div class="lookupTableDesignerInfoLabel" *ngIf="selectedLookupTableDefinition == null">
                <div>{{ 'LookupTableEditor.Label.PleaseSelectTheLookupTableDefinition' | texttransform }}</div>
            </div>
            <div class="lookupTableDesigner__content" *ngIf="selectedLookupTableDefinition == null">                
                <div class="everleadTableRow lookupTableDefinitions fingerCursor" 
                    *ngFor="let lookupTableDefinition of lookupTableDefinitions">
                    <div class="lookupTableDefinitions__id everleadCoral">{{ lookupTableDefinition.definitionId }}</div>           
                    <div class="lookupTableDefinitions__defaultName">
                        <span *ngIf="lookupTableDefinition.nameTranslationToken">{{ lookupTableDefinition.nameTranslationToken.tokenFullName | texttransform }}</span>
                        <span *ngIf="!lookupTableDefinition.nameTranslationToken">{{ lookupTableDefinition.definitionName | texttransform }}</span>
                    </div>
                    <div class="lookupTableDefinitions__buttons">                        
                        <app-wizard-translations *ngIf="lookupTableSource == 'central'" [array]="lookupTableDefinition" 
                            updateBaseArrayExternaldatasourceId=1002 mode="3"></app-wizard-translations>    
                        <app-wizard-translations *ngIf="lookupTableSource == 'data'" [array]="lookupTableDefinition" 
                            updateBaseArrayExternaldatasourceId=1003 mode="3"></app-wizard-translations>    
                        <app-jj-icons type="pencil-simple-line" [width]="28" [height]="28" 
                            (click)="selecteLookupTableDefinition(lookupTableDefinition)"></app-jj-icons>
                    </div>
                </div>
            </div>

            <div class="lookupTableDesigner__content" *ngIf="selectedLookupTableDefinition != null">
                <app-layout [header]="getDefinitionName(selectedLookupTableDefinition)">
                    <div class="lookupTableDesignerContent">
                        <div class="lookupTableDefinitions lookupTableDefinitions__header fingerCursor">
                            <div class="lookupTableDefinitions__id">Id</div>
                            <div class="lookupTableDefinitions__isActive">{{ 'Feature.Label.IsActive' | texttransform }}</div>
                            <div class="lookupTableDefinitions__defaultName">{{ 'LookupTableEditor.Label.NameAndTranslation' | texttransform }}</div>
                            <div class="lookupTableDefinitions__delete"></div>
                        </div>
                        <div class="lookupTableDesignerContentTable">
                            <div class="lookupTableDefinitions fingerCursor everleadTableRow" *ngFor="let lookupTable of lookups">
                                <div class="lookupTableDefinitions__id everleadCoral">
                                    {{ lookupTable.id }}
                                </div>
                                <div class="lookupTableDefinitions__isActive">
                                    <app-checkbox label="" [(ngModel)]="lookupTable.isActive" (valueChange)="saveLookupTable(lookupTable)"></app-checkbox>
                                </div>
                                <div class="lookupTableDefinitions__defaultName">
                                    <input [(ngModel)]="lookupTable.defaultName" (change)="saveLookupTable(lookupTable)">
                                    <app-wizard-translations *ngIf="lookupTableSource == 'central'" [array]="lookupTable" updateBaseArrayExternaldatasourceId=215 mode="3"></app-wizard-translations>    
                                    <app-wizard-translations *ngIf="lookupTableSource == 'data'" [array]="lookupTable" updateBaseArrayExternaldatasourceId=859 mode="3"></app-wizard-translations>    
                                </div>
                                <!-- <div class="lookupTableDefinitions__delete">
                                    <app-jj-icons type="trash" [width]="28" [height]="28" (click)="deleteLookupTable(lookupTable)"></app-jj-icons>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </app-layout>
            </div>
        </div>
    </app-layout>
    
    <div class="lookupTableDesignerNewEntryPopupBackground" *ngIf="showPopup">
    <div class="lookupTableDesignerNewEntryPopup" *ngIf="showPopup">
        <div class="lookupTableDesignerNewEntryPopup__header">
            <div *ngIf="selectedLookupTableDefinition == null">{{ 'LookupTableEditor.Label.CreateDefinition' | texttransform }}</div>
            <div *ngIf="selectedLookupTableDefinition != null">{{ 'LookupTableEditor.Label.CreateValue' | texttransform }}</div>
            <div>
                <app-jj-icons type="x" [width]="28" [height]="28" (click)="showPopup = false"></app-jj-icons>                
            </div>
        </div>
        <div class="lookupTableDesignerNewEntryPopup__content">
            <div>{{ 'LookupTableEditor.Label.PleaseEnterName' | texttransform }}</div>
            <div>
                <input class="maxWidth" [(ngModel)]="newEntryName">
            </div>
        </div>
        <div class="lookupTableDesignerNewEntryPopup__button">
            <app-main-button type="small" svg="plus" (onClick)="createLookupTable()" *ngIf="selectedLookupTableDefinition != null"
                label="LookupTableEditor.Label.CreateValue" [disabled]="newEntryName == ''"></app-main-button>
            <app-main-button type="small" svg="plus" (onClick)="createLookupTableDefinition()" *ngIf="selectedLookupTableDefinition == null"
                label="LookupTableEditor.Label.CreateDefinition" [disabled]="newEntryName.length == ''"></app-main-button>                    
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { ApplicationInfoService, ProjectMode } from 'app/core/application/application-info.service';
import { Dictionary } from 'app/core/dictionary';
import { GraphQLService, ApolloMethod } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from '../service/graphqlquery.service';
import { ExternaldatasourceService } from '../service/externaldatasource.service';
import { CommonService } from '../service/common.service';
import { environment } from 'environments/environment';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { isNullOrUndefined } from 'util';
import { ExternalContentService } from '../service/external-content.service';
import { SessionStorageService } from 'ngx-webstorage';
import { EventService } from '../service/event.service';
import { UiService } from '../service/ui.service';
import { AuthService } from 'app/core/authentication/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshValueService {

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private externalContentService: ExternalContentService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private eventService: EventService,
    private sessionStorage: SessionStorageService,
    private axivasTranslateService: AxivasTranslateService,
    private uiService: UiService,
    private authService: AuthService
  ) { }

  clearProjectValues(): Promise<any> {
    return new Promise(resolve => {  
      this.axivasTranslateService.translatedValues = [];
      this.applicationInfoService.projectQuestionaires = null;
      this.applicationInfoService.projectBattleCards = null;
      this.applicationInfoService.campaingnModeId = null;
      this.applicationInfoService.additionalMenuBarControlId = 0;
      this.applicationInfoService.blockSettingChange = false;
      this.applicationInfoService.userSettingsPoolSelected = false;
      this.applicationInfoService.entityMember = new Dictionary<any>();
      this.applicationInfoService.entities = new Dictionary<any>();
      this.applicationInfoService.steps = new Dictionary<any>();
      this.applicationInfoService.campaigns = new Dictionary<any>();
      this.applicationInfoService.workflows = new Dictionary<any>();
      this.applicationInfoService.logicalEntities = new Dictionary<any>();
      this.applicationInfoService.stepworkflow = new Dictionary<any>();
      this.applicationInfoService.results = new Dictionary<any>();
      this.applicationInfoService.workflowSteps = new Dictionary<any>();
      this.applicationInfoService.pools = new Dictionary<any>();      
      this.applicationInfoService.entitiesorig = new Dictionary<any>();
      this.applicationInfoService.subResults = new Dictionary<any>();
      this.applicationInfoService.controls = new Dictionary<any>();
      this.applicationInfoService.blockSettingChange = false;
      this.applicationInfoService.controlValues = new Dictionary<any>();
      this.applicationInfoService.containerData = new Dictionary<any>();
      this.applicationInfoService.leadstates = new Dictionary<any>();
      this.applicationInfoService.questionaires = new Dictionary<any>();
      this.applicationInfoService.externalDataSourceCache = new Dictionary<any>();
      this.applicationInfoService.entitiesDynamicFields = new Dictionary<any>();
      this.applicationInfoService.dataLookupTableDefinitions = new Dictionary<any>();
      this.applicationInfoService.selectedMenuItem = null;
      this.applicationInfoService.highlightedMenuItem = null;
      this.applicationInfoService.currentTask = null;
      this.applicationInfoService.currentContact = null;
      this.applicationInfoService.currentAccount = null;
      this.applicationInfoService.hasCampaignsWithOwnTimeRecordingProject = false;
      this.applicationInfoService.currentUserGroupsCampaignId = 0;
      this.applicationInfoService.timeRecordingProjectId = null;  
      this.applicationInfoService.currentUserGroup = null;    
      resolve(null);
    });
  }

  getLookupTableDefinitions(): Promise<any>  {
    return new Promise((getLookupTableDefinitionsResolve, getLookupTableDefinitionsReject) => {
      this.applicationInfoService.dataLookupTableDefinitions = new Dictionary<any>();
      this.externaldatasourceService.executeExternalDataSource(266, [])
      .then(getLookupTableDefinitionsResult => {
        getLookupTableDefinitionsResult.forEach(definition => {
          this.applicationInfoService.dataLookupTableDefinitions.Add(definition.id, definition);
        });
        getLookupTableDefinitionsResolve(null);
      })
      .catch((error => {
        getLookupTableDefinitionsReject(error);
      }));
    });
  }

  getProjectCategories(): Promise<any> {
    return new Promise((getProjectCategoriesResolve, getProjectCategoriesReject) => {
      this.externaldatasourceService.executeExternalDataSource(271)
      .then(getProjectCategoriesResult => {
        this.applicationInfoService.projectInfo.categories = getProjectCategoriesResult;
        getProjectCategoriesResolve(getProjectCategoriesResult);
      })
      .catch(error => {
        getProjectCategoriesReject(error);
      });
    });
  }

  getProjectEntities(preserveDataValues: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      let backupEntities = null;
      let backupEntitiesOrig = null;
      if (preserveDataValues) {
        backupEntities = this.applicationInfoService.entities.createDictionaryBackup();
        backupEntitiesOrig = this.applicationInfoService.entitiesorig.createDictionaryBackup();
      }
      this.applicationInfoService.entities = new Dictionary();
      this.applicationInfoService.entitiesorig = new Dictionary();
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.entityServiceEntities)
      .then(result => {
        result.data.entities.forEach(element => {
          if (element.uiEntityInstances) {
            element.uiEntityInstances.forEach(uientity => {
              this.addEntityForCustomer(uientity.logicalUiEntityInstanceId, element, backupEntities, backupEntitiesOrig);
            });
          }
        });
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  addEntityForCustomer(id: string, entity: any, backup, backuporig) {
    entity.uiEntityInstance = id;
    entity.entityMembers.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
    entity.data = new Object();
    if (!this.commonService.isNullOrUndefined(backup) && backup.Item(id).data !== undefined) {
      entity.data = backup.Item(id).data;
    }
    this.applicationInfoService.entities.Add(id, JSON.parse(JSON.stringify(entity)));
    entity.entityMembers.forEach(member => {
      if (!this.applicationInfoService.entityMember.ContainsKey(member.id)) {
        this.applicationInfoService.entityMember.Add(member.id, member);
      }
    });
    if (!this.commonService.isNullOrUndefined(backuporig) && backuporig.Item(id).data !== undefined) {
      entity.data = backuporig.Item(id).data;
    }
    this.applicationInfoService.entitiesorig.Add(id, JSON.parse(JSON.stringify(entity)));
  }

  getProjectFollowUpGroups(): Promise<any> {
    return new Promise((getProjectFollowUpGroupsResolve, getProjectFollowUpGroupsreject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.projectServiceFollowUpUserGroups, [])
      .then(getProjectFollowUpGroupsResult => {
        this.applicationInfoService.availableUserGroups = getProjectFollowUpGroupsResult.data.followUpUserGroups;
        if (this.applicationInfoService.availableUserGroups.length > 0) {
          this.applicationInfoService.currentUserGroup = this.applicationInfoService.availableUserGroups[0].id;
          const userGroup = this.applicationInfoService.availableUserGroups.find(group => group.id == this.applicationInfoService.currentUserGroup);
          if (userGroup && this.applicationInfoService.applicationSettings['useCampaignModeFilter'] == 'true') {
            this.applicationInfoService.campaingnModeId = userGroup.campaignId;
            console.warn('group campaign', this.applicationInfoService.campaingnModeId);
          }
        }
        getProjectFollowUpGroupsResolve(getProjectFollowUpGroupsResult);
      })
      .catch(error => { getProjectFollowUpGroupsreject(error); });
    });
  }

  getPrereqs(): Promise<any> {
    this.applicationInfoService.externalDataSources = new Dictionary();
    return new Promise((getExternalDataSourcresResolve, getExternalDataSourcresReject) => {
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.getQuery('externaldatasources'))
      .then(getPrereqsResult => {
        // ExternalDatasources
        getPrereqsResult.data.externalDataSources.sort((a, b) => a.id < b.id ? -1 : 1);
        getPrereqsResult.data.externalDataSources.forEach(element => {
          this.applicationInfoService.externalDataSources.Add(element.id, element);
        });

        // Translations
        this.axivasTranslateService.translationTextIdArray = getPrereqsResult.translations;
        getExternalDataSourcresResolve(null);
      })
      .catch(error => { getExternalDataSourcresReject(error); });
    });
  }

  getExternalDataSources(): Promise<any> {
    this.applicationInfoService.externalDataSources = new Dictionary();
    return new Promise((getExternalDataSourcresResolve, getExternalDataSourcresReject) => {
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.getQuery('externaldatasources'))
      .then(result => {
        result.data.externalDataSources.sort((a, b) => a.id < b.id ? -1 : 1);
        result.data.externalDataSources.forEach(element => {
          this.applicationInfoService.externalDataSources.Add(element.id, element);
        });
        getExternalDataSourcresResolve(null);
      })
      .catch(error => { getExternalDataSourcresReject(error); });
    });
  }

  getProjectQuestionaires() {
    return new Promise((getProjectQuestionairesResolve, getProjectQuestionairesReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.projectServiceGetQuestionaires, [])
      .then(projectServiceGetQuestionairesResult => {
        projectServiceGetQuestionairesResult.data.entities.forEach(entity => {
          if (entity.lookupEntityDynamicalType) {
            if (  entity.lookupEntityDynamicalType.id === 25
               || entity.lookupEntityDynamicalType.id === 26
               || entity.lookupEntityDynamicalType.id === 27) {
              this.applicationInfoService.questionaires.Add(entity.id, entity);
              this.addEntityForCustomer(entity.uiEntityInstances.logicalUiEntityInstanceId, entity, null, null);
            }
          }
        });
        getProjectQuestionairesResolve(null);
      })
      .catch(error => { getProjectQuestionairesReject(error); });
    });
  }

  getLogicalEntities(): Promise<any> {
    this.applicationInfoService.logicalEntities = new Dictionary();
    return new Promise((getLogicalEntitiesResolve, getLogicalEntitiesReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.projectSelectionLogicalEntities, [])
      .then(projectSelectionLogicalEntitiesResult => {
        projectSelectionLogicalEntitiesResult.data.logicalEntities.forEach(logicalEntity => {
          this.applicationInfoService.logicalEntities.Add(logicalEntity.id, logicalEntity);
        });
        getLogicalEntitiesResolve(null);
      })
      .catch(error => { getLogicalEntitiesReject(error); });
    });
  }

  getApplicationSettings(): Promise<any> {
    return new Promise((getApplicationSettingsResolve, getApplicationSettingsReject) => {
      this.applicationInfoService.applicationSettings = [];
      this.externaldatasourceService.executeExternalDataSource(120, [])
      .then(getApplicationSettingsResult => {
        getApplicationSettingsResult.forEach(applicationSetting => {
          this.applicationInfoService.applicationSettings[applicationSetting.setting] = applicationSetting.value;
        });
        getApplicationSettingsResolve(getApplicationSettingsResult);
      })
      .catch(error => {
        getApplicationSettingsReject(error);
        console.error('', error);
      });
    });
  }

  getProjectCampaigns(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.applicationInfoService.campaigns = new Dictionary<any>();
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.projectServiceCampaigns)
        .then(result => {
          result.data.campaigns.sort((a, b) => a.id < b.id ? -1 : 1);
          result.data.campaigns.forEach(element => {
            element.defaultName = this.commonService.getTranslationValueFromArray(element);
            this.applicationInfoService.campaigns.Add(element.id, element);
          });
          // console.warn('getProjectCampaigns', result)
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // getProjectWorkflowsAndSteps() {
  //   return new Promise((resolve, reject) => {
  //     this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.projectServiceWorkflows)
  //       .then(result => {
  //         this.applicationInfoService.isFollowUpWorkflowId = null
  //         this.applicationInfoService.isFollowUpWorkflowStepId = null
  //         result.data.workflows.forEach(workflow => {
  //           workflow.defaultName = this.commonService.getTranslationValueFromArray(workflow);
  //           this.applicationInfoService.workflows.Add(workflow.id, workflow);
  //           workflow.workflowSteps.forEach(step => {
  //             if (step.isFollowUp == true) {
  //               this.applicationInfoService.isFollowUpWorkflowId = workflow.id;
  //               this.applicationInfoService.isFollowUpWorkflowStepId = step.id;
  //             }  
  //             step.defaultName = this.commonService.getTranslationValueFromArray(step);
  //             const workflowInfo = JSON.parse(JSON.stringify(workflow));
  //             workflowInfo.id = step.id;
  //             this.applicationInfoService.stepworkflow.Add(step.id, workflowInfo);
  //             this.applicationInfoService.steps.Add(step.id, step);
  //             step.results.forEach(stepresult => {
  //               stepresult.defaultName = this.commonService.getTranslationValueFromArray(stepresult);
  //               this.applicationInfoService.results.Add(stepresult.id, stepresult);
  //               if (stepresult.leadStatus) {
  //                 if (!this.applicationInfoService.leadstates.ContainsKey(stepresult.leadStatus.lookupTable.id)) {
  //                   this.applicationInfoService.leadstates.Add(stepresult.leadStatus.lookupTable.id,
  //                     {id: stepresult.leadStatus.lookupTable.id, defaultName: stepresult.leadStatus.lookupTable.defaultName});
  //                 }
  //               }
  //               const questions = [];
  //               stepresult.questions.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
  //               stepresult.questions.forEach(question => {
  //                 question.answers.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
  //                 questions.push(question);
  //               });
  //               this.addWorkflowStepToDictionary(workflow.id, step);
  //               this.applicationInfoService.subResults.Add(stepresult.id, questions);
  //             });
  //           });
  //         });
  //         resolve(result);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // }

  addWorkflowStepToDictionary(workflowId: any, step: any) {
    if (this.applicationInfoService.workflowSteps.ContainsKey(workflowId)) {
      const stepArray = this.applicationInfoService.workflowSteps.Item(workflowId);
      if (!this.commonService.checkIfItemIsInArray(stepArray, step)) {
        stepArray.push(step);
      }
      this.applicationInfoService.workflowSteps.Set(workflowId, stepArray);
    } else {
      this.applicationInfoService.workflowSteps.Add(workflowId, [step]);
    }
  }

  refreshTranslations(): Promise<any> {    
    return new Promise((refreshTranslationsResovle, refreshTranslationsReject) => {
      this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/Translation/0').subscribe(() => {
        this.axivasTranslateService.getTranslationIds().
        then(translations => {
          this.externaldatasourceService.executeExternalDataSource(260, [
            this.commonService.getModifyArrayBody({ 
              id: this.applicationInfoService.projectID, 
              translationCache: JSON.stringify(translations)
            } , [])
          ]);
          refreshTranslationsResovle(null);
        })
        .catch(error => {
          refreshTranslationsReject(error);
        });
      })
    });
  }

  refreshProjectTranslations(): Promise<any> {
    return new Promise((refreshProjectTranslationsResolve, refreshProjectTranslationsReject) => {
      let languageId = 2;
      if (this.axivasTranslateService.currentLanguage == 'en') {
        languageId = 1;
      }
      this.applicationInfoService.languageId = languageId;
      console.warn(this.applicationInfoService.languageId, 'LanguageId')
      this.externaldatasourceService.executeExternalDataSource(725, [languageId])
      .then(refreshProjectTranslationsResult => {
        this.axivasTranslateService.translationTextArrayProject = refreshProjectTranslationsResult;
        this.axivasTranslateService.translationTextArray = [{ id: 0 }];
        refreshProjectTranslationsResolve(refreshProjectTranslationsResult);
      }).catch(error => { refreshProjectTranslationsReject(error); })
    });
  }

  refreshGlobalTranslations() {    
    return new Promise((refreshGlobalTranslationsResolve, refreshGlobalTranslationsReject) => {
      let languageId = 2;
      if (this.axivasTranslateService.currentLanguage == 'en') {
        languageId = 1;
      }
      if (this.applicationInfoService.userSettings['LanguageIdentifier'] == 'en') { 
        languageId = 1; 
      }
      
      this.externaldatasourceService.executeExternalDataSource(724, [languageId])
      .then(refreshGlobalTranslations => {
        this.axivasTranslateService.translationTextArrayGlobal = refreshGlobalTranslations;  
        refreshGlobalTranslationsResolve(refreshGlobalTranslations);
      }).catch(error => { refreshGlobalTranslationsReject(error); })
    });
  }

  saveSelectedProjectInStorage(project: any) {
    this.sessionStorage.store('lastproject', project.id);
    localStorage.setItem('lastProject', project.id);
  }

  refreshProject() {
    this.getCompleteProjectInformaton();
  }

  getWorkflows(): Promise<any> {
    return new Promise((getWorkflowsResolve, getWorkflowsReject) => {
      this.applicationInfoService.workflows = new Dictionary();
      this.applicationInfoService.stepworkflow = new Dictionary();
      this.applicationInfoService.steps = new Dictionary();
      this.applicationInfoService.results = new Dictionary();
      this.applicationInfoService.leadstates = new Dictionary();
      this.applicationInfoService.subResults = new Dictionary();

      this.applicationInfoService.isFollowUpWorkflowId = null
      this.applicationInfoService.isFollowUpWorkflowStepId = null
      this.externaldatasourceService.executeExternalDataSource(949)
      .then(getWorkflowResult => { 
        getWorkflowResult.forEach(workflow => {
          // workflow.defaultName = this.commonService.getTranslationValueFromArray(workflow);
          this.applicationInfoService.workflows.Add(workflow.id, workflow);
          workflow.workflowSteps.forEach(step => {
            if (step.isFollowUp == true) {
              this.applicationInfoService.isFollowUpWorkflowId = workflow.id;
              this.applicationInfoService.isFollowUpWorkflowStepId = step.id;
            }  
            // step.defaultName = this.commonService.getTranslationValueFromArray(step);
            const workflowInfo = JSON.parse(JSON.stringify(workflow));
            workflowInfo.id = step.id;
            this.applicationInfoService.stepworkflow.Add(step.id, workflowInfo);
            this.applicationInfoService.steps.Add(step.id, step);
            step.results.forEach(stepresult => {
              // stepresult.defaultName = this.commonService.getTranslationValueFromArray(stepresult);
              this.applicationInfoService.results.Add(stepresult.id, stepresult);            
              const questions = [];
              stepresult.questions.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
              stepresult.questions.forEach(question => {
                question.answers.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
                questions.push(question);
              });
              this.addWorkflowStepToDictionary(workflow.id, step);
              this.applicationInfoService.subResults.Add(stepresult.id, questions);
            });
          });
          this.eventService.customEvent.emit({ id: 'workflowUpdated'})
        });
        getWorkflowsResolve(null);
      }).catch(() => {
        getWorkflowsReject(null);
      });    
    });
  }

  getLookupsForProject() : Promise<any> {    
    return new Promise((getLookupsForProjectResolve, getLookupsForProjectReject) => {
      this.externaldatasourceService.executeExternalDataSource(1005).then(getLookupsForProject => {
        this.applicationInfoService.projectLookupTables = getLookupsForProject;
        getLookupsForProjectResolve(getLookupsForProject);
      }).catch(error => { getLookupsForProjectReject(error); });
    });
  }

  getCompleteProjectInformaton(): Promise<any> {
    return new Promise((getCompleteProjectInformatonResolve, getCompleteProjectInformatonReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
          this.graphqlqueryService.loadFullProjectInformation, [])
      .then(getCompleteProjectInformatonResult => {
        // FollowupGroups
        this.applicationInfoService.availableUserGroups = getCompleteProjectInformatonResult.data.followUpUserGroups;
        if (this.applicationInfoService.availableUserGroups.length > 0) {
          this.applicationInfoService.currentUserGroup = this.applicationInfoService.availableUserGroups[0].id;
          const userGroup = this.applicationInfoService.availableUserGroups.find(group => group.id == this.applicationInfoService.currentUserGroup);
          if (userGroup && this.applicationInfoService.applicationSettings['useCampaignModeFilter'] == 'true') {
            this.applicationInfoService.campaingnModeId = userGroup.campaignId;
          }
        }

        // Dashboards
        this.applicationInfoService.customDashboards = getCompleteProjectInformatonResult.data.dashboards;

        // Tabledefinitions
        this.applicationInfoService.tableLayoutDefinitions = getCompleteProjectInformatonResult.data.tableLayoutDefinitions;

        // Roles 
        this.applicationInfoService.securityRoles = getCompleteProjectInformatonResult.data.roles;
        
        // Questionaire
        this.applicationInfoService.questionaires = new Dictionary();
        getCompleteProjectInformatonResult.data.entities.forEach(entity => {
          if (entity.lookupEntityDynamicalType) {
            if (  entity.lookupEntityDynamicalType.id === 25
               || entity.lookupEntityDynamicalType.id === 26
               || entity.lookupEntityDynamicalType.id === 27) {
              this.applicationInfoService.questionaires.Add(entity.id, entity);
              this.addEntityForCustomer(entity.uiEntityInstances.logicalUiEntityInstanceId, entity, null, null);
            }
          }
        });

        // Logical entities
        this.applicationInfoService.logicalEntities = new Dictionary();
        getCompleteProjectInformatonResult.data.logicalEntities.forEach(logicalEntity => {
          this.applicationInfoService.logicalEntities.Add(logicalEntity.id, logicalEntity);
        });

        // Campaigns
        this.applicationInfoService.campaigns = new Dictionary();
        getCompleteProjectInformatonResult.data.campaigns.sort((a, b) => a.id < b.id ? -1 : 1);
        getCompleteProjectInformatonResult.data.campaigns.forEach(element => {
          element.defaultName = this.commonService.getTranslationValueFromArray(element);
          this.applicationInfoService.campaigns.Add(element.id, element);
        });
        const lastSelectedCampaignId = this.commonService.getSessionStorageItem('lastSelectedCampaignId');
        if (!this.commonService.isNullOrUndefined(lastSelectedCampaignId)) {
          const campaign = this.applicationInfoService.campaigns.toArray().find(campaign => campaign.id == lastSelectedCampaignId);
          if (!this.applicationInfoService.blockSettingChange && !this.applicationInfoService.userSettingsCampaignSelected) {
            if (campaign) {
              this.applicationInfoService.campaingnModeId = lastSelectedCampaignId;
            } else {
              this.applicationInfoService.campaingnModeId = null;
            }          
          }
        }        
    
        // ApplicationSetting
        this.applicationInfoService.applicationSettings = [];
        getCompleteProjectInformatonResult.data.applicationSettings.forEach(applicationSetting => {
          this.applicationInfoService.applicationSettings[applicationSetting.setting] = applicationSetting.value;
        });

        // Entities
        let backupEntities = null;
        let backupEntitiesOrig = null;        
        this.applicationInfoService.entities = new Dictionary();
        this.applicationInfoService.entitiesorig = new Dictionary();
        getCompleteProjectInformatonResult.data.entities.forEach(element => {
          if (element.uiEntityInstances) {
            element.uiEntityInstances.forEach(uientity => {
              this.addEntityForCustomer(uientity.logicalUiEntityInstanceId, element, backupEntities, backupEntitiesOrig);
            });
          }
        });

        // Workflows 
        this.getWorkflows();
      
        // Leadstates
        this.getLeadstates();

        // ProjectUsers
        this.applicationInfoService.projectUsers = new Dictionary();
        getCompleteProjectInformatonResult.data.projectUsers.forEach(element => {
          this.applicationInfoService.projectUsers.Add(element.id, element);
        });
        this.getAllProjectUsers();

        // Show project screen
        this.applicationInfoService.showLoadProjectSpinner = false;

        // UserMessages
        this.getUserMessages();
        
        getCompleteProjectInformatonResolve(null);
      });
    });
  }

  refreshControls() {
    this.uiService.getControlsForProject();
  }

  getUserMessages(): Promise<any> {
    return new Promise((getUserMessagesResolve, getUserMessagesReject) => {
      this.externaldatasourceService.executeExternalDataSource(677)
      .then(getUserMessagesResult => {
        this.applicationInfoService.userMessages = getUserMessagesResult;
        getUserMessagesResolve(getUserMessagesResult);
      }).catch(error => { getUserMessagesReject(error); });  
    });
  }

  updateUiControl(controlId) {        
    this.externaldatasourceService.executeExternalDataSource(594, [controlId, this.graphqlqueryService.uiServiceControlBaseString])    
    .then(updateUiControlResult => {     
      const oldUiControl = JSON.parse(JSON.stringify(this.applicationInfoService.controls.Item(updateUiControlResult[0].logicalControlId.toString())));
      if (oldUiControl.childs.length == 0) {
        this.uiService.cleanControl(updateUiControlResult[0]);
        this.applicationInfoService.controls.Remove(updateUiControlResult[0].logicalControlId);
        this.applicationInfoService.controls.Add(updateUiControlResult[0].logicalControlId, updateUiControlResult[0]);
        this.eventService.onUiControlCacheUpdateEvent.emit(updateUiControlResult[0]);  
      }
    });
  }

  getLeadstates() {
    this.externaldatasourceService.executeExternalDataSource(926)      
    .then(leadStateResult => {
      this.applicationInfoService.leadstates = new Dictionary();
      leadStateResult.forEach(leadState => {
        this.applicationInfoService.leadstates.Add(leadState.id, leadState);
      });
      this.eventService.customEvent.emit('leadStatesLoaded')
    });
  }

  getAllProjectUsers() {
    this.applicationInfoService.projectUsersAll = [];    
    this.externaldatasourceService.executeExternalDataSource(884)      
    .then(getAllProjectUsersResult => {
      getAllProjectUsersResult.forEach(userInfo => {
        const projectInfo = userInfo.userProjects.find(project => project.projectId == this.applicationInfoService.projectID);
        if (projectInfo) {
          userInfo['isActive'] = projectInfo.isActive;
        } else {
          userInfo['isActive'] = false;
        }
        this.applicationInfoService.projectUsersAll.push(userInfo);
      });
    });
  }
}

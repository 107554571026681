<div class="defaultDashboardsWrapper">
    <div class="defaultDashboardsSelectedReport" *ngIf="selectedReport != null">
        <div>{{ getDashboardDefaultName() | texttransform }}</div>
        <div>
            <app-main-button (onClick)="selectedReport = null" label="General._.Back" type="small"></app-main-button>
        </div>
    </div>
    <div class="itemBoxList" *ngIf="selectedReport == null">
        <div class="everleadShadowBox itemBox fingerCursor" *ngFor="let dashboard of dashboards" 
            (click)="selectedReport=dashboard">
            <div class="itemBox__icon">
                <app-jj-icons [type]="dashboard.icon" [width]="60" [height]="60"></app-jj-icons>                     
            </div>
            <div class="itemBox__label">
                <div class="itemBox__label__name">
                    {{ dashboard.defaultName | texttransform }}
                </div>
                <div class="itemBox__label__description">
                    {{ dashboard.description | texttransform }}
                </div>                
            </div>
        </div>
    </div>

    <div class="defaultDashboardsContent" *ngIf="selectedReport != null">
        <app-defaultdashboard-projectoverview-account [selectedReport]="selectedReport" [reports]="reports" *ngIf="reports != null && selectedReport.id == 1"></app-defaultdashboard-projectoverview-account>
        <app-defaultdashboard-opportunity [selectedReport]="selectedReport" [reports]="reports"  *ngIf="reports != null && selectedReport.id == 2"></app-defaultdashboard-opportunity>
        <app-defaultdashboard-activity [selectedReport]="selectedReport" [reports]="reports" *ngIf="reports != null && selectedReport.id == 3"></app-defaultdashboard-activity>
        <app-defaultdashboard-leadstatedevelopment [selectedReport]="selectedReport" *ngIf="reports != null && selectedReport.id == 4"></app-defaultdashboard-leadstatedevelopment>
        <app-defaultdashboard-consent [selectedReport]="selectedReport" *ngIf="reports != null && selectedReport.id == 5"></app-defaultdashboard-consent>
        <app-defaultdashboard-agentwvl [selectedReport]="selectedReport" [reports]="reports" *ngIf="reports != null && selectedReport.id == 6"></app-defaultdashboard-agentwvl>
        <app-defaultdashboard-mailshotdetail [selectedReport]="selectedReport" *ngIf="selectedReport.id == 7"></app-defaultdashboard-mailshotdetail>
    </div>
</div>
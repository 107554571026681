import { Component, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ProcessvisualService } from 'app/jollyjupiter/controls/static/processvisual/processvisual.service';
import { WizardService } from 'app/jollyjupiter/controls/static/wizard/wizard.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-designer-campaign-flowchart',
  templateUrl: './designer-campaign-flowchart.component.html',
  styleUrl: './designer-campaign-flowchart.component.scss'
})
export class DesignerCampaignFlowchartComponent implements OnInit, OnChanges {
  refreshBlocker = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public processvisualService: ProcessvisualService,
    private axivasTranslationService: AxivasTranslateService,
    private commonService: CommonService,
    private eventService: EventService,
    private wizardService: WizardService,
    private userService: UserService,
    private packagefeatureService: PackagefeatureService,

  ) {}

  customEventSubscription: Subscription = new Subscription();
  options = {};
  updateOptions: EChartsOption;
  chartInstance;
  openModalConfig;
  editButton = "";
  buttonType;
  modalData;
  featureId=null;
  emailTemplateFeatureIsActive:boolean;
  //campaignName;

  isSuperVisor: boolean = true;
  isEditor: boolean = true;
  starterStepId:number = 0;
  isStarterStep:boolean = false;

  @Input() chartData;
  @Input() controlid: string = "";
  @Input() treeWidth: string = "";
  @Input() treeHeight: string = "";
  @Input() initialTreeDepth: number;
  @Input() height;
  @Input() width;
  @Input() backgroundColor;
  @Input() top;


  // * ToolBox 
  @Input() toolboxInfo : boolean = false;
  @Input() toolboxExpand : boolean = false;

  @Input() dataToolbox;
  showInfo: boolean = true;


  ngOnInit(){
    this.checkFeatures();

/*     if(this.controlid == 'campaignOverview'){
      this.chartData = this.campaignOverviewData(this.chartData);
      this.isEditor = false;
      this.createFlowChartCampaign();
    } */

      this.chartData = this.campaignOverviewData(this.chartData);
      this.isEditor = false;
      this.createFlowChartCampaign();
  }

  checkFeatures(){
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_emailtemplate']);
    this.emailTemplateFeatureIsActive = this.packagefeatureService.selectedPackageHasFeature(this.featureId);

  }


  ngOnChanges(changes: SimpleChanges): void {
    // todo if needed 
    //console.log('changes', changes);
  }




  onChartInit(e) {
    this.chartInstance = e;
    this.chartInstance.resize();
  }

  createFlowChartCampaign(){
    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }

    this.options = {
      responsive: true,
      backgroundColor: this.backgroundColor ? this.backgroundColor : 'transparent',
      tooltip: { 
        show: true,
        trigger: "item",
        triggerOn: "mousemove",
        enterable: true,
        confine: true,
        formatter: ({ data }) => {
          return this.tooltipHTMLFormater(data.type, data);
        },
      },
      textStyle:{
        fontSize: 12,
      },
      grid: {
        left: 10,
        containLabel: true,
        bottom: 10,
        top: 10,
        right: 30
      },
      animation:true,
      animationDuration:0,
      animationEasing:'linear',
      series: [
        {
          name:'flowchart-campaign',
          value:0,
          id:'flowchart_0',
          type: "tree",
          data: [this.chartData],
          lineStyle:{ color: '#5465FF'},
          width: this.treeWidth ? this.treeWidth : undefined,
          height: this.treeHeight ? this.treeHeight : undefined,
          top: this.top ? this.top : '1%',
          layout: "orthogonal",
          //edgeShape:'polyline',
          orient: "LR",
          initialTreeDepth:2,
          nodeGap: 100,      
          levelHeight: 80,
          edgeShape:"curve",
          edgeForkPosition:"50%",
          //roam: true,
          zoom:0,
          //zoom:0.8,
          roam:true,
          silent: false,
          animation:true,
          animationDuration:0,
          animationEasing:'linear',
          leaves: {
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "left",
            },
          },
          label:{
            formatter:(v)=>{
              //console.log('v',v)
                return this.getCampaignOverViewLabelFormatter(v)
            },
            rich:{
              ...this.getRichIcons(),
              stepsBg:{
                align:'right',
                backgroundColor:'#5465FF',
                borderRadius:4,
                width:'100%',
                height:40,
                padding:[0,8],
              },
              resultBg:{
                align:'right',
                backgroundColor:'#d2d2d2',
                borderRadius:4,
                width:'100%',
                height:40,
                fontWeight:700,
                borderColor:'#333333',
                borderWidth:1,
                color:"#333333",
                padding:[0,8]
              },
              childrenBg:{
                height:40,
                align:'right',
                backgroundColor:'#BFD7FF',
                borderRadius:4,
                width:'100%',
                fontWeight:700,
                borderColor:"#5162F6",
                borderWidth:1,
                color:'#5162F6',
                padding:[0,8]
              },
              bookingBg:{
                height:40,
                align:'right',
                backgroundColor:'#FFFFFF',
                borderRadius:4,
                width:'100%',
                fontWeight:700,
                borderWidth:1,
                borderColor:'#333333',
                color:'#333333',
                padding:[0,8]
              },
               reactionBg:{
                height:40,
                align:'right',
                backgroundColor:'#FFFFFF',
                borderRadius:4,
                width:'100%',
                fontWeight:700,
                borderWidth:1,
                borderColor:'#333333',
                color:'#333333',
                padding:[0,8]
               },
               text:{
                 backgroundColor: 'transparent',
                 color:  'black',
                 padding:10,
                 position: 'inside',
                 //distance:10
               },
               hr:{
                 padding:10,
                 fontSize:12,
                 height: 12,
               },
               textNode:{
                fontSize: 12,
                align: 'left',
                fontWeight:'900',
                padding:[0,8]
               },
               textR:{
                fontSize: 12,
                align: 'left',
                fontWeight:'700',
                width:100,
                padding:[0,8]
               },
               textChildren:{
                fontSize: 12,
                align: 'left',
                fontWeight:'bold',
                padding:[0,8],
                width:80,
               },
               textResult:{
                fontSize: 12,
                align: 'left',
                fontWeight:'bold',
                padding:[0,8],
                width:100,
               }
             },
          },
        },
      ],
    };
  }

  getCampaignOverViewLabelFormatter(v){
    let nodeType = v.data.type
    let icon = v.data.svgLabelName
    if(v.data.type === 'noReaction'){
      nodeType = 'reaction'
    }
    switch(nodeType){
      case 'steps':
        return [
        `{text| ${v.data.nodeHeader}}`,
        ` {textNode|${this.ellipsis(v.data.name)}} {${icon}WhiteBg|} {caretWhite|} {stepsBg|}`,
        `{hr|}`
       ].join('\n');
      case 'results':
        return [
        `{text| ${v.data.nodeHeader ? v.data.nodeHeader : ''}}`,
        `  {textResult|${this.ellipsis(v.data.name, 13)}} {${icon}Bg|} {resultBg|}`,
        `{hr|}`
      ].join('\n');
      case 'resultsChildren':
          return [`  {textChildren|${this.ellipsis(v.data.name, 13)}} {${icon ? icon : 'rocket'}Blue|}{caretBlue|}{childrenBg|}`,].join('\n');
      case 'reaction':
        return [`  {textR|${v.data.name}} {caretBlack|} {reactionBg|}`].join('\n');
      case 'bookingDays':
        //console.log('booking', v.data)
        return [`  {textNode|${this.ellipsis(v.data.name)}} {clock|}{caretBlack|}{bookingBg|}`].join('\n');
    }
  }

  styleConfigCampaignOverview(string, svgType?: string){
    switch (string) {
      case "workflows":
        return {
          itemStyle: {
            color: "#788BFF",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: "circle",
          symbolSize: 90,
          label: {
            align: "center",
            position: 'inside',
            color: "white",
            borderRadius: 12,
            overflow: "truncate",
            ellipsis: "...",
            width: 75,
            padding: 30,
            lineHeight: 17,
          },

        };
        break;
      case "steps":
            return {
              itemStyle: {
                color: "#5465FF",
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
              },
              symbol:'none',
              symbolSize: 0,
              label: {
                align: 'center',
                padding: 10,
                backgroundColor:'transparent',
                position: 'inside',
                color: "white",
      
              },

            };
          break;
      case "bookingDays":
        return {
          itemStyle: {
            color: "#333333",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: 0,
          symbolSize: 0,
          label: {
            backgroundColor: "transparent",
            align: "center",
            padding: 10,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color: "#333333",
            borderColor:"#333333",
          },
        };
        break;
      case "reaction":
        return {
          itemstyle: { color: "transparent" },
          symbol:  'none',
          symbolSize: 0,
           label: {
            backgroundColor: "transparent",
            align: "center",
            padding: 10,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color: "#333333",
          },
        };
        break;
      case "results":
        return {
          itemStyle: {
            color:"#333333",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: 'none',
          symbolSize: 0 ,
          label: {
            backgroundColor: "transparent",
            align: 'center',
            position:'inside',
            padding: 10,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color: "#333333",
          },
        };
        break;
      case "results-children":
        return {
          itemStyle: {
            color:'#5162F6',
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: 'none',
          symbolSize: 0,
          label: {
            backgroundColor: "transparent",
            align: 'center',
            position:'inside',
            padding: 10,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color:'#5162F6',
          },
        };
        break;
        default:
          return;
    }
  }

  campaignOverviewData(data){
    if(data.previousTaskStep){
      return {
        name:this.getKeyToText(data.previousTaskStep),
        nodeHeader: this.getKeyToText('MicroCampaign.Label.Previous'),
        svgLabelName: data.previousTaskType,
        description: this.getKeyToText(data.previousTaskResult),
        type: 'steps',
        ...this.styleConfigCampaignOverview('steps'),
        children:[{
          name:this.getKeyToText(data.currentTaskStep),
          nodeHeader:this.getKeyToText('MicroCampaign.Label.Current'),
          svgLabelName:data.currentTaskType,
          type: 'steps',
          ...this.getcampaignOverviewTask(data),
          children:this.campaignOverviewCurrentChildren(data),
          ...this.styleConfigCampaignOverview('steps')
        }]
      }
    } else {
      return{
          name:this.getKeyToText(data.currentTaskStep),
          nodeHeader:this.getKeyToText('MicroCampaign.Label.Current'),
          svgLabelName:data.currentTaskType,
        type: 'steps',
          ...this.getcampaignOverviewTask(data),
          children:this.campaignOverviewCurrentChildren(data),
          ...this.styleConfigCampaignOverview('steps')
        }
      }
  }

  campaignOverviewCurrentChildren(data){
    //console.log('campaignOOverview', data)
    let results =[]
    let noReactionResult =[]
    let reactionResults = []

    // ! manufacture results 
    if(data.resultTypes){
      data?.resultTypes?.map((item) =>{

        // console.log('item', item)
        if(data.nextAutomaticBookingResultId){
           const foundResult = item.results.find(result => result.id === data.nextAutomaticBookingResultId)
           if(foundResult){
            noReactionResult.push({
              resultType: item.resultType,
              name: this.getKeyToText(foundResult.name),
              id: foundResult.id,
              svgLabelName:item.resultType,
              apiBooking:data,
              type:item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'resultsChildren',
              ...this.styleConfigCampaignOverview(item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'results-children')
            })
           }

          item.results.filter((item)=> item.id !== foundResult?.id).map(result =>{
            reactionResults.push({
              name: this.getKeyToText(result.name),
              id: result.id,
              svgLabelName:item.resultType,
              apiBooking:data,
              type:item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'resultsChildren',
              ...this.styleConfigCampaignOverview(item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'results-children')
          })
          }) 
        } 
          item.results.map(result =>{
             results.push({
               name: this.getKeyToText(result.name),
               id: result.id,
               svgLabelName:item.resultType,
               apiBooking:data,
               type:item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'resultsChildren',
               ...this.styleConfigCampaignOverview(item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'results-children')
           })
           })
        
      })
    }

    // ! conditions for results
    if(data.currentTaskResultId && data.nextAutomaticBooking == null && data.currentTaskResultDate != null){
      return [{
        name:this.getKeyToText(data.currentTaskResult),
        nodeHeader: this.getKeyToText('MicroCampaign.Label.EndedWith'),
        type: 'results',
        ...this.styleConfigCampaignOverview('results')
      }]
    } 

    if( data.nextAutomaticBooking != null &&  data.currentTaskResultDate == null && data.nextAutomaticBookingResultId){
      return [
        {
          name: this.commonService.getTimeDifferenceString(data.nextAutomaticBooking, null, "max. <0>", true),
            ...this.styleConfigCampaignOverview("bookingDays"),
            type: 'bookingDays',
            children:[
              {
                type:'noReaction',
                name: this.axivasTranslationService.getTranslationTextForToken('MicroCampaign.Label.NoMoreAction'),
                id: data.id,
                ...this.styleConfigCampaignOverview("reaction"),
                children:noReactionResult,
              },
              {
                type:'reaction',
                name: this.axivasTranslationService.getTranslationTextForToken('MicroCampaign.Label.Reaction'),
                id:data.id,
                ...this.styleConfigCampaignOverview("reaction"),
                children: reactionResults,
              }

            ]
        }]
    } else {
      return results
    }

  }

  getcampaignOverviewTask(data: any) {
    if (this.isValidStep(data.currentTaskStepId) && data.currentTaskResultDate == null) {
      return {
        currentTaskFollowUpDateLabel: this.getKeyToText('MicroCampaign.Label.TaskFollowUpDate'),
        currentTaskFollowUpDate: data.currentTaskFollowUpdate
      }
    } else {
      return
    }
  }


  isValidStep(step) {
    let returnValue = true;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step))) {
      if (this.applicationInfoService.steps.Item(step).typeLookupId == 3842) {
        returnValue = false;
      }
    }
    return returnValue;
  }


  getKeyToText(key) {
    return this.axivasTranslationService.getTranslationTextForToken(key);
   }

   getRichIcons(){

    return {
      clock:{
        backgroundColor:{
          image:"/assets/images/svg/charts/clock.svg",
        },
        height: 20,
      },
      editorBg:{
        backgroundColor: {
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/note-pencil-black.svg"
        },
        width:20,
        height:20,
        align:'center',
      },
      closeBg:{
        backgroundColor: {
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/calendar-x.svg"
        },
        width:20,
        height:20,
      },
      editorBlue:{
        backgroundColor: {
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/note-pencil-blue.svg"
        },
        width:20,
        height:20,
        align:'center',

      },
      emailTemplateBlue:{
        backgroundColor: {
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/at-blue.svg",
        },
        height: 20,
        width:20,
        align:'center'
      },
      emailTemplateBlack:{
        backgroundColor: {
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/at-black.svg",
        },
        height: 20,
        width:20,
        align:'center'
      },
      caretWhite:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/caret-right-white.svg"
        },
        width:20,
        height:20,
        align:'center'
      },
      caretBlack:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/caret-right-black.svg"
        },
        width:20,
        height:20,
        align:'center'
      },
      caretBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/caret-right-blue.svg"
        },
        width:20,
        height:20,
        align:'center'
      },
      footprint:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/footprints.svg"
        },
        width:20,
        height:20,
        align:'center'
      },

      starterProcess:{
        backgroundColor:{
          image:"/assets/images/svg/charts/flag-checkered.svg",
        },
        height: 20,
        width: 20,
      },
      space:{
        height:5,
        width:5
      },
      mailBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/envelope-simple.svg",
        },
        height: 20,
      },
      mailWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/envelope-simple.svg",
        },
        height: 20,
      },
      mailBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/envelope-simple.svg",
        },
        height: 20,
      },
      conversionBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/factory.svg",
        },
        height: 20,
      },
      conversionWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/factory.svg",
        },
        height: 20,
      },
      conversionBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/factory.svg",
        },
        height: 20,

      },
      pendingBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/clock.svg",
        },
        height: 20,
      },
      pendingWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/clock.svg",
        },
        height: 20,
      },
      pendingBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/clock.svg",
        },
        height: 20,
      },
      followupBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/signpost.svg",
        },
        height: 20,
      },
      followupWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/swap.svg",
        },
        height: 20,
      },
      followupBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/swap.svg",
        },
        height: 20,
      },
      callBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/phone.svg",
        },
        height: 20,
        //align: 'center',
      },
      callWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/phone.svg",
        },
        height: 20,
      },
      callBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/phone.svg",
        },
        height: 20,
      },
      meetingBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/users-four.svg",
        },
        height: 20,
      },
      meetingWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/users-four.svg",
        },
        height: 20,
      },
      meetingBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/users-four.svg",
        },
        height: 20,
      },
      miscellaneousBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/shuffle-angular.svg",
        },
        height: 20,
      },
      miscellaneousWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/shuffle-angular.svg",
        },
        height: 20,
      },
      miscellaneousBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/shuffle-angular.svg",
        },
        height: 20,
      },
      bookBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/calendar-plus.svg",
        },
        height: 20,
      },
      bookWhiteBg:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_white/calendar-plus.svg",
        },
        height: 20,
      },
      bookBlue:{
        backgroundColor:{
          image:"/assets/NEW_EVERLEAD_ICONS/flowChart/info_Icons_blue/calendar-plus.svg",
        },
        height: 20,
      }
    }
  }


  ellipsis(string, length?:number){
    let maxLength= length ? length : 27;
    if(string.trim().length >= maxLength){
      return string.slice(0, maxLength).trim() + '...' 
    } else {
      return string.trim()
    } 

  }



  getToolBox(string){
    switch(string){
      case 'info':
        this.showInfo = this.showInfo === true ? false : true;
        break;
        case 'expand':
            this.applicationInfoService.miscSettings["flowChartOverviewExpanded"] = this.dataToolbox;
            this.applicationInfoService.miscSettings["flowChartOverviewExpandedType"] = this.controlid;
            this.eventService.showJjPopup('',
              "expandcampaignflowcomponent", 100, true);
        break;
        case 'edit':
        default:
          return
    }
  }

  getHeight() {
    if (isNaN(this.height)) {
      return this.height;
    } else {
      return this.height.toString().concat('px');
    }
  }

  getWidth() {
    if (isNaN(this.width)) {
      return this.width;
    } else {
      return this.width.toString().concat('px');
    }
  }

  hasSVGs(data){
    switch(data.typeLookupId){
      case 2435:
        return 'mail'
      case 2446:
        return 'conversion'
      case 3842:
        return 'pending'
      case 4190:
        return 'followup'
      case 2434:
        return 'call'
      case 4905:
        return 'meeting'
      case 4906:
        return 'miscellaneous'
    }

  }

  tooltipHTMLFormater(type, data) {
    console.log('FORMATTER', data)

    return`
    <div class="tooltipFlowChart">
      ${data.name ? `<div class="tooltipFlowChart__section"><h4>${data.name}</h4></div>` : ""} 
      ${data.description ? `
        <div class="tooltipFlowChart__section">
          <h4>${this.axivasTranslationService.getTranslationTextForToken("ProcessVisual.Label.Description")}</h4>
          <p>${data.description}</p>
        </div>` : ""}
      </div> `
  }

}


import { Component, Input, OnInit } from '@angular/core';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';

@Component({
  selector: 'app-userhierarchy-designer-item',
  templateUrl: './userhierarchy-designer-item.component.html',
  styleUrl: './userhierarchy-designer-item.component.scss'
})

export class UserhierarchyDesignerItemComponent implements OnInit {
  @Input() userId = null;
  @Input() users = [];
  @Input() level = 0;
  @Input() isGroupOwner = false  
  showPopup = false;
  
  userChildren = [];

  constructor(
    public dragdropService: DragdropService
  ) {}

  ngOnInit(): void {
    const user = this.users.find(user => user.id == this.userId);
  }

  getUserName() {
    const user = this.users.find(user => user.id == this.userId);
    if (user) {
      return user.userName;
    } else {
      return '-';
    }
  }

  addSubUser() {
    this.userChildren.push({ id: null });
  }

  addMe(event) {
    this.userChildren.push({ userId: event.item.data.id });
  }
}

import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-admin-aiprompts',
  templateUrl: './admin-aiprompts.component.html',
  styleUrls: ['./admin-aiprompts.component.scss']
})
export class AdminAipromptsComponent implements OnInit {
  promptLookups = null;
  responseLookups = null;
  modelLookups = null;
  toggledItems = [];
  placeholders = [];
  languages = [];
  filterString = '';
  filterPlaceholderString = '';
  aiPrompts = [];
  promptModels = [];
  prompts = [];
  defaultSettings = {
    Temperature: 0.6,
    TopP: 1.0,
    MaxTokens: 2048,
    PresencePenalty: 0.0,
    FrequencyPenalty: 0.0,
    UseTemperature: true
  }
  selectedPrompt = null;
  placeholderEntity = 'account';
  caretPos = 0;
  placeholderArray = [];
  entityOptions = [
    { defaultName: 'Task.Label.Header', id: 'lastActivity', translation: '' },
    { defaultName: 'Account.Label.Header', id: 'account', translation: '' },
    { defaultName: 'Contact.Label.Header', id: 'contact', translation: '' },
    { defaultName: 'AIPrompts.Label.SecurityUser', id: 'securityUser', translation: '' },
    { defaultName: 'AIPrompts.Label.Questionnaire', id: 'questionnaire', translation: '' },
    { defaultName: 'AIPrompts.Label.Misc', id: null, translation: '' }
    
  ]
  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    public axivasTranslateService: AxivasTranslateService,
    public userService: UserService,
    private eventService: EventService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.getAiPrompts();
    this.getPlaceHolders();
    this.getLanguages();
    this.getAiPromptModels();
    this.modelLookups = this.commonService.getProjectLookupTableValues(86, 'central');
    this.responseLookups = this.commonService.getProjectLookupTableValues(87, 'central');
    this.promptLookups = this.commonService.getProjectLookupTableValues(85, 'central');

    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'lookupsLoaded') {
        this.modelLookups = this.commonService.getProjectLookupTableValues(86, 'central');
        this.responseLookups = this.commonService.getProjectLookupTableValues(87, 'central');
        this.promptLookups = this.commonService.getProjectLookupTableValues(85, 'central');
      }
    });

    this.entityOptions.forEach(item => {
      item['translation'] = this.axivasTranslateService.getTranslationTextForToken(item.defaultName);
    });
    this.entityOptions.sort((a, b) => a.translation < b.translation ? -1 : 1);
  }

  toggleItem(index) {
    if (this.toggledItems[index] == true)  {
      this.toggledItems[index] = false;
    } else {
      this.toggledItems[index] = true;
    }
  }

  getAiPromptModels() {
    this.externaldatasourceService.executeExternalDataSource(833, []).then(getAiPromptModelsResult => {
      this.promptModels = getAiPromptModelsResult;
    });    
  }

  getLanguages() {
    this.externaldatasourceService.executeExternalDataSource(89, []).then(getLanguagesResult => {
      this.languages = getLanguagesResult;
    });    
  }

  getAiPrompts() {
    this.externaldatasourceService.executeExternalDataSource(775, []).then(getAiPromptsResult => {
      getAiPromptsResult.forEach(result => {
        if (this.commonService.isNullOrUndefined(result.settings)) {
          result.settingsArray = this.defaultSettings;
        } else {
          result.settingsArray = JSON.parse(result.settings);
        }
      });
      this.aiPrompts = getAiPromptsResult;
    });
  }

  createAiPrompt() {
    const aiPrompt = {
      projectId: this.applicationInfoService.projectID, 
      defaultName: this.axivasTranslateService.getTranslationTextForToken('AIPrompts.Label.NewAIPrompt'), 
      promptLookupId: 6666, 
      prompt: '', 
      nameTranslationTokenId: null, 
      descriptionTranslationTokenId: null,
      isDefault: false, 
      isActive: true, 
      languageId: 2, 
      responseLookupId: 6670, 
      modelLookupId: 8000,
      settings: JSON.stringify(this.defaultSettings),
      pictureData: null
    }
    this.externaldatasourceService.executeExternalDataSource(776, [
      this.commonService.getModifyArrayBody(aiPrompt, [])
    ]).then(createAiPromptResult => {
      this.eventService.customEvent.emit({ id: 'refreshBattleCards' });
      aiPrompt['settingsArray'] = this.defaultSettings;
      aiPrompt['id'] = createAiPromptResult.id;
      this.aiPrompts.push(aiPrompt);
    });
  }


  saveAiPrompt(aiPrompt) {    
    aiPrompt.settings = JSON.stringify(aiPrompt.settingsArray);
    this.externaldatasourceService.executeExternalDataSource(777, [this.commonService.getModifyArrayBody(aiPrompt, ['settingsArray'])
    ]).then(() => {
      this.eventService.customEvent.emit({ id: 'refreshBattleCards' });
    });
  }

  deleteAiPrompt(aiPrompt) {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('AIPrompts.Label.DeleteMessage'),
      header: this.axivasTranslateService.getTranslationTextForToken('AIPrompts.Label.DeleteHeader'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.externaldatasourceService.executeExternalDataSource(778, [aiPrompt.id]).then(() => {
          this.commonService.removeItemFromArray(this.aiPrompts, aiPrompt);
          this.eventService.customEvent.emit({ id: 'refreshBattleCards' });
        });
      },
    });    
  }

  getPlaceHolders() {
    this.externaldatasourceService.executeExternalDataSource(1004, [])
    .then(getPlaceHoldersResult => { this.placeholders = getPlaceHoldersResult; })
  }

  addMemberToPrompt(placeholder, aiPrompt) {
    aiPrompt.prompt = ''.concat(
      aiPrompt.prompt.substring(0, this.caretPos),
      placeholder,
      aiPrompt.prompt.substring(this.caretPos, aiPrompt.prompt.length),
    );
    this.caretPos = this.caretPos + placeholder.length;
    this.saveAiPrompt(aiPrompt);
  }

  getEntity(placeholder) {
    let entityId = null;
    switch(this.getPlaceholderDetails(placeholder)[0]) {
      case 'account': entityId = 1; break;
      case 'lastActivity': entityId = 2; break;
      case 'contact': entityId = 4; break;      
    }
    if (entityId != null) {
      return this.axivasTranslateService.getTranslationTextForObject(this.applicationInfoService.entities.Item(entityId.toString()), 'nameTranslationToken')
    } else {
      return placeholder;
    }
  }

  getMember(placeholder) {
    let entityId = null;
    const details = this.getPlaceholderDetails(placeholder);
    switch(details[0]) {
      case 'account': entityId = 1; break;
      case 'lastActivity': entityId = 2; break;
      case 'contact': entityId = 4; break;      
    }
    if (entityId != null) {
      const member = this.applicationInfoService.entities.Item(entityId).entityMembers.find(member => member.defaultName == details[1]);      
      if (member) {
        return this.axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId, member.defaultName)
      } else {
        return '';
      }  
    } else {
      return '';
    }
  }

  getPlaceholderDetails(placeholder) {
    placeholder = placeholder.replace(/{/g, '');
    placeholder = placeholder.replace(/}/g, '');
    return placeholder.split('.');
  }

  getPlaceHolderInfo(placeholder) {
    return;
    let returnString = '';
    let entityId = null;
    placeholder = placeholder.replace(/{/g, '');
    placeholder = placeholder.replace(/}/g, '');
    const placeHolderDetails = placeholder.split('.');
    switch (placeHolderDetails[0]) {
      case 'account':
        entityId = 1;
        break;

      case 'contact':
        entityId = 4;
        break;

      case 'lastActivity':
        entityId = 2;
        break;  
    }
    if (entityId == null) {
      returnString = placeholder;
    } else {
      const member = this.applicationInfoService.entities.Item(entityId).entityMembers.find(member => member.defaultName == placeHolderDetails[1]);      
      if (member) {
        returnString = ''.concat(
          this.axivasTranslateService.getTranslationTextForObject(this.applicationInfoService.entities.Item(entityId), 'nameTranslationToken'),
          ' - ',
          this.axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId, member.defaultName)
        );
      } else {
        returnString = placeholder;
      }
    }
    return returnString;
  }

  saveSettings(aiPrompt) {
    aiPrompt.settings = JSON.stringify(aiPrompt.settingsArray);    
    // Object.keys(aiPrompt.settingsArray).forEach(key => { aiPrompt.settings = aiPrompt.settings.replace('"' + key + '"', key);  });
    this.saveAiPrompt(aiPrompt);
  }

  getMinValue(aiPrompt) {
    return '0.0';
  }

  getMaxValue(aiPrompt) {
    return '1.0';
  }

  isItemFiltered(prompt) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(prompt.defaultName, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  isPlaceholderFiltered(placeholder) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(placeholder.translationText, this.filterPlaceholderString)) {
      returnValue = false;
    }
    return returnValue;
  }

  uploadLoadingImage(event, aiPrompt) {
    this.createBase64String(event, aiPrompt);
  }

  createBase64String(fileInput: any, aiPrompt) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const imgBase64Path = e.target.result;
          aiPrompt.pictureData = imgBase64Path;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeLoadingImage(aiPrompt) {
    aiPrompt.pictureData = null;
  }

  sortPlaceholderArray(array, sortArray = true) {
    array.forEach(arrayItem => {
      if (arrayItem.alternateText != null) {
        arrayItem['translationText'] = arrayItem.alternateText;
      } else {
        if (arrayItem.nameTranslationTokenId != null) {
          arrayItem['translationText'] = this.axivasTranslateService.getTranslationTextForToken(this.axivasTranslateService.getTranslationTextForId(arrayItem.nameTranslationTokenId));
        } else {
          arrayItem['translationText'] = arrayItem.tokenName;
        }
      }
    });
    if (sortArray) {
      array.sort((a, b) => a.translationText < b.translationText ? -1 : 1);
    }    
  }

  getPlaceholderList(placeholders) {
    let sortArray = true;
    if (this.placeholderEntity == 'questionnaire') {
      sortArray = false;
    }        

    if (this.commonService.isNullOrUndefined(this.placeholderArray[this.placeholderEntity])) {
      let checkArray = [];
      if (this.placeholderEntity == 'account') {
        checkArray = this.applicationInfoService.controls.toArray().filter(uicontrol => 
          uicontrol.isActive == true &&
          uicontrol.uiEntityInstanceId == 1
        );
      }
      if (this.placeholderEntity == 'contact') {
        checkArray = this.applicationInfoService.controls.toArray().filter(uicontrol => 
          uicontrol.isActive == true && 
          uicontrol.uiEntityInstanceId == 4
        );
      }
      if (checkArray.length > 0) {
        this.placeholderArray[this.placeholderEntity] = [];
        const itemArray = placeholders.filter(placeholder => placeholder.tokenEntityName == this.placeholderEntity);
        itemArray.forEach(item => {
          const memberNameDetail1 = item.tokenName.split('.');
          const memberNameDetail2 = memberNameDetail1[1].split('}}');
          const memberName = memberNameDetail2[0];
          console.warn(memberName)
          const memberVisible = checkArray.find(checkArrayItem => checkArrayItem.value == memberName);
          if (memberVisible) {
            this.placeholderArray[this.placeholderEntity].push(item);            
          }
        });
        this.sortPlaceholderArray(this.placeholderArray[this.placeholderEntity], sortArray);
        return this.placeholderArray[this.placeholderEntity];
      } else {
        this.placeholderArray[this.placeholderEntity] = placeholders.filter(placeholder => placeholder.tokenEntityName == this.placeholderEntity);  
        this.sortPlaceholderArray(this.placeholderArray[this.placeholderEntity], sortArray);
        return this.placeholderArray[this.placeholderEntity];  
      }      
    } else {
      return this.placeholderArray[this.placeholderEntity];
    }
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == '0') {
      this.caretPos = oField.selectionStart;
    }
  }
}

import { Component, OnInit, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { Dictionary } from 'app/core/dictionary';
import { GraphQLService, ApolloMethod, JJApolloClient } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { ExternalDataSource_QueryType, ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessageService } from 'primeng/api';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { isNullOrUndefined } from 'util';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';
import { Location } from '@angular/common';
import { WizardMode, WizardService } from '../wizard/wizard.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Component({
  selector: 'app-myleads',
  templateUrl: './myleads.component.html',
  styleUrls: ['./myleads.component.scss']
})
export class MyleadsComponent implements OnInit, OnDestroy {
  changeMyLeadsSubLevelSubscription: Subscription = new Subscription();
  sendCompactListInformationEventSubscription: Subscription = new Subscription();
  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  callCompactListExternalPaginationEventSubscription: Subscription = new Subscription();
  updateMyLeadsRecordsetsEventSubscription: Subscription = new Subscription();
  updateAndBackToMyLeadsEventSubscription: Subscription = new Subscription();
  newLogicalEntityCreatedEventSubscription: Subscription = new Subscription();
  updateControlContentEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  selectedFilter = null;
  taskResultInnerHTMLBase = '<jj-universal additionalvalues="jjCompactListSelectBox" controlid=<0>></jj-universal>';
  taskResultSummaryInnerHTMLBase = '<jj-universal controlid=<0>></jj-universal>';
  taskModifyInnerHTMLBase = '<jj-container controluiid=<0>></jj-container>';
  taskResultInnerHTML = null;
  taskResultSummaryInnerHTML = null;
  taskModifyInnerHTML = null;
  mainLevel = null;
  resultHeaderText = '';
  resultBottomText = '';
  // showResultQueryIdentifier = 'accounts';
  selectedTabIndex = 1;
  baseData = [];
  filterCollapsed = true;
  additionalFilterCollapsed = true;
  fixedlFilterCollapsed = false;
  resultsCollapsed = false;
  dataCollectionCollapsed = false;
  modifyPanelCollapsed = false;
  lastSelectedRowsCount = 0;
  selectedRecordsets = null;
  campaigns = null;
  selectedCampaign = 0;
  pagingIdentifier = 'accountsPaged';
  useMyLeadsV2 = false;
  controlSubMode = 0; // 0 Nur Export 1 UpdateData 2 CreateTask 3 MailShot
  filterExpanded = false;

  // Filter
  filterSegments = [];
  dataFilter = null;
  dataFilterCaller = 'myleads';
  filterSegmentMemberOperators = [];
  validEntities = [];
  availableFilters = [];
  selectedFilterId = null;

  // Additional Filter
  additionalFilter = [];
  additionalFilterdict: Dictionary<any> = new Dictionary();

  // Fixed Filter
  fixedFilter = [];
  myLeadLevels = [];

  // Pagination
  paginationInitialDataCount = 0;
  inititalPageSize = 0;

  constructor(
    private datafilterService: DatafilterService,
    private domSanitizer: DomSanitizer,
    private refreshValueService: RefreshValueService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private methodService: MethodService,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private axivasTranslateService: AxivasTranslateService,
    private uiService: UiService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private entityService: EntityService,
    private commonService: CommonService,
    private messagingService: MessagingService,
    private trackingService: TrackingService,
    private location: Location,
    private wizardService: WizardService,
    private packagefeatureService: PackagefeatureService
  ) { }

  ngOnDestroy() {
    if (this.selectCurrentDataFilterEventSubscription) { this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
    if (this.sendCompactListInformationEventSubscription) { this.sendCompactListInformationEventSubscription.unsubscribe(); }
    if (this.updateMyLeadsRecordsetsEventSubscription) { this.updateMyLeadsRecordsetsEventSubscription.unsubscribe(); }
    if (this.callCompactListExternalPaginationEventSubscription) { this.callCompactListExternalPaginationEventSubscription.unsubscribe(); }
    if (this.updateAndBackToMyLeadsEventSubscription) { this.updateAndBackToMyLeadsEventSubscription.unsubscribe(); }
    if (this.newLogicalEntityCreatedEventSubscription) { this.newLogicalEntityCreatedEventSubscription.unsubscribe(); }
    if (this.updateControlContentEventSubscription) { this.updateControlContentEventSubscription.unsubscribe(); }
    if (this.changeMyLeadsSubLevelSubscription) { this.changeMyLeadsSubLevelSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getMyLeadsLevel() {
    if (this.myLeadLevels.length > 0) {
      this.mainLevel = this.myLeadLevels[0];
      this.getAdditionalFiltersForLevel();
      this.getFixedFiltersForLevel();
      this.loadControls();
    }
  }

  getLogicalEntities(): Promise<any> {
    return new Promise((getLogicalEntitiesResolve, getLogicalEntitiesReject) => {
      this.myLeadLevels = [];
      this.externaldatasourceService.executeExternalDataSource(595)
      .then(myLeadsLogicalEntitiesResult => {       
        this.addLogicalEntityToList(1, myLeadsLogicalEntitiesResult);
        this.addLogicalEntityToList(2, myLeadsLogicalEntitiesResult);
        this.addLogicalEntityToList(3, myLeadsLogicalEntitiesResult);
        this.addLogicalEntityToList(6, myLeadsLogicalEntitiesResult);
        this.addLogicalEntityToList(15, myLeadsLogicalEntitiesResult);
        if (this.packagefeatureService.selectedPackageHasFeature(17)) {
          this.addLogicalEntityToList(18, myLeadsLogicalEntitiesResult);
        }
        const qmResults = this.applicationInfoService.results.toArray().filter(result => result.qmUserGroupId != null);
        if (qmResults.length > 0) {          
          this.addLogicalEntityToList(4, myLeadsLogicalEntitiesResult);
        }
        this.applicationInfoService.myLeadsDefinitions = myLeadsLogicalEntitiesResult;
        getLogicalEntitiesResolve(myLeadsLogicalEntitiesResult);
        if (this.myLeadLevels.length > 0) {
          this.mainLevel = this.myLeadLevels[0];
        }
      })
      .catch(error => {
        console.error('getLogicalEntities', error);
        getLogicalEntitiesReject(error);
      });
    });
  }

  addLogicalEntityToList(logicalEntityId, myLeadsLogicalEntitiesResult) {
    const entity = myLeadsLogicalEntitiesResult.find(myLeadsLogicalEntitiesResultItem => myLeadsLogicalEntitiesResultItem.id == logicalEntityId);
    if (entity) {
      this.myLeadLevels.push(entity);
    }
  }

  setMyLeadsSubLevel() {
    this.controlSubMode = Number(this.applicationInfoService.miscSettings['myLeadsSubMode']);
    if (isNaN(this.controlSubMode)) {
      this.controlSubMode = Number(localStorage.getItem('myLeadSubLevel'));
      if (isNaN(this.controlSubMode)) {
        this.controlSubMode = 0;
      }
    }
    this.applicationInfoService.miscSettings['myLeadsSubMode'] = null;
    // this.location.replaceState(''.concat('/myleads'));
    if (this.controlSubMode == 3) {
      const microCampaign = this.myLeadLevels.find(level => level.id == 15);
      if (microCampaign) {
        this.mainLevel = microCampaign;
        this.changeMyLeadsLevel();
      }
    } else {
      this.mainLevel = this.myLeadLevels[0];
    }
    localStorage.setItem('myLeadSubLevel', this.controlSubMode.toString());
  }

  ngOnInit() {
    this.getDataFilterList();
    this.applicationInfoService.matTableCustomFiltersUsed = false;
    this.applicationInfoService.myLeadsSelectedFilter = null;
    this.inititalPageSize = Number(this.applicationInfoService.applicationSettings['recordCountForInitialPagination']);
    this.trackingService.trackPageView();
    this.eventService.updateMyLeadsRecordsetsEvent.subscribe(event => {
      this.showResults();
    });

    this.updateControlContentEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getLogicalEntities();
      }      
    });
    

    if (this.applicationInfoService.applicationSettings['useMyLeadsV2'] == 'true') { this.useMyLeadsV2 = true;  }
    this.getLogicalEntities().then(() => {
      this.getCampaigns();
      this.getMyLeadsLevel();
      this.setMyLeadsSubLevel();      
      this.updateAndBackToMyLeadsEventSubscription = this.eventService.updateAndBackToMyLeadsEvent.subscribe(event => {
        console.warn('updateAndBackToMyLeadsEventSubscription', event);
        this.eventService.updateControlData(this.mainLevel.listContainerLogicalControlId,
          [event]);
      });

      this.updateControlContentEventSubscription = this.eventService.updateControlContentEvent.subscribe(event => {
        if (event.target === this.mainLevel.listContainerLogicalControlId) {
          this.refreshResults();
        }
      });

      this.newLogicalEntityCreatedEventSubscription = this.eventService.newLogicalEntityCreatedEvent.subscribe(() => {
        this.getLogicalEntities().then(() => {
          this.getMyLeadsLevel();
          this.changeMyLeadsLevel();
        });
      });

      this.changeMyLeadsSubLevelSubscription = this.eventService.changeMyLeadsSubLevelEvent.subscribe(event => {
        this.applicationInfoService.miscSettings['myLeadsSubMode'] = Number(event);
        this.setMyLeadsSubLevel();
      });

      this.callCompactListExternalPaginationEventSubscription =
        this.eventService.callCompactListExternalPaginationEvent.subscribe(event => {
          console.warn('MyLeads - callCompactListExternalPaginationEventSubscription', event);
          this.showPagingResults(event.arguments[0]);
      });

      this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(event => {
        if (event.target === this.dataFilterCaller) {
          this.getDataFilterList(event.arguments[0].id);
          this.showDataFilter(event.arguments[0].id);
        }
      });
    });

    this.sendCompactListInformationEventSubscription = this.eventService.sendCompactListInformationEvent.subscribe(event => {
      if (Number(this.mainLevel.listContainerLogicalControlId) === Number(event.target)) {

        let baseText = this.axivasTranslateService.getTranslationTextForToken('MyLeads.Label.SelectedDataItems');
        this.lastSelectedRowsCount = Number(event.arguments[1]);
        baseText = baseText.replace('<0>', event.arguments[0]);
        baseText = baseText.replace('<1>', event.arguments[1]);
        this.selectedRecordsets = event.arguments[2];
        this.resultBottomText = baseText;
      }
    });
  }

  getDataFilterList(selectedFilterFromFilterDesigner = null) {
    this.externaldatasourceService.executeExternalDataSource(726, [36])
    .then(getDataFilterListResult => {
      this.availableFilters = getDataFilterListResult;
      this.selectedFilterId = selectedFilterFromFilterDesigner;
    });
  }

  showDataFilter(filterId) {
    if (filterId == null) {
      this.clearDataFilter();
      this.eventService.customEvent.emit('myLeadsFilterChanged');
      return;
    }
    this.datafilterService.getDataFilterInformation(filterId)
    .then(getDataFilterInformationResult => {
      this.datafilterService.getSegmentMemberOperators()
      .then(getFilterSegmentMemberOperatorsResult => {
        this.filterSegmentMemberOperators = getFilterSegmentMemberOperatorsResult;
      });
      this.validEntities = this.datafilterService.getValidEntities(0);
      this.applicationInfoService.myLeadsSelectedFilter = getDataFilterInformationResult;
      this.filterSegments = [];
      if (getDataFilterInformationResult.length > 0) {
        getDataFilterInformationResult.filterSegments.forEach(filterSegment => {
          this.filterSegments.push(filterSegment);
        });
        this.dataFilter = filterId;
      }
      this.eventService.customEvent.emit('myLeadsFilterChanged');
    });
  }

  setCurrentChild(selectedTabIndex: any) {
    this.selectedTabIndex = selectedTabIndex;
  }

  getCampaigns() {
    this.externaldatasourceService.executeExternalDataSource(123)
    .then(getCampaignsResult => {
      this.campaigns = getCampaignsResult;
    });
  }

  changeMyLeadsLevel() {
    this.applicationInfoService.myLeadsSelectedFilter = null;
    this.selectedFilterId = null;
    // this.getAdditionalFiltersForLevel();
    // this.getFixedFiltersForLevel();
    this.loadControls();
    this.entityService.clearEntityData(this.mainLevel.uiEntityInstanceId);
  }

  modifySelectedResults() {
    this.eventService.compactListShowOnlyDataEvent.emit(new JJEvent(this.mainLevel.listContainerLogicalControlId,
      [this.selectedRecordsets]));
    this.selectedTabIndex = 2;
  }

  backToSelection() {
    this.eventService.compactListShowOnlyDataEvent.emit(new JJEvent(this.mainLevel.listContainerLogicalControlId,
      [this.baseData]));
    this.selectedTabIndex = 1;
  }

  clearDataFilter() {
    this.dataFilter = null;
    this.filterExpanded = false;
    this.applicationInfoService.myLeadsSelectedFilter = null;
    this.filterSegments = [];
  }

  editDataFilter() {
    console.log('editDataFilter', this.mainLevel);
    const externalDataSource = this.entityService.getExternalDataSourceByType(
      this.applicationInfoService.entities.Item(this.mainLevel.entityId),
        ExternalDataSource_QueryType.MyLeadsQuery + this.mainLevel.id);
    if (!isNullOrUndefined(externalDataSource)) {
      this.applicationInfoService.dataFilterPreviewControl = this.mainLevel.listContainerLogicalControlId;
      this.applicationInfoService.dataFilterPreviewControQuery = externalDataSource.dataQuery;
    }
    this.datafilterService.setFilterEnvironment(
      this.dataFilter,
      this.mainLevel.entityId,
      this.dataFilterCaller,
      null,
      36
    );
    this.eventService.showJjPopup('DataFilterMainPanel.Label.SectionHeader', 'datafiltermainpanel', '80');
  }

  loadControls() {
    this.loadResultControl();
    this.loadModifyControl();
  }

  loadResultControl() {
    this.taskResultInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      this.taskResultInnerHTMLBase.replace('<0>', this.mainLevel.listContainerLogicalControlId)
    );
    this.taskResultSummaryInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      this.taskResultSummaryInnerHTMLBase.replace('<0>', this.mainLevel.listContainerLogicalControlId)
    );
  }

  loadModifyControl() {
    this.taskModifyInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      this.taskModifyInnerHTMLBase.replace('<0>', this.mainLevel.editContainerLogicalControlId)
    );
  }

  toggleItem(toggleName) {
    this[toggleName] = !this[toggleName];
  }

  getFixedFiltersForLevel() {
    this.fixedFilter = [];
    this.lastSelectedRowsCount = 0;
    this.mainLevel.fixedFilters.forEach(fixedFilter => {
      this.datafilterService.getDataFilterInformation(fixedFilter.id)
      .then(getDataFilterInformationResult => {
        this.fixedFilter.push(getDataFilterInformationResult);
      })
      .catch(error => { console.error('getFixedFiltersForLevel', error); });
    });
  }

  getAdditionalFiltersForLevel() {
    this.additionalFilter = [];
    this.lastSelectedRowsCount = 0;
    this.additionalFilterdict = new Dictionary();
    this.mainLevel.templatesFilters.forEach(templatesFilter => {
      this.datafilterService.getDataFilterInformation(templatesFilter.id)
      .then(getDataFilterInformationResult => {
        this.additionalFilter.push(getDataFilterInformationResult);
      })
      .catch(error => { console.error('getAdditionalFiltersForLevel', error); });
    });
  }

  refreshResults() {
    if (this.lastSelectedRowsCount > 0) {
      this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, [
        this.axivasTranslateService.getTranslationTextForToken('MyLeads.Message.LoadDataAfterModifyingHeader'),
        this.axivasTranslateService.getTranslationTextForToken('MyLeads.Message.LoadDataAfterModifyingMessage')
      ])
      .then(() => {
        this.showResults();
      })
      .catch(() => {});
    } else {
      this.showResults();
    }
  }

  showResults() {
    // Get query for selected entity
    const externalDataSource = this.entityService.getExternalDataSourceByType(
      this.applicationInfoService.entities.Item(this.mainLevel.entityId),
        ExternalDataSource_QueryType.MyLeadsQuery + this.mainLevel.id);
    if (!externalDataSource) {
      console.error('no external datasource found for ', ExternalDataSource_QueryType.MyLeadsQuery, this.mainLevel.entityId);
      return;
    }

    if (externalDataSource.schema === 'paging') {
      this.getPagingQueryResult(externalDataSource.dataQuery, [], 1)
      .then(requestQueryResult => {
        if (requestQueryResult.data[this.pagingIdentifier].numRecords >
          Number(this.applicationInfoService.applicationSettings['recordCountThresholdForPagination'])) {
            console.warn('showResults paging');
            this.showPagingResults([], true);
        } else {
          console.warn('showResults nonpaging');
          const pagingResults: any = [];
          pagingResults.pageSize = Number(this.applicationInfoService.applicationSettings['recordCountThresholdForPagination']);
          this.showPagingResults(pagingResults, true, true);
        }
      });
    } else {
      this.getNonPagingResults(externalDataSource);
    }
  }

  getNonPagingResults(externalDataSource: any) {
    let finalRequestQuery = externalDataSource.dataQuery.replace('<0>', this.getFilterValueArray().toString());
    finalRequestQuery = finalRequestQuery.replace('<1>', this.uiService.getQueryFieldsFromListControl(
      this.applicationInfoService.controls.Item(this.mainLevel.listContainerLogicalControlId)
    ));
    finalRequestQuery = finalRequestQuery.replace('<selectedCampaign>', this.selectedCampaign);

    // Änderungen CStuck
    if (this.applicationInfoService.useNewDesign) {
       this.eventService.externaldDataQueryEvent.emit(new JJEvent(
         this.mainLevel.listContainerLogicalControlId, [finalRequestQuery]));
    //   return;
    }

    // Änderungen CStuck Ende
    this.graphQLService.apolloGQLpromiseWithParameter('data', ApolloMethod.Query, finalRequestQuery,
    [])
    .then(finalRequestQueryResult => {
      this.baseData = finalRequestQueryResult.data[externalDataSource.identifier];
      this.eventService.updateControlData(this.mainLevel.listContainerLogicalControlId,
        [this.baseData]);
    })
    .catch(error => {
      console.error('showResults', error);
    });
  }

  getFilterValueArray(): any[] {
    const finalAdditionalFilterArray = [];
    // template filter
    this.mainLevel.templatesFilters.forEach(templatesFilter => {
      const filterParameter = [];
      let updateArray = false;
      this.additionalFilterdict.impArray.items.forEach(item => {
        const parameter = this.additionalFilterdict.Item(item);
        if (parameter.filterId === templatesFilter.id) {
          updateArray = true;
          if (parameter.filterParameter1) {
            parameter.filterParameter1 = String(parameter.filterParameter1);
          }
          if (parameter.filterParameter1 === false) {
            parameter.filterParameter1 = 'false';
          }
          if (parameter.filterParameter2) {
            parameter.filterParameter2 = String(parameter.filterParameter2);
          }
          filterParameter.push({
            filterSegmentMemberId: parameter.filterSegmentMemberId,
            filterParameter1: parameter.filterParameter1,
            filterParameter2: parameter.filterParameter2
          });
        }
      });

      if (updateArray) {
        finalAdditionalFilterArray.push(this.createFilterArrayString(this.graphqlqueryService.myLeadsFilterQuerySegment,
          filterParameter, templatesFilter.id));
      }
    });

    // Non-template filters
    if (this.dataFilter != null) {
      finalAdditionalFilterArray.push(this.createFilterArrayString(this.graphqlqueryService.myLeadsFilterQuerySegment, '',
        this.dataFilter, false));
    }

    // fixed filters
    this.fixedFilter.forEach(fixedFilterItem => {
      finalAdditionalFilterArray.push(this.createFilterArrayString(this.graphqlqueryService.myLeadsFilterQuerySegment, '',
        fixedFilterItem.id, false));
    });
    return finalAdditionalFilterArray;
  }

  createFilterArrayString(filterString: any, filterParameter: any, templatesFilterId: any, templateFilter = true): string {
    if (templateFilter) {
      filterString = filterString.replace('<filterParameters>', JSON.stringify(filterParameter));
      filterString = filterString.replace(/""/g, null);
      filterString = filterString.replace(/"filterParameter1"/g, 'filterParameter1');
      filterString = filterString.replace(/"filterParameter2"/g, 'filterParameter2');
      filterString = filterString.replace(/"filterSegmentMemberId"/g, 'filterSegmentMemberId');
    } else {
      filterString = filterString.replace('<filterParameters>', '[]');
    }
    filterString = filterString.replace('<filterId>', templatesFilterId.toString());
    return filterString;
  }

  filterChanged(filterValues: any) {
    console.log('filterChanged', filterValues, this.additionalFilterdict);
    if (this.additionalFilterdict.ContainsKey(filterValues.filterSegmentMemberId)) {
      this.additionalFilterdict.Set(filterValues.filterSegmentMemberId, filterValues);
    } else {
      this.additionalFilterdict.Add(filterValues.filterSegmentMemberId, filterValues);
      console.log('filterChanged added', filterValues, this.additionalFilterdict);
    }
  }

  launchMultiUpdate() {
    console.log('launchMultiUpdate', this.mainLevel.id);
    const externalDataSource = this.entityService.getExternalDataSourceByType(
      this.applicationInfoService.entities.Item(this.mainLevel.uiEntityInstanceId),
        ExternalDataSource_QueryType.UpdateMulti + this.mainLevel.id);
    this.updateMulti(externalDataSource.dataQuery, externalDataSource.identifier);
  }

  updateMulti(updateQuery, identifier: any) {
    console.log('updateMultiAccount', updateQuery);
    updateQuery = updateQuery.replace('<selectedCampaign>', this.selectedCampaign);
    updateQuery = updateQuery.replace('<0>', this.getIdsToModify());
    updateQuery = updateQuery.replace('<1>', this.getValuesToModify());
    updateQuery = updateQuery.replace('<2>', this.uiService.getQueryFieldsFromListControl(
      this.applicationInfoService.controls.Item(this.mainLevel.listContainerLogicalControlId)));
    console.warn('updateMulti', updateQuery);
    this.graphQLService.apolloGQLpromiseWithParameter('data', ApolloMethod.Query, updateQuery,
    []).then(myLeadsUpdateMultiAccountMutationResult => {
      this.refreshValueService.getProjectCategories();
      this.eventService.compactListUpdateDataContentEvent.emit(new JJEvent(this.mainLevel.listContainerLogicalControlId,
        [[]]));
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success,
        '',
        this.axivasTranslateService.getTranslationTextForToken('MyLeads.Message.UpdateSuccessfull'),
        false
      );
      this.lastSelectedRowsCount = 0;
      this.selectedTabIndex = 1;
      // this.eventService.updateControlData(this.mainLevel.listContainerLogicalControlId,
      //   [myLeadsUpdateMultiAccountMutationResult.data[identifier]]);
      console.log('updateMultiAccount', myLeadsUpdateMultiAccountMutationResult);
    });
  }

  getValuesToModify(): string {
    let result = '';
    const entity = this.applicationInfoService.entities.Item(this.mainLevel.uiEntityInstanceId.toString());
    const modifyData = this.commonService.getModifiedEntityValues(this.mainLevel.uiEntityInstanceId.toString());
    let uiDisabledEntityMember = this.entityService.getEntityValue(this.mainLevel.uiEntityInstanceId.toString(),
      this.applicationInfoService.disabledEntityMemberIdentifier, 1);
    if (!uiDisabledEntityMember) {
      uiDisabledEntityMember = [];
    }
    console.warn('getValuesToModify info', modifyData, entity, this.mainLevel.uiEntityInstanceId);
    modifyData.forEach(modifyDataElement => {
      if (!this.commonService.checkIfItemIsInArray(uiDisabledEntityMember, modifyDataElement)) {
        result = result.concat(this.commonService.getQueryStringForEntityMemberValue(this.mainLevel.uiEntityInstanceId,
          modifyDataElement), ' ');
        // result = result.concat(modifyDataElement, ': "', this.entityService.getEntityValue(entity, modifyDataElement, 1), '"');
      }
    });
    console.warn('getValuesToModify', result);
    return result;
  }

  getIdsToModify(): string {
    let result = '';
    this.selectedRecordsets.forEach(selectedRecordset => {
      result = result.concat(selectedRecordset.id, ',');
    });
    result = this.commonService.removeLastCharacterFromString(result);
    return result;
  }


  ///////////////////////////////////// Paging
  showPagingResults(paginationInfo: any, initalQuery = false, redirectToNonPaging = false) {
    const externalDataSource = this.entityService.getExternalDataSourceByType(
      this.applicationInfoService.entities.Item(this.mainLevel.entityId),
        ExternalDataSource_QueryType.MyLeadsQuery + this.mainLevel.id);
    if (!externalDataSource) {
      console.error('no external datasource found for ', ExternalDataSource_QueryType.MyLeadsQuery, this.mainLevel.entityId);
      return;
    }

    this.getPagingQueryResult(externalDataSource.dataQuery, paginationInfo)
    .then(finalRequestQueryResult => {
      if (initalQuery) {
        this.paginationInitialDataCount = finalRequestQueryResult.data[this.pagingIdentifier].numRecords;
      }
      paginationInfo.dataCount = this.paginationInitialDataCount;
      paginationInfo.numRecords = finalRequestQueryResult.data[this.pagingIdentifier].numRecords;

      this.baseData = finalRequestQueryResult.data[this.pagingIdentifier][externalDataSource.identifier];
      if (redirectToNonPaging) {
        this.eventService.updateControlData(this.mainLevel.listContainerLogicalControlId, [this.baseData]);
      } else {
        this.eventService.updateControlDataPaging(this.mainLevel.listContainerLogicalControlId,
          [this.baseData, paginationInfo]);
      }
    })
    .catch(error => {
      console.error('showResults', error);
    });
  }

  getPagingQueryResult(query: any, paginationInfo: any, dataAmount = this.inititalPageSize): Promise<any> {
    return new Promise((getPagingQueryResultResolve, getPagingQueryResultReject) => {
      query = query.replace('<fieldsFromList>', this.uiService.getQueryFieldsFromListControl(
        this.applicationInfoService.controls.Item(this.mainLevel.listContainerLogicalControlId)
      ));
      paginationInfo.pageNumber = this.commonService.nullOrUndefinedToDefaultValue(paginationInfo.pageNumber, 1);
      paginationInfo.pageSize = this.commonService.nullOrUndefinedToDefaultValue(paginationInfo.pageSize, dataAmount);
      paginationInfo.crossColumnFilter = this.commonService.nullOrUndefinedToDefaultValue(paginationInfo.crossColumnFilter, '');
      paginationInfo.filterInformation = this.commonService.nullOrUndefinedToDefaultValue(paginationInfo.filterInformation, '');
      paginationInfo.additionalColumnsSettings =
        this.commonService.nullOrUndefinedToDefaultValue(paginationInfo.additionalColumnsSettings, '');

      query = query.replace('<selectedCampaign>', this.selectedCampaign);
      query = query.replace('<pageNumber>', paginationInfo.pageNumber);
      query = query.replace('<pageSize>', paginationInfo.pageSize);
      query = query.replace('<crossColumnFilter>', paginationInfo.crossColumnFilter, '');
      query = query.replace('<filterInformation>', paginationInfo.filterInformation, '');
      query = query.replace('<additionalColumnsSettings>', paginationInfo.additionalColumnsSettings);
      this.graphQLService.apolloGQLpromiseWithParameter('data', ApolloMethod.Query, query, [])
      .then(finalRequestQueryResult => {
        getPagingQueryResultResolve(finalRequestQueryResult);
      })
      .catch(error => {
        getPagingQueryResultReject(error);
      });
    });
  }

  newEntityLevel() {
    this.applicationInfoService.miscSettings['newEntityLevelBaseId'] = this.mainLevel.id;
    this.eventService.showJjPopup('', 'new-logical-entity', 40, true);
  }

  exportData() {
    this.eventService.exportCompactListData(this.mainLevel.listContainerLogicalControlId, this.mainLevel.defaultName, true);
  }

  updateMatTableContent() {
    this.eventService.updateControlContentWithCustomParameter.emit(
      new JJEvent(this.mainLevel.listContainerLogicalControlId, [this.dataFilter])
    );
    console.log('updateMatTableContent');
  }

  // createFollowUps() {
  //   const idList = this.getIdsToModify();
  //   this.applicationInfoService.miscSettings['createTasksBatch'] = true;
  //   this.applicationInfoService.miscSettings['createTasksIdList'] = JSON.parse('[' + idList + ']');
  //   this.applicationInfoService.miscSettings['createTasksIdListHideStep'] = true;
  //   this.applicationInfoService.miscSettings['createTasksFollowups'] = true;
  //   this.applicationInfoService.miscSettings['createTasksIdListHideIdList'] = true;

  //   if (this.mainLevel.entityId == 1) {
  //     this.applicationInfoService.miscSettings['createTasksLevel'] = '273';
  //   } else {
  //     this.applicationInfoService.miscSettings['createTasksLevel'] = '274';
  //   }
  //   this.eventService.showJjPopup('', 'newtask', 50);
  // }



  createTasks(bookingMailMode) {
    let headerText = 'MyLeads.Label.CreateTasks';
    if (bookingMailMode == 2) {
      headerText = 'MyLeads.Label.CreateEMails';
    }
    this.applicationInfoService.miscSettings['bookingMailMode'] = bookingMailMode;
    const idList = this.getIdsToModify();
    this.applicationInfoService.miscSettings['createTasksBatch'] = true;
    this.applicationInfoService.miscSettings['createTasksIdList'] = JSON.parse('[' + idList + ']');
    this.applicationInfoService.miscSettings['createTasksIdListHideStep'] = false;
    this.applicationInfoService.miscSettings['createTasksFollowups'] = false;
    this.applicationInfoService.miscSettings['createTasksIdListHideIdList'] = true;

    if (this.mainLevel.entityId == 1) {
      this.applicationInfoService.miscSettings['createTasksLevel'] = '273';
    } else {
      this.applicationInfoService.miscSettings['createTasksLevel'] = '274';
    }
    // this.eventService.showJjPopup('', 'newtask', 50);

    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['15', '-1'])
    .then(() => {
      this.applicationInfoService.miscSettings['bookingMode'] = 2;
      this.eventService.showJjPopup(headerText, 'booking', 80);
    });
  }

  createEMailShot() {
    this.wizardService.showWizard(WizardMode.Mailshot, {
      'taskCreationGuid': '',
      'campaignId': null,
      'campaignName': ''
    }, '90', '90');
    return;

    this.applicationInfoService.miscSettings['bookingMailMode'] = 2;
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['15', '-1'])
    .then(() => {
      this.applicationInfoService.miscSettings['bookingMode'] = 2;
      this.eventService.showJjPopup('MyLeads.Label.CreateEMails', 'booking', 80);
      this.applicationInfoService.miscSettings['createTasksBatch'] = true;
      this.applicationInfoService.miscSettings['createTasksIdListHideStep'] = false;
      this.applicationInfoService.miscSettings['createTasksFollowups'] = false;
      this.applicationInfoService.miscSettings['createTasksIdListHideIdList'] = true;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';

@Component({
  selector: 'app-newtask',
  templateUrl: './newtask.component.html',
  styleUrls: ['./newtask.component.scss']
})
export class NewtaskComponent implements OnInit {
  createMode = null;
  workflows = [];
  campaigns = [];
  users = [];
  workflowsteps = [];
  newTask = null;
  hideStep = false;
  createFollowUps = false;
  hideIdList = false;
  contacts = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private entityService: EntityService,
    private userService: UserService,
    public methodService: MethodService,
    private loaderService: LoaderService,
    private messagingService: MessagingService,
    private externaldatasourceService:  ExternaldatasourceService
  ) { }

  ngOnInit() {
    this.createMode = this.applicationInfoService.miscSettings['createMode'];
    this.applicationInfoService.miscSettings['createMode'] = null;
        
    this.getCampaigns();
    this.getUsers();
    this.getContacts();
    this.newTask = new Object();
    this.getWorkflows();
    this.newTask['isFollowUpAppointment'] = false;
    this.newTask['accountId'] = this.entityService.getEntityValue(
      this.applicationInfoService.accountEntityId,
      'id', 1
    );
    this.newTask['contactId'] = this.applicationInfoService.miscSettings['newtaskcontactid'];
    this.newTask['followUpDate'] = new Date(Date.now()).toUTCString();
    this.newTask['followUpUserId'] = this.applicationInfoService.userID;
    this.newTask['assignCp'] = false;
    this.newTask['level'] = '273';
    this.newTask['projectId'] = Number(this.applicationInfoService.projectID);

    if (this.applicationInfoService.miscSettings['createTasksBatch'] == true) {
      this.createMode = 'multi';
      this.newTask.targetArray = this.applicationInfoService.miscSettings['createTasksIdList'];
      this.hideStep = this.applicationInfoService.miscSettings['createTasksIdListHideStep'];
      this.hideIdList = this.applicationInfoService.miscSettings['createTasksIdListHideIdList'];
      this.newTask['level'] = this.applicationInfoService.miscSettings['createTasksLevel']
      this.createFollowUps = this.applicationInfoService.miscSettings['createTasksFollowups'];

      this.applicationInfoService.miscSettings['createTasksFollowups'] = false;
      this.applicationInfoService.miscSettings['createTasksIdListHideStep'] = false;
      this.applicationInfoService.miscSettings['createTasksBatch'] = false;
      this.applicationInfoService.miscSettings['createTasksIdListHideIdList'] = false;

      if (this.createFollowUps) {
        this.newTask['workflowId'] = this.applicationInfoService.isFollowUpWorkflowId;
        this.newTask['stepId'] = this.applicationInfoService.isFollowUpWorkflowStepId;
      }
    }    
  }

  getWorkflows() {
    this.workflows = this.applicationInfoService.workflows.toArray().filter(workflowItem => 
      workflowItem.isActive == true &&
      workflowItem.isVisible == true &&
      workflowItem.id != 174
    );
    if (this.workflows.length > 0) {
      this.newTask['workflowId'] = this.workflows[0].id;
      this.getWorkflowSteps();
    }
  }

  getUsers() {
    this.externaldatasourceService.executeExternalDataSource(931, [])
    .then(getUsersResult => {
      this.users = getUsersResult;
    });
  }

   getWorkflowSteps() {
    this.externaldatasourceService.executeExternalDataSource(342, [this.newTask.workflowId])
    .then(result => {
      this.workflowsteps = result;
      if (result.length > 0) {
        this.newTask.stepId = result[0].id;
      }
    });
  }

  getCampaigns() {
    this.externaldatasourceService.executeExternalDataSource(269)
    .then(result => {
      this.campaigns = result;
      if (this.campaigns.length > 0) {
        this.newTask['campaignId'] = this.campaigns[0].id;
      }
    });
  }

  workflowChanged() {
    this.getWorkflowSteps();
  }

  levelChanged() {
    console.warn('levelChanged', this.newTask);
  }

  targetChanged() {
    this.newTask['targetArray'] = JSON.parse('[' + this.newTask.target + ']');
  }

  getContacts() {
    if (this.applicationInfoService.currentAccount) {
      if (this.applicationInfoService.currentAccount.isDummyAccount) {
        if (this.applicationInfoService.currentContact) {
          this.externaldatasourceService.executeExternalDataSource(85, [
            this.applicationInfoService.entities.Item(this.applicationInfoService.currentContact.id).data.contactId
          ]).then(result => {
            if (result == null) {
              this.contacts = []
            } else {
              this.contacts = [result];
            }
          });  
        } else {
          this.contacts = [];      
        }
      } else {
        this.externaldatasourceService.executeExternalDataSource(492).then(result => { 
          this.contacts = result; 
        });        
      }     
    } else {
      this.contacts = [];      
    }
  }
}

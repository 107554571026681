import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-custom-aipromptresult',
  templateUrl: './custom-aipromptresult.component.html',
  styleUrls: ['./custom-aipromptresult.component.scss']
})
export class CustomAipromptresultComponent implements OnInit {
  resultToDisplay = null;
  resultToDisplayPdf = null;
  loadingData = false;
  aiPrompts = [];
  selectedPompt = null;
  forceUpdate = false;
  featureId = null;
  featureNotFound = null;

  @Input() type : number | null = 1;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private packagefeatureService: PackagefeatureService,
    private sharedAPI: SharedAPI,
    private eventService: EventService,
    private uiService: UiService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_aiBattleCards']);    
    if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
      this.featureNotFound = true;
    } else {
      this.getAiPrompts();
      this.featureNotFound = false;
    }    

    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'refreshBattleCards') {
        if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
          this.featureNotFound = true;
        } else {
          this.getAiPrompts();
          this.featureNotFound = false;
        }        
      }
    });
  }

  showDesigner() {
    this.eventService.showJjPopup('AIPrompt.Label.ManageBattleCards', 'adminaiprompt', '90');
  }

  getAiPrompts() {
    this.externaldatasourceService.executeExternalDataSource(775, []).then(getAiPromptsResult => {
      this.aiPrompts = getAiPromptsResult.filter(result => result.promptLookupId == 6666);
    });
  }

  runPrompt(promptId, forceUpdate = false) {
    if (promptId == null) { return; }
    
    this.loadingData = true;
    let contactId = null;
    if (this.applicationInfoService.currentContact) {
      contactId = this.applicationInfoService.currentContact.id;
    }
    console.warn(this.applicationInfoService.currentAccount.id,
      contactId,
      promptId,
      null,
      null,
      forceUpdate);
    this.externaldatasourceService.executeExternalDataSource(871, [
      this.applicationInfoService.currentAccount.id,
      contactId,
      promptId,
      null,
      null,
      forceUpdate
    ])
    .then(result => {
      this.loadingData = false;
      if (result != null) {
        const prompt = this.aiPrompts.find(prompt => prompt.id == this.selectedPompt.id);
        this.resultToDisplay = result.responseText;        
        let resultToDisplayPdfHtml = this.modifyHeaderString(this.applicationInfoService.applicationSettings['battleCardPdfHeader'], prompt, result);
        this.resultToDisplayPdf = resultToDisplayPdfHtml;
      }      
    });
  }

  public exportPDF() {
    let firstName = '';    
    let lastName = '';
    if (this.applicationInfoService.currentContact) {
      this.applicationInfoService.currentContact.firstName;
      this.applicationInfoService.currentContact.lastName;  
    } else {
      this.resultToDisplayPdf = this.resultToDisplayPdf.replace('<div style="min-width: 150px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; color: #ed5c73;">Ansprechpartner</div>', '');
    }
    if (firstName == null) { firstName = ''; }
    if (lastName == null) { lastName = ''; }
    this.resultToDisplayPdf = this.resultToDisplayPdf.replace('{{contact.firstName}}', firstName);
    this.resultToDisplayPdf = this.resultToDisplayPdf.replace('{{contact.lastName}}', lastName);
    const body = {
      html: this.resultToDisplayPdf,
      fileName: 'test.pdf'
    }
    this.sharedAPI.createPDFFromHTML(body).subscribe(pdfToHtmlResult => {
      this.commonService.downloadFile(pdfToHtmlResult, 'application/pdf',
        'BattleCard '.concat(this.applicationInfoService.currentAccount.name1, ' ',
        this.commonService.getDateTimeString(new Date(Date.now())), '.pdf')
      );

    });
    return;   
  }

  modifyHeaderString(headerString, prompt, result) {
    // BattleCard
    headerString = headerString.replace('{{BattleCardName}}', prompt.defaultName);
    
    headerString = this.commonService.replaceStringWithEntityValues(headerString, 1, 'account');
    headerString = this.commonService.replaceStringWithEntityValues(headerString, 4, 'contact');

    // Add the result of the aiPrompt
    if (this.commonService.checkIfStringContainsString(headerString, '{{PromptResult}}')) {
      headerString = headerString.replace('{{PromptResult}}', result.responseText);
    } else {
      headerString = headerString.concat(result.responseText);
    }    

    return headerString;
  }



  openPopup() {
    this.eventService.showJjPopup('AIPrompt.Label.BattleCardsSmall', 'aipromptresult', '90');
  }

  getMaxHeight() {
    return Number(this.uiService.getDesignSpanPositionMobile('aiPromptResultDesign', 30));
  }
}

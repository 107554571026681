import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent implements OnInit, OnChanges, OnDestroy{
  updateControlAttributesEventSubscription: Subscription = new Subscription();
  optStatesResult = null;
  optStatesCalculated = [];

  constructor(
    public applicationInfoService:  ApplicationInfoService,
    public commonService: CommonService,
    public axivasTranslationService: AxivasTranslateService,
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService
  ){}

  @Input() type: string = '';
  @Input() data;
  @Input() noRightBorder = false;
  @Input() small: boolean = false;
  
  processingStatus;
  leadStatus;
  leadStatusPositivity;
  processingStateId = null;
  processingStates = [];
  showSelectionOfEditingState = false;
  showDropdown = true;

  // Todo if account leadStateId : if contact : leadStateCalculated

  ngOnInit(): void {
    this.updateControlAttributesEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'updateCardOptins' && this.type == 'contact') {
        this.getOptIns();
      }
      if (event.id == 'contactLoaded' && this.type == 'contact') {
        this.getOptIns();
      }

      if (event.id == 'updateEverleadAccountHeader') {
        setTimeout(() => {
          this.refreshStateIds();
          if (this.type == 'contact') {
            this.getOptIns();
          }
        }, 1000);
      }
    });

    if (this.type == 'contact') {
      this.getOptIns();
      this.processingStates = this.commonService.getProjectLookupTableValues(31, 'central');
    }

    if (this.applicationInfoService.applicationSettings['showSelectionOfEditingState'] == 'true') {
      this.showSelectionOfEditingState = true;
    }
  }


  

  getOptIns() {
    setTimeout(() => {
      if (this.applicationInfoService.currentAccount) {
        this.optStatesCalculated = [];
        this.externaldatasourceService.executeExternalDataSource(62, []).then(getOptStatesResult => {
          this.optStatesResult = getOptStatesResult;
        });
      }
    }, 900);
  }

  getOptInStatus(availableMediaItem) {
    // if (!this.commonService.isNullOrUndefined(this.optStatesCalculated[availableMediaItem.id])) {
    //   return this.optStatesCalculated[availableMediaItem.id];
    // }
    const returnValue = this.commonService.getOptinStateForMedia(
      availableMediaItem,
      this.optStatesResult,
      this.applicationInfoService.currentContact
    );
    this.optStatesCalculated[availableMediaItem.id] = returnValue;
    return returnValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //throw new Error('Method not implemented.');
  }

  ngOnDestroy(): void {
    //throw new Error('Method not implemented.');
    if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }
  }

  getStates() {
    const processState = this.commonService.getProjectLookupTableValues(31, 'central')
    if(this.data.calculatedEditingState){
      this.processingStatus = processState.find((item) => item.id === this.data.calculatedEditingState)
    }

    const leadStates = this.applicationInfoService.leadstates.toArray();
    let leadStateId = null;

    if (this.data.calculatedLeadStateId != null) {
      leadStateId = this.data.calculatedLeadStateId;
    } else {
      if (this.data.leadStateId) {
        leadStateId = this.data.leadStateId;
      }
    }

    this.leadStatus = leadStates.find((item) =>  item.lookupTable.id === leadStateId);
    if(this.leadStatus){
      const positivity = this.commonService.getProjectLookupTableValues(2 , 'central');
      this.leadStatusPositivity = positivity.find((item)=> item.id === this.leadStatus.positivityLookupId);
    }
  }

  getCurrentProcessingState() {
    const processState = this.commonService.getProjectLookupTableValues(31, 'central')
    this.processingStateId = null;

    if (this.type === 'account' && this.applicationInfoService.currentAccount.isDummyAccount) {
      return '';
    }

    if (this.type == 'account') {
      this.processingStateId = this.applicationInfoService.currentAccount.calculatedEditingState;
    } else {
      this.processingStateId = this.applicationInfoService.currentContact.calculatedEditingState;
    }
    if (this.processingStateId) {
      this.processingStatus = processState.find((item) => item.id === this.processingStateId);
      if (this.processingStatus) {
        return this.commonService.getTranslationValueFromArray(this.processingStatus);
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getCurrentLeadState() {
    let leadStateId = null;

    if (this.type === 'account' && this.applicationInfoService.currentAccount.isDummyAccount) {
      return '';
    }

    if (this.type == 'account') {
      leadStateId = this.applicationInfoService.currentAccount.calculatedLeadStateId;
    } else {
      leadStateId = this.applicationInfoService.currentContact.leadStateId;
    }
    this.leadStatus = this.applicationInfoService.leadstates.toArray().find((item) =>
      item.lookupTable.id === leadStateId
    );
    if(this.leadStatus){
      const positivity = this.commonService.getProjectLookupTableValues(2 , 'central');
      this.leadStatusPositivity = positivity.find((item)=> item.id === this.leadStatus.positivityLookupId);
      return this.commonService.getTranslationValueFromArray(this.leadStatus);
    } else {
      return '';
    }
  }

  refreshStateIds() {
    if (this.applicationInfoService.currentAccount && this.type == 'account') {
      this.externaldatasourceService.executeExternalDataSource(897, [this.applicationInfoService.currentAccount.id])
      .then(refreshStateAccountResult => {
        this.applicationInfoService.currentAccount.calculatedEditingState = refreshStateAccountResult.calculatedEditingState;
        this.applicationInfoService.currentAccount.calculatedLeadStateId = refreshStateAccountResult.calculatedLeadStateId;
      });
    }

    if (this.applicationInfoService.currentContact && this.type != 'account') {
      this.externaldatasourceService.executeExternalDataSource(896, [this.applicationInfoService.currentContact.id])
      .then(refreshStateContactResult => {
        this.applicationInfoService.currentContact.calculatedEditingState = refreshStateContactResult.calculatedEditingState;
        this.applicationInfoService.currentContact.leadStateId = refreshStateContactResult.leadStateId;
      });
    }

  }

  getOptinColor(refTypeId) {
    let returnValue = 'disabledGray';
    if (this.optStatesResult == null) {
      return returnValue;
    } else {
      const status = this.getOptInStatus({ id: refTypeId });
      switch (status.lookupTypeRefId) {
        case 43:
          returnValue = 'green';
          break;
        case 44:
          returnValue = 'red';
          break;
        case 1815:
          returnValue = 'green';
          break;
      }
    }
    return returnValue;
  }

  changeEditingState() {
    console.warn({
      id: this.applicationInfoService.currentContact.id,
      calculatedEditingState: this.processingStateId
    })

    if (this.processingStateId == 292) {
      this.externaldatasourceService.executeExternalDataSource(491).then(tasklist => {
        if (tasklist.length > 0) {
          this.confirmationService.confirm({
            message: this.axivasTranslateService.getTranslationTextForToken('Contact.ChangeEditingState.Message'),
            header: this.axivasTranslateService.getTranslationTextForToken('Contact.ChangeEditingState.Header'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => { this.changeEditingStateCall(292); },
            reject: () => {
              this.showDropdown = false;
              this.processingStateId = this.applicationInfoService.currentContact.calculatedEditingState;
              setTimeout(() => { this.showDropdown = true }, 4);
            }
          });
        } else {
          this.changeEditingStateCall(292);
        }
      })
    } else {
      this.changeEditingStateCall();
    }
  }

  changeEditingStateCall(customId = null) {
    if (customId == null) {
      customId = this.processingStateId;
    }
    this.externaldatasourceService.executeExternalDataSource(843, [
      this.commonService.getModifyArrayBody({
        id: this.applicationInfoService.currentContact.id,
        accountId: this.applicationInfoService.currentAccount.id,
        calculatedEditingState: customId
      }, [])
    ]).then(() => {
      setTimeout(() => {
        if (customId == 292) {
          setTimeout(() => { this.eventService.updateCombinedActivityList.emit() }, 1000);
        }
        this.eventService.customEvent.emit({ id: 'updateEverleadAccountHeader' });
      }, 0);
    })

  }

  getContactTippy(data) {
    let returnValue = '';
    if (this.commonService.isNullOrUndefined(data.firstName)) {
      returnValue = data.lastName;
    } else {
      returnValue = data.firstName.concat(' ', data.lastName);
    }
    return returnValue;
  }

  getOptinTippyText(generalText, mediumText) {
    let returnValue = '<b>';
    returnValue = returnValue.concat(
      this.axivasTranslateService.getTranslationTextForToken(mediumText),
      '</b><br>',
      this.axivasTranslateService.getTranslationTextForToken(generalText),
    );
    return returnValue;
  }
}

import { Injectable } from '@angular/core';
import { AuthService } from '../../core/authentication/services/auth.service';
import { SettingsService, Setting } from '../../shared/service/settings.service';
import { EventService, JJEvent } from './event.service';
import { EntityService, ValueType } from './entity.service';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { Router } from '@angular/router';
import { GraphQLService, ApolloMethod } from '../../shared/service/graphql.service';
import { ConfirmationService } from 'primeng/api';
import { MessagingService, MessagePosition, MessageSeverity } from './messaging.service';
import { GraphqlqueryService } from './graphqlquery.service';
import { ProjectService } from './project.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { reject } from 'q';
import { MailService } from './mail.service';
import { environment } from 'environments/environment';
import { CommonService } from './common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { TrackingService } from './tracking.service';
import { Dictionary } from 'app/core/dictionary';
import { WizardMode, WizardService } from '../controls/static/wizard/wizard.service';
import {formatDate} from '@angular/common';
import { ApplicationinitService } from './applicationinit.service';

enum VariableModifyingMode {
  ParameterValue = 1,
  ControlData = 2,
  ValueFromControlDataParameter = 3,
  ValueFromControlSelection = 4,
  ValueFromEntity = 5,
  CompactListFilter = 7,
  Null = 6,
  MiscSettings = 8,
  UserInformation = 9,
  CurrentDate = 10,
  CampaignModeId = 11,
  CurrentAccountId = 12,
  CurrentContactId = 13
}

export enum InternalMethodType {
  UpdateTaskRelevantInformation = 'updatetaskrelevantinformation',
  LoadNewContact = 'loadnewcontact',
  AskYesNo = 'askyesno',
  ActivateTab = 'activatetab',
  SaveEntityToServer = 'saveentityserver',
  UpdateDropdownControl = 'updatedropdowncontrol',
  LoadEntity = 'loadentity',
  UpdateEntity = 'updateentity',
  UpdateEntityValue = 'updateentityvalue',
  Message = 'message',
  Logout = 'logout',
  CallInternalMethod = 'callinternalmethod',
  ShowUrl = 'url',
  ShowAccountData = 'showaccountdata',
  ShowAccountDataPopup= 'showaccountdatapopup',
  LoadContact = 'loadcontact',
  RefreshContact = 'refreshcontact',
  LoadConversionContact = 'loadconversioncontact',
  BookTask = 'booktask',
  BookTaskAndStayInAccount = 'booktaskandstayinaccount',
  NextTask = 'nexttask',
  UpdateBookingPreview = 'updatebookingpreview',
  StartWorking = 'startworking',
  UpdateControlContent = 'updatecontrolcontent',
  UpdateControlTypeContent = 'updatecontroltypecontent',
  ShowTask = 'showtask',
  ChangeLanguage = 'changelanguage',
  ChangePageContent = 'changepagecontent',
  ChangeContainerContent = 'changecontainercontent',
  ShowPopup = 'showpopup',
  ShowJJPopup = 'showjjpopup',
  ClosePopup = 'closepopup',
  CloseJJPopup = 'closejjpopup',
  ShowSoftphonePopup = 'showsoftphonepopup',
  CloseSoftphonePopup = 'closesoftphonepopup',
  BookNotRelevantCp = 'booknotrelevantcp',
  ShowTaskByTaskId = 'showtaskbytaskid',
  NavigateTo = 'navigateto',
  LoadProject = 'loadproject',
  UnlockAccount = 'unlockaccount',
  ScrollIntoView = 'scrollintoview',
  BookTaskAndShowNext = 'booktaskandshownext',
  CallNumber = 'callnumber',
  CreateRedirectMail = 'createredirectmail',
  ExecuteExternalDataSource = 'executeexternaldatasource',
  SetAccountPopupData = 'setaccountpopupdata',
  ShowQMDataPopup = 'showqmdatapopup',
  LoadFollowUp = 'loadfollowup',
  UpdateMiscSettings = 'updatemiscsettings',
  CreateContactAndAddToTask = 'createcontactandaddtotask',
  CheckIfTaskCanBeLoaded = 'checkiftaskcanbeloaded',
  LoadFirstTaskOfContact = 'loadfirsttaskofcontact',
  CopyValuesFromEntityToEntity = 'copyvaluesfromentitytoentity',
  CreateNewTaskForContact = 'createnewtaskforcontact',
  AssignAccountToContact = 'assignaccounttocontact',
  CreateSingleAction = 'createsingleaction',
  LoadContactAndAssignToTask = 'loadcontactandassigntotask',
  SetDeleteFlag = 'setdeleteflag',
  RollbackAuditValue = 'rollbackauditvalue',
  ChangePageContentToContainer = 'changepagecontenttocontainer',
  DownloadExternalDataSourceResult = 'downloadexternaldatasourceresult',
  PrepareTaskForShowing = 'preparetaskforshowing',
  ShowSettingPage = 'showsettingpage',
  GetNextLogicalItemFromMyFollowups = 'getnextlogicalitemfrommyfollowups',
  ChangeMyLeadsSubLevel = 'changemyleadssublevel',
  ShowEventWizard = 'showeventwizard',
  ConsumeTrackingEvent = 'consumetrackingevent',
  ShowBattleCard = 'showbattlecard',
  ShowAccountGroup = 'showaccountgroup',
  ShowVoiceScript = 'showvoicescript',
  SetAccountForNewContact = 'setaccountfornewcontact',
  EditConversion = 'editconversion',
  AssignAccountToProspect = 'assignaccounttoprospect',
}

//// TODO : Refactor, aufspalten in verschiedene Unterservices

@Injectable({
  providedIn: 'root'
})
export class MethodService {
  constructor(
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private applicationInfoService: ApplicationInfoService,
    private settingsService: SettingsService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService,
    private entityService: EntityService,
    private graphQLService: GraphQLService,
    private router: Router,
    private projectService: ProjectService,
    private messagingService: MessagingService,
    private graphqlqueryService: GraphqlqueryService,
    private mailService: MailService,
    private axivasTranslateService: AxivasTranslateService,
    private trackingService: TrackingService,
    private wizardService: WizardService
  ) { }

  checkMethodCondition(conditions: any) {
    let returnValue = null;
    if (!this.commonService.isNullOrUndefined(conditions)) {
      conditions.forEach(condition => {
        returnValue = this.commonService.compare(condition, this.commonService.getCompareValue(condition, ''), returnValue, 'returnValue');
      });
    }
    return returnValue;
  }

  public launchInternalMethod(method: any, params: any[], conditions = null): Promise<any> {
    return new Promise((launchInternalMethodResolve, launchInternalMethodReject) => {
      this.messagingService.showDebugMessageInConsole('launchInternalMethod', [method, params]);
        switch (this.checkMethodCondition(conditions)) {
          case 'reject':
            launchInternalMethodReject('checkMethodCondition - rejected');
            return;
          case 'resolve':
            launchInternalMethodResolve('checkMethodCondition - resolved');
            return;
        }

      switch (method.toLowerCase()) {
        case InternalMethodType.GetNextLogicalItemFromMyFollowups:
          this.loaderService.display(true);
          this.externaldatasourceService.executeExternalDataSource(563, [this.applicationInfoService.lastLogicalItemFromMyFollowups])
          .then(getNextLogicalItemFromMyFollowupsResult => {
            this.loaderService.display(false);
            if (getNextLogicalItemFromMyFollowupsResult != null) {
              this.projectService.getCurrentAccount(getNextLogicalItemFromMyFollowupsResult.accountId)
              .then(() => {
                if (getNextLogicalItemFromMyFollowupsResult.contactId == null) {
                  this.applicationInfoService.currentContact = null;
                  this.entityService.clearEntityData(this.applicationInfoService.contactEntityId);
                  this.eventService.updateControlAttributes();
                  this.eventService.updateContactQuestionaire();
                } else {
                  this.projectService.getCurrentContact(getNextLogicalItemFromMyFollowupsResult.contactId)
                  .then(() => {});
                }
              });
              this.applicationInfoService.lastLogicalItemFromMyFollowups = getNextLogicalItemFromMyFollowupsResult.id;
            } else {
              this.messagingService.showDefaultInfo('', 'NextFollowUp.Message.NoFollowUpFound');
              this.applicationInfoService.lastLogicalItemFromMyFollowups = null;
            }

            launchInternalMethodResolve(getNextLogicalItemFromMyFollowupsResult);
          })
          .catch(error => {
            this.loaderService.display(false);
            launchInternalMethodReject(error);
          });
          return;

        case InternalMethodType.ChangeMyLeadsSubLevel:
          this.eventService.changeMyLeadsSubLevelEvent.emit(params[0]);
          this.applicationInfoService.miscSettings['myLeadsSubMode'] = params[0];
          this.launchInternalMethod('changepagecontent', ['jjsection', 'myleads']);
          launchInternalMethodResolve(null);
          return;

        case InternalMethodType.AskYesNo:
          this.confirmationService.confirm({
            message: this.axivasTranslateService.getTranslationTextForToken((params[1])),
            header: this.axivasTranslateService.getTranslationTextForToken(params[0]),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              launchInternalMethodResolve(null);
            },
            reject: () => {
              launchInternalMethodReject();
            }
          });
          return;

        case InternalMethodType.ConsumeTrackingEvent:
          this.wizardService.showWizard(WizardMode.EventTrigger, params[0]);
          launchInternalMethodResolve(null);
          break;

        case InternalMethodType.LoadNewContact:
          this.isDirtyCheck()
            .then(() => {
              launchInternalMethodResolve(this.router.navigate([this.applicationInfoService.mainFrameName + '/account']));
            });
          break;

        case InternalMethodType.ShowBattleCard:
          this.applicationInfoService.miscSettings['AIPromptTypeId'] = 6666;
          this.eventService.showJjPopup('AIPrompt.Mode.BattleCard', 'aipromptresult', '100');
          launchInternalMethodResolve(null);
          break;

        case InternalMethodType.SetAccountPopupData:
          this.applicationInfoService.accountPopupDataArray = params[0];
          this.applicationInfoService.accountPopupDataType = params[1];
          launchInternalMethodResolve(null);
          return;

        case InternalMethodType.ShowSettingPage:
          this.applicationInfoService.projectSettingPage = Number(params[0]);
          this.launchInternalMethod('changepagecontent', ['jjsection', 'supervisor-main']);
          this.eventService.showSettingPageEvent.emit(params[0]);
          break;

        case InternalMethodType.ShowAccountGroup:
          this.applicationInfoService.miscSettings['accountGroupParentLookupId'] = params[1];
          this.applicationInfoService.miscSettings['accountGroupSubsidaryLookupId'] = params[2];
          this.applicationInfoService.miscSettings['accountGroupShowParents'] = params[3];
          this.eventService.showJjPopup(params[0], 'accountgroup', 80);
          launchInternalMethodResolve(null);
          break;

        case InternalMethodType.ActivateTab:
          this.eventService.activateTab(params[0], params[1]);
          return;

        case InternalMethodType.ExecuteExternalDataSource:
          this.loaderService.display(true, false, 'InternalMethodType.ExecuteExternalDataSource');
          this.externaldatasourceService.executeExternalDataSource(params[0])
            .then(() => { launchInternalMethodResolve(null); this.loaderService.display(false); })
            .catch(error => { launchInternalMethodReject(error); this.loaderService.display(false); });
          return;

        case InternalMethodType.ShowVoiceScript:
          this.applicationInfoService.miscSettings['voiceScriptId'] = params[1];
          this.eventService.showJjPopup(params[0], 'voicescript', '90');
          launchInternalMethodResolve(null);
          break;

        case InternalMethodType.UnlockAccount:
          this.projectService.unlockAccount()
            .then(() => {
              this.applicationInfoService.accountLockInformation = null;
              this.applicationInfoService.currentContact = null;
              this.applicationInfoService.currentAccount = null;
              this.applicationInfoService.currentTask = null;
              launchInternalMethodResolve(null);
            })
            .catch(error => { launchInternalMethodReject(error); });
          return;

        case InternalMethodType.AssignAccountToContact:
          let contactId = null;
          if (this.commonService.isNullOrUndefined(params[2])) {
            contactId = this.applicationInfoService.currentContact.id;
          } else {
            contactId = params[2];
          }
          if (params[1] == 'loadContact') {
            this.externaldatasourceService.executeExternalDataSource(300, [
              this.applicationInfoService.projectInfo.id,
              contactId,
              params[0]
            ])
            .then(() => {
              this.projectService.getCurrentAccount(params[0])
              .then(() => { this.projectService.getCurrentContact(contactId) });
              launchInternalMethodResolve(null);
            })
            .catch(() => { launchInternalMethodReject(); });
          } else {
            this.confirmationService.confirm({
              message: this.axivasTranslateService.getTranslationTextForToken('AssignContact.Question.Message'),
              header: this.axivasTranslateService.getTranslationTextForToken('AssignContact.Question.Header'),
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.externaldatasourceService.executeExternalDataSource(300, [
                  this.applicationInfoService.projectInfo.id,
                  contactId,
                  params[0]
                ])
                .then(() => {
                  this.projectService.getCurrentAccount(params[0]);
                  launchInternalMethodResolve(null);
                })
                .catch(() => { launchInternalMethodReject(); });
              },
              reject: () => {
                launchInternalMethodReject();
              }
            });
          }
          return;

        case InternalMethodType.CreateNewTaskForContact:
          this.applicationInfoService.miscSettings['createMode'] = 'single';
          this.applicationInfoService.miscSettings['newtaskcontactid'] = params[0];

          // Check if there is already a task
          this.externaldatasourceService.executeExternalDataSource(13)
          .then(CreateNewTaskForContactTaskCheckResult => {
            const tasks = CreateNewTaskForContactTaskCheckResult.filter(task => task.contactId == params[0]);
            if (this.applicationInfoService.applicationSettings['blockMultipleTasksForContacts'] == 'true' && tasks.length > 0) {
              this.messagingService.showDefaultInfo('CreateNewTaskForContact.TasksFound.Header', 'CreateNewTaskForContact.TasksFound.BlockMessage')
              launchInternalMethodReject();
              return;
            } else {
              if(CreateNewTaskForContactTaskCheckResult.length > 0) {
                this.confirmationService.confirm({
                  message: this.axivasTranslateService.getTranslationTextForToken('CreateNewTaskForContact.TasksFound.AccountMessage'),
                  header: this.axivasTranslateService.getTranslationTextForToken('CreateNewTaskForContact.TasksFound.Header'),
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {
                    this.launchInternalMethod(InternalMethodType.ShowJJPopup, ['NewTask.Label.CreateNewTask', 'newtask', '60', true]).then(() => { launchInternalMethodResolve(null); }).catch(error => { launchInternalMethodReject(error); });
                  },
                  reject: () => {
                    launchInternalMethodReject();
                  }
                });
              } else {
                this.launchInternalMethod(InternalMethodType.ShowJJPopup, ['NewTask.Label.CreateNewTask', 'newtask', '60', true]).then(() => { launchInternalMethodResolve(null); }).catch(error => { launchInternalMethodReject(error); });
              }
              return;
            }
          });

        case InternalMethodType.SaveEntityToServer:
          // this.loaderService.display(true, false, 'InternalMethodType.SaveEntityToServer');
          this.entityService.saveEntityToServer(params[0])
            .then(data => {
              // this.loaderService.display(false, false, 'InternalMethodType.SaveEntityToServer');
              launchInternalMethodResolve(data);
            })
            .catch(error => {
              // this.loaderService.display(false, false, 'InternalMethodType.SaveEntityToServer');
              launchInternalMethodReject(error);
            });
          return true;

        case InternalMethodType.LoadProject:
          this.projectService.getProjectByProjectId(params[0])
          .then(getProjectByProjectIdResult => {
           
          })
          .catch(getProjectByProjectIdResultError => {
            launchInternalMethodReject(getProjectByProjectIdResultError);
          });
          return;

        case InternalMethodType.UpdateDropdownControl:
          this.eventService.updateDropDownList(params[0]);
          launchInternalMethodResolve(null);
          return true;

        case InternalMethodType.CloseJJPopup:
          this.eventService.closeJjPopup(params[0]);
          launchInternalMethodResolve(null);
          return true;

        case InternalMethodType.LoadFollowUp:
          this.applicationInfoService.currentTask = null;
          this.applicationInfoService.currentContact = null;
          params[0] = this.commonService.dapperToGQL(params[0]);
          this.entityService.clearEntityData(this.applicationInfoService.contactEntityId);
          this.projectService.getCurrentAccount(params[0].accountId).
          then(() => {
            if (params[0].contactId !== null) {
              this.projectService.getCurrentContact(params[0].contactId).then(() => {
                launchInternalMethodResolve(null);
                this.launchInternalMethod('changepagecontent', ['jjsection', 'account']);
                this.eventService.closeJjPopup();
              });
            } else {
              launchInternalMethodResolve(null);
              this.launchInternalMethod('changepagecontent', ['jjsection', 'account']);
              this.eventService.closeJjPopup();
            }
          });
          return;

        case InternalMethodType.ShowAccountData:
          this.projectService.clearTaskData();
          this.commonService.clearSessionStorageItem('CurrentContact');
          this.projectService.getCurrentAccount(params[0])
          .then(getCurrentAccountResult => {
            this.launchInternalMethod('changepagecontent', ['jjsection', 'account']);
            launchInternalMethodResolve(null);
            this.eventService.customEvent.emit('ShowAccountData');
          })
          .catch(error => { launchInternalMethodReject(error); });
          return true;

        case InternalMethodType.ShowQMDataPopup:
          this.projectService.clearTaskData();
          // this.loaderService.display(true, false, 'ShowAccountDataPopup');
          this.loadQMData(params[0], params[1])
          .then(() => {
            // this.loaderService.display(false, false, 'ShowAccountDataPopup');
            this.applicationInfoService.accountPopupQMMode = true;
            this.launchInternalMethod(InternalMethodType.ShowJJPopup, ['QM.Label.Header', 'account-popup', 100]);
            launchInternalMethodResolve(null);
          })
          .catch(error => {
            // this.loaderService.display(false, false, 'ShowAccountDataPopup');
            launchInternalMethodReject(error);
          });
          return true;

        case InternalMethodType.ShowAccountDataPopup:
          this.projectService.clearTaskData();
          // this.loaderService.display(true, false, 'ShowAccountDataPopup');
          this.projectService.getCurrentAccount(params[0])
          .then(getCurrentAccountResult => {
            this.applicationInfoService.accountPopupDataId = Number(params[0]);
            this.launchInternalMethod(InternalMethodType.ShowJJPopup, ['Account.Label.Header', 'account-popup', 100]);
            launchInternalMethodResolve(null);
          })
          .catch(error => {
            // this.loaderService.display(false, false, 'ShowAccountDataPopup');
            launchInternalMethodReject(error);
          });
          return true;

        case InternalMethodType.ScrollIntoView:
          const element = document.getElementById('cc' + params[0]);
          if (!this.commonService.isNullOrUndefined(element)) {
            launchInternalMethodResolve(element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'}));
          } else {
            launchInternalMethodReject(console.error('ScrollIntoView: Element not found'));
          }
          return;

        case InternalMethodType.CreateRedirectMail:
          let mailbody = '';
          let mailsubject = '';
          mailbody = mailbody.concat(environment.serverUrl.concat(environment.baseRef, '/redirect/data?pid=',
          this.applicationInfoService.projectID.toString()));
          if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentTask)) {
            mailbody = this.axivasTranslateService.getTranslationTextForToken('ConsultMail.Message.BodyTask').concat('\n', '\n').concat(
              mailbody.concat('&tid=', this.applicationInfoService.currentTask.id));
            mailsubject = mailsubject.concat(this.axivasTranslateService.getTranslationTextForToken('ConsultMail.Message.SubjectTask'));
          } else {
            if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentAccount)) {
              mailbody = this.axivasTranslateService.
                getTranslationTextForToken('ConsultMail.Message.BodyAccount').concat('\n', '\n').concat(
                mailbody.concat('&aid=', this.applicationInfoService.currentAccount.id));
              mailsubject = mailsubject.concat(this.axivasTranslateService.
                getTranslationTextForToken('ConsultMail.Message.SubjectAccount'));
            }
          }
          launchInternalMethodResolve(this.mailService.mailTo('', mailsubject, mailbody));
          return;

        case InternalMethodType.LoadEntity:
          // this.loaderService.display(true);
          this.entityService.loadEntity(params)
            .then(() => {
              // this.loaderService.display(false);
              launchInternalMethodResolve(null);
            })
            .catch(() => {
              // this.loaderService.display(false);
              launchInternalMethodReject();
            });
          return;

        case InternalMethodType.UpdateEntity:
          this.entityService.updateEntity(params[0], params[1])
          .then(() => {
            launchInternalMethodResolve(null);
          });
          return true;

        case InternalMethodType.UpdateEntityValue:
          this.entityService.insertDataValue(params[0], params[1], params[2]);
          launchInternalMethodResolve(null);
          return true;

        case InternalMethodType.CallNumber:
          this.eventService.callNumberEvent.emit(params[0]);
          launchInternalMethodResolve(null);
          return;

        case InternalMethodType.BookNotRelevantCp:
          this.projectService.bookNotRelevantCp(params[0]).then(bookNotRelevantCpResult => {
            this.launchInternalMethod(InternalMethodType.LoadContact, [params[0]])
              .then(() => {
                this.applicationInfoService.currentTask.contact.id = params[0];
                this.projectService.updateTaskRelevantInformation();
                launchInternalMethodResolve(null);
              })
              .catch(() => launchInternalMethodReject());
          })
            .catch(error => {
              launchInternalMethodReject(error);
            });
          return;

        case InternalMethodType.Message:
          alert(params[0]);
          launchInternalMethodResolve(null);
          return true;

        case InternalMethodType.Logout:
          localStorage.removeItem('awstoken');
          localStorage.removeItem('awsrefreshtoken');
          return true;

        case InternalMethodType.ShowUrl:
          launchInternalMethodResolve(window.open(this.axivasTranslateService.getTranslationTextForToken(params[0]), params[1]));
          return true;

        case InternalMethodType.LoadContact:
          launchInternalMethodResolve(this.projectService.getCurrentContact(params[0]));
          return true;

        case InternalMethodType.SetAccountForNewContact:
          this.eventService.customEvent.emit({ id: 'SetAccountForNewContact', value: params[0]});
          this.eventService.closeJjPopup();
          launchInternalMethodResolve(params[0]);
          break;

        case InternalMethodType.LoadContactAndAssignToTask:
          launchInternalMethodResolve(this.projectService.getCurrentContact(params[0]));
          if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentTask)) {
            this.entityService.updateValuebyType(
              this.applicationInfoService.taskEntityId,
              'contactId',
              params[0],
              ValueType.Number
            );
          }
          return;

        case InternalMethodType.CreateSingleAction:
          const actionData = this.applicationInfoService.entities.Item(params[0]).data;
          // console.log('CreateSingleAction', params, actionData);
          if (this.projectService.checkIfQuestionsHaveRequiredAnswers(this.entityService.getEntityValue(params[0], 'resultId', 1), params[0]) === false) {
            this.messagingService.showDefaultWarning(
              this.axivasTranslateService.getTranslationTextForToken('BookTask.Message.Header'),
              this.axivasTranslateService.getTranslationTextForToken('BookTask.Message.QuestionAnswerRequired'),
            );
            launchInternalMethodReject(null);
            return;
          }
          let subResultArray = [];
          if (this.commonService.isNullOrUndefined(actionData.campaignId)) {
            actionData.campaignId = this.applicationInfoService.campaigns.Item(
              this.applicationInfoService.campaigns.impArray.items[0]
            ).id;
          }
          if (this.commonService.isNullOrUndefined(actionData.isFollowUpAppointment)) {
            actionData.isFollowUpAppointment = false;
          }
          if (actionData.contactId == undefined) {
            if (this.applicationInfoService.currentContact) {
              actionData.contactId = this.applicationInfoService.currentContact.id;
            } else {
              actionData.contactId = null;
            }
          }
          let bodyParam = '';
          if (!this.commonService.isNullOrUndefined(actionData.id) && actionData.id != -1) {
            bodyParam = this.commonService.getModifyArrayBody(actionData, ['isPersonalFollowup', 'processId', 'workflowId', 'uiTemplateContentOverride', 'uiFollowUpTask', 'uiFollowUpDate', 'uiNextFollowUpDate'], [], this.applicationInfoService.entities.Item(params[0]));
          } else {
            bodyParam = this.commonService.getModifyArrayBody(actionData, ['isPersonalFollowup', 'processId', 'workflowId', 'uiTemplateContentOverride', 'uiFollowUpTask', 'uiFollowUpDate', 'uiNextFollowUpDate', 'id'], [], this.applicationInfoService.entities.Item(params[0]));
          }
          if (actionData.subResults) {
            let subresultIds = [];
            actionData.subResults.forEach(subresult => {
              if (subresult != null) {
                if (actionData.subResultsadditionalText) {
                  if (actionData.subResultsadditionalText[subresult]) {
                    subresultIds.push({ answerId: subresult, answerText: actionData.subResultsadditionalText[subresult]});
                  } else {
                    subresultIds.push({ answerId: subresult });
                  }
                } else {
                  subresultIds.push({ answerId: subresult });
                }
              }
            })
            if (subresultIds.length > 0) {
              let subresultIdsString = JSON.stringify(subresultIds);
              subresultIdsString = subresultIdsString.replace(/"answerId"/g, 'answerId');
              subresultIdsString = subresultIdsString.replace(/"answerText"/g, 'answerText');
              bodyParam = bodyParam.concat('questionAnswers: ' + subresultIdsString);
            }
          }

          let templateContentOverride = '<templateContentOverride>';

          if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(params[0]).data.uiTemplateContentOverride)) {
            const eMailArray = [];
            const uiTemplateContentOverride: Dictionary<string> = this.applicationInfoService.entities.Item(params[0]).data.uiTemplateContentOverride;
            uiTemplateContentOverride.impArray.items.forEach(item => {
              let content = uiTemplateContentOverride.Item(item);
              content = content.replace(/\r\n/g, '<br>');
              content = content.replace(/\n/g, '<br>');
              eMailArray.push({ key: item, content: content });
            })
            templateContentOverride = templateContentOverride.replace('<templateContentOverride>', 'templateContentOverride: <templateContentOverrideContent>');
            templateContentOverride = templateContentOverride.replace('<templateContentOverrideContent>', JSON.stringify(eMailArray));
            templateContentOverride = templateContentOverride.replace(/"key"/g, 'key');
            templateContentOverride = templateContentOverride.replace(/"content"/g, 'content');
          } else {
            templateContentOverride = templateContentOverride.replace('<templateContentOverride>', '');
          }


          if (this.commonService.isNullOrUndefined(actionData.resultId)) {
            this.messagingService.showDefaultWarning('', 'CreateSingleAction.Message.Body');
            launchInternalMethodReject();
          } else {
            this.loaderService.display(true);
            let externalDatasourceId = 428;
            let finalMessage = 'CreateSingleAction.Message.SingleActionCreated'
            if (!this.commonService.isNullOrUndefined(actionData.id) && actionData.id != -1) {
              externalDatasourceId = 418;
              finalMessage = 'CreateSingleAction.Message.SingleActionUpdated';
              const resultObject = this.applicationInfoService.results.toArray().find(result => result.id == actionData.resultId);
              if (resultObject) {
                bodyParam = bodyParam.concat(' leadStatusId: ', resultObject.leadStatusId);
              }
            }
            this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [bodyParam, templateContentOverride]).then(result => {
              this.loaderService.display(false);
              this.messagingService.showDefaultSuccess('', finalMessage);
              this.eventService.updateCombinedActivityList.emit(null);
              this.eventService.updateMicroCampaignViewEvent.emit(null);
              launchInternalMethodResolve(result);
            }).catch(error => {
              console.error('CreateSingleAction', error);
              this.loaderService.display(false);
              launchInternalMethodReject(error);
            });
          }
          return;

        case InternalMethodType.LoadConversionContact:
          this.applicationInfoService.currentTask = null;
          this.applicationInfoService.currentAccount = null;
          this.applicationInfoService.currentContact = null;
          this.entityService.clearEntityData(this.applicationInfoService.contactEntityId);
          this.entityService.clearEntityData(this.applicationInfoService.taskEntityId);
          this.entityService.clearEntityData(this.applicationInfoService.accountEntityId);
          this.launchInternalMethod('changepagecontent', ['jjsection', 'account']);
          this.applicationInfoService.accountLocked = false;
          this.projectService.getCurrentContact(params[0])
          .then(contact => {
            if (!this.commonService.isNullOrUndefined(contact.accountId)) {
              this.projectService.getCurrentAccount(contact.accountId)
              .then(() => {
                launchInternalMethodResolve(null);
              });
            } else {
              launchInternalMethodResolve(null);
            }
          })
          .catch(() => {
          });
          return true;

        case InternalMethodType.UpdateBookingPreview:
          launchInternalMethodResolve(this.eventService.updateBookingPreview(params[0]));
          return;

        case InternalMethodType.CallInternalMethod:
          this.eventService.callInternalMethod(params);
          launchInternalMethodResolve(null);
          return;

        case InternalMethodType.CheckIfTaskCanBeLoaded:
            const allowedCampaign = this.applicationInfoService.availableUserGroups.filter(
              x => x.id === this.applicationInfoService.currentUserGroup)[0].campaignId;
            const taskCampaignId = params[0].campaignId;
            if ((Number(taskCampaignId) === Number(allowedCampaign)) || allowedCampaign === null) {
              launchInternalMethodResolve(null);
            } else {
              this.messagingService.showDefaultWarning(
                this.axivasTranslateService.getTranslationTextForToken('CheckIfTaskCanBeLoaded.Message.Header'),
                this.axivasTranslateService.getTranslationTextForToken('CheckIfTaskCanBeLoaded.Message.CantBeLoaded'),
              );
              launchInternalMethodReject();
            }
            return;

        case InternalMethodType.UpdateMiscSettings:
          this.applicationInfoService.miscSettings[params[0]] = params[1];
          launchInternalMethodResolve(null);
          return;

        case InternalMethodType.SetDeleteFlag:
            this.projectService.setDeleteFlag(params);
            launchInternalMethodResolve(null);
            return;

        case InternalMethodType.RollbackAuditValue:
          this.externaldatasourceService.executeExternalDataSource(355, [params[0].id])
          .then(() => {
            launchInternalMethodResolve(null);
            const memberName = this.applicationInfoService.entityMember.Item(params[0].entityMemberId).defaultName;
            this.applicationInfoService.entities.Item(params[0].entityId).data[memberName] = params[0].oldValueText;
            this.applicationInfoService.entitiesorig.Item(params[0].entityId).data[memberName] = params[0].oldValueText;
            this.eventService.updateEntityEvent.emit(new JJEvent(params[0].entityId, []));
            this.messagingService.showDefaultSuccess('', ''.concat('Wert wurde zurückgesetzt: ', params[0].oldValueText));
          })
          .catch(error => {
            launchInternalMethodReject(error);
          });
          return;

        case InternalMethodType.BookTaskAndStayInAccount:
          this.bookTask(true).then(() => {
            launchInternalMethodResolve(null);
          }).catch(error => {
            launchInternalMethodReject(error);
          });
          return true;

        case InternalMethodType.BookTask:
          this.bookTask(false).then(() => {
            launchInternalMethodResolve(null);
          }).catch(error => {
            launchInternalMethodReject(null);
          });
          return;

        case InternalMethodType.StartWorking:
            this.projectService.getNextTask()
            .then(getNextTaskResult => {
              launchInternalMethodResolve(null);
              if (getNextTaskResult.data.nextFollowUp) {
                this.launchInternalMethod('showtaskbytaskid', [getNextTaskResult.data.nextFollowUp.id]);
              }
            })
            .catch(error => { launchInternalMethodReject(error); });
          return;

        case InternalMethodType.NextTask:
          this.projectService.saveCurrentTask()
            .then(() => {
              this.loaderService.display(true);
              this.projectService.getNextTask()
              .then(getNextTaskResult => {
                this.loaderService.display(false);
                // this.launchInternalMethod('showtask', [getNextTaskResult.data.nextFollowUp]);
                launchInternalMethodResolve(null);
              })
              .catch(error => {
                // console.error('getNextTask error', error);
                this.loaderService.display(false);
                launchInternalMethodReject(error);
              });
            })
            .catch(error => { launchInternalMethodReject(error); });
          return;

        case InternalMethodType.UpdateControlContent:
          launchInternalMethodResolve(this.eventService.updateControlContent(params[0]));
          return;

        case InternalMethodType.CreateContactAndAddToTask:
          this.loaderService.display(true);
          this.entityService.saveEntityToServer(params[0])
          .then(result => {
            this.loaderService.display(false);
            this.applicationInfoService.currentTask.contactId = result.data.createContactMutation.id;
            this.applicationInfoService.entities.Item(params[0]).data.id = result.data.createContactMutation.id;
            this.applicationInfoService.currentContact =
              JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(params[0]).data));
            this.applicationInfoService.currentContact.id = result.data.createContactMutation.id;
            this.applicationInfoService.entities.Item(this.applicationInfoService.contactEntityId).data =
              JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(params[0]).data));
            this.applicationInfoService.entitiesorig.Item(this.applicationInfoService.contactEntityId).data =
              JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(this.applicationInfoService.contactEntityId).data));
            this.updateTaskFormControls();
            this.eventService.closeJjPopup();
            launchInternalMethodResolve(null);
          })
          .catch(error => {
            this.loaderService.display(false);
            launchInternalMethodReject(error);
          });
          return;

        case InternalMethodType.ShowTaskByTaskId:
          this.loaderService.display(true);
          this.applicationInfoService.currentTask = { id: params[0]};
          this.projectService.getCurrentTask()
            .then(getCurrentTaskResult => {
              this.loaderService.display(false);
              this.showTaskDetailPanel();
              this.eventService.closeJjPopup();
              this.eventService.updateQuestionaireDataEvent.emit();
              launchInternalMethodResolve(getCurrentTaskResult);
            })
            .catch(error => {
              this.loaderService.display(false);
              launchInternalMethodReject(error);
            });
          return;

        case InternalMethodType.ShowTask:
          this.loaderService.display(true);
          this.projectService.clearTaskData();
          this.applicationInfoService.currentTask = params[0];
          this.projectService.getCurrentTask()
          .then(() => {
            this.launchInternalMethod('changepagecontent', ['jjsection', 'account']);
            this.eventService.showNewTask(params[0]);
            this.showTaskDetailPanel();
            this.eventService.closeJjPopup();
            this.loaderService.display(false);
            launchInternalMethodResolve(null);
          })
          .catch(error => {
            this.loaderService.display(false);
            launchInternalMethodReject(error);
          });
          return;

        case InternalMethodType.ChangeLanguage:
          this.settingsService.setUserSetting(Setting.LanguageIdentifier, params[0].toLowerCase(), false);
          // this.axivasTranslateService.getTranslationIds(params[0].toLowerCase());

          setTimeout(() => this.eventService.languageUpdated(), 1000);
          launchInternalMethodResolve(null);
          return true;

        case InternalMethodType.PrepareTaskForShowing:
          let workflowId = params[1].workflowId;
          this.applicationInfoService.entities.Item(params[0]).data = new Object();
          if (params[2] == 'true') {
            this.applicationInfoService.entities.Item(params[0]).data = JSON.parse(JSON.stringify(params[1]));
          }
          if (this.commonService.isNullOrUndefined(workflowId)) {
            workflowId = this.applicationInfoService.steps.Item(params[1].stepId.toString()).workflowId;
          }          
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'campaignId', params[1].campaignId]);
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'eventId', params[1].eventId]);
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'contactId', params[1].contactId]);
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'stepId', params[1].stepId]);
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'workflowId', workflowId]);
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'id', params[1].id]);
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'notes', params[1].notes]);
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'subject', params[1].subject]);
          this.launchInternalMethod(InternalMethodType.UpdateEntityValue, [params[0], 'followUpUserId', params[1].followUpUserId]);
          launchInternalMethodResolve(null);
          return true;

        case InternalMethodType.NavigateTo:
          launchInternalMethodResolve(this.router.navigate([this.applicationInfoService.mainFrameName + '/' + params[0]]));
          return;

        case InternalMethodType.ChangePageContent:
          let currentRoute = this.router.url.split('?')[0];
          currentRoute = currentRoute.split('/')[ currentRoute.split('/').length-1];
          if (currentRoute.toLocaleLowerCase() == params[1].toLowerCase()) {
            launchInternalMethodResolve(null);
            return;
          }
          this.trackingService.trackAPIPageView(document.location, environment.serverUrl + '/' + params[1]);
          this.applicationInfoService.breadCrumbText = '';
          this.applicationInfoService.miscSettings['navigatedSection'] = '';
          this.applicationInfoService.resetAdditionalMenuBar();
          localStorage.removeItem('containerViewId');
          this.commonService.clearSessionStorageItem('LastNavigationContainerId');
          this.commonService.setSessionStorageItem('LastNavigation', params[1]);
          const url = '/' + params[1];
          launchInternalMethodResolve(this.router.navigate([url]));
          this.eventService.customEvent.emit({ id: 'pageContentChanged' });
          return;

        case InternalMethodType.ChangePageContentToContainer:
            this.trackingService.trackAPIPageView(document.location, environment.serverUrl + '/containerview');
            this.applicationInfoService.breadCrumbText = '';
            this.applicationInfoService.miscSettings['navigatedSection'] = '';
            this.applicationInfoService.resetAdditionalMenuBar();
            this.commonService.clearSessionStorageItem('LastNavigation');
            this.commonService.setSessionStorageItem('LastNavigationContainerId', params[1]);
            localStorage.setItem('containerViewId', params[0]);
            // this.applicationInfoService.miscSettings['pagecontentcontainerid'] = params[0];
            launchInternalMethodResolve(this.router.navigate(['/containerview']));
            this.eventService.customEvent.emit({ id: 'pageContentChanged' });
            this.eventService.changeContainerViewContent.emit();
            return;

        case InternalMethodType.ChangeContainerContent:
          launchInternalMethodResolve(this.eventService.loadNewContainerContent(params[0], params[1]));
          return;

        case InternalMethodType.ShowPopup:
          this.eventService.showJjPopup(params[0], params[1], 70);
          return;

        case InternalMethodType.ShowJJPopup:
          if (params[3]) {
            params[3] = JSON.parse(params[3]);
          }
          this.eventService.showJjPopup(params[0], params[1], params[2], params[3]);
          return;

        case InternalMethodType.ClosePopup:
          this.eventService.closePopup();
          return true;

        case InternalMethodType.ShowSoftphonePopup:
          this.eventService.softphonePopup(params[0]);
          return;

        case InternalMethodType.CopyValuesFromEntityToEntity:
          this.entityService.copyValuesFromEntityToEntity(params[0]);
          return;

        case InternalMethodType.CloseSoftphonePopup:
          this.eventService.closeSoftPhonePopup();
          return true;

        case InternalMethodType.UpdateTaskRelevantInformation:
          console.error('InternalMethodType.UpdateTaskRelevantInformation');
          this.projectService.updateTaskRelevantInformation();
          break;

        case InternalMethodType.LoadFirstTaskOfContact:
          this.externaldatasourceService.executeExternalDataSource(280, [params[1]])
          .then(queryResult => {
            if (queryResult.openTasks.length > 0) {
              this.launchInternalMethod(InternalMethodType.ShowTaskByTaskId, [queryResult.openTasks[0].id]);
            } else {
              this.launchInternalMethod(InternalMethodType.UpdateEntity, ['5', params[1]])
              .then(() => {
                this.launchInternalMethod(InternalMethodType.ShowJJPopup, ['Contact.Details.ContactDetailsHeader', '6171', '50']);
              });
            }
          });
          launchInternalMethodResolve(null);
          break;

        case InternalMethodType.DownloadExternalDataSourceResult:
          this.externaldatasourceService.executeExternalDataSource(params[0], [params[1]])
          .then(result => {
            this.commonService.downloadFile(result, params[2], params[3].concat('.', params[2]))
            launchInternalMethodResolve(result);
          })
          .catch(error => {
            launchInternalMethodReject(error);
          })
          break;

        case InternalMethodType.ShowEventWizard:
          this.wizardService.showWizard(WizardMode.EventAssignment, params[0])
          launchInternalMethodResolve(null);
          break;

        case InternalMethodType.EditConversion:
          this.applicationInfoService.miscSettings['conversion-editor-conversion'] = {
            eventId: params[0]['_462_eventid'],
            conversionAssetName: params[0]['_462_eventid'],
            conversionUrl: params[0]['_462_url'],
            date: params[0]['_462_date'],
            contact: {
              firstName: params[0]['_4_firstname'],
              lastName: params[0]['_4_lastname'],
            }
          };
          this.eventService.showJjPopup('ConversionEditor.Label.Header', 'conversion-editor', '80');
          break;
        
        case InternalMethodType.AssignAccountToProspect:
          console.warn('AssignAccountToProspect', params[0]);
          this.confirmationService.confirm({
            message: this.axivasTranslateService.getTranslationTextForToken('Prospect.Label.AddAccountToProspectMessage'),
            header: this.axivasTranslateService.getTranslationTextForToken('Prospect.Label.AddAccountToProspectHeader'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.loaderService.display(true);
              this.externaldatasourceService.executeExternalDataSource(291, [this.commonService.getModifyArrayBody(
                { 
                  id: this.applicationInfoService.miscSettings['prospectToConvertAttach'].id,
                  accountId: params[0].id,
                  projectId: this.applicationInfoService.projectID
                }
              , [])]).then(() => {
                this.loaderService.display(false);
                this.eventService.customEvent.emit({
                  id: 'updateEntityValueConnectedProspect',
                  value: {
                    id: this.applicationInfoService.miscSettings['prospectToConvertAttach'].id,
                    accountId: params[0].id,
                    street: params[0]['_1_street'],
                    zipcode: params[0]['_1_zipcode'],
                    city: params[0]['_1_city'],
                    name1: params[0]['_1_name1']
                  }
                });
                launchInternalMethodResolve(null);
                this.eventService.closeJjPopup();            
              }).catch(error => { 
                launchInternalMethodReject(error);
                this.loaderService.display(false);
              });   
            },
            reject: () => {
              launchInternalMethodReject();
            }
          });        
          break;
      }
    });
  }

  showTaskDetailPanel() {
    // console.warn('showTaskDetailPanel');
    this.launchInternalMethod('changecontainercontent', [
      this.applicationInfoService.taskDetailsContainerId.toString(),
      this.applicationInfoService.taskDetailsOverviewId.toString()
    ]);
  }

  finallyBookTask(stayInAccount = false): Promise<any> {
    return new Promise((finallyBookTaskResolve, finallyBookTaskReject) => {
      if (stayInAccount) {
        this.projectService.bookTask()
        .then(() => {
          this.entityService.clearEntityData(this.applicationInfoService.contactEntityId);
          this.entityService.clearEntityData(this.applicationInfoService.taskEntityId);
          this.applicationInfoService.currentContact = null;
          this.applicationInfoService.currentTask = null;
          this.eventService.updateControlAttributes();
          this.updateTaskFormControls();
          finallyBookTaskResolve(null);
        })
        .catch(bookTaskError => { finallyBookTaskReject(bookTaskError); });
      } else {
        this.projectService.bookTask()
        .then(bookTaskResult => {
          this.projectService.getNextTask()
          .then(getNextTaskResult => {
            this.updateTaskFormControls();
            this.eventService.customEvent.emit('ShowAccountData');
            // this.projectService.unlockAccount();
            if (getNextTaskResult === null) {
              this.projectService.clearTaskInfo();
            } else {
              this.showTaskDetailPanel();
              // this.launchInternalMethod(InternalMethodType.ShowTaskByTaskId, [getNextTaskResult.data.nextFollowUp.id]);
            }
          });
          finallyBookTaskResolve(bookTaskResult);
        })
        .catch(error => { finallyBookTaskReject(error); });
      }
    });
  }

  public launchInternalMethods(control: any) {
    //console.log('launchInternalMethods');
    control.methods.forEach(item => {
      const params: any[] = [];
      item.parameters.forEach(param => {
        params.push(param.value);
      });
      this.launchInternalMethod(item.method, params, item.conditions);
    });
  }

  isDirtyCheck(): Promise<any> {
    return new Promise((isDirtyCheckResolve, isDirtyCheckReject) => {
      if (this.applicationInfoService.isDirty) {
        this.confirmationService.confirm({
          message: this.axivasTranslateService.getTranslationTextForToken('Message.IsDirty.Question'),
          header: this.axivasTranslateService.getTranslationTextForToken('Message.IsDirty.Header'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            isDirtyCheckResolve(null);
          },
          reject: () => {
            reject();
          }
        });
      } else {
        isDirtyCheckReject();
      }
    });
  }

  GetMethod(methodID: string): Promise<any> {
    // console.warn('methodID', methodID, this.graphqlqueryService.methodServiceSingleMethodQuery
    // .replace('{methodID}', methodID));
    return new Promise((getMethodResolve, getMethodReject) => {
      if (this.applicationInfoService.methods.ContainsKey(methodID)) {
        getMethodResolve(this.applicationInfoService.methods.Item(methodID));
      } else {
        this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.methodServiceSingleMethodQuery
          .replace('{methodID}', methodID))
          .then(data => {
            const method = data.data.method;
            // sorting params
            if (method.paramers != null) {
              method.parameters.sort((a, b) => a.order < b.order ? -1 : 1);
            }
            this.applicationInfoService.methods.Add(methodID, method);
            getMethodResolve(this.applicationInfoService.methods.Item(methodID));
          })
          .catch(error => getMethodReject(error));
      }
    });
  }

  CallMethodStack(controlData: any, methods: any[]) {
    // console.warn('CallMethodStack', controlData, methods);
    methods.forEach(method => {
      // console.log('CallMethodStack', method);
      this.GetMethod(method.id)
      .then(methodData => {
        // console.log('GetMethod then', method);
        this.launchInternalMethod(methodData.method, this.GetMethodParameter(methodData.parameters, controlData), method.conditions)
          .then(() => {
            if (methodData.childs == null) { methodData.childs = []; }
            if (methodData.childs.length > 0) {
              this.CallMethodStack(controlData, method.childs);
            }
          })
          .catch(error => {
            console.warn('CallMethodStack', error);
          });
      })
      .catch(error => console.error(error));
    });
  }

  callMethod(type: string, controlDefinition: any, controlData: any) {
    const methodArray: any[] = [];
    controlDefinition.methods.forEach(element => {
      let params: any[] = [];
      const elementType: string = element.type;
      if (elementType.toLowerCase() === type.toLowerCase()) {
        params = this.GetMethodParameter(element.parameters, controlData);
        methodArray.push(element, params);
        if (element.parentControlMethodId == null) {
          this.launchInternalMethod(element.method, params, element.conditions)
          .then(() => {
            if (element.childs) {
              if (element.childs.length > 0) {
                this.CallMethodStack(controlData, element.childs);
              }
            }
          })
          .catch(error => {
            if (this.commonService.isNullOrUndefined(error)) {
              error = 'undefined';
            }
            // this.messagingService.showDefaultError('CallMethod', error);
            console.log('callMethod', 'catch', error, element.method, params, element.conditions);
          })
        }
      }
    });
  }

  GetMethodParameter(parameters: any, controlData: any): any[] {
    // console.warn('GetMethodParameter', parameters, controlData);
    const params: any[] = [];
    let toArray = '';
    if (parameters == null)    { return params; }
    parameters.sort((a, b) => a.order < b.order ? -1 : 1);
    parameters.forEach(parameter => {
      // console.warn('GetMethodParameter parameter', parameter, params);
      switch (parameter.type) {
        case VariableModifyingMode.ParameterValue:
          params.push(parameter.value);
          break;
        case VariableModifyingMode.ControlData:
          params.push(controlData);
          break;
        case VariableModifyingMode.ValueFromControlDataParameter:
          params.push(controlData[parameter.value]);
          break;
        case VariableModifyingMode.ValueFromControlSelection:
          toArray = parameter.value.split(';');
          params.push(this.applicationInfoService.controlValues.Item(toArray[0])[toArray[1]]);
          break;
        case VariableModifyingMode.ValueFromEntity:
          toArray = parameter.value.split(';');
          params.push(this.applicationInfoService.entities.Item(toArray[0]).data[toArray[1]]);
          break;
        case VariableModifyingMode.Null:
          params.push(null);
          break;
        case VariableModifyingMode.CompactListFilter:
          params.push(this.applicationInfoService.dataFilterFilteredItemArray[Number(parameter.value)]);
          break;
        case VariableModifyingMode.UserInformation:
          params.push(this.applicationInfoService.user[parameter.value]);
          break;
        case VariableModifyingMode.MiscSettings:
          if (this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings[parameter.value])) {
            params.push(null);
          } else {
            params.push(this.applicationInfoService.miscSettings[parameter.value]);
          }
          break;
        case VariableModifyingMode.CurrentDate:
            params.push(new Date(Date.now()));
            break;
        case VariableModifyingMode.CampaignModeId:
            params.push(this.commonService.getCampaignModeCampaignId());
            break;
        case VariableModifyingMode.CurrentAccountId:
            if (this.applicationInfoService.currentAccount) {
              params.push(this.applicationInfoService.currentAccount.id);
            } else {
              params.push(null);
            }
            break;
        case VariableModifyingMode.CurrentContactId:
            if (this.applicationInfoService.currentContact) {
              params.push(this.applicationInfoService.currentContact.id);
            } else {
              params.push(null);
            }
            break;

        default:
          params.push(parameter.value);
          break;
      }
    });
    return params;
  }

  callTranslationPopup(item: any, translationToken: any, target: any, source) {
    if (this.commonService.isNullOrUndefined(translationToken)) {
      translationToken = null;
    }
    this.applicationInfoService.translationPopupCallingSource = source;
    this.applicationInfoService.translationPopupTarget = target;
    this.applicationInfoService.translationPopupToken = translationToken;
    this.applicationInfoService.translationPopupCallingItem = item;
    this.eventService.showJjPopup('TranslationTokenPopup.Label.PopupHeader', 'translation-token-popup', 50, true);
  }

  loadQMData(qmEntityId, task): Promise<any> {
    return new Promise((loadQMDataResolve, loadQMDataReject) => {
      this.applicationInfoService.currentTask = { id: task.id };
      this.projectService.getCurrentTask()
      .then(() => {
        this.entityService.updateEntity(qmEntityId, task.id)
        .then(() => {
          this.applicationInfoService.accountPopupDataId = task.id;
          this.eventService.customEvent.emit({ id: 'qmLoaded' });
          loadQMDataResolve(null);
        })
        .catch(error => { loadQMDataReject(error); });
      })
      .catch(error => { loadQMDataReject(error); });
    });
  }

  updateTaskFormControls() {
    const controls = this.applicationInfoService.applicationSettings['controlsToUpdateForNewTask'].split(';');
    controls.forEach(control => {
      this.launchInternalMethod(InternalMethodType.UpdateControlContent, [control]);
    });
  }

  bookTask(stayInAccount = false): Promise<any> {
    return new Promise((bookTaskResolve, bookTaskReject) => {
      if (this.projectService.checkIfQuestionsHaveRequiredAnswers(
        this.entityService.getEntityValue(this.applicationInfoService.taskEntityId, 'resultId', 1),
        this.applicationInfoService.taskEntityId) === false) {
        this.messagingService.showDefaultWarning(
          this.axivasTranslateService.getTranslationTextForToken('BookTask.Message.Header'),
          this.axivasTranslateService.getTranslationTextForToken('BookTask.Message.QuestionAnswerRequired'),
        );
        bookTaskReject('questionAnswersRequired');
        return;
      }

      if (!this.projectService.checkOpportunityRequirement(this.entityService.getEntityValue(
        this.applicationInfoService.taskEntityId, 'resultId', 1))) {
          this.applicationInfoService.miscSettings['createNewForResult'] = 'true';
          bookTaskReject('prereqs failed');
          this.launchInternalMethod(InternalMethodType.ShowJJPopup, ['opportunity', 'opportunity', 80]);
          return;
        }

      this.projectService.saveCurrentTask()
      .then(() => {
        if (this.projectService.checkIfTaskResultIsOptInOptOut(
          this.entityService.getEntityValue(this.applicationInfoService.taskEntityId, 'resultId', 1), true)) {
            this.launchInternalMethod(InternalMethodType.ShowPopup, [
              this.axivasTranslateService.getTranslationTextForToken('OptInOptOut.Label.OptInOptOut'),
              'optinoptout'
            ]);
        } else {
          if (this.entityService.getEntityValue(this.applicationInfoService.taskEntityId, 'resultId', 1) === null) {
            this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
              this.axivasTranslateService.getTranslationTextForToken('Task.Message.SuccessfullSaved'), false);
              bookTaskResolve(null);
          } else {
            this.finallyBookTask(stayInAccount)
            .then(() => { bookTaskResolve(null); })
            .catch(finallyBookTaskError => { bookTaskReject(finallyBookTaskError); });
          }
        }
      }).catch(error => {
        bookTaskReject(error);
      });
    });
  }

  createNewTasks(newTask, closePopup = false) {
    this.loaderService.display(true);
    if (this.commonService.isNullOrUndefined(newTask.followUpUserId)) {
      return;
    }
    let restraints = [];
    restraints.push('workflowId');
    restraints.push('level');
    restraints.push('id');
    restraints.push('target');
    restraints.push('targetArray');
    restraints.push('assignCp');
    restraints.push('accountId');
    restraints.push('contactId');
    restraints.push('uiFollowUpTask');
    restraints.push('uiFollowUpDate');
    restraints.push('resultId');
    restraints.push('notes');
    restraints.push('subject');
    restraints.push('uiNextFollowUpDate');
    restraints.push('isFollowUpAppointment');
    restraints.push('subResultsadditionalText');
    if (this.commonService.isNullOrUndefined(newTask.projectId)) {
      newTask.projectId = Number(this.applicationInfoService.projectID);
    }
    if (newTask.level === '273') {
      restraints.push('contactIds');
      newTask.accountIds = [newTask.targetArray];
    } else {
      restraints.push('accountIds');
      newTask.contactIds = [newTask.targetArray];
    }

    // let date = new Date(newTask.followUpDate);
    // let dateString = formatDate(Date.now(),'yyyy-MM-ddTHH:mm:ss', 'de-DE');
    // console.warn(date, dateString);
    // newTask.followUpDate = dateString;

    this.externaldatasourceService.executeExternalDataSource(newTask.level, [
      this.commonService.getModifyArrayBody(newTask, restraints, [], null, true),
    ])
    .then(result => {
      this.messagingService.showDefaultSuccess('', 'NewTask.Label.TasksCreated');
      this.eventService.updateMicroCampaignViewEvent.emit();
      if (closePopup) {
        this.eventService.closeJjPopup();
      }
      this.loaderService.display(false);
    })
    .catch(error => {
      this.loaderService.display(false);
      console.error(error);
    });
  }

  createNewTask(newTask) {
    this.loaderService.display(true);
    if (this.commonService.isNullOrUndefined(newTask.followUpUserId)) {
      return;
    }
    this.externaldatasourceService.executeExternalDataSource(270, [
      this.commonService.getModifyArrayBody(newTask, ['workflowId', 'level', 'target',
        'targetArray', 'assignCp', 'accountIds', 'contactIds', 'projectId'])
    ])
    .then(result => {
      this.loaderService.display(false);
      this.launchInternalMethod(InternalMethodType.ShowTaskByTaskId, [result.id])
      .then(() => {
        this.messagingService.showDefaultSuccess('', 'NewTask.Label.TaskCreated');
        this.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
      });
    })
    .catch(error => {
      this.loaderService.display(false);
      console.error(error);
    });
  }

  bookLeadstatusChange(accountId, contactId, leadStateId) {
    let campaignId = this.applicationInfoService.campaingnModeId;
    if (this.applicationInfoService.campaingnModeId == null) {
      campaignId = this.applicationInfoService.campaigns.toArray()[0].id;
    }
    this.externaldatasourceService.executeExternalDataSource(270, [
      this.commonService.getModifyArrayBody({
        stepId: 1123,
        resultId: 10705,
        accountId: accountId,
        contactId: contactId,
        leadStatusId: leadStateId,
        followUpUserId: this.applicationInfoService.userID,
        followUpDate: new Date(Date.now()),
        isFollowUpAppointment: false,
        campaignId: campaignId
      } , [])
    ]);
  }

  bookOpportunitystatusChange(accountId, contactId, bookingNotes) {
    let campaignId = this.applicationInfoService.campaingnModeId;

    if (this.applicationInfoService.campaingnModeId == null) {
      campaignId = this.applicationInfoService.campaigns.toArray()[0].id;
    }
    this.externaldatasourceService.executeExternalDataSource(270, [
      this.commonService.getModifyArrayBody({
        stepId: 1123,
        resultId: 13303,
        accountId: accountId,
        contactId: contactId,
        followUpUserId: this.applicationInfoService.userID,
        followUpDate: new Date(Date.now()),
        isFollowUpAppointment: false,
        campaignId: campaignId,
        notes: bookingNotes
      } , [])
    ]);
  }
}

<div class="everleadToastContainer">
    <div class="everleadToastContainer__inner">
        <div class="everleadToaster" 
            *ngFor="let toast of toastArray; let i = index" 
            [ngStyle]="getStyleForToast(toast)"
            [@toastAnimation]
            (mouseenter)="activateToast(toast)"
            (mouseleave)="deactivateToast(toast)">
            <div class="everleadToaster__nav">
                <button (click)="deleteToast(toast)" >
                    <app-jj-icons type="x" color="#333333" [width]="16" [height]="16" [pointer]="true"></app-jj-icons>
                </button>  
            </div>
            <div class="everleadToaster__inner">
                <div class="everleadToaster__messageContainer">
                    <app-jj-icons [type]="getIcon(toast.severity)" [width]="20" [height]="20" [color]="toast.color " [pointer]="true"></app-jj-icons>
                    <div class="everleadToaster__messageContainer__placeholder">
                        <span class="everleadToaster__messageContainer__placeholder__header">{{ toast.summary}}</span>
                        <span class="everleadToaster__messageContainer__placeholder__text" [tippy]="toast.detail && toast.detail?.length >= 90 ? toast.detail : unddefined">{{ getEllipsis(toast.detail) }}</span>

                        <!-- todo for actions -> links or buttons -->
                        <div class="everleadToaster__messageContainer__placeholder__action" *ngIf="toast.action">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



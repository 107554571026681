import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-userhierarchy-designer',
  templateUrl: './userhierarchy-designer.component.html',
  styleUrl: './userhierarchy-designer.component.scss'
})

export class UserhierarchyDesignerComponent implements OnInit {
  userHierarchyGroups = [
    { id: 1, defaultName: 'Testgruppe 1', userId: 2 }
  ]
  users = [];
  groupConnections = [];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    public dragdropService: DragdropService
  ) {}

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.externaldatasourceService.executeExternalDataSource(14, []).then(userList => this.users = userList);
  }

  createGroup() {
    this.userHierarchyGroups.push({ 
      id: this.userHierarchyGroups.length + 1, 
      defaultName: 'Testgruppe '.concat((this.userHierarchyGroups.length + 1).toString()), 
      userId: 2 
    });
  }
}
